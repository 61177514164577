export const minWidth = {
  xs: 0,
  sm: 540,
  md: 820,
  lg: 1060,
  xl: 1200,
}

export const maxWidth = {
  xs: minWidth.sm - 1,
  sm: minWidth.md - 1,
  md: minWidth.lg - 1,
  lg: minWidth.xl - 1,
  xl: 9000,
}

export enum APP_TYPE {
  IOS_PWA = 'pwa_ios',
  ANDROID_PWA = 'pwa_android',
  IOS_NATIVE = 'ios',
  ANDROID_TWA = 'android',
  EMULATION = 'emulation',
  BROWSER_MOBILE = 'browser_mobile',
  BROWSER_DESKTOP = 'browser_desktop',
}

export enum OS {
  MAC_OS = 'MAC_OS',
  IOS = 'IOS',
  WINDOWS = 'WINDOWS',
  ANDROID = 'ANDROID',
  LINUX = 'LINUX',
}

export enum EFeatures {
  Cookies = 'cookies',
  Fullscreen = 'fullscreen',
  History = 'history',
  NotifAPI = 'notification',
  PostMessage = 'postmessage',
  SW = 'serviceworker',
  localStorage = 'localstorage',
  sessionStorage = 'sessionstorage',
}
