import React, { ReactElement, useEffect, useState } from 'react'

import { Translate } from '@hozana/intl/Translate'
import { useRouter } from '@hozana/router'

import { Button, TButtonProps } from 'elements/button/Button'
import { Cell } from 'elements/layout/Cell'
import { Div, TDivProps } from 'elements/layout/Div'
import { Row } from 'elements/layout/Row'
import { StretchCell } from 'elements/layout/StretchCell'
import { Icon, TIconProps } from 'elements/media/Icon'
import { Transition } from 'elements/motion/Transition'

import { ICON } from 'config/icons'

import { ACCORDION_SECTION_PARAM_NAME } from 'modules/user/constants'

export type TAccordionButtonProps = {
  isOpen?: boolean
  icon?: TIconProps['name']
} & TDivProps &
  TButtonProps

export const AccordionButton: React.FC<TAccordionButtonProps> = ({
  isOpen,
  children,
  icon,
  onClick,
  ...otherProps
}) => (
  <Div
    as={Button}
    size="big"
    type="button"
    colors="greyTransparent"
    color="darkBlue"
    borderColor="black10"
    w="100%"
    bold={false}
    m="0 0 1em 0"
    onClick={onClick}
    {...otherProps}
  >
    <Row gutter="1em" align="middleBetween">
      {icon && (
        <Cell>
          <Icon name={icon} />
        </Cell>
      )}
      <StretchCell flex="1" align="left">
        <Translate>{children}</Translate>
      </StretchCell>
      {onClick && (
        <Cell>
          <Icon name={isOpen ? ICON.CHEVRON_UP : ICON.CHEVRON_DOWN} />
        </Cell>
      )}
    </Row>
  </Div>
)

export type THeaderProps = {
  isOpen?: boolean
  render: (props: Pick<THeaderProps, 'isOpen' | 'toggle'>) => JSX.Element
  sectionId?: string
  toggle?: VoidFunction
}

export const Header: React.FC<THeaderProps> = ({ isOpen, render, toggle }) => render({ isOpen, toggle })

export type TContentProps = {
  maxHeight?: string
  scrollable?: boolean
  isOpen?: boolean
} & TDivProps

export const Content: React.FC<TContentProps> = ({
  maxHeight = '500px',
  isOpen,
  children,
  p = '0 0 20px',
  scrollable,
  ...otherProps
}) => (
  <Div align="center" {...otherProps}>
    <Transition
      initial={{ height: '0px' }}
      final={{ height: maxHeight }}
      duration={300}
      active={isOpen}
      scrollable={scrollable}
    >
      <Div p={p}>{children}</Div>
    </Transition>
  </Div>
)

export type TAccordionProps = {
  defaultSectionId?: string
  tabId?: string
  /** Wether the accordion is a common child of several tabs */
  isIncludedInTabs?: boolean
  isDefaultTab?: boolean
  children: ReactElement | ReactElement[]
}

export const Accordion: React.FC<TAccordionProps> & {
  Content: typeof Content
  Header: typeof Header
  Button: typeof AccordionButton
} = ({ defaultSectionId, isIncludedInTabs, tabId, isDefaultTab, children }) => {
  const { pathname, query, replace } = useRouter() // #router.replace

  const sectionIds = [
    ...new Set(React.Children.map<string, ReactElement>(children, (child) => child.props?.sectionId)),
  ].filter((i) => i)
  /**
   * openedSectionIndex = -1 => no sections are open
   * openedSectionIndex = 0 => 1st section is open
   * If no defaultSectionId is set, no section is open by default
   */
  const [openedSectionIndex, setOpenedSectionIndex] = useState(
    defaultSectionId ? sectionIds.indexOf(defaultSectionId) : -1,
  )

  // Ensure correct url if this accordion is related to the displayed tab
  // but the [ACCORDION_SECTION_PARAM_NAME] URL param does not match with the opened sectionId
  useEffect(() => {
    if (
      // Check if children are loaded before redirecting otherwise it creates a redirections loop
      sectionIds.length &&
      (!isIncludedInTabs || (isIncludedInTabs && (query.tab === tabId || (isDefaultTab && !query.tab)))) &&
      ((openedSectionIndex === -1 && query[ACCORDION_SECTION_PARAM_NAME] !== undefined) ||
        (openedSectionIndex !== -1 && sectionIds[openedSectionIndex] !== query[ACCORDION_SECTION_PARAM_NAME]))
    ) {
      const { [ACCORDION_SECTION_PARAM_NAME]: _previousSectionId, ...remainingQuery } = query
      replace(
        {
          pathname,
          query:
            openedSectionIndex === -1
              ? remainingQuery
              : {
                  ...remainingQuery,
                  [ACCORDION_SECTION_PARAM_NAME]: sectionIds[openedSectionIndex],
                },
        },
        undefined,
        { shallow: true },
      )
    }
  }, [isDefaultTab, isIncludedInTabs, openedSectionIndex, pathname, query, replace, sectionIds, tabId])

  const toggle = (section: number | string) => {
    const sectionIndex = typeof section === 'number' ? section : sectionIds.indexOf(section)
    setOpenedSectionIndex(openedSectionIndex !== sectionIndex ? sectionIndex : -1)
  }

  return (
    <>
      {React.Children.map(
        children,
        (child: React.ReactElement<{ isOpen: boolean; toggle: VoidFunction }>, i: number) => {
          if (!React.isValidElement(child)) {
            throw new Error('Invalid child element in Accordion')
          }

          // Find section index and id by grouping header and title. (Headers are the even numbers)
          const sectionIndex = (i % 2 ? i - 1 : i) / 2

          return React.cloneElement(child, {
            isOpen: openedSectionIndex === sectionIndex,
            toggle: () => toggle(sectionIndex),
          })
        },
      )}
    </>
  )
}

Accordion.Button = AccordionButton
Accordion.Content = Content
Accordion.Header = Header
