import React from 'react'

import { QA } from '@hozana/tests/constants'

import { TBaseButtonProps } from 'elements/button/BaseButton'
import { RoundButton, TRoundButtonProps } from 'elements/button/RoundButton'

import { ICON } from 'config/icons'

export type TCommunitiesFilterButtonProps = Pick<TRoundButtonProps, 'onClick' | 'size'> &
  Pick<TBaseButtonProps, 'color' | 'hoverColor'>

export const CommunitiesFilterButton: React.FC<TCommunitiesFilterButtonProps> = ({
  size,
  onClick,
  color,
  hoverColor,
}) => (
  <RoundButton
    color={color}
    hoverColor={hoverColor}
    icon={ICON.FILTER}
    size={size}
    data-testid={QA.COMMUNITIES_PAGE.SEARCH_FILTER_BUTTON}
    onClick={onClick}
    label="trans:community:word.filter"
    ariaLabel="trans:community:communities.filter.filter"
  />
)
