import Modernizr from 'modernizr'

import type { TIntentionWidgetLocalIntention } from 'modules/intention/types'

import type { TConcurrencyEntityType, TConcurrencyTabs } from '../form/types'

// Waiting for the 2 following commented types to be parsed correctly
type TConcurrencyKey = `conflicts:${TConcurrencyEntityType}:${number}`
type TWidgetIntentionKey = `widget-intention-${number}`

export const LOCAL_STORAGE = {
  CONCURRENCY: (entityType: TConcurrencyEntityType, entityId: number): TConcurrencyKey =>
    `conflicts:${entityType}:${entityId}`,
  // We now use local storage to store the intention id because there are issues with cookies in iframes (https://www.pivotaltracker.com/n/projects/1876797/stories/175184738)
  WIDGET_INTENTION: (widgetId: number): TWidgetIntentionKey => `widget-intention-${widgetId}`,
}
export enum SESSION_STORAGE {
  SS_TAB_ID_KEY = 'tabId',
  SS_TAB_EDITING_KEY = 'willBeEditing',
}

const storageSpecialKeys = ['length', 'clear', 'key', 'removeItem', 'getItem', 'setItem'] as const
type TStorageSpecialKeys = (typeof storageSpecialKeys)[number]

type TCustomStorage<Values extends Record<Exclude<string, TStorageSpecialKeys>, any>> = Pick<
  Storage,
  Exclude<TStorageSpecialKeys, 'setItem' | 'getItem'>
> & {
  setItem: <K extends keyof Values>(key: K, value: Values[K]) => boolean
  updateItem: <K extends TKeysMatching<Values, Record<any, any> | any[]>>(key: K, value: Partial<Values[K]>) => boolean
  getItem: <K extends keyof Values>(key: K) => Values[K] | undefined
}

/**
 * This function upgrades localStorage and sessionStorage:
 * - It stringifies/parses values in json automatically to make localStorage/sessionStorage able to store numbers, booleans, arrays, objects... any value storable in json.
 * - It checks if localStorage/sessionStorage is available and return default values if not.
 */
export const browserStorage = <Values = Record<string, any>>(
  storageName: 'localStorage' | 'sessionStorage',
): TCustomStorage<Values> => {
  const isStorageAvailable = __CLIENT__ && Modernizr[storageName.toLowerCase() as 'localstorage' | 'sessionstorage']

  return new Proxy(isStorageAvailable ? window[storageName] : ({} as Storage), {
    // Proxy the attempts to access the storage values
    get(storage, prop: string) {
      // Proxy and improve setItem method
      if (prop === 'setItem') {
        const setItem: TCustomStorage<Values>['setItem'] = (key, value) => {
          if (isStorageAvailable) {
            storage.setItem(key as string, JSON.stringify(value))
            return true
          }
          return false
        }
        return setItem
      }
      // Add an updateItem method
      if (prop === 'updateItem') {
        const updateItem: TCustomStorage<Values>['updateItem'] = (key, value: Record<any, any> | any[]) => {
          if (isStorageAvailable) {
            const prevValue = JSON.parse(storage.getItem(key as string))
            if (typeof prevValue === 'object' && typeof value === 'object') {
              if (Array.isArray(prevValue) && Array.isArray(value)) {
                storage.setItem(key as string, JSON.stringify([...prevValue, ...value]))
                return true
              } else if (!Array.isArray(prevValue) && !Array.isArray(value)) {
                storage.setItem(key as string, JSON.stringify({ ...prevValue, ...value }))
                return true
              }
            }
          }
          return false
        }
        return updateItem
      }
      // Proxy and improve getItem method
      if (prop === 'getItem') {
        const getItem: TCustomStorage<Values>['getItem'] = <K extends keyof Values>(key: keyof Values): Values[K] => {
          if (isStorageAvailable) {
            return JSON.parse(storage.getItem(key as string))
          }
          return null
        }
        return getItem
      }
      // Proxy removeItem and key methods as is
      if (prop === 'removeItem') {
        return (arg: string) => (isStorageAvailable ? storage[prop](arg) : null)
      }
      if (prop === 'key') {
        return (arg: number) => (isStorageAvailable ? storage[prop](arg) : null)
      }
      // Proxy and improve the attempt to access directly to the values stored, ex: localStorage.myProperty.
      if (isStorageAvailable) {
        try {
          return JSON.parse(storage[prop])
        } catch {
          return null
        }
      }
      return null
    },

    // Proxy the attempts to set the storage values
    set(storage, prop, value) {
      // Proxy and improve the attempt to directly set a value without passing by setItem, ex: localStorage.myProperty = myValue.
      if (typeof prop === 'string' && !storageSpecialKeys.includes(prop as TStorageSpecialKeys)) {
        storage.setItem(prop, JSON.stringify(value))
        return value
      }
      return null
    },
  }) as Storage & TCustomStorage<Values>
}

/** Upgraded sessionStorage */
export const sessionStorage = browserStorage<{
  [SESSION_STORAGE.SS_TAB_ID_KEY]: string
  [SESSION_STORAGE.SS_TAB_EDITING_KEY]: boolean
}>('sessionStorage')
/** Upgraded localStorage */
export const localStorage = browserStorage<
  Record<TConcurrencyKey, TConcurrencyTabs> & Record<TWidgetIntentionKey, TIntentionWidgetLocalIntention>
>('localStorage')
