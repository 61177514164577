import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useEventListener } from '@hozana/hooks/useEventListener'

import { minWidth } from './constants'
import { mobileDetect } from './functions/mobileDetect'

export type TScreenProps = {
  xl: boolean
  lg: boolean
  md: boolean
  sm: boolean
  xs: boolean
  isMobile: boolean
  winWidth: number
}

export const ScreenContext = React.createContext<TScreenProps>(null)

const getSizeFromWidth = (width: number) => {
  if (width >= minWidth.lg) return 'lg'
  else if (width >= minWidth.md) return 'md'
  else if (width >= minWidth.sm) return 'sm'
  else return 'xs'
}

type TScreenProviderProps = { userAgent?: string }

/**
 * Component which detects screen resizing.
 * It must be put at the top of the React app.
 */
export const ScreenProvider: React.FC<TScreenProviderProps> = ({ children, userAgent }) => {
  const isMobile = useMemo(() => (userAgent ? mobileDetect(userAgent) : false), [userAgent])
  const [winWidth, setWinWidth] = useState(
    __CLIENT__ ? window.innerWidth : isMobile ? minWidth.sm - 1 : minWidth.lg + 1,
  )
  const [size, setSize] = useState('xs')

  const updateSize = useCallback(() => {
    const newWinWidth = window.innerWidth
    setWinWidth(newWinWidth)
    setSize(getSizeFromWidth(newWinWidth))
  }, [])

  useEffect(updateSize, [updateSize])
  useEventListener('resize', updateSize)

  const screen = useMemo(
    () => ({
      xl: false,
      lg: false,
      md: false,
      sm: false,
      xs: false,
      [size]: true,
      winWidth,
      isMobile,
    }),
    [isMobile, size, winWidth],
  )

  return <ScreenContext.Provider value={screen}>{children}</ScreenContext.Provider>
}
