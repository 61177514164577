import type { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

const responsiveProps = { w: 'width', h: 'height' } as const

export type TIframeProps = HTMLAttributes<HTMLIFrameElement> & TResponsiveProps<typeof responsiveProps>

export const Iframe = styled.iframe<TIframeProps>`
  max-height: 500px; // #iframeMaxHeight
  ${responsiveStyle(responsiveProps)}
`
