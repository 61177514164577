import { decrementEntityValue, incrementEntityValue, updateEntities, updateEntity } from '@hozana/api/actions'

import type { TCommunity } from './types'

// Entities manipulation
export const updateCommunity = (id: number, data: Partial<TCommunity>) => updateEntity('community', id, data)
export const updateCommunities = (data: Record<number, Partial<TCommunity>>) => updateEntities('community', data)
export const decreaseNumMembers = (id: number) => decrementEntityValue('community', id, 'nbMembers')
export const increaseNumPublications = (id: number) => incrementEntityValue('community', id, 'nbPublications')
export const decreaseNumPublications = (id: number) => decrementEntityValue('community', id, 'nbPublications')
export const increaseNumTotalPublications = (id: number) => incrementEntityValue('community', id, 'nbTotalPublications')
export const decreaseNumTotalPublications = (id: number) => decrementEntityValue('community', id, 'nbTotalPublications')
