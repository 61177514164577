import 'regenerator-runtime/runtime'

import { isQueryPending, selectQuery } from '@hozana/api/selectors'
import { select, waitFor } from '@hozana/sagas'

import { uploadFileMutation } from './queries'

/**
 * UTILS
 */

/*
 * If a file is currently being uploaded, wait for it and return the file ID
 * If no file is being uploaded, return the passed parameter
 */
export function* runWaitForFileUpload(file: number): Generator<unknown, number> {
  const isFileBeingUploaded = yield* select((state) => isQueryPending(state, uploadFileMutation()))

  if (isFileBeingUploaded) {
    // Wait for the file to be uploaded
    yield* waitFor((state) => isQueryPending(state, uploadFileMutation()), false)

    const queryData = yield* select((state) => selectQuery(state, uploadFileMutation()))

    if (queryData.error) {
      throw new Error('upload:common.form.error.upload.failed')
    } else {
      return queryData.data.id
    }
  } else {
    return file
  }
}
