import { createRoutine } from '@hozana/routines/functions'

import type {
  TDeleteTestimonyMutationPayload,
  TEditTestimonyMutationPayload,
  TPostTestimonyMutationPayload,
} from 'modules/testimony/queries'

export const postTestimony = createRoutine<'POST_TESTIMONY', TPostTestimonyMutationPayload>('POST_TESTIMONY')
export const editTestimony = createRoutine<'EDIT_TESTIMONY', TEditTestimonyMutationPayload>('EDIT_TESTIMONY')
export const deleteTestimony = createRoutine<'DELETE_TESTIMONY', TDeleteTestimonyMutationPayload>('DELETE_TESTIMONY')
