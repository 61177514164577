import { createRoutine } from '@hozana/routines/functions'

import { CONNECT_METHOD } from 'modules/auth/constants'
import type { TFacebookConfirmPopinFormData } from 'modules/auth/popins/FacebookConfirmPopin'
import type {
  TConnectMutationData,
  TGetLoginMethodsPayload,
  TLoginMutationPayload,
  TRequestPasswordRecoveryMutationData,
  TRequestPasswordRecoveryMutationPayload,
  TResetPasswordMutationData,
  TResetPasswordMutationPayload,
} from 'modules/auth/queries'
import type {
  T2FaPayload,
  TLogEmailPayload,
  TLogoutPayload,
  TRefreshTokenPayload,
  TRefreshTokenResult,
  TSaveTokenPayload,
  TSignupFormSubmitPayload,
  TSocialLoginPayload,
} from 'modules/auth/sagas'

export const saveToken = createRoutine<'SAVE_TOKEN', TSaveTokenPayload, TConnectMutationData>('SAVE_TOKEN')
export const socialLogin = createRoutine<'SOCIAL_LOGIN', TSocialLoginPayload>('SOCIAL_LOGIN')
export const logout = createRoutine<'LOGOUT', TLogoutPayload>('LOGOUT')
export const refreshToken = createRoutine<'REFRESH_TOKEN', TRefreshTokenPayload, TRefreshTokenResult>('REFRESH_TOKEN')
export const loginFormSubmit = createRoutine<'LOGIN_FORM_SUBMIT', TLoginMutationPayload, TConnectMutationData>(
  'LOGIN_FORM_SUBMIT',
)
export const signupFormSubmit = createRoutine<'SIGNUP_FORM_SUBMIT', TSignupFormSubmitPayload, TConnectMutationData>(
  'SIGNUP_FORM_SUBMIT',
)
export const lostPwdFormSubmit = createRoutine<
  'LOST_PWD_FORM_SUBMIT',
  TRequestPasswordRecoveryMutationPayload,
  TRequestPasswordRecoveryMutationData
>('LOST_PWD_FORM_SUBMIT')
export const doubleFaFormSubmit = createRoutine<'DOUBLE_FA_FORM_SUBMIT', T2FaPayload, TConnectMutationData>(
  'DOUBLE_FA_FORM_SUBMIT',
)
export const resetPwdFormSubmit = createRoutine<
  'RESET_PASSWORD_FORM_SUBMIT',
  TResetPasswordMutationPayload,
  TResetPasswordMutationData
>('RESET_PASSWORD_FORM_SUBMIT')
export const logEmail = createRoutine<'LOG_EMAIL', TLogEmailPayload>('LOG_EMAIL')
export const getLoginMethods = createRoutine<'GET_LOGIN_METHODS', TGetLoginMethodsPayload, CONNECT_METHOD[]>(
  'GET_LOGIN_METHODS',
)
export const facebookConfirmFormSubmit = createRoutine<'FB_CONFIRM_FORM_SUBMIT', TFacebookConfirmPopinFormData>(
  'FB_CONFIRM_FORM_SUBMIT',
)
