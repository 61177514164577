import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { TDateSlashDDMMYYYY, TTime, dayjs } from '@hozana/dates'
import { formatSubmissionError } from '@hozana/form/functions'
import type { TRouter } from '@hozana/router'
import { all, put, select, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { GTM_AUTH_SOURCE } from '@hozana/tracking/constants'

import { addFlash, displaySavingIndicator } from 'app/actions'
import { QUERY_ACTION } from 'app/constants'
import { runConfirm } from 'app/sagas'
import { PAGE } from 'routes/constants'

import { runConnect } from 'modules/auth/sagas'
import { increaseNumPublications, increaseNumTotalPublications } from 'modules/community/actions'
import { COMMUNITY_PAGE_TABS } from 'modules/community/constants'
import { selectCommunity } from 'modules/community/selectors'
import { PUBLICATION_FILTERS, PUBLICATION_ORDER } from 'modules/publication/constants'
import { isPublicationPublished } from 'modules/publication/functions'
import { selectPublication } from 'modules/publication/selectors'
import type { TPublication } from 'modules/publication/types'
import { runWaitForFileUpload } from 'modules/upload/sagas'

import {
  TCreatePublicationMutationPayload,
  TEditPublicationMutationPayload,
  addPublicationFavoriteMutation,
  createPublicationMutation,
  deletePublicationFavoriteMutation,
  deletePublicationMutation,
  duplicatePublicationMutation,
  editPublicationMutation,
  getPublicationsQuery,
} from './queries'
import {
  addFavorite,
  createPublication,
  deletePublication,
  duplicatePublication,
  editPublication,
  removeFavorite,
} from './routines'

export type TCreatePublicationPayload = Pick<
  TCreatePublicationMutationPayload,
  'title' | 'image' | 'imagesToAttach'
> & {
  router: TRouter
}

function* createPublicationSaga({
  payload: { title, image, imagesToAttach = [], router },
}: TSagaProps<TCreatePublicationPayload>): Generator<unknown, undefined> {
  try {
    if (!title) {
      yield* put(displaySavingIndicator({ label: 'trans:publication:publication.title.required', error: true }))
      return undefined
    }

    const uploadedImage = yield* runWaitForFileUpload(image)

    const publicationId = yield* fetchApiSaga(
      createPublicationMutation({
        communityId: Number(router.query.communityId),
        title,
        image: uploadedImage,
        imagesToAttach,
        isDraft: true,
      }),
    )

    const newPublication = yield* select((state) => selectPublication(state, publicationId))

    router.push({
      pathname: PAGE.PUBLICATION_EDIT,
      query: { publicationId, publicationSlug: newPublication.slug },
    })

    yield* put(createPublication.success(newPublication))
  } catch (error) {
    yield* put(displaySavingIndicator({ label: 'trans:publication:draft.autosave.error', error: true }))
    yield* put(createPublication.failure(formatSubmissionError(error.message, { name: 'title' })))
  }
  return undefined
}

export type TEditPublicationPayload = TEditPublicationMutationPayload & {
  router: TRouter
  date: TDateSlashDDMMYYYY
  hour: TTime
  isScheduled: boolean
  isManualSave?: boolean
}

function* editPublicationSaga({
  payload: {
    publicationId,
    isDraft,
    title,
    image,
    content,
    date,
    hour,
    isScheduled,
    isManualSave = true,
    imagesToAttach = [],
    imagesToRemove = [],
    router,
  },
}: TSagaProps<TEditPublicationPayload>): Generator<unknown, undefined> {
  try {
    const oldPublication = yield* select((state) => selectPublication(state, publicationId))

    if (!isScheduled && !isDraft && !oldPublication.published) {
      const createConfirm = yield* runConfirm(
        'trans:publication:word.publish',
        'trans:publication:publication.create.confirm',
      )
      if (!createConfirm) {
        yield* put(editPublication.failure('cancel'))
        return undefined
      }
    }

    const scheduledTime = isScheduled && date && hour && !isDraft ? dayjs(`${date}T${hour}`).toDate() : undefined
    image = yield* runWaitForFileUpload(image)

    // Save the publication with new data
    yield* fetchApiSaga(
      editPublicationMutation({
        publicationId,
        title,
        image,
        content,
        scheduledTime,
        imagesToAttach,
        imagesToRemove,
        isDraft,
      }),
    )

    const updatedPublication = yield* select((state) => selectPublication(state, publicationId))
    const community = yield* select((state) => selectCommunity(state, updatedPublication.community))

    if (updatedPublication.published) {
      yield* put(increaseNumPublications(updatedPublication.community))
      if (!oldPublication.isDraft && !oldPublication.isScheduled) {
        yield* put(increaseNumTotalPublications(updatedPublication.community))
      }
    }

    if (!isDraft) {
      router.push(
        isPublicationPublished(updatedPublication)
          ? {
              pathname: PAGE.PUBLICATION_VIEW,
              query: {
                publicationId: updatedPublication.id,
                publicationSlug: updatedPublication.slug,
              },
              host: window.location.host,
            }
          : {
              pathname: PAGE.COMMUNITY_VIEW_ADMIN,
              query: {
                communityId: community.id,
                communitySlug: community.slug,
                tab: COMMUNITY_PAGE_TABS.SCHEDULED_AND_DRAFT,
              },
            },
      )
    }

    if (isDraft && isManualSave) {
      yield* fetchApiSaga(
        getPublicationsQuery({
          communityId: community.id,
          filter: PUBLICATION_FILTERS.UNPUBLISHED,
          order: PUBLICATION_ORDER.DRAFTS_FIRST,
        }),
      )

      router.push(
        {
          pathname: PAGE.COMMUNITY_VIEW,
          query: {
            communityId: community.id,
            communitySlug: community.slug,
            tab: COMMUNITY_PAGE_TABS.SCHEDULED_AND_DRAFT,
          },
        },
        undefined,
        { locale: community.lang, shallow: true },
      )
    }

    if (isDraft && !isManualSave) {
      yield* put(displaySavingIndicator({ label: 'trans:publication:draft.autosave.success', savedAt: new Date() }))
    } else {
      if (isDraft || oldPublication.published) {
        yield* put(addFlash({ message: 'publication:publication.edit.success' }))
      } else if (isScheduled) {
        yield* put(addFlash({ message: 'publication:publication.new.success.schedule' }))
      } else {
        yield* put(addFlash({ message: 'publication:publication.new.success.publish' }))
      }
    }

    yield* put(editPublication.success(updatedPublication))
  } catch (error) {
    if (!isManualSave) {
      yield* put(displaySavingIndicator({ label: 'trans:publication:draft.autosave.error', error: true }))
    }
    yield* put(editPublication.failure(formatSubmissionError(error.message)))
  }
  return undefined
}

export type TDuplicatePublicationPayload = {
  publication: TPublication
  router: TRouter
}

function* duplicatePublicationSaga({ payload: { publication, router } }: TSagaProps<TDuplicatePublicationPayload>) {
  try {
    const community = yield* select((state) => selectCommunity(state, publication.community))

    yield* fetchApiSaga(
      duplicatePublicationMutation({ publicationId: publication.id, communityId: publication.community }),
    )

    router.push({
      pathname: PAGE.COMMUNITY_VIEW_ADMIN,
      query: {
        communityId: community.id,
        communitySlug: community.slug,
        tab: COMMUNITY_PAGE_TABS.SCHEDULED_AND_DRAFT,
      },
    })
    router.reload()
    yield* put(duplicatePublication.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(duplicatePublication.failure(error.message))
  }
}

export type TDeletePublicationPayload = {
  publication: TPublication
  router: TRouter
}

function* deletePublicationSaga({
  payload: { publication, router },
}: TSagaProps<TDeletePublicationPayload>): Generator<unknown, undefined> {
  try {
    const deleteConfirm = yield* runConfirm(
      'trans:publication:publication.delete.delete',
      'trans:publication:publication.delete.confirm',
    )
    if (!deleteConfirm) {
      yield* put(deletePublication.failure('cancel'))
      return undefined
    }

    const community = yield* select((state) => selectCommunity(state, publication.community))

    yield* fetchApiSaga(
      deletePublicationMutation({
        publicationId: publication.id,
        communityId: publication.community,
        filter: publication.published ? PUBLICATION_FILTERS.PUBLISHED : PUBLICATION_FILTERS.UNPUBLISHED,
      }),
    )

    if (router.query.publicationId) {
      router.push(
        {
          pathname: PAGE.COMMUNITY_VIEW_ADMIN,
          query: {
            communityId: community.id,
            communitySlug: community.slug,
            tab: publication.published ? COMMUNITY_PAGE_TABS.PUBLICATIONS : COMMUNITY_PAGE_TABS.SCHEDULED_AND_DRAFT,
          },
        },
        undefined,
        { locale: community.lang, shallow: true },
      )
    }
    router.reload()
    yield* put(addFlash({ message: 'publication:publication.delete.success' }))
    yield* put(deletePublication.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(deletePublication.failure(error.message))
  }
  return undefined
}

export type TAddRemoveFavoritePayload = { id: number; slug?: string }

function* addFavoriteSaga({
  payload: { id: publicationId, slug: publicationSlug },
}: TSagaProps<TAddRemoveFavoritePayload>) {
  try {
    const isAuthenticated = yield* runConnect({
      authSource: GTM_AUTH_SOURCE.ADD_FAVORITE_PUBLICATION,
      initialTitle: 'publication:publication.add-to-favorite.auth-required',
      connectDetails: {
        redirectUrl: undefined,
        /**
         * After social login on mobile, on reload Hozana, redirect to community page with "?action=show-notifications"
         * so that the addFavoriteSaga (which has been interrupted by social connect) is retriggered to be performed
         */
        reloadUrl: {
          pathname: PAGE.PUBLICATION_VIEW,
          query: {
            publicationId,
            publicationSlug,
            action: QUERY_ACTION.ADD_FAVORITE,
          },
        },
      },
    })
    if (!isAuthenticated) {
      yield* put(addFavorite.failure('cancel'))
    } else {
      yield* fetchApiSaga(addPublicationFavoriteMutation({ publicationId }))
      yield* put(addFlash({ message: 'publication:publication.added-to-favorites' }))
      yield* put(addFavorite.success())
    }
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(addFavorite.failure(error.message))
  }
}

function* removeFavoriteSaga({ payload: { id: publicationId } }: TSagaProps<TAddRemoveFavoritePayload>) {
  try {
    yield* fetchApiSaga(deletePublicationFavoriteMutation({ publicationId }))
    yield* put(addFlash({ message: 'publication:publication.removed-from-favorites' }))
    yield* put(removeFavorite.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(removeFavorite.failure(error.message))
  }
}

/*
 * LISTENER
 */

export default function* publicationSagasListener() {
  yield* all([
    takeLatest(createPublication, createPublicationSaga),
    takeLatest(editPublication, editPublicationSaga),
    takeLatest(duplicatePublication, duplicatePublicationSaga),
    takeLatest(deletePublication, deletePublicationSaga),
    takeLatest(addFavorite, addFavoriteSaga),
    takeLatest(removeFavorite, removeFavoriteSaga),
  ])
}
