import type { ComponentType, HTMLAttributes, MouseEventHandler } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { TAlign, alignStyle } from '@hozana/styling/alignStyle'
import { mergeStyles } from '@hozana/styling/mergeStyles'
import {
  TResponsiveProps,
  TResponsiveVisibilityProps,
  mediaQuery,
  responsiveStyle,
  responsiveVisibility,
} from '@hozana/styling/responsive'

const responsiveProps = {
  m: 'margin',
  p: 'padding',
  w: 'width',
  h: 'height',
  maxW: 'max-width',
  maxH: 'max-height',
  minW: 'min-width',
  minH: 'min-height',
  fontSize: 'font-size',
} as const

export type TDivProps = {
  align?: TAlign
  bg?: keyof DefaultTheme['colors']
  color?: keyof DefaultTheme['colors']
  column?: boolean
  noOverflow?: boolean
  as?: ComponentType<any> | keyof JSX.IntrinsicElements
  noPrint?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  radius?: keyof DefaultTheme['borderRadius']
  transition?: boolean
  zIndex?: keyof DefaultTheme['zIndex']
} & HTMLAttributes<HTMLDivElement> &
  Pick<HTMLAttributes<HTMLDivElement>['style'], 'display' | 'opacity' | 'position' | 'transform'> &
  TResponsiveProps<typeof responsiveProps> &
  TResponsiveVisibilityProps

export const Div = styled.div.attrs<TDivProps, TDivProps>(
  ({
    theme,
    align,
    bg,
    color,
    column,
    display,
    noOverflow,
    onClick,
    opacity,
    position = 'relative',
    radius,
    style,
    transform,
    transition,
    zIndex,
  }) => ({
    style: mergeStyles(style, {
      backgroundColor: bg && theme.colors[bg],
      borderRadius: radius && theme.borderRadius[radius],
      color: color && theme.colors[color],
      opacity,
      position,
      transform,
      zIndex: zIndex && `${theme.zIndex[zIndex]}`,
      ...(noOverflow && { overflow: 'hidden' }),
      ...(onClick && { cursor: 'pointer' }),
      ...(transition && { transition: 'all 0.3s ease-in-out' }),
      ...alignStyle({ align, column }),
      ...(display && { display }),
    }),
  }),
)`
  clear: both; /* to prevent float children overflow */

  ${responsiveStyle(responsiveProps)}
  ${responsiveVisibility}

  /* Print */
  ${(props) => props.noPrint && '@media print {display: none}'}

  /* To prevent the blue highlighting in Chrome on mobile */
  ${mediaQuery('xs', 'xs')} {
    -webkit-tap-highlight-color: transparent;
    cursor: default;
  }
`
