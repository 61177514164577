import styled, { css } from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'

import { Cell } from './Cell'
import { Div, TDivProps } from './Div'

/** If value is '20px', returns '10px' */
const halfValue = (value: string): string => `${parseInt(value, 10) / 2}${value.slice(-2)}`

export type TRowProps = {
  gutter?: string
  noPaddingOnCells?: boolean
  noMargin?: boolean
  nowrap?: boolean
  column?: boolean
} & TDivProps

export const Row = styled(Div).attrs<TRowProps, TRowProps>(({ column, gutter, m, noMargin, nowrap, style }) => ({
  style: mergeStyles(style, {
    flexWrap: nowrap ? 'nowrap' : 'wrap',
    ...(column && { flexDirection: 'column' }),
    ...(!noMargin && !m && gutter && { margin: `-${halfValue(gutter)}` }),
  }),
}))`
  display: flex;
  position: relative;
  > ${Cell} {
    ${(props) =>
      props.gutter &&
      !props.noPaddingOnCells &&
      css`
        padding: ${halfValue(props.gutter)};
      `}
  }
`
