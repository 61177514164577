import { createRoutine } from '@hozana/routines/functions'

import type { TDeletePresubscriberMutationPayload, TIgnoreExhortationMutationPayload } from 'modules/user/queries'
import type { TEditAccountSettingsPayload } from 'modules/user/sagas'
import type { TMe } from 'modules/user/types'

export const editAccountSettings = createRoutine<'EDIT_ACCOUNT_SETTINGS', TEditAccountSettingsPayload>(
  'EDIT_ACCOUNT_SETTINGS',
)
export const deleteAccount = createRoutine<'DELETE_ACCOUNT'>('DELETE_ACCOUNT')
export const ignoreExhortation = createRoutine<'IGNORE_EXHORTATION', TIgnoreExhortationMutationPayload>(
  'IGNORE_EXHORTATION',
)
export const deletePresubscriber = createRoutine<'DELETE_PRESUBSCRIBER', TDeletePresubscriberMutationPayload>(
  'DELETE_PRESUBSCRIBER',
)
export const saveWizard = createRoutine<'SAVE_WIZARD', TMe['wizard']>('SAVE_WIZARD')
