import type { Dayjs } from 'dayjs'

export enum DATE_FORMAT_PART {
  DAY = 'DD',
  MONTH = 'MM',
  YEAR = 'YYYY',
}
type TDateFormatLiteral = '/' | '-' | '.'

export type TDateFormat =
  `${DATE_FORMAT_PART}${TDateFormatLiteral}${DATE_FORMAT_PART}${TDateFormatLiteral}${DATE_FORMAT_PART}`

export enum KnownDateFormat {
  SlashYYYYMMDD = 'YYYY/MM/DD',
  SlashDDMMYYYY = 'DD/MM/YYYY',
  HyphenYYYYMMDD = 'YYYY-MM-DD',
  HyphenDDMMYYYY = 'DD-MM-YYYY',
  ColonHHmm = 'HH:mm',
}

// The following types are fake types that must me assigned with 'as' and tested with the dedicated functions below

/** String YYYY/MM/DD date type */
export type TDateSlashYYYYMMDD = 'YYYY/MM/DD'
/** String DD/MM/YYYY date type */
export type TDateSlashDDMMYYYY = 'DD/MM/YYYY'
/** String YYYY-MM-DD date type */
export type TDateHyphenYYYYMMDD = 'YYYY-MM-DD'
/** String DD-MM-YYYY date type */
export type TDateHyphenDDMMYYYY = 'DD-MM-YYYY'
/** String HH:MM time type */
export type TTime = 'HH:mm'
/** String date time type */
export type TDateTime = 'YYYY-MM-DDTHH:MM+TZ' | TDateHyphenYYYYMMDD

/**
 * Any date type that can be given to dayjs
 * - a javascript Date object
 * - a number
 * - a `YYYY/MM/DD` or `YYYY-MM-DD` string
 * - a dayjs object
 */
export type TDateArg = Date | number | TDateTime | Dayjs

/**
 * Formatted date types associated to their string:
 * TDateFormats['DD/MM/YYYY'] = DD/MM/YYYY formatted date type
 */
export type TDateFormats = {
  [KnownDateFormat.SlashYYYYMMDD]: TDateSlashYYYYMMDD
  [KnownDateFormat.SlashDDMMYYYY]: TDateSlashDDMMYYYY
  [KnownDateFormat.HyphenYYYYMMDD]: TDateHyphenYYYYMMDD
  [KnownDateFormat.HyphenDDMMYYYY]: TDateHyphenDDMMYYYY
  [KnownDateFormat.ColonHHmm]: TTime
} & Record<string, string>

export const parseDateFormat = (format: string) => {
  const separator = format.includes('/') ? '/' : ('-' as '/' | '-')
  const isYYYYMMDD = !!format.match(/^Y{4}.M{2}.D{2}$/)
  const isKnownFormat = !!format.match(
    new RegExp(`^${isYYYYMMDD ? 'Y{4}' : 'D{2}'}${separator}M{2}${separator}${isYYYYMMDD ? 'D{2}' : 'Y{4}'}`),
  )

  return {
    isKnownFormat,
    ...(isKnownFormat
      ? {
          separator,
          isYYYYMMDD,
          isDDMMYYYY: !isYYYYMMDD,
        }
      : {}),
  }
}

export const isDateFormatPart = (str: string): str is DATE_FORMAT_PART =>
  (Object.values(DATE_FORMAT_PART) as string[]).includes(str)

export const isFormattedDate = (str: string, format: KnownDateFormat): boolean => {
  const { isYYYYMMDD, separator } = parseDateFormat(format)
  const regex = new RegExp(
    `^(\\d{${isYYYYMMDD ? '4' : '2'}})${separator}(\\d{2})${separator}(\\d{${isYYYYMMDD ? '2' : '4'}})$`,
  )
  const match = str.match(regex)
  if (!match) {
    return false
  }
  const month = parseInt(match[2])
  const day = parseInt(match[isYYYYMMDD ? 3 : 1])
  if (!month || month > 12 || !day || day > 31) {
    return false
  }
  return true
}

export const isDateSlashYYYYMMDD = (str: string): str is TDateSlashYYYYMMDD =>
  isFormattedDate(str, KnownDateFormat.SlashYYYYMMDD)
export const isDateSlashDDMMYYYY = (str: string): str is TDateSlashDDMMYYYY =>
  isFormattedDate(str, KnownDateFormat.SlashDDMMYYYY)
export const isDateHyphenYYYYMMDD = (str: string): str is TDateHyphenYYYYMMDD =>
  isFormattedDate(str, KnownDateFormat.HyphenYYYYMMDD)
export const isDateHyphenDDMMYYYY = (str: string): str is TDateHyphenDDMMYYYY =>
  isFormattedDate(str, KnownDateFormat.HyphenDDMMYYYY)

export const isTime = (str: string): str is TTime => !!str.match(/^\d{2}:\d{2}$/)
