/**
 * Functions to manipulate dates
 *
 * [See day.js docs](https://day.js.org/docs/en/installation/installation)
 */
import dayjs, { Dayjs } from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import { TFunction } from 'next-i18next'

import type { TLocale } from 'i18n/constants'

import { KnownDateFormat, TDateArg, TDateFormat, TDateFormats, TDateSlashDDMMYYYY, TTime } from './types'

dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.extend(localeData)
dayjs.extend(isYesterday)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(calendar)

require('dayjs/locale/es')
require('dayjs/locale/fr')
require('dayjs/locale/en')
require('dayjs/locale/pt')

export { dayjs, KnownDateFormat }
export type { TTime, TDateSlashDDMMYYYY, TDateFormats, TDateArg, TDateFormat }

type TDate = {
  dayMonthYear: string
  dayMonth: string
}
export const LocaleDateFormat: Record<TLocale, Partial<TDate>> = {
  fr: { dayMonthYear: 'D MMMM YYYY', dayMonth: 'D MMMM' },
  en: { dayMonthYear: 'MMMM D, YYYY', dayMonth: 'MMMM D' },
  es: { dayMonthYear: 'D [de] MMMM [de] YYYY', dayMonth: 'D [de] MMMM' },
  pt: { dayMonthYear: 'D [de] MMMM [de] YYYY', dayMonth: 'D [de] MMMM' },
  it: { dayMonthYear: 'YYYY [m.] MMMM D [d.]', dayMonth: '[m.] MMMM D [d.]' },
  pl: { dayMonthYear: 'D MMMM YYYY', dayMonth: 'D MMMM' },
}

/**
 * Get time format in DD, MM, YYYY
 * Ex: DD.MM.YYYY (pl)
 *
 * @param locale (optional) If undefined, get the browser default time format
 */
export const getLocaleTimeFormat = (locale?: TLocale) =>
  Intl.DateTimeFormat(locale)
    .formatToParts()
    .map(
      ({ type, value }) =>
        ((
          { day: 'DD', month: 'MM', year: 'YYYY', literal: value } as Partial<
            Record<Intl.DateTimeFormatPartTypes, string>
          >
        )[type]),
    )
    .filter(Boolean)
    .join('') as TDateFormat

/**
 * Return a string formatted date string
 * Default format is 'DD/MM/YYYY'
 *
 * Input date can be:
 * - a dayjs object
 * - a date
 * - a timestamp
 * - a YYYY/MM/DD or YYYY-MM-DD date string
 * - a YYYY-MM-DDTHH:MM:SS+TZ datetime string
 */
export const formatDate = <F extends TDateFormat | TTime = 'DD/MM/YYYY'>(
  date: TDateArg,
  format: F = 'DD/MM/YYYY' as F,
): TDateFormats[F] => (dayjs(date).isValid() ? (dayjs(date).format(format) as TDateFormats[F]) : null)

/** Format date in the YYYY-MM-DD server-friendly format */
export const formatDateForServer = (date: TDateArg) => formatDate(date, 'YYYY-MM-DD')

/** Format time to HH:MM format */
export const formatTime = (date: TDateArg) => formatDate(date, 'HH:mm')

/** Format time to calendar format */
export const formatCalendar = (date: TDateArg, t: TFunction) =>
  dayjs(date).calendar(null, {
    sameDay: `[${t('common:common.word.today')}]`, // "Today"
    lastDay: `[${t('common:common.word.yesterday')}]`, // "Yesterday"
    lastWeek: 'dddd', // "Monday"
    sameElse: function callback(this: Dayjs, now: Dayjs) {
      return now.year() !== this?.year()
        ? this.format(LocaleDateFormat[now.locale() as TLocale]?.dayMonthYear)
        : this.format(LocaleDateFormat[now.locale() as TLocale]?.dayMonth)
    },
  })

/** Return a date from a day (formatted DD/MM/YYYY) */
export const parseDay = (day: TDateSlashDDMMYYYY) => dayjs(day, KnownDateFormat.SlashDDMMYYYY).toDate()

/** Return a date set to the default timemezone, which is the french summer timezone */
export const setToDefaultTimeZone = (date: TDateArg) => {
  // We need consider that the offset of the date is +02 (2 * 60 = 120 min)
  // to prevent errors in timeZone CEST (Paris)
  const offsetServerFR = 120 * 60000 // 2h in ms
  const offset = new Date().getTimezoneOffset() * 60000 // Local offset in ms

  return dayjs(date).add(offsetServerFR).add(offset)
}

/** Number of days between to dates */
export const getDiffInDays = (fromDate: TDateArg, toDate: TDateArg) => {
  const from = dayjs(fromDate).hour(0).minute(0).second(0) // So that a day begin at midnight
  const to = dayjs(toDate)
  return to.diff(from, 'day')
}

/** Number of days between the date and now */
export const numDaysSince = (date: TDateArg) => getDiffInDays(date, Date.now())

/** Number of days between now and the date */
export const numDaysTo = (date: TDateArg) => getDiffInDays(Date.now(), date)

/** Number of seconds between now and the date */
export const numSecondsTo = (date: TDateArg) => dayjs(date).diff(dayjs(), 'second')

/** Get all days between two dates */
export const getDays = (start: TDateArg, end: TDateArg): dayjs.Dayjs[] => {
  const dateStart = dayjs(start)
  const dateEnd = dayjs(end)
  if (dateStart > dateEnd) {
    throw new Error('start date cannot be greater than end date')
  }

  const dateDiff = dateEnd.diff(dateStart, 'day', true)
  let daysCount = Math.ceil(dateDiff) //2023-01-01 29:59 - 2023-01-02 00:00 should return 1
  if (dateDiff <= 1 && dateStart.day() === dateEnd.day()) {
    daysCount-- //2023-01-01 10:00 - 2023-01-01 23:00 should return 0
  }
  const result = [dateStart]
  for (let index = 0; index < daysCount; index++) {
    result.push(dateStart.add(index + 1, 'day'))
  }
  return result
}

export const isBirthdayToday = (birthdate: TDateArg) => dayjs(birthdate).year(dayjs().year()).isToday()
