/**
 * Util functions whose job is to save the landing information in cookies.
 */
import type { TDateTime } from '@hozana/dates/types'
import { cookie } from '@hozana/storage/cookies'

import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'

import type { TLandingInfo } from './landingInfo'

/**
 * Saves landing info as a cookie to pass it to API on register.
 * @param sessionInfo
 */
export const saveSessionInfo = (sessionInfo: TLandingInfo) =>
  cookie.save(COOKIES.sessionInfo, JSON.stringify(sessionInfo), COOKIES_CONFIG.SESSION)

/**
 * Get landing information previously stored in cookies.
 */
export const getSessionInfo = () => {
  const rawSessionInfo = cookie.load<(Omit<TLandingInfo, 'landingDate'> & { landingDate: TDateTime }) | undefined>(
    COOKIES.sessionInfo,
  )
  if (rawSessionInfo) {
    return {
      ...rawSessionInfo,
      landingDate: new Date(rawSessionInfo.landingDate),
    }
  }
  return undefined
}
