import { useEffect, useMemo } from 'react'
import { useIsMounted } from 'usehooks-ts'

import { useDeepCompare } from '@hozana/hooks/useDeepCompare'
import { useRouter } from '@hozana/router'
import { removeQueryValuesFromUrl } from '@hozana/router/functions'

export type TUseQueryOptions = {
  disabled?: boolean
  queryKeysToRemove?: string[]
}
export type TUseQueryCallback = (query: Record<string, string | string[]>) => void

/**
 * Check if a key or a query object is included in the url query.
 * If it does, call the callback with the query as parameter,
 * then remove all the matches from the url.
 *
 * @param callback Callback that will be executed if the query match the actionKey
 * @param dependencies Callback dependencies array
 * @param requestedQuery A query key or an object of query key-values pairs
 * @param options (optional)
 * @param options.disable (optional) Don't do anything while disabled is true
 * @param options.queriesToRemove (optional) Some query keys to remove
 */
export const useQuery = (
  callback: TUseQueryCallback,
  dependencies: any[],
  requestedQuery: string | string[] | Record<string, string> | null,
  { disabled, queryKeysToRemove: rawQueryKeysToRemove = [] }: TUseQueryOptions = {},
): void => {
  const { pathname, query, replace, isReady } = useRouter()
  const isMounted = useIsMounted()

  const [reqQuery, queryKeysToRemove] = useDeepCompare([requestedQuery, rawQueryKeysToRemove])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useMemo(() => callback, dependencies) // Update callback only on dependencies change

  useEffect(() => {
    if (!disabled && reqQuery && isReady) {
      const removeAndRun = (queryKeys: string[]) => {
        // Store the query before removal to give it to the callback after removing the query keys
        const beforeRemoveQuery = { ...query }
        removeQueryValuesFromUrl({ pathname, query, replace }, [...queryKeys, ...queryKeysToRemove]).then(() => {
          if (isMounted()) {
            memoizedCallback(beforeRemoveQuery)
          }
        })
      }

      if (typeof reqQuery === 'string') {
        if (reqQuery in query) {
          removeAndRun([reqQuery])
        }
      } else if (Array.isArray(reqQuery)) {
        if (reqQuery.every((key) => key in query)) {
          removeAndRun(reqQuery)
        }
      } else {
        if (Object.entries(reqQuery).every(([key, value]) => query[key] === value)) {
          removeAndRun(Object.keys(reqQuery))
        }
      }
    }
  }, [disabled, isMounted, isReady, memoizedCallback, pathname, query, queryKeysToRemove, replace, reqQuery])
}
