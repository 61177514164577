import type { TQueryConstructor } from '@hozana/api/types'
import { normalize } from '@hozana/redux/normalize'
import { cookie } from '@hozana/storage/cookies'

import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'
import entities, { TRawUser } from 'config/entities'
import type { TRawCommunity } from 'config/entities'

import { saveTokenCookie } from 'modules/auth/functions'
import { setRetreatStatusToCommunities } from 'modules/community/functions'
import type { TNotificationSettings } from 'modules/notification/types'

import { updateUser } from './actions'
import { GENDER, getMeQueryKey } from './constants'
import type { TAvatar, TLocation, TMe, TUser } from './types'

export const getMeQuery: TQueryConstructor<void, number> = () => ({
  queryKey: getMeQueryKey,
  url: '/me',
  normalize: (data: TMe) => normalize(data, entities.user),
  onSuccess: () => {
    cookie.save(COOKIES.hasAlreadyLogged, true, COOKIES_CONFIG.ONE_YEAR)
  },
})

export const getCommunitiesJoinedQuery: TQueryConstructor<{ userId: number; limit?: number }, number[]> = ({
  userId,
  limit = 30,
}) => ({
  queryKey: `getCommunitiesJoined/${userId}`,
  url: `/user/${userId}/communities/joined`,
  params: {
    order: 'activity',
    meta: ['hasMore'],
  },
  meta: { limit },
  normalize: (data: TRawCommunity[]) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunities = setRetreatStatusToCommunities(data)
    return normalize(newCommunities, [entities.community])
  },
})

export const getCommunitiesWithRoleQuery: TQueryConstructor<{ userId: number; limit?: number }, number[]> = ({
  userId,
  limit = 30,
}) => ({
  queryKey: `getCommunitiesWithRole/${userId}`,
  url: `/user/${userId}/communities/withRole`,
  params: {
    order: 'activity',
    meta: ['hasMore'],
  },
  meta: { limit },
  normalize: (data: TRawCommunity[]) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunities = setRetreatStatusToCommunities(data)
    return normalize(newCommunities, [entities.community])
  },
})

export const getUserQuery: TQueryConstructor<{ userId: number }, number> = ({ userId }) => ({
  queryKey: `getUser/${userId}`,
  url: `/user/${userId}`,
  normalize: (data: TUser | TMe) => normalize(data, entities.user),
})

export const getUserByUpdateParametersTokenQuery: TQueryConstructor<
  { updateParametersToken: string },
  TNotificationSettings
> = ({ updateParametersToken }) => ({
  queryKey: `getUserByUpdateParametersToken/${updateParametersToken}`,
  url: `/user/update-parameters-token/${updateParametersToken}`,
})

export const getAvatarsQuery: TQueryConstructor<void, TAvatar[]> = () => ({
  queryKey: 'getAvatars',
  url: '/avatars',
})

/*
 * MUTATIONS
 */

export const updateUserSessionAppTypeMutation: TQueryConstructor<{ appType: string }, void> = ({ appType }) => ({
  queryKey: 'setCurrentAppType',
  url: `/me`,
  method: 'PATCH',
  params: { appType },
})

export type TEditAccountSettingsMutationPayload = {
  email?: string
  notificationEmail?: string
  oldPassword?: string
  password?: string
  lang?: string
  avatarId?: number
  customAvatarFileId?: number
  name?: string
  title?: string
  location?: TLocation
  birthdate?: string
  gender?: GENDER
}

export const editAccountSettingsMutation: TQueryConstructor<TEditAccountSettingsMutationPayload, number> = ({
  email,
  notificationEmail,
  oldPassword,
  password,
  lang,
  avatarId,
  customAvatarFileId,
  name,
  title,
  location,
  birthdate,
  gender,
}) => ({
  queryKey: 'editAccountSettings',
  url: '/me/settings',
  method: 'PATCH',
  params: {
    email,
    notificationEmail,
    oldPassword,
    password,
    lang,
    avatarId,
    customAvatarFileId,
    name,
    title,
    location,
    // If birthdate is undefined, it won't be changed. If null or '', it will be deleted
    birthdate: birthdate === undefined ? undefined : birthdate ? birthdate.split('/').reverse().join('-') : null,
    gender,
  },
  normalize: (data: TRawUser) => {
    if (email) {
      // A new token is provided if user change his email
      saveTokenCookie(data.token)
    }
    return normalize(data, entities.user)
  },
})

export const deleteAccountMutation: TQueryConstructor<void, void> = () => ({
  queryKey: `deleteAccount`,
  url: `/me`,
  method: 'DELETE',
})

export type TIgnoreExhortationMutationPayload = { email: string }

export const ignoreExhortationMutation: TQueryConstructor<TIgnoreExhortationMutationPayload, void> = ({ email }) => ({
  queryKey: `ignoreExhortation`,
  url: `/ignore-exhortation`,
  method: 'POST',
  params: { email },
})

export type TDeletePresubscriberMutationPayload = { email: string }

export const deletePresubscriberMutation: TQueryConstructor<TDeletePresubscriberMutationPayload, void> = ({
  email,
}) => ({
  queryKey: `deletePresubscriber`,
  url: `/presubscriber`,
  method: 'DELETE',
  params: { email },
})

export const saveWizardMutation: TQueryConstructor<TMe['wizard'], TRawUser> = (wizard) => ({
  queryKey: 'saveWizard',
  url: '/me/wizard',
  method: 'PATCH',
  params: wizard,
  onSuccess: (dispatch, user) => {
    dispatch(updateUser(user.id, { wizard }))
  },
})

export const clearMailDeliveryIssueMutation: TQueryConstructor<{ id: number }, void> = ({ id }) => ({
  queryKey: 'clearMailDeliveryIssue',
  url: '/me/mail-delivery-issues/clear',
  method: 'POST',
  onSuccess: (dispatch) => {
    dispatch(updateUser(id, { mailDeliveryIssues: { hardBounce: false, invalid: false, spamReport: false } }))
  },
})

export const reportUserMutation: TQueryConstructor<{ reportUserId: number; reason: string; lang: string }, void> = ({
  reportUserId,
  reason,
  lang,
}) => ({
  queryKey: 'reportUser',
  url: `/user/${reportUserId}/report-abuse`,
  method: 'POST',
  params: { reason },
  meta: { lang },
})

export const blockUserMutation: TQueryConstructor<
  { blockerUser: TUser; blockedUserId: number; lang: string; action: 'block' | 'unblock' },
  void
> = ({ blockerUser, blockedUserId, lang, action }) => ({
  queryKey: 'blockUser',
  url: `/user/${blockedUserId}/${action}`,
  method: 'POST',
  meta: { lang },
  params: { action },
  onSuccess: (dispatch) => {
    dispatch(
      updateUser(blockerUser.id, {
        blockedUserIds:
          action === 'block'
            ? [...blockerUser.blockedUserIds, blockedUserId]
            : blockerUser.blockedUserIds.filter((id) => id !== blockedUserId),
      }),
    )
  },
})
