import { selectEntities, selectEntity } from '@hozana/api/selectors'

import type { TState } from 'config/types'

import { selectUser } from 'modules/user/selectors'

export const selectIntention = (state: TState, intentionId: number) => selectEntity(state, 'intention', intentionId)

export const selectIntentions = (state: TState, intentionsArray: number[]) =>
  selectEntities(state, 'intention', intentionsArray)

export const selectIntentionUser = (state: TState, intentionId: number) => {
  const intention = selectIntention(state, intentionId)
  return intention?.user && selectUser(state, intention.user)
}

export const selectWidgetIntention = (state: TState) =>
  state.intention.widgetIntention && selectIntention(state, state.intention.widgetIntention.id)
