/**
 * A/B test on IDs pairs/impairs (A: 50%, B: 50%)
 * Keep for duplication
 */
// export const testIntentionPrayers = (userId: number) => !!(userId % 2)

/**
 * Get a cookie value
 *
 * @param name         The cookie name
 * @param defaultValue The default value when cookie does not exist
 *
 * @returns The cookie value
 */
export function getCookie(name: string, defaultValue: string | null = null): string | null {
  const cookies = new Map()
  window.document.cookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.trim().split('=')
    // see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Errors/Malformed_URI
    try {
      cookies.set(key, decodeURIComponent(value))
    } catch (error) {
      // ignore the error
    }
  })

  return cookies.has(name) ? cookies.get(name) : defaultValue
}
