import type { TAction } from '@hozana/redux/types'

import type { TEntities, TEntityType } from 'config/entities'

import { ApiActionTypes } from './constants'
import type { TApiState, TStoredQuery } from './types'

// Necessary for the fetch function
require('es6-promise').polyfill()
require('isomorphic-fetch')

export const fetchApiTrigger = (
  queryKey: TStoredQuery['queryKey'],
  method: TStoredQuery['method'],
  url: TStoredQuery['url'],
  params: TStoredQuery['params'],
  meta: TStoredQuery['meta'],
): TAction<
  ApiActionTypes.FETCH_API_TRIGGER,
  Pick<TStoredQuery, 'queryKey' | 'method' | 'url' | 'params' | 'meta'>
> => ({
  type: ApiActionTypes.FETCH_API_TRIGGER,
  queryKey,
  method,
  url,
  params,
  meta,
})

export const fetchApiSuccess = (
  queryKey: TStoredQuery['queryKey'],
  entities: TApiState['entities'],
  data: TStoredQuery['data'],
  meta: TStoredQuery['meta'],
): TAction<ApiActionTypes.FETCH_API_SUCCESS> &
  Pick<TStoredQuery, 'queryKey' | 'data' | 'meta'> & {
    entities: TApiState['entities']
  } => ({
  type: ApiActionTypes.FETCH_API_SUCCESS,
  queryKey,
  entities,
  data,
  meta,
})

export const fetchApiFailure = (
  queryKey: TStoredQuery['queryKey'],
  error: TStoredQuery['error'],
  meta: TStoredQuery['meta'],
): TAction<ApiActionTypes.FETCH_API_FAILURE, Pick<TStoredQuery, 'queryKey' | 'error' | 'meta'>> => ({
  type: ApiActionTypes.FETCH_API_FAILURE,
  queryKey,
  error,
  meta,
})

// Queries update

export const addQueryData = (
  queryKey: string,
  value: unknown,
  side: 'push' | 'unshift' = 'unshift',
): TAction<
  ApiActionTypes.ADD_QUERY_DATA,
  {
    queryKey: string
    value: unknown
    side: 'push' | 'unshift'
  }
> => ({
  type: ApiActionTypes.ADD_QUERY_DATA,
  queryKey,
  value,
  side,
})

/* Remove an element from the data returned by a query */
export const removeFromQueryData = (
  queryKey: string,
  value: unknown,
): TAction<
  ApiActionTypes.REMOVE_FROM_QUERY_DATA,
  {
    queryKey: string
    value: unknown
  }
> => ({
  type: ApiActionTypes.REMOVE_FROM_QUERY_DATA,
  queryKey,
  value,
})

export const removeQuery = (
  queryKey: string,
): TAction<
  ApiActionTypes.REMOVE_QUERY,
  {
    queryKey: string
  }
> => ({
  type: ApiActionTypes.REMOVE_QUERY,
  queryKey,
})

// Entities update

export const updateEntity = <ET extends TEntityType>(
  entityType: ET,
  entityId: number,
  data: Partial<TEntities[ET]>,
): TAction<
  ApiActionTypes.UPDATE_ENTITY,
  {
    entityType: ET
    entityId: number
    data: Partial<TEntities[ET]>
  }
> => ({
  type: ApiActionTypes.UPDATE_ENTITY,
  entityType,
  entityId,
  data,
})

export const updateEntities = <ET extends TEntityType>(
  entityType: ET,
  data: Record<number, Partial<TEntities[ET]>>,
): TAction<
  ApiActionTypes.UPDATE_ENTITIES,
  {
    entityType: ET
    data: Record<number, Partial<TEntities[ET]>>
  }
> => ({
  type: ApiActionTypes.UPDATE_ENTITIES,
  entityType,
  data,
})

type TEntitiyUpdateCreator<AT extends ApiActionTypes> = <ET extends TEntityType, K extends keyof TEntities[ET], V>(
  entityType: ET,
  entityId: number,
  key: K,
  value: V,
) => TAction<
  AT,
  {
    entityType: ET
    entityId: number
    key: K
    value: V
  }
>

/* Add an element of "value" inside (at the end) the "key" list element of the "entityType"/"entityId" entity */
export const pushIntoEntityList: TEntitiyUpdateCreator<ApiActionTypes.PUSH_INTO_ENTITY_LIST> = (
  entityType,
  entityId,
  key,
  value,
) => ({
  type: ApiActionTypes.PUSH_INTO_ENTITY_LIST,
  entityType,
  entityId,
  key,
  value,
})

// Remove an element of "value" inside the "key" list element of the "entityType"/"entityId" entity
export const removeFromEntityList: TEntitiyUpdateCreator<ApiActionTypes.REMOVE_FROM_ENTITY_LIST> = (
  entityType,
  entityId,
  key,
  value,
) => ({
  type: ApiActionTypes.REMOVE_FROM_ENTITY_LIST,
  entityType,
  entityId,
  key,
  value,
})

// Remove an entity of type "entityType" if it has a "key" of "value".
export const removeMatchingEntity = <ET extends TEntityType, K extends keyof TEntities[ET]>(
  entityType: ET,
  key: K,
  value: number,
): TAction<
  ApiActionTypes.REMOVE_MATCHING_ENTITY,
  {
    entityType: ET
    key: K
    value: number
  }
> => ({
  type: ApiActionTypes.REMOVE_MATCHING_ENTITY,
  entityType,
  key,
  value,
})

export const incrementEntityValue = <ET extends TEntityType, K extends keyof TEntities[ET]>(
  entityType: ET,
  entityId: number,
  key: K,
  incrementBy = 1,
): TAction<
  ApiActionTypes.INCREMENT_ENTITY_VALUE,
  {
    entityType: ET
    entityId: number
    key: K
    incrementBy: number
  }
> => ({
  type: ApiActionTypes.INCREMENT_ENTITY_VALUE,
  entityType,
  entityId,
  key,
  incrementBy,
})

export const decrementEntityValue = <ET extends TEntityType, K extends keyof TEntities[ET]>(
  entityType: ET,
  entityId: number,
  key: K,
  decrementBy = 1,
): TAction<
  ApiActionTypes.DECREMENT_ENTITY_VALUE,
  {
    entityType: ET
    entityId: number
    key: K
    decrementBy: number
  }
> => ({
  type: ApiActionTypes.DECREMENT_ENTITY_VALUE,
  entityType,
  entityId,
  key,
  decrementBy,
})

export const addEntity = <ET extends TEntityType>(
  entityType: ET,
  entityId: number,
  entity: TEntities[ET],
): TAction<
  ApiActionTypes.ADD_ENTITY,
  {
    entityType: ET
    entityId: number
    entity: TEntities[ET]
  }
> => ({
  type: ApiActionTypes.ADD_ENTITY,
  entityType,
  entityId,
  entity,
})

export const removeEntity = <ET extends TEntityType>(
  entityType: ET,
  entityId: number,
): TAction<
  ApiActionTypes.REMOVE_ENTITY,
  {
    entityType: ET
    entityId: number
  }
> => ({
  type: ApiActionTypes.REMOVE_ENTITY,
  entityType,
  entityId,
})

export const maintenanceActivate = (): TAction<ApiActionTypes.MAINTENANCE_ACTIVATE> => ({
  type: ApiActionTypes.MAINTENANCE_ACTIVATE,
})

export const maintenanceDeactivate = (): TAction<ApiActionTypes.MAINTENANCE_DEACTIVATE> => ({
  type: ApiActionTypes.MAINTENANCE_DEACTIVATE,
})

export const fetchI18nFallbackNamespaces = (): TAction<ApiActionTypes.FETCH_I18N_FALLBACK_NAMESPACE> => ({
  type: ApiActionTypes.FETCH_I18N_FALLBACK_NAMESPACE,
})
