import React, { HTMLAttributes, MouseEventHandler } from 'react'
import styled from 'styled-components'

import { useTranslate } from '@hozana/intl/useTranslate'
import { defaultStyledConfig } from '@hozana/styling/config'
import { TImageSize, TImageSrc, TPicture, getImageUrl } from '@hozana/styling/image'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

const responsivePropsDef = {
  m: 'margin',
  p: 'padding',
  w: 'width',
  h: 'height',
  maxW: 'max-width',
  maxH: 'max-height',
  minW: 'min-width',
  minH: 'min-height',
} as const

const parse = (elem: string) => elem.split(/\D/).map((s) => parseInt(s))

type TStyledImgProps = HTMLAttributes<HTMLImageElement> & TResponsiveProps<typeof responsivePropsDef>

export const StyledImg = styled('img').withConfig<TStyledImgProps>(defaultStyledConfig(['src', 'width', 'loading']))`
  ${responsiveStyle(responsivePropsDef)}
  width: 100%;
  font-size: 1em;
  text-align: center;
  color: grey;
  font-style: italic;
  @media print {
    max-width: 360px !important;
    max-height: 360px !important;
    display: block;
    margin: auto;
  }
`

export type TImageProps = {
  src: TImageSrc
  size?: TImageSize
  alt: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  loading?: 'lazy' | 'eager'
} & TStyledImgProps

export const Image: React.FC<TImageProps> = ({
  src,
  size,
  w = '100%',
  maxH = '100%',
  maxW = '100%',
  loading = 'lazy',
  alt,
  ...otherProps
}) => {
  const { url, webpUrl } = getImageUrl(src, size, true)
  const altTrans = useTranslate(alt)

  if (webpUrl) {
    const picture = src as TPicture

    const [widthSize, heightSize] = size ? parse(size) : [0, 1]
    const [widthRatio, heightRatio] = picture?.aspectRatio ? parse(picture?.aspectRatio) : []
    const height =
      heightSize ?? (!heightRatio || !widthRatio ? 'unset' : Math.round(widthSize * (heightRatio / widthRatio)))

    return (
      <picture>
        <source srcSet={webpUrl} type="image/webp" />
        <StyledImg
          src={url}
          w={widthSize ? `${widthSize}px` : 'unset'}
          h={height === 'unset' ? 'unset' : `${height}px`}
          maxH={maxH}
          maxW={maxW}
          loading={loading}
          alt={altTrans}
          {...otherProps}
        />
      </picture>
    )
  }
  return <StyledImg src={url} w={w} maxH={maxH} maxW={maxW} loading={loading} alt={altTrans} {...otherProps} />
}
