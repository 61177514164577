// Warning : File loaded by Node. ECMAScript imports/exports don't work.
//
// Additionally, we must use the process.env object (defined in Next configs) instead of CONF_KEYS,
// because this file is loaded too early and the global CONF_KEYS is not yet defined by webpack

// /!\ Also declared in full-ssr-functions
const SESSION_INFO = 'sessionInfo'
const LANDING_INFO = 'landingInfo'

/**
 * @type <(rawValue: string) => import('../types').TCookieName>
 */
const createCookieName = (rawValue) => `${process.env.ENV === 'prod' ? '____' : 'test_'}${rawValue}`

/**
 * @type <CN extends Record<string, string>>(cookies: CN) => CN
 */
const createCookieNames = (cookies) =>
  Object.entries(cookies).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: createCookieName(value),
    }),
    {},
  )

const COOKIES = createCookieNames(
  /** @type {const} */ ({
    /** Used to check if the client uses the native app */
    isNative: 'isNative',
    /** Used by useAutomaticJoinListener */
    automaticJoinListener: 'automaticJoinListener',
    /** Used by CookieBanner */
    hasConsent: 'hasConsent',
    /** If true, the intention panel will be closed at start time */
    keepIntentionsPanelClosed: 'keepIntentionsPanelClosed',
    /** Used to store referrer, landingUrl and landingDate */
    landingInfo: LANDING_INFO,
    /** Used to store referrer, landingUrl, eventOrigin */
    sessionInfo: SESSION_INFO,
    /** True if the user closed the language suggestion banner */
    hideBannerLang: 'hideBannerLang',
    /** Sponsor key */
    sponsorUik: 'latestUik',
    /** Token which identify the user */
    token: 'jwtToken',
    /** Used by LinuxDevPopin */
    devHasSeenPopin: 'devHasSeenPopin',
    /** Used by ConnectPopin */
    hasAlreadyLogged: 'hasAlreadyLogged',
    /** Used by widget intentions */
    widgetIntention: 'widgetIntention',
    /** Used by push notification sagas */
    deviceID: 'deviceID',
    /** Used to memoize device type */
    appType: 'appType',
    /** Used by PushRequestManager */
    pushRequestHistory: 'pushRequestHistory',
    /** Used by AppPromptManager */
    appPromptHistory: 'appPromptHistory',
    /** Used by RatePromptManager */
    ratePromptHistory: 'ratePromptHistory',
    /** Used by PrayerPromptManager. Also used to display someone prayed for me bubble.  */
    prayerPromptAndBubble: 'prayerPromptAndBubble',
    /** Used by PrayerPromptManager. Also used to display someone prayed for me bubble.  */
    arrivalPrayerPrompt: 'arrivalPrayerPrompt',
    /** Used by PrayerPromptManager. Also used to display someone prayed for me bubble.  */
    birthdayPrayerPrompt: 'birthdayPrayerPrompt',
    /** Used by Header, SocialAuthPage and socialLoginSaga */
    connectDetails: 'connectDetails',
    /** Used by AlertBanner */
    hideAlertBanner: 'hideAlertBanner',
    /** Array of communities that already have fired CommunitiesPropositionsPopin */
    poppedCommunities: 'poppedCommunities',
    /** Used to know if a user has already prayed for an inteniton */
    hasPrayedForAnIntention: 'hasPrayedForAnIntention',
    /** Used to know if a user subscribed to a prayer meeting  */
    subscribedPrayerEventMeeting: 'subscribedPrayerEventMeeting',
    /** Used to know if a GuideCommunityPopin has already be shown during the session */
    guideCommunityPopinShown: 'guideCommunityPopinShown',

    // Errors

    /** Used to remember if the invalid timezone has already been sent by getTimezone in intl/functions */
    invalidTimezone: 'invalidTimezone',
    /** Used make sure that the MailDeliveryIssuePopin appears only once per user */
    mailDeliveryIssue: 'mailDeliveryIssue',

    // TESTS

    /** Used for test, to use dedicated e2e database */
    e2eDB: 'e2eDB',
    /** Activate debug tools in prod */
    debugMode: 'debugMode',
    /** Used for test, to remove unexpectable popins */
    withPopin: 'withPopin',
    /** Used for test, to remove unexpectable banners */
    withBanner: 'withBanner',

    // ANALYTICS

    /** Keep track of how user connected (login or signup) */
    connectMethod: 'connectMethod',
    /** Record if the user session is taking place in a TWA */
    isTwa: 'isTwa',
    /** Record data awaiting to be sent to GTM */
    gtmData: 'gtmData',
  }),
)

module.exports = { COOKIES, createCookieName }
