import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchApi } from '@hozana/api/fetchApi'
import { useDispatch } from '@hozana/redux/hooks'
import { getFullUrl } from '@hozana/router/functions'
import { EVENT_ORIGIN, TRACKING_SOURCE, UTM } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'
import { Sentry } from '@hozana/tracking/sentry'

import { PAGE } from 'routes/constants'

import type { TCommunity } from 'modules/community/types'
import type { TPrayerEvent } from 'modules/event/types'
import type { TIntention } from 'modules/intention/types'
import type { TPrayerMeeting } from 'modules/meeting/types'
import type { TPublication } from 'modules/publication/types'
import { SHARE_OBJECTS, SHARE_TYPES } from 'modules/share/constants'
import { ShareContext } from 'modules/share/context'
import { incrementSharesMutation, shortenMutation } from 'modules/share/queries'
import { useUser } from 'modules/user/hooks/useUser'

const getEvent = (eventType: SHARE_TYPES) => {
  const eventName = `PARTAGE_${eventType.toUpperCase() as Uppercase<SHARE_TYPES>}`
  return eventName in GTM.EVENTS ? GTM.EVENTS[eventName as keyof typeof GTM.EVENTS] : null
}

export type TShareProviderProps = {
  community?: TCommunity
  publication?: TPublication
  intention?: TIntention
  prayerEvent?: TPrayerEvent
  prayerMeeting?: TPrayerMeeting
  utmContent?: string
  trackingSource: string
  objectToShare: SHARE_OBJECTS
  shareOptions?: {
    tomorrow?: boolean
  }
}

export const ShareProvider: React.FC<TShareProviderProps> = ({
  community,
  publication,
  intention,
  prayerEvent,
  prayerMeeting,
  utmContent = UTM.CONTENT.UNDEFINED_CONTENT_HOZANA,
  trackingSource,
  objectToShare,
  children,
  shareOptions,
}) => {
  const dispatch = useDispatch()
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const me = useUser()

  const track = useCallback(
    (eventType: SHARE_TYPES) => {
      const event = getEvent(eventType)
      if (event) {
        GTM.trackEvent(event, { trackingSource })
      }
    },
    [trackingSource],
  )

  const generateInviteLink = useCallback(
    (shareType: SHARE_TYPES, shareObjectToLink: SHARE_OBJECTS = objectToShare): Promise<string> =>
      new Promise((resolve) => {
        const utmMediums: Record<SHARE_TYPES, string> = {
          [SHARE_TYPES.FACEBOOK]: UTM.MEDIUM.FB,
          [SHARE_TYPES.TWITTER]: UTM.MEDIUM.TW,
          [SHARE_TYPES.INSTAGRAM]: UTM.MEDIUM.INSTAGRAM,
          [SHARE_TYPES.YOUTUBE]: UTM.MEDIUM.YOUTUBE,
          [SHARE_TYPES.PINTEREST]: UTM.MEDIUM.PINTEREST,
          [SHARE_TYPES.WHATSAPP]: UTM.MEDIUM.WHATS_APP,
          [SHARE_TYPES.MESSENGER]: UTM.MEDIUM.MESSENGER,
          [SHARE_TYPES.TIKTOK]: UTM.MEDIUM.TIKTOK,
          [SHARE_TYPES.COPY]: UTM.MEDIUM.COPYPASTE,
          [SHARE_TYPES.EMAIL_BUTTON]: UTM.MEDIUM.MAILTO,
          [SHARE_TYPES.EMAIL_ICON]: UTM.MEDIUM.MAILTO,
          [SHARE_TYPES.EMAIL_TEXTAREA]: UTM.MEDIUM.COPYPASTE_EMAIL,
          [SHARE_TYPES.COPY_LINK]: UTM.MEDIUM.COPYPASTE,
          [SHARE_TYPES.EMAIL]: UTM.MEDIUM.COPYPASTE_EMAIL,
          [SHARE_TYPES.MORE]: UTM.MEDIUM.OTHER_SHARE,
          [SHARE_TYPES.EMBED]: undefined,
          [SHARE_TYPES.CLOSE]: undefined,
        }

        const query: {
          uik: string
          pik: string | undefined
          utm_source: string
          utm_medium: string
          utm_campaign?: string | null
          utm_content?: string
        } = {
          uik: me.inviteKey,
          pik: me.isLogged && community ? community.sponsorKey : undefined,
          utm_source: UTM.SOURCE.SHARE,
          utm_medium: utmMediums[shareType],
        }

        let longUrl = ''
        switch (shareObjectToLink) {
          case SHARE_OBJECTS.HOZANA: {
            query.utm_content = utmContent
            longUrl = getFullUrl({ pathname: PAGE.HOME, query }, language)
            break
          }
          case SHARE_OBJECTS.COMMUNITY: {
            query.utm_campaign = community ? UTM.CAMPAIGN.COMMUNITY(community.id, community.slug) : null
            query.utm_content = utmContent || UTM.CONTENT.UNDEFINED_CONTENT_COMMUNITY
            longUrl = getFullUrl(
              {
                pathname: PAGE.COMMUNITY_VIEW_LANDING,
                query: {
                  communityId: community?.id,
                  communitySlug: community?.slug,
                  course: 'direct',
                  origin: EVENT_ORIGIN.COMMUNITY_SHARE,
                  source: TRACKING_SOURCE.SHARE,
                  ...query,
                },
              },
              community?.lang,
            )
            break
          }
          case SHARE_OBJECTS.PUBLICATION: {
            query.utm_campaign = community ? UTM.CAMPAIGN.COMMUNITY(community.id, community.slug) : null
            query.utm_content = utmContent || UTM.CONTENT.UNDEFINED_CONTENT_PUBLICATION
            longUrl = getFullUrl(
              {
                pathname: PAGE.PUBLICATION_VIEW,
                query: {
                  publicationId: publication?.id,
                  publicationSlug: publication?.slug,
                  ...query,
                },
              },
              language,
            )
            break
          }
          case SHARE_OBJECTS.TESTIMONIALS: {
            query.utm_content = utmContent || UTM.CONTENT.UNDEFINED_CONTENT_TESTIMONY
            longUrl = getFullUrl(
              {
                pathname: PAGE.TESTIMONIES,
                ...query,
              },
              language,
            )
            break
          }
          case SHARE_OBJECTS.INTENTION: {
            query.utm_campaign = intention ? UTM.CAMPAIGN.INTENTION(intention.id) : null
            query.utm_content = utmContent || UTM.CONTENT.UNDEFINED_CONTENT_INTENTION
            longUrl = getFullUrl(
              {
                pathname: PAGE.INTENTION,
                query: {
                  intentionId: intention?.id,
                  shareToken: intention?.shareToken || '',
                  ...query,
                },
              },
              language,
            )
            break
          }
          case SHARE_OBJECTS.PRAYER_MEETING: {
            query.utm_content = utmContent || UTM.CONTENT.UNDEFINED_CONTENT_PRAYER_MEETING
            longUrl = getFullUrl(
              {
                pathname: PAGE.EVENT_MAP,
                query: {
                  prayerEventId: prayerMeeting?.prayerEvent,
                  prayerEventSlug: prayerEvent?.slug,
                  meeting: prayerMeeting.id,
                  ...query,
                },
              },
              language,
            )
            break
          }
          default: {
            Sentry.captureMessage('Unknown shared object', 'error', { shareType, objectToShare })

            // Don't block user, share hozana main page
            query.utm_content = utmContent
            longUrl = getFullUrl({ pathname: PAGE.HOME, query }, language)
            break
          }
        }

        // increment share for whatsapp|messenger|copylink|email
        if (
          [SHARE_TYPES.WHATSAPP, SHARE_TYPES.MESSENGER, SHARE_TYPES.EMAIL_TEXTAREA].includes(shareType) &&
          [SHARE_OBJECTS.COMMUNITY, SHARE_OBJECTS.PUBLICATION].includes(shareObjectToLink)
        ) {
          dispatch(
            fetchApi(
              incrementSharesMutation({
                objectKey: `/${objectToShare}/${
                  objectToShare === SHARE_OBJECTS.COMMUNITY ? community?.id : publication?.id
                }`,
                shareType:
                  shareType === SHARE_TYPES.COPY
                    ? SHARE_TYPES.COPY_LINK
                    : shareType === SHARE_TYPES.EMAIL_TEXTAREA
                    ? SHARE_TYPES.EMAIL
                    : shareType,
              }),
            ),
          )
        }

        // Shorten it
        dispatch(fetchApi(shortenMutation({ url: longUrl }), true))
          .then(({ hozanaShortUrl }) => resolve(hozanaShortUrl))
          .catch(() => resolve(longUrl))
      }),
    [
      community,
      dispatch,
      intention,
      language,
      me.inviteKey,
      me.isLogged,
      objectToShare,
      prayerEvent?.slug,
      prayerMeeting?.id,
      prayerMeeting?.prayerEvent,
      publication?.id,
      publication?.slug,
      utmContent,
    ],
  )

  const generateEmailBody = useCallback(
    (url: string, shareType: SHARE_TYPES): Promise<string> =>
      new Promise((resolve) => {
        switch (objectToShare) {
          case SHARE_OBJECTS.HOZANA:
            resolve(
              t('share:share.hozana.email-body', {
                url,
                username: me.name,
              }),
            )
            break
          case SHARE_OBJECTS.COMMUNITY:
            resolve(
              t('share:share.share-by-email-body', {
                url,
                communityName: community?.name.trim(),
                username: me.name,
              }),
            )
            break
          case SHARE_OBJECTS.PUBLICATION:
            // For invitations to publications, we also need the URL of the community
            generateInviteLink(shareType, SHARE_OBJECTS.COMMUNITY).then((communityUrl) => {
              resolve(
                t('share:share.publication.email-body', {
                  publicationUrl: url,
                  communityUrl,
                  publicationName: publication?.name.trim(),
                  communityName: community?.name.trim(),
                  username: me.name,
                }),
              )
            })
            break
          case SHARE_OBJECTS.TESTIMONIALS:
            // For invitations to publications, we also need the URL of the community
            generateInviteLink(shareType, SHARE_OBJECTS.TESTIMONIALS).then((testimonyUrl) => {
              resolve(t('share:testimony.share.email-body', { testimonyUrl, username: me.name }))
            })
            break
          case SHARE_OBJECTS.INTENTION:
            resolve(
              t('share:share.intention.email-body', {
                intentionUrl: url,
                username: me.name,
              }),
            )
            break
          default:
            resolve(url)
        }
      }),
    [community, objectToShare, generateInviteLink, publication?.name, me.name, t],
  )

  const share = useMemo(
    () => ({
      generateInviteLink,
      generateEmailBody,
      track,
      trackingSource,
      objectToShare,
      community,
      publication,
      intention,
      shareOptions: shareOptions || {},
      utmContent,
    }),
    [
      generateInviteLink,
      generateEmailBody,
      track,
      trackingSource,
      objectToShare,
      community,
      publication,
      shareOptions,
      utmContent,
      intention,
    ],
  )

  return <ShareContext.Provider value={share}>{children}</ShareContext.Provider>
}
