import { produce } from 'immer'

import { GlobalActionTypes } from '@hozana/redux/constants'
import type { TGlobalAction } from '@hozana/redux/types'

import type { TEntity } from 'config/entities'

import { ApiActionTypes } from './constants'
import type { TApiAction, TApiState } from './types'

const API_INITIAL_STATE: TApiState = {
  entities: {},
  queries: {},
  isMaintenanceActive: false,
  fetchI18nFallbackNamespaces: false,
}

const mergeDeepEntities = (newState: TApiState, entities: TApiState['entities']) => {
  if (entities) {
    Object.keys(entities).forEach((entityType) => {
      Object.keys(entities[entityType]).forEach((entityId) => {
        if (!newState.entities[entityType]) newState.entities[entityType] = {}
        newState.entities[entityType][entityId] = {
          ...newState.entities[entityType][entityId],
          ...entities[entityType][entityId],
        }
      })
    })
  }
}

export const apiReducer = (state = API_INITIAL_STATE, action: TApiAction | TGlobalAction): TApiState =>
  action.type === GlobalActionTypes.CLEAR_STATE
    ? API_INITIAL_STATE
    : produce(state, (newState) => {
        switch (action.type) {
          case ApiActionTypes.FETCH_API_TRIGGER: {
            const { polling, fetchingMore, refetching } = action.meta
            newState.queries[action.queryKey] = {
              ...newState.queries[action.queryKey],
              queryKey: action.queryKey,
              url: action.url,
              method: action.method,
              params: action.params,
              meta:
                polling || fetchingMore || refetching
                  ? {
                      ...newState.queries[action.queryKey]?.meta,
                      polling: false,
                      fetchingMore: false,
                      refetching: false,
                      ...action.meta,
                    }
                  : action.meta,
              loading: true,
              error: null,
            }
            break
          }
          case ApiActionTypes.FETCH_API_SUCCESS: {
            const newQuery = newState.queries[action.queryKey]
            const oldData = newQuery.data
            /** Remove ssgFetched meta so that it does not remain true */
            const { ssgFetched: _extracted, ...oldMeta } = newQuery.meta

            // If data have already been returned by the API
            if (oldData) {
              // If this is a list of entities, merge the new and old data intelligently
              if (Array.isArray(oldData)) {
                if (action.meta.appendData) {
                  if (typeof action.data === 'object' && 'id' in action.data) {
                    newQuery.data = [
                      ...oldData.filter(
                        (oldDataElement) =>
                          !(typeof oldDataElement === 'object' && oldDataElement.id !== action.data.id),
                      ),
                      ...(action.data as unknown[]),
                    ]
                  } else {
                    newQuery.data = [...new Set([...oldData, ...(action.data as unknown[])])]
                  }
                } else if (action.meta.prependData) {
                  if (typeof action.data === 'object' && 'id' in action.data) {
                    newQuery.data = [
                      ...(action.data as unknown[]),
                      ...oldData.filter(
                        (oldDataElement) =>
                          !(typeof oldDataElement === 'object' && oldDataElement.id !== action.data.id),
                      ),
                    ]
                  } else {
                    newQuery.data = [...new Set([...(action.data as unknown[]), ...oldData])]
                  }
                } else {
                  // No merging, just replace the data
                  newQuery.data = action.data
                }
              } else {
                // if old data exist but no rule to merge them,
                // just replace data by new ones
                newQuery.data = action.data
              }
            } else {
              // No previous data, assign the new data to the query
              newQuery.data = action.data
            }

            mergeDeepEntities(newState, action.entities)

            // Meta
            newQuery.meta = {
              ...oldMeta,
              ...action.meta,
            }
            if (action.meta.polling) {
              // When polling, offset is 0: previous hasMore value must be kept as is
              newQuery.meta.hasMore = oldMeta.hasMore ?? newQuery.meta.hasMore
            } else if (typeof action.meta.hasMore === 'undefined') {
              // If no 'hasMore' value has been returned by the API, calculate it ourselves
              newQuery.meta.hasMore = !action.data || (action.data as unknown[]).length > 0
            } else if (action.meta.hasMore && (action.data as unknown[]).length < action.meta.limit) {
              newQuery.meta.hasMore = false
            }

            // Query state
            newQuery.loading = false
            newQuery.error = null

            break
          }

          case ApiActionTypes.FETCH_API_FAILURE:
            // Don't erase data if we append data (refetch)
            if (action.meta?.appendData) {
              newState.queries[action.queryKey] = {
                ...newState.queries[action.queryKey],
                loading: false,
                error: action.error,
              }
            } else {
              newState.queries[action.queryKey] = {
                ...newState.queries[action.queryKey],
                loading: false,
                error: action.error,
                data: null,
              }
            }
            break

          // Queries

          case ApiActionTypes.ADD_QUERY_DATA:
            if (!newState.queries[action.queryKey]) {
              break
            }
            if (!Array.isArray(newState.queries[action.queryKey].data)) {
              throw new Error(
                `Impossible to ${action.side} query data on non-array data. queryKey: ${action.queryKey}.`,
              )
            } else {
              ;(newState.queries[action.queryKey].data as unknown[])[action.side](action.value)
            }
            break

          case ApiActionTypes.REMOVE_FROM_QUERY_DATA:
            if (newState.queries[action.queryKey]?.data) {
              newState.queries[action.queryKey].data = (newState.queries[action.queryKey].data as unknown[]).filter(
                (entry) => {
                  if (typeof entry === 'number') {
                    return entry !== action.value
                  } else {
                    return (entry as Record<string, unknown>).id !== action.value
                  }
                },
              )
            }
            break

          case ApiActionTypes.REMOVE_QUERY:
            if (newState.queries[action.queryKey]) {
              delete newState.queries[action.queryKey]
            }
            break

          // Entities

          case ApiActionTypes.UPDATE_ENTITY:
            newState.entities[action.entityType][action.entityId] = {
              ...newState.entities[action.entityType][action.entityId],
              ...action.data,
            } as TEntity
            break

          case ApiActionTypes.UPDATE_ENTITIES:
            mergeDeepEntities(newState, { [action.entityType]: action.data })
            break

          case ApiActionTypes.PUSH_INTO_ENTITY_LIST:
            if (newState.entities[action.entityType][action.entityId][action.key]) {
              ;(newState.entities[action.entityType][action.entityId][action.key] as unknown as unknown[]).push(
                action.value,
              )
            } else {
              ;(newState.entities[action.entityType][action.entityId][action.key] as unknown as unknown[]) = [
                action.value,
              ]
            }
            break

          case ApiActionTypes.REMOVE_FROM_ENTITY_LIST:
            ;(newState.entities[action.entityType][action.entityId][action.key] as unknown as unknown[]) = (
              newState.entities[action.entityType][action.entityId][action.key] as unknown as unknown[]
            ).filter((id) => id !== action.value)
            break

          case ApiActionTypes.REMOVE_MATCHING_ENTITY:
            if (newState.entities[action.entityType]) {
              const entityEntry = Object.entries(newState.entities[action.entityType]).find(
                ([, entity]) => entity[action.key] === action.value,
              )
              if (entityEntry) {
                delete newState.entities[action.entityType][entityEntry[0]]
              }
            }
            break

          case ApiActionTypes.INCREMENT_ENTITY_VALUE:
            newState.entities[action.entityType][action.entityId][action.key] += action.incrementBy
            break

          case ApiActionTypes.DECREMENT_ENTITY_VALUE:
            newState.entities[action.entityType][action.entityId][action.key] -= action.decrementBy
            break

          case ApiActionTypes.ADD_ENTITY:
            if (!newState.entities[action.entityType]) {
              newState.entities[action.entityType] = {}
            }
            newState.entities[action.entityType][action.entityId] = action.entity
            break

          case ApiActionTypes.REMOVE_ENTITY:
            if (newState.entities[action.entityType]?.[action.entityId]) {
              delete newState.entities[action.entityType][action.entityId]
            }
            break

          case ApiActionTypes.MAINTENANCE_ACTIVATE:
            newState.isMaintenanceActive = true
            break

          case ApiActionTypes.MAINTENANCE_DEACTIVATE:
            newState.isMaintenanceActive = false
            break

          case ApiActionTypes.FETCH_I18N_FALLBACK_NAMESPACE:
            newState.fetchI18nFallbackNamespaces = true
            break

          default:
            break
        }
      })
