import type { PropertiesHyphen } from 'csstype'
import type { DefaultTheme } from 'styled-components'

const formatValue = (value: string | number, important?: boolean) =>
  `${typeof value === 'number' ? `${value * 100}%` : value}${important ? ' !important' : ''}`

export type TBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type TResponsivePropsDef = Record<string, keyof PropertiesHyphen<number | boolean>>

export type TResponsiveValue<T = string | number | boolean> = T | Partial<Record<TBreakpoint, T>>

export type TResponsiveProps<TRPD extends TResponsivePropsDef> = {
  [K in keyof TRPD]?: TResponsiveValue<PropertiesHyphen<number | boolean>[TRPD[K]]>
}

type TOtherPropsWithTheme = {
  [i: string]: any
  theme: DefaultTheme
}

/**
 * responsiveStyle({ maxW: 'max-width' })({ maxW: '500px' }) === 'max-width: 500px;'
 * - maxW: js prop name
 * - max-width: correspounding css prop name
 *
 * It allows maxW to receive:
 * - either a single value (ex: '500px')
 * - either a object of values with breakpoints as keys (ex: { xs: '200px', md: '500px' })
 */
export const responsiveStyle =
  <TRPD extends TResponsivePropsDef>(
    responsiveProps: TRPD,
    modifiers?: Partial<Record<keyof TRPD, { important?: boolean }>>,
  ) =>
  (props: TResponsiveProps<TRPD> & TOtherPropsWithTheme): string => {
    const result: string[] = []
    Object.entries(responsiveProps).forEach(([jsProp, cssProp]) => {
      if (props[jsProp] === null || props[jsProp] === undefined || !(jsProp in props)) {
        // no props set, move along.
        return undefined
      }
      if (!props[jsProp] || typeof props[jsProp] !== 'object') {
        // not an object, use its value directly
        result.push(`${cssProp}: ${formatValue(props[jsProp], modifiers?.[jsProp]?.important)};`)
      } else {
        ;(Object.keys(props[jsProp]) as TBreakpoint[]).forEach((size) => {
          if (!Object.prototype.hasOwnProperty.call(props.theme.breakpoints, size)) {
            throw new Error(
              `Calling responsiveStyle with an invalid size name (${size}), valid ones are [${Object.keys(
                props.theme.breakpoints,
              ).join(', ')}]`,
            )
          }
          if (props.theme.breakpoints[size] && props[jsProp][size]) {
            result.push(`
            @media (min-width: ${props.theme.breakpoints[size]}) {
              ${cssProp}: ${formatValue(props[jsProp][size], modifiers?.[jsProp]?.important)};
            }
          `)
          }
        })
      }
    })
    return result.join('')
  }

export type TResponsiveVisibilityProps = {
  visible?: 'none' | TBreakpoint | TBreakpoint[]
  invisible?: 'none' | TBreakpoint | TBreakpoint[]
}

export const responsiveVisibility = ({
  visible,
  invisible,
  theme,
}: TResponsiveVisibilityProps & TOtherPropsWithTheme): string | undefined => {
  const result: string[] = []
  const propSizes = visible || invisible
  if (!propSizes) {
    return undefined
  } else {
    let sizes: TBreakpoint[] = []
    if (typeof propSizes === 'string') {
      if (propSizes === 'none') {
        sizes = Object.keys(theme.breakpoints) as TBreakpoint[]
      } else {
        sizes[0] = propSizes
      }
    } else {
      sizes = propSizes
    }

    sizes.forEach((propSize) => {
      if (!Object.prototype.hasOwnProperty.call(theme.breakpoints, propSize)) {
        throw new Error(
          `Calling responsiveVisibility with an invalid size name (${
            visible || invisible
          }), valid ones are [${Object.keys(theme.breakpoints).join(', ')}]`,
        )
      }
    })
    ;(Object.keys(theme.breakpoints) as TBreakpoint[]).forEach((size) => {
      if ((visible && !visible.includes(size)) || invisible?.includes(size)) {
        result.push(`
          @media (min-width: ${theme.breakpoints[size]}) and (max-width: ${theme.breakpointsMax[size]}) {
            display: none !important;
          }
        `)
      }
    })
    return result.join('')
  }
}

/**
 * Use css calc on a responsive value.
 * Ex: `calcOnResponsiveValue({ xs: '32px', lg: '54px' }, '* 3') === { xs: 'calc(32px * 3)', lg: 'calc(54px * 3)' }
 * /!\ Do not forget to include a space between the operator and the value!
 */
export const calcOnResponsiveValue = <Prop extends TResponsiveValue>(prop: Prop, operation: string): Prop =>
  typeof prop === 'object'
    ? Object.entries(prop).reduce(
        (newProp, [breakpoint, value]) =>
          ({
            ...(newProp as Record<string, string | number>),
            [breakpoint]: `calc(${value} ${operation})`,
          } as Prop),
        {} as Prop,
      )
    : (`calc(${prop} ${operation})` as Prop)

export const mediaQuery =
  (minSize: TBreakpoint, maxSize?: TBreakpoint) =>
  (props: { theme: DefaultTheme } & any): string => {
    if (maxSize) {
      return `
      @media (min-width: ${props.theme.breakpoints[minSize]})
         and (max-width: ${props.theme.breakpointsMax[maxSize]})
      `
    } else {
      return `@media (min-width: ${props.theme.breakpoints[minSize]})`
    }
  }

export type TDefaultToZeroProps<P extends keyof PropertiesHyphen> = Partial<
  Record<P, boolean | TResponsiveValue<PropertiesHyphen[P]>>
>

export const defaultToZero = <P extends keyof PropertiesHyphen>(
  property: P,
  style: boolean | TResponsiveValue<PropertiesHyphen[P]>,
): string | ((props: TResponsiveProps<Record<P, P>> & TOtherPropsWithTheme) => string) =>
  typeof style === 'boolean'
    ? `${property}: 0`
    : responsiveStyle<Record<P, P>>({ [property]: property } as Record<P, P>)
