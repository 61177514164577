import React from 'react'
import styled from 'styled-components'

import { BorderedSpan } from 'elements/layout/BorderedSpan'
import { Div, TDivProps } from 'elements/layout/Div'
import { Icon } from 'elements/media/Icon'
import { Text } from 'elements/text/Text'

import { ICON } from 'config/icons'

export const StyledInput = styled.input`
  opacity: 0;
  pointer-events: none;
  position: absolute;
`

export type TCheckboxProps = {
  value?: boolean
  onChange?: (newValue: boolean) => void
  disabled?: boolean
  align?: string
  name?: string
} & Omit<TDivProps, 'onChange'>

export const Checkbox: React.FC<TCheckboxProps> = ({
  children,
  value,
  name,
  onChange,
  disabled,
  p = '10px 0',
  ...otherProps
}) => (
  <Div as="label" align="topLeft" style={disabled ? { opacity: '0.5' } : { cursor: 'pointer' }} p={p} {...otherProps}>
    <StyledInput type="checkbox" name={name} onChange={() => onChange(!value)} disabled={disabled} />
    <BorderedSpan
      left
      m="0 10px 0 0"
      w="25px"
      h="25px"
      borderRadius="5px"
      bg="white"
      borderColor={value ? 'black' : 'black30'}
      borderWidth="2px"
      style={{ flex: '0 0 25px' }}
    >
      {value && <Icon name={ICON.CHECK} />}
    </BorderedSpan>
    {children && (
      <Text style={{ textAlign: 'left' }} w="100%">
        {children}
      </Text>
    )}
  </Div>
)
