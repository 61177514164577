import { EventHandler, SyntheticEvent } from 'react'

export const withEventDelay = <EH extends EventHandler<SyntheticEvent<any>> = EventHandler<SyntheticEvent<any>>>({
  onDelay,
  onResume,
  delay,
}: {
  onDelay?: EH
  onResume?: EH
  delay: number
}) =>
  ((e) => {
    if (e.isTrusted) {
      onDelay?.(e)
      const clonedNativeEvent = new e.nativeEvent.constructor(e.type, e.nativeEvent)
      e.preventDefault()
      setTimeout(() => {
        e.target.dispatchEvent(clonedNativeEvent)
      }, delay)
    } else {
      onResume?.(e)
    }
  }) as EH
