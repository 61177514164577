import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'

import { Div, TDivProps } from './Div'

export type TShadowProps = {
  depth?: string
  inset?: boolean
  spread?: string
  opacity?: string | number
  borderRadius?: string
  x?: string
  y?: string
} & TDivProps

export const Shadow = styled(Div).attrs<TShadowProps, TShadowProps>(
  ({ borderRadius, depth = '3px', inset, opacity = '0.25', spread = '0', style, x = '0', y = '3px' }) => ({
    style: mergeStyles(style, {
      boxShadow: `${inset ? 'inset' : ''} ${x} ${y} ${depth} ${spread} rgba(0, 0, 0, ${opacity})`,
      borderRadius,
      // Set opacity back to 1, because it has been set to {opacity} in Div (attrs are applied from bottom to top)
      opacity: 1,
    }),
  }),
)``
