import type { TAction } from '@hozana/redux/types'

import type { TPrayer } from 'modules/pray/types'

import { IntentionActionTypes } from './constants'

// Entities manipulations

export const addPrayer = (
  id: number,
  prayer: TPrayer,
): TAction<IntentionActionTypes.ADD_PRAYER, { id: number; prayer: TPrayer }> => ({
  type: IntentionActionTypes.ADD_PRAYER,
  id,
  prayer,
})

export const setWidgetIntention = (
  id: number,
  hash: string,
): TAction<IntentionActionTypes.SET_WIDGET_INTENTION, { id: number; hash: string }> => ({
  type: IntentionActionTypes.SET_WIDGET_INTENTION,
  id,
  hash,
})

export const clearWidgetIntention = (): TAction<
  IntentionActionTypes.SET_WIDGET_INTENTION,
  { id: number; hash: string }
> => ({
  type: IntentionActionTypes.SET_WIDGET_INTENTION,
  id: null,
  hash: null,
})
