import NextHead from 'next/head'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { stripHtmlTags } from '@hozana/dom/functions'
import { getAllActiveLocales, getRegionLocale } from '@hozana/intl/functions'
import { useTranslate } from '@hozana/intl/useTranslate'
import { useRouter } from '@hozana/router'
import { getFullUrl } from '@hozana/router/functions'

import type { TLocale } from 'i18n/constants'
import { PAGE } from 'routes/constants'

type TMeta = { name?: string; property?: string; content: string; httpEquiv?: string }

type THeadProps = {
  isIndexed?: boolean
  title?: string // If set, it will overwrite the title set on the <Page> component
  description?: string
  type?: string
  article?: {
    published_time?: string
    modified_time?: string
    author?: string
    section?: string
    tag?: string
  }
  profile?: {
    first_name?: string
    last_name?: string
    username?: string
  }
  language?: TLocale
  image?: string
  imageWidth?: string
  imageHeight?: string
  href?: string
  links?: Partial<Record<'canonical' | 'prev', string>> & { next?: string | string[] }
  alternateUrls?: boolean // If true, we will add alternate URLs to other languages
  rss?: {
    title: string
    href: string
  }
  fontUrl?: string
}

export const Head: React.FC<THeadProps> = ({
  children,
  title,
  isIndexed = true,
  description = 'common:common.hozana.description',
  type = 'website',
  article,
  profile,
  language: propLanguage,
  image,
  imageWidth,
  imageHeight,
  links,
  alternateUrls = false,
  rss,
  fontUrl,
}) => {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation()
  const language = propLanguage || currentLanguage
  const { pathname, query, isFallback } = useRouter()

  const descriptionTranslation = useTranslate(description)
  const formattedDesc = stripHtmlTags(descriptionTranslation)
  // The !isFallback condition is needed to make sure the page params (ex: [communityId]) are available in query - #isFallbackCondition
  const currentPageUrl = pathname && !isFallback && getFullUrl({ pathname, query }, language)

  const linksWithCanonical = {
    ...links,
    canonical: links?.canonical || currentPageUrl,
  }

  const metas: TMeta[] = [
    { name: 'description', content: formattedDesc },
    { name: 'twitter:description', content: formattedDesc },
    { property: 'og:description', content: formattedDesc },
    { property: 'og:type', content: type },
    { name: 'twitter:card', content: 'summary_large_image' },
    { property: 'fb:app_id', content: CONF_KEYS.FB_ID },
    { property: 'og:url', content: linksWithCanonical.canonical },
  ]

  const titleTranslation = useTranslate(title)

  if (title) {
    metas.push({ property: 'og:title', content: titleTranslation })
    metas.push({ name: 'twitter:title', content: titleTranslation })
  }

  if (language) metas.push({ property: 'og:locale', content: getRegionLocale(language) })
  if (article) {
    Object.entries(article).forEach(([key, value]) => {
      metas.push({ property: `article:${key}`, content: value })
    })
  }
  if (profile) {
    Object.entries(profile).forEach((profileEntry) => {
      metas.push({ property: `profile:${profileEntry[0]}`, content: profileEntry[1] })
    })
  }

  return (
    <NextHead>
      {titleTranslation && (
        <title>
          {titleTranslation}
          {pathname !== PAGE.HOME ? ' - Hozana' : ''}
        </title>
      )}
      {Object.entries(linksWithCanonical).map(([key, links]) => {
        if (!links) {
          return null
        }

        const urls = Array.isArray(links) ? links : [links]
        return urls.map((url) => <link key={url} rel={key} href={url} />)
      })}
      {metas?.map((meta) => {
        // Only one type of meta data per page
        // See https://nextjs.org/docs#populating-head
        const propKey = meta.property || meta.name || meta.httpEquiv
        if (meta !== null) {
          return <meta key={propKey} {...meta} />
        } else {
          return null
        }
      })}
      {/*
        Next does not handle well duplicates when the key is set in an array map, so we define these outside of the loop
        See https://nextjs.org/docs#populating-head
       */}
      <meta
        key="robots"
        name="robots"
        content={isIndexed && CONF_KEYS.ENV !== 'preprod' ? 'index, follow' : 'noindex, nofollow'}
      />
      {image && (
        <meta key="image" property="og:image" content={image.startsWith('http') ? image : CONF_KEYS.HOME + image} />
      )}
      {image && (
        <meta
          key="twitterImage"
          name="twitter:image"
          content={image.startsWith('http') ? image : CONF_KEYS.HOME + image}
        />
      )}
      {imageWidth && <meta key="imageWidth" property="og:image:width" content={imageWidth} />}
      {imageHeight && <meta key="imageHeight" property="og:image:height" content={imageHeight} />}
      {alternateUrls &&
        pathname &&
        getAllActiveLocales().map((lang) => {
          if (lang !== language) {
            const langUrl = getFullUrl({ pathname, query }, lang)
            return <link key={lang} rel="alternate" href={langUrl} hrefLang={lang} />
          }
          return undefined
        })}
      {rss && <link rel="alternate" type="application/rss+xml" title={rss.title} href={rss.href} />}
      {fontUrl && <link href={fontUrl} rel="stylesheet" type="text/css" />}
      {children}
    </NextHead>
  )
}
