var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4.60.0-84f563e2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, replayIntegration } from '@sentry/nextjs'

init({
  environment: CONF_KEYS.ENV,
  enabled: CONF_KEYS.ENV === 'prod' || CONF_KEYS.ENV === 'test',
  dsn: 'https://9cf9f6b4594b4d468a9f5c5295444f8a@o86393.ingest.sentry.io/215148',
  release: process.env.npm_package_version,
  tracesSampleRate: 0.2,
  debug: CONF_KEYS.ENV != 'prod',
  replaysOnErrorSampleRate: 0.007,
  replaysSessionSampleRate: 0.001,
  integrations: [
    replayIntegration({
      blockAllMedia: true,
      maskAllText: true,
    }),
  ],
})
