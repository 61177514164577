import type { ImageProps } from 'next/image'

import { COMMUNITY_AVATAR_SIZES } from 'modules/community/constants'

export const getCloserAuthorizedSize = (size: number, authorized: Record<string, number>) => {
  const getDiff = (key: string) => Math.abs(size - authorized[key])
  return Object.keys(authorized).sort(
    (keyA, keyB) => getDiff(keyA) - getDiff(keyB),
  )[0] as keyof typeof COMMUNITY_AVATAR_SIZES
}

enum CommentImageSize {
  small = '80x80',
  big = '448x200',
  big_no_height_limit = '535',
}
enum AvatarImageSize {
  tiny = '40x40',
  small = '60x60',
  medium = '100x100',
  big = '285x285',
}
enum CommunityImageSize {
  // small = '230x99', // unused but it may be good to keep it for later
  square = '150x150',
  medium = '448x200',
  big = '990x427',
  preview = '300x129',
}
enum PublicationImageSize {
  medium_no_height_limit = '350',
  big_no_height_limit = '680',
  medium = '350x140',
  big = '380x272',
}

/**
 * We can request whatever dimension we want, but it will then be cached on the filesystem.
 * To avoid generating a bazillion dimensions, please use the following constants.
 *
 * Note: "80" !== "80x80". The first one will restrict the width and let the height be whatever it wants.
 */
export const IMAGE_SIZES = {
  avatar: AvatarImageSize,
  community: CommunityImageSize,
  publication: PublicationImageSize,
}

export type TImageSize = CommentImageSize | AvatarImageSize | CommunityImageSize | PublicationImageSize

export type TPicture = {
  id: number
  slug?: string
  ext?: 'jpg' | 'png' | 'svg' | 'webp' | 'gif'
  aspectRatio?: `${number}:${number}`
}

export type TImageSrc = ImageProps['src'] | TPicture | string | number

export function getImageUrl<W extends boolean | undefined = false>(
  image: ImageProps['src'] | TImageSrc,
  size?: TImageSize | null,
  withWebp?: W,
): W extends true
  ? {
      url: string
      webpUrl: string
    }
  : string
export function getImageUrl(
  image: TImageSrc,
  size: string | null = null,
  withWebp = false,
):
  | {
      url: string
      webpUrl: string
    }
  | string {
  if (!image) {
    if (withWebp) {
      return { url: '', webpUrl: '' }
    }
    return ''
  }

  let url = ''
  let webpUrl = ''

  if (typeof image === 'string') {
    url = image
  } else if (typeof image === 'number' && !isNaN(image)) {
    let imageUrl = CONF_KEYS.FILES_URL + '/' + image
    if (size) imageUrl += '!' + size
    url = imageUrl
  } else if (typeof image === 'object' && 'id' in image) {
    let imageUrl = CONF_KEYS.FILES_URL + '/' + image.id
    if (image.slug) imageUrl += '-' + image.slug
    if (size) imageUrl += '!' + size
    url = imageUrl + '.' + (image.ext || 'jpg')
    webpUrl = `${imageUrl}.webp`
  } else {
    throw new Error('Unknown image value :' + JSON.stringify(image))
  }

  if (withWebp) {
    return { url, webpUrl }
  }

  return url
}
