import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { formatSubmissionError } from '@hozana/form/functions'
import type { TAction } from '@hozana/redux/types'
import { all, call, put, race, take, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'

import { TConnectDetails, redirect } from 'app/functions'
import { POPINS } from 'app/managers/popins/constants'
import type { TPopinsOptions } from 'app/managers/popins/types'
import type { TAbuseObjectType } from 'app/types'
import type { TAllAction, TAllActionType } from 'config/types'

import { reportIntentionMutation } from 'modules/intention/queries'
import { reportPublicationMutation } from 'modules/publication/queries'
import { reportTestimonyMutation } from 'modules/testimony/queries'
import { reportUserMutation } from 'modules/user/queries'

import { addFlash, closePopin, openPopin } from './actions'
import { AppActionTypes } from './constants'
import { reportAbuse, reportAbuseFormSubmit } from './routines'

export type TReportAbusePayload = {
  objectId: number
  objectType: TAbuseObjectType
  lang?: string
}

function* reportAbuseSaga({ payload: { objectId, objectType, lang } }: TSagaProps<TReportAbusePayload>) {
  yield* put(openPopin(POPINS.ReportAbusePopin, { objectId, objectType }))

  do {
    const { formSubmitResult, closeReportPopin } = yield* race({
      formSubmitResult: take(reportAbuseFormSubmit),
      closeReportPopin: take(AppActionTypes.CLOSE_POPIN),
    })

    if (closeReportPopin) {
      yield* put(reportAbuse.failure('cancel'))
      return
    }

    try {
      yield* objectType === 'publication'
        ? fetchApiSaga(
            reportPublicationMutation({
              publicationId: objectId,
              reason: formSubmitResult.payload.reason,
            }),
          )
        : objectType === 'intention'
        ? fetchApiSaga(
            reportIntentionMutation({
              intentionId: objectId,
              reason: formSubmitResult.payload.reason,
              lang,
            }),
          )
        : objectType === 'testimony'
        ? fetchApiSaga(
            reportTestimonyMutation({
              testimonyId: objectId,
              reason: formSubmitResult.payload.reason,
              lang,
            }),
          )
        : objectType === 'user' &&
          fetchApiSaga(
            reportUserMutation({
              reportUserId: objectId,
              reason: formSubmitResult.payload.reason,
              lang,
            }),
          )
      yield* put(reportAbuseFormSubmit.success())
      yield* put(closePopin(POPINS.ReportAbusePopin))
      const message = {
        intention: 'common:intention.report.success',
        publication: 'common:publication.report.success',
        testimony: 'common:testimony.report.success',
        user: 'common:user.report.success',
      }[objectType]
      yield* put(addFlash({ message }))
      yield* put(reportAbuse.success())
      return
    } catch (error) {
      const message = error.message || error
      yield* put(reportAbuseFormSubmit.failure(formatSubmissionError(message, {}, 'reportAbuse')))
    }
  } while (true)
}

/*
 * LISTENER
 */

export default function* appSagasListener() {
  yield* all([takeLatest(reportAbuse, reportAbuseSaga)])
}

/*
 * UTILS
 */

/**
 * Open a popin and wait for the "successActionType" message
 * If the popin is closed without triggering the result, return false
 *
 * @param popinName - Name of the popin (see config/constants.js file)
 * @param popinOptions - Options passed to the popin
 * @param successActionType - Action type which will trigger a success
 */
export function* runPopin<
  PN extends keyof TPopinsOptions = keyof TPopinsOptions,
  AT extends TAllActionType = TAllActionType,
>(popinName: PN, popinOptions: TPopinsOptions[PN], successActionType: AT): Generator<any, TAllAction<AT>> {
  yield* put(openPopin(popinName, popinOptions))

  const { success } = yield* race({
    success: take<TAllAction<AT>>(successActionType),
    failure: take(
      (action: TAction) => action.type === AppActionTypes.CLOSE_POPIN && (!action.name || action.name === popinName),
    ),
  })

  if (success) {
    // Make sure we close the popin
    yield* put(closePopin(popinName))

    // Return the success data
    return success
  }

  return null
}

export function* runConfirm(title: string, message?: string) {
  return yield* runPopin(POPINS.ConfirmationPopin, { title, message }, AppActionTypes.CONFIRM)
}

/**
 * Open the InputPopin and returns the values entered by the user
 * If the user close the popin or choose 'cancel', it will return false.
 *
 * @param title - Title of the popin
 * @param message - Message inside the popin
 * @param inputType - Type of the input field
 */
export function* runInput(title: string, message: string, inputType = 'text') {
  const result = yield* runPopin(POPINS.InputPopin, { title, message, inputType }, AppActionTypes.CONFIRM)
  return result?.value
}

export function* runRedirect(options?: TConnectDetails) {
  return yield* call(redirect, options)
}
