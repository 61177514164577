import styled, { DefaultTheme } from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import { Div, TDivProps } from './Div'

const responsiveProps = { borderRadius: 'border-radius' } as const

export type TBorderedDivProps = {
  overflow?: string
  noBorder?: boolean
  dashed?: boolean
  borderColor?: keyof DefaultTheme['colors']
  borderWidth?: string
} & TDivProps &
  TResponsiveProps<typeof responsiveProps>

export const BorderedDiv = styled(Div).attrs<TBorderedDivProps, TBorderedDivProps>(
  ({ theme, borderColor = 'transparent', borderWidth = '1px', dashed, noBorder, style, overflow }) => ({
    style: mergeStyles(style, {
      overflow,
      ...(!noBorder && { border: `${borderWidth} ${dashed ? 'dashed' : 'solid'} ${theme.colors[borderColor]}` }),
    }),
  }),
)`
  ${({ borderRadius = '10px', ...otherProps }) => responsiveStyle(responsiveProps)({ borderRadius, ...otherProps })}
`
