import React from 'react'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'

import { Link, TLinkProps } from 'elements/text/Link'
import { P } from 'elements/text/P'

import { NavigationLi } from './NavigationLi'

type TSecondaryLinkProps = {
  light?: boolean
} & TLinkProps

export const SecondaryLink: React.FC<TSecondaryLinkProps> = ({ children, light, ...otherProps }) => {
  const attrs = useAttributes() // used for accessibility to pass "tabindex = -1" to avoid focus
  const color = light ? 'white' : 'black70'
  const hoverColor = light ? 'white' : 'black'
  const hoverBg = light ? 'fadedWhite10' : 'background'

  return (
    <NavigationLi hoverBg={hoverBg}>
      <Link color={color} hoverColor={hoverColor} {...otherProps} {...attrs}>
        <P p="0 20px" lineHeight="2.8em" fontSize="1em">
          {children}
        </P>
      </Link>
    </NavigationLi>
  )
}
