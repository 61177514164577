export enum POPINS {
  AvatarPopin = 'AvatarPopin',
  AvatarsListPopin = 'AvatarsListPopin',
  ConnectPopin = 'ConnectPopin',
  LostPwdPopin = 'LostPwdPopin',
  ConfirmationPopin = 'ConfirmationPopin',
  DoubleFaPopin = 'DoubleFaPopin',
  InputPopin = 'InputPopin',
  CommunityNotificationsPopin = 'CommunityNotificationsPopin',
  PushBlockedPopin = 'PushBlockedPopin',
  CommunityLeavePopin = 'CommunityLeavePopin',
  SubmittedCommunityPopin = 'SubmittedCommunityPopin',
  CommunitiesPropositionsPopin = 'CommunitiesPropositionsPopin',
  LinuxDevPopin = 'LinuxDevPopin',
  CommunityPrayPopin = 'CommunityPrayPopin',
  UserPrayPopin = 'UserPrayPopin',
  ReportAbusePopin = 'ReportAbusePopin',
  InvitePopin = 'InvitePopin',
  InviteEmailPopin = 'InviteEmailPopin',
  WidgetPopin = 'WidgetPopin',
  PresubscriberDeletedPopin = 'PresubscriberDeletedPopin',
  BroadcastIntentionWidgetPopin = 'BroadcastIntentionWidgetPopin',
  PrayerPromptPopin = 'PrayerPromptPopin',
  CommunityMembersListPopin = 'CommunityMembersListPopin',
  CommunityCommentsListPopin = 'CommunityCommentsListPopin',
  PushRequestPopin = 'PushRequestPopin',
  AppPromptPopin = 'AppPromptPopin',
  FacebookConfirmPopin = 'FacebookConfirmPopin',
  RatePromptPopin = 'RatePromptPopin',
  MailDeliveryIssuePromptPopin = 'MailDeliveryIssuePromptPopin',
}
