import { addEntity, removeEntity, removeFromQueryData, updateEntity } from '@hozana/api/actions'
import type { TQuery, TQueryConstructor } from '@hozana/api/types'
import { normalize } from '@hozana/redux/normalize'

import entities, { TRawTestimony } from 'config/entities'

import type { TTestimony } from 'modules/testimony/types'
import { updateUser } from 'modules/user/actions'

type TTestimonyQueriesProps = {
  content: string
  userId: number
  testimonyId: number
}

export const getTestimoniesQuery: TQueryConstructor<
  { favorites?: boolean; limit?: number; deck?: number },
  number[]
> = ({ favorites, limit, deck } = {}): TQuery => ({
  queryKey: `getTestimonies${favorites ? '/favorites' : ''}`,
  url: `/testimonies`,
  meta: {
    limit: limit ?? favorites ? 8 : 20,
  },
  params: {
    returnFavorites: favorites,
    limit,
    deck,
  },
  normalize: (data: TRawTestimony[]) => normalize(data, [entities.testimony]),
})

export const getTestimonyQuery: TQueryConstructor<{ userId: number }, number> = ({ userId }) => ({
  queryKey: `getTestimony`,
  url: `/testimony`,
  onSuccess: (dispatch, testimony) => {
    dispatch(updateUser(userId, { testimony }))
  },
  normalize: (data: TRawTestimony[]) => normalize(data, entities.testimony),
})

/*
 * MUTATIONS
 */

export type TPostTestimonyMutationPayload = {
  content: string
  userId: number
}

export const postTestimonyMutation: TQueryConstructor<TPostTestimonyMutationPayload, TTestimony> = ({
  content,
  userId,
}: Pick<TTestimonyQueriesProps, 'content' | 'userId'>): TQuery => ({
  queryKey: 'testimonyCreate',
  url: '/testimony',
  method: 'POST',
  params: {
    content,
  },
  onSuccess: (dispatch, data) => {
    dispatch(updateUser(userId, { testimony: data.id }))
    dispatch(addEntity('testimony', data.id, data))
  },
})

export type TDeleteTestimonyMutationPayload = {
  userId: number
  testimonyId: number
}

export const deleteTestimonyMutation: TQueryConstructor<TDeleteTestimonyMutationPayload, void> = ({
  userId,
  testimonyId,
}: Pick<TTestimonyQueriesProps, 'userId' | 'testimonyId'>) => ({
  queryKey: `testimonyDelete/${testimonyId}`,
  url: `/testimony/${testimonyId}`,
  method: 'DELETE',
  onSuccess: (dispatch) => {
    dispatch(updateUser(userId, { testimony: null }))
    dispatch(removeEntity('testimony', testimonyId))
  },
})

export type TEditTestimonyMutationPayload = TPostTestimonyMutationPayload & { testimonyId: number }

export const editTestimonyMutation: TQueryConstructor<TEditTestimonyMutationPayload, TTestimony> = ({
  testimonyId,
  content,
}) => ({
  queryKey: `testimonyEdit/${testimonyId}`,
  url: `/testimony/${testimonyId}`,
  method: 'PATCH',
  params: {
    content,
  },
  onSuccess: (dispatch) => {
    dispatch(updateEntity('testimony', testimonyId, { content }))
  },
})

export const reportTestimonyMutation: TQueryConstructor<{ testimonyId: number; reason: any; lang: string }, void> = ({
  testimonyId,
  reason,
  lang,
}) => ({
  queryKey: `reportAbuse`,
  url: `/testimony/${testimonyId}/report-abuse`,
  method: 'POST',
  params: { reason },
  meta: { lang },
  onSuccess: (dispatch) => dispatch(removeFromQueryData(getTestimoniesQuery().queryKey, testimonyId)),
})
