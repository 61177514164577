import cookie from 'react-cookie'

import { COOKIES } from 'app/managers/cookies/cookieNames'

import { APP_TYPE } from '../constants'

export const isIOSWebview = (userAgent = __CLIENT__ ? navigator?.userAgent : '') =>
  cookie.load<APP_TYPE>(COOKIES.isNative) !== undefined ||
  /(iPhone|iPod|iPad)(?!.*Safari)|(WebView)/i.test(userAgent) ||
  /HozanaAppli/i.test(userAgent)
