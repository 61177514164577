export enum GUIDE_TYPE {
  BIBLE = 'bible',
  FEAST = 'feast',
  LIVE = 'live',
  PRAYER = 'prayer',
  SAINTS = 'saints',
  CATECHISM = 'catechism',
  MIRACLES = 'miracles-and-apparitions',
  ROSARY = 'rosary',
  MEDITATION = 'meditation',
}

const GUIDES_FR = {
  [GUIDE_TYPE.BIBLE]: 'bible',
  [GUIDE_TYPE.FEAST]: 'fetes',
  [GUIDE_TYPE.LIVE]: 'live',
  [GUIDE_TYPE.PRAYER]: 'priere',
  [GUIDE_TYPE.SAINTS]: 'saints',
  [GUIDE_TYPE.CATECHISM]: 'catechisme',
  [GUIDE_TYPE.MIRACLES]: 'miracles-et-apparitions',
  [GUIDE_TYPE.ROSARY]: 'chapelet',
  [GUIDE_TYPE.MEDITATION]: 'meditation',
}
const GUIDES_EN = {
  [GUIDE_TYPE.BIBLE]: 'bible',
  [GUIDE_TYPE.FEAST]: 'feast',
  [GUIDE_TYPE.LIVE]: 'live',
  [GUIDE_TYPE.PRAYER]: 'prayer',
  [GUIDE_TYPE.SAINTS]: 'saints',
  [GUIDE_TYPE.CATECHISM]: 'catechism',
  [GUIDE_TYPE.MIRACLES]: 'miracles-and-apparitions',
  [GUIDE_TYPE.ROSARY]: 'rosary',
  [GUIDE_TYPE.MEDITATION]: 'meditation',
}
const GUIDES_ES = {
  [GUIDE_TYPE.BIBLE]: 'biblia',
  [GUIDE_TYPE.FEAST]: 'fiestas',
  [GUIDE_TYPE.LIVE]: 'live',
  [GUIDE_TYPE.PRAYER]: 'oracion',
  [GUIDE_TYPE.SAINTS]: 'santos',
  [GUIDE_TYPE.CATECHISM]: 'catecismo',
  [GUIDE_TYPE.MIRACLES]: 'milagros-y-apariciones',
  [GUIDE_TYPE.ROSARY]: 'rosario',
  [GUIDE_TYPE.MEDITATION]: 'meditacion',
}
const GUIDES_PT = {
  [GUIDE_TYPE.BIBLE]: 'biblia',
  [GUIDE_TYPE.FEAST]: 'festas',
  [GUIDE_TYPE.LIVE]: 'live',
  [GUIDE_TYPE.PRAYER]: 'oracao',
  [GUIDE_TYPE.SAINTS]: 'santos',
  [GUIDE_TYPE.CATECHISM]: 'catecismo',
  [GUIDE_TYPE.MIRACLES]: 'milagres-e-aparicoes',
  [GUIDE_TYPE.ROSARY]: 'rosario',
  [GUIDE_TYPE.MEDITATION]: 'meditacao',
}
const GUIDES_IT = {
  [GUIDE_TYPE.BIBLE]: 'bibbia',
  [GUIDE_TYPE.FEAST]: 'feste',
  [GUIDE_TYPE.LIVE]: 'live',
  [GUIDE_TYPE.PRAYER]: 'preghiera',
  [GUIDE_TYPE.SAINTS]: 'santi',
  [GUIDE_TYPE.CATECHISM]: 'catechismo',
  [GUIDE_TYPE.MIRACLES]: 'miracoli-e-apparizioni',
  [GUIDE_TYPE.ROSARY]: 'rosario',
  [GUIDE_TYPE.MEDITATION]: 'meditazione',
}

const GUIDES_PL = {
  [GUIDE_TYPE.BIBLE]: 'biblia',
  [GUIDE_TYPE.FEAST]: 'swieto',
  [GUIDE_TYPE.LIVE]: 'live',
  [GUIDE_TYPE.PRAYER]: 'modlitwa',
  [GUIDE_TYPE.SAINTS]: 'swiety',
  [GUIDE_TYPE.CATECHISM]: 'katechizm',
  [GUIDE_TYPE.MIRACLES]: 'cuda-i-objawienia',
  [GUIDE_TYPE.ROSARY]: 'rozaniec',
  [GUIDE_TYPE.MEDITATION]: 'medytacja',
}

export const GUIDES = {
  fr: GUIDES_FR,
  en: GUIDES_EN,
  es: GUIDES_ES,
  pt: GUIDES_PT,
  it: GUIDES_IT,
  pl: GUIDES_PL,
}

export enum PARTNER {
  MEDITATIO = 'meditatio',
  ROSARIO = 'rosario',
}

export const GuideLinksByLang = {
  fr: [
    GUIDE_TYPE.PRAYER,
    GUIDE_TYPE.FEAST,
    GUIDE_TYPE.BIBLE,
    GUIDE_TYPE.SAINTS,
    GUIDE_TYPE.CATECHISM,
    GUIDE_TYPE.MIRACLES,
    GUIDE_TYPE.ROSARY,
    GUIDE_TYPE.MEDITATION,
  ],
  en: [
    GUIDE_TYPE.PRAYER,
    GUIDE_TYPE.FEAST,
    GUIDE_TYPE.BIBLE,
    GUIDE_TYPE.SAINTS,
    GUIDE_TYPE.CATECHISM,
    GUIDE_TYPE.MIRACLES,
    GUIDE_TYPE.ROSARY,
  ],
  es: [
    GUIDE_TYPE.PRAYER,
    GUIDE_TYPE.FEAST,
    GUIDE_TYPE.BIBLE,
    GUIDE_TYPE.SAINTS,
    GUIDE_TYPE.CATECHISM,
    GUIDE_TYPE.ROSARY,
  ],
  pt: [GUIDE_TYPE.PRAYER, GUIDE_TYPE.BIBLE, GUIDE_TYPE.SAINTS],
  it: [GUIDE_TYPE.PRAYER, GUIDE_TYPE.FEAST, GUIDE_TYPE.BIBLE, GUIDE_TYPE.SAINTS, GUIDE_TYPE.ROSARY],
  pl: [GUIDE_TYPE.PRAYER, GUIDE_TYPE.SAINTS, GUIDE_TYPE.BIBLE, GUIDE_TYPE.ROSARY],
}

// /!\ Also declared in full-ssr-functions.js
export const POPIN_CTA = 'guide-popin__button'
export const GUIDE_CARD = 'guide-card'
export const GUIDE_CARD_LINK = 'guide-card__link'
export const GUIDE_CARD_BUTTON = 'guide-card__button'
