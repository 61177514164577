import { selectEntity, selectQueryByKey } from '@hozana/api/selectors'
import { TQuery, TStoredQuery } from '@hozana/api/types'

import type { TState } from 'config/types'

import { getMeQueryKey } from 'modules/user/constants'
import { TMe } from 'modules/user/types'

export const selectUser = (state: TState, userId: number) => selectEntity(state, 'user', userId)

export const selectMe = (state: TState) => {
  const meQuery = selectQueryByKey<TStoredQuery<TQuery<TMe>>>(state, getMeQueryKey)
  return selectUser(state, meQuery?.data?.id)
}
