import type { Action, AnyAction } from 'redux'
import type { SimpleEffect } from 'redux-saga/effects'
import type { ThunkAction } from 'redux-thunk'
import type { SagaGenerator } from 'typed-redux-saga'
import { put as rawPut } from 'typed-redux-saga'

export { default as createSagaMiddleware } from 'redux-saga'

export {
  call,
  take,
  fork,
  cancel,
  delay,
  race,
  select,
  actionChannel,
  takeLatest,
  takeEvery,
  all,
} from 'typed-redux-saga'

type TActionOrThunkAction = Action | ThunkAction<any, any, any, Action>
interface TThunkPutEffectDescriptor<A extends TActionOrThunkAction> {
  action: A
  channel: null
  resolve?: boolean
}
type TThunkPutEffect<A extends TActionOrThunkAction = AnyAction> = SimpleEffect<'PUT', TThunkPutEffectDescriptor<A>>
type TPut = typeof rawPut
interface TThunkPut extends TPut {
  <A extends TActionOrThunkAction>(action: A): SagaGenerator<A, TThunkPutEffect<A>>
}

export const put = rawPut as TThunkPut

export { waitFor } from './waitFor'
