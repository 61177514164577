import type { Properties } from 'csstype'

export type TAlign =
  | 'topLeft'
  | 'top'
  | 'topCenter'
  | 'topRight'
  | 'topStretch'
  | 'topBetween'
  | 'topAround'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomCenter'
  | 'bottomRight'
  | 'bottomStretch'
  | 'bottomBetween'
  | 'bottomAround'
  | 'middleLeft'
  | 'middle'
  | 'middleCenter'
  | 'middleRight'
  | 'middleStretch'
  | 'middleBetween'
  | 'middleAround'
  | 'betweenLeft'
  | 'betweenCenter'
  | 'betweenRight'
  | 'stretchCenter'
  | 'center'
  | 'left'
  | 'right'
  | 'justify'

export type TAlignStyleProps = { align?: TAlign; column?: boolean }

export const alignStyle = ({
  align,
  column,
}: TAlignStyleProps): Pick<Properties, 'display' | 'flexDirection' | 'alignItems' | 'justifyContent' | 'textAlign'> => {
  if (!align) return undefined

  let alignItems = ''
  let justifyContent = ''

  // TODO: use regex to improve performances
  switch (align) {
    // TOP in vertical
    case 'topLeft':
      alignItems = 'flex-start'
      justifyContent = 'flex-start'
      break
    case 'top':
    case 'topCenter':
      alignItems = 'flex-start'
      justifyContent = 'center'
      break
    case 'topRight':
      alignItems = 'flex-start'
      justifyContent = 'flex-end'
      break
    case 'topStretch':
      column = true
      alignItems = 'stretch' // reverted : horizontal
      justifyContent = 'flex-start' // reverted : vertical
      break
    case 'topBetween':
      alignItems = 'flex-start'
      justifyContent = 'space-between'
      break
    case 'topAround':
      alignItems = 'flex-start'
      justifyContent = 'space-around'
      break
    // BOTTOM in vertical
    case 'bottomLeft':
      alignItems = 'flex-end'
      justifyContent = 'flex-start'
      break
    case 'bottom':
    case 'bottomCenter':
      alignItems = 'flex-end'
      justifyContent = 'center'
      break
    case 'bottomRight':
      alignItems = 'flex-end'
      justifyContent = 'flex-end'
      break
    case 'bottomStretch':
      column = true
      alignItems = 'stretch' // reverted : horizontal
      justifyContent = 'flex-end' // reverted : vertical
      break
    case 'bottomBetween':
      alignItems = 'flex-end'
      justifyContent = 'space-between'
      break
    case 'bottomAround':
      alignItems = 'flex-end'
      justifyContent = 'space-around'
      break
    // MIDDLE in vertical
    case 'middleLeft':
      alignItems = 'center'
      justifyContent = 'flex-start'
      break
    case 'middle':
      alignItems = 'center'
      justifyContent = undefined
      break
    case 'middleCenter':
      alignItems = 'center'
      justifyContent = 'center'
      break
    case 'middleRight':
      alignItems = 'center'
      justifyContent = 'flex-end'
      break
    case 'middleStretch':
      column = true
      alignItems = 'center'
      justifyContent = 'stretch'
      break
    case 'middleBetween':
      alignItems = 'center'
      justifyContent = 'space-between'
      break
    case 'middleAround':
      alignItems = 'center'
      justifyContent = 'space-around'
      break
    // BETWEEN in vertical
    case 'betweenLeft':
      alignItems = 'space-between'
      justifyContent = 'flex-start'
      break
    case 'betweenCenter':
      alignItems = 'space-between'
      justifyContent = 'center'
      break
    case 'betweenRight':
      alignItems = 'space-between'
      justifyContent = 'flex-end'
      break
    // STRETCH in vertical
    case 'stretchCenter':
      alignItems = 'stretch'
      justifyContent = 'center'
      break
    // simple horizontal align. don't use flex.
    case 'center':
      return { textAlign: 'center' }
    case 'left':
      return { textAlign: 'left' }
    case 'right':
      return { textAlign: 'right' }
    case 'justify':
      return { textAlign: 'justify' }
    default:
      return {} // invalid value, return nothing
  }

  return {
    display: 'flex',
    flexDirection: column ? 'column' : 'row',
    alignItems,
    justifyContent,
  }
}
