import { useEffect } from 'react'

import { useSyncedRef } from '@hozana/hooks/useSyncedRef'

export const MAX_TIMEOUT_DELAY = 2147483647 //This is the maximum delay supported by setTimeout() function

/**
 * ### useTimeout ###
 *
 * Run the callback once the delay has passed.
 *
 * Inspired from https://github.com/siddharthkp/use-timeout
 *
 * @param callback Callback that can be changed: it won't restart the timer
 * @param delay Delay in ms: set it to 0 to trigger the callback immediately
 * @param disabled (optional: default to false) The timer will be canceled and will remain unstarted if disabled is set to true
 */
export const useTimeout = (callback: () => void, delay: number, disabled = false): void => {
  const callbackRef = useSyncedRef(callback)

  // Set up the interval
  useEffect(() => {
    let validDelay = delay
    try {
      if (delay >= MAX_TIMEOUT_DELAY && !disabled) {
        validDelay = MAX_TIMEOUT_DELAY - 1
        throw new Error()
      }
    } catch (e) {
      if (process.env.ENV === 'dev') {
        console.error(`useTimeout: your delay has a value of ${delay} which is too high`, e)
      }
    }

    if (!disabled) {
      const tick = () => callbackRef.current?.()
      if (typeof validDelay === 'number') {
        const id = setTimeout(tick, validDelay)
        return () => clearTimeout(id)
      } else if (validDelay === 0) {
        tick()
      }
    }
    return undefined
  }, [delay, disabled, callbackRef])
}
