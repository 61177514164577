import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useInterval } from '@hozana/hooks/useInterval'
import { useOnline } from '@hozana/hooks/useOnline'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { deepEqual, sanitize } from '@hozana/utils/functions/objects'

import { fetchApi } from '../fetchApi'
import { addLangMeta } from '../functions'
import { selectQuery } from '../selectors'
import type { TQuery, TQueryData, TStoredQuery } from '../types'

/**
 * Check if a query changed from another.
 * It is not a mere deep-compare, but diagnoses if the query should be refetched or not.
 *
 * @return true if it changed, false otherwise.
 */
const hasQueryChanged = (query: TQuery, storedQuery: TStoredQuery, pollParams?: Partial<TQuery['params']>) => {
  if (query && !storedQuery) {
    return true
  }
  if (query === storedQuery) {
    return false
  }
  if (
    query.queryKey !== storedQuery.queryKey ||
    (query.method || 'GET') !== storedQuery.method ||
    query.url !== storedQuery.url
  ) {
    return true
  }
  if (!pollParams && query.params === storedQuery.params) {
    return false
  }
  // Sanitize params because server side queries are sanitized with the rest of the state
  if (deepEqual(sanitize({ ...query.params, ...pollParams }), sanitize({ ...storedQuery.params, ...pollParams }))) {
    return false
  }
  return true
}

/**
 * Query as defined in the useApiData
 */
export type TUseApiQuery<Q extends TQuery = TQuery> = {
  /** Query to fetch: A [TQuery object](types.ts). Ex: `getCommunitiesQuery()`. */
  query: Q
  /** No call to the API will be performed as long as this is true. */
  disabled?: boolean
  /**
   * If this parameter is set, the endpoint will be refetched every XX milliseconds.
   * New data will be automatically prepended at the beginning of existing data.
   * Note that polling is disabled when the window is not visible.
   */
  pollInterval?: number
  pollParams?: Partial<Q['params']>
  /**
   * If set to true, useApiData will not call the API
   * but only get the data available in the Redux store.
   * This can be useful to avoid multiple queries.
   */
  cacheOnly?: boolean
  /** If set to true, useApiData will not look at the Redux store for cached queries. */
  noCache?: boolean
}

export type TFetchMore<Query extends TQuery = TQuery> = (
  options?: (Partial<TQuery> & { offset?: number; limit?: number }) | number,
) => Promise<void | TQueryData<Query>>
type TRefetch<Query extends TQuery = TQuery> = (queryUpdates?: Partial<TQuery>) => Promise<void | TQueryData<Query>>

export type TQueryDetails<Query extends TQuery = TQuery> = {
  /**
   * This function can be used to refetch if the query failed or need to be updated.
   * Additionnally, you can pass a partial [TQuery object](types.ts) as a first argument of the function to update the query.
   */
  refetch?: TRefetch<Query>
  /**
   * If the query has a defined `meta.limit` property, this fetchMore utility will be available to fetch more entities
   * based on the `limit` and `offset` parameters. You can set a new limit in the first argument of the method,
   * otherwise it will take the existing `limit` parameter.
   */
  fetchMore?: TFetchMore<Query>
} & Partial<TStoredQuery<Query>>

/**
 * ### useApiData ###
 *
 * When you need to get data to a component, you will most likely use this hook,
 * which can be used like this:
 *
 * ```ts
 * import { useApiData } from '@hozana/api/useApiData'
 * import { getAnnouncements } from 'modules/community/queries'
 *
 * export const AnyComponent: React.FC<{ communityId: number }> = ({ communityId }) => {
 *   const [announcements, announcementsQuery] = useApiData(() => ({
 *     query: getAnnouncements({ communityId }),
 *   }), [communityId])
 *
 *   return (
 *     // [...]
 *   )
 * }
 * ```
 *
 * This hook automatically makes a call to `fetchApi` on mount,
 * and also whenever one of the dependencies in the dependencies array (the second argument) changed.
 *
 * @param getUseApiQuery useApiData takes, as first argument,
 * a function returning a TUseApiQuery object as defined above.
 * @param dependencies useEffect-like dependencies array that triggers a new call to the API on change
 * @returns a tuple `[result, queryDetails]`, where result is the query normalized result,
 * and queryDetails is a TQueryDetails object as defined above
 * that contains details about the query and utility functions.
 */
export const useApiData = <Query extends TQuery = TQuery>(
  getUseApiQuery: () => TUseApiQuery<Query>,
  dependencies: any[],
): [TQueryData<Query> | undefined, TQueryDetails<Query>] => {
  const {
    i18n: { language },
  } = useTranslation()
  const online = useOnline()

  const apiQueryRef = useRef<TUseApiQuery>(getUseApiQuery())

  // Keep apiQuery ref fresh
  apiQueryRef.current = getUseApiQuery()

  const { pollInterval, pollParams } = apiQueryRef.current

  const dispatch = useDispatch()
  const storedQuery = useSelector<TStoredQuery>((state) => selectQuery(state, apiQueryRef.current.query))
  const queryHasChanged = hasQueryChanged(apiQueryRef.current.query, storedQuery, pollParams)

  const fetch = useCallback(
    (queryToFetch: TQuery): Promise<TQueryData<Query> | void> =>
      dispatch(fetchApi(addLangMeta(queryToFetch, language), true)).catch((error) => {
        console.error(`Failed to fetch ${queryToFetch.url}:`, error)
      }),
    [dispatch, language],
  )

  // Fetching
  useEffect(() => {
    const { query, disabled, cacheOnly, noCache } = apiQueryRef.current

    if (
      !disabled &&
      !cacheOnly &&
      // No need to fetch if the query is in cache and we can use it
      (noCache || queryHasChanged)
    ) {
      fetch(query)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, queryHasChanged, storedQuery?.error, ...dependencies])

  // Polling: only if we are not already loading data, if the document is visible and if we are online
  useInterval(
    () =>
      fetch({
        ...apiQueryRef.current.query,
        params: { ...apiQueryRef.current.query.params, ...pollParams },
        meta: {
          ...storedQuery?.meta,
          offset: 0,
          polling: true,
          prependData: true,
          appendData: false,
          fetchingMore: false,
          refetching: false,
        },
      }),
    pollInterval,
    !__CLIENT__ || !pollInterval || storedQuery?.loading || document.visibilityState !== 'visible' || !online,
  )

  const refetch = useCallback(
    (queryUpdate?: Partial<TQuery>) =>
      fetch({
        ...apiQueryRef.current.query,
        ...queryUpdate,
        meta: {
          ...apiQueryRef.current.query.meta,
          ...queryUpdate?.meta,
          refetching: true,
          fetchingMore: false,
          polling: false,
        },
      }),
    [fetch],
  )

  /**
   * fetchMore
   * Should be available only if there is a meta.limit
   */
  const fetchMore = useCallback(
    (options?: (Partial<TQuery> & { offset?: number; limit?: number }) | number) => {
      const {
        offset = storedQuery?.data?.length,
        limit,
        ...queryUpdates
      } = typeof options === 'object'
        ? options
        : ({ limit: options } as Partial<TQuery> & { offset?: number; limit?: number })

      return fetch({
        ...apiQueryRef.current.query,
        ...queryUpdates,
        meta: {
          ...queryUpdates?.meta,
          limit: limit || apiQueryRef.current.query?.meta?.limit || storedQuery?.meta?.limit,
          offset,
          fetchingMore: true,
          appendData: true,
          prependData: false,
          refetching: false,
          polling: false,
        },
      })
    },
    [fetch, storedQuery?.data?.length, storedQuery?.meta?.limit],
  )

  return useMemo(
    () =>
      [
        storedQuery?.data ?? undefined,
        {
          meta: {},
          loading: true,
          ...storedQuery,
          refetch,
          ...((apiQueryRef.current.query?.meta?.limit || storedQuery?.meta?.limit) && { fetchMore }),
        },
      ] as [TQueryData<Query>, TQueryDetails<Query>],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchMore, refetch, storedQuery, ...dependencies],
  )
}
