import React from 'react'

import type { TIntentionsWidget } from 'modules/intention/types'

export const IntentionsWidgetContext = React.createContext<TIntentionsWidget>(undefined)

export type TIntentionsWidgetProviderProps = { widget?: TIntentionsWidget }

export const IntentionsWidgetProvider: React.FC<TIntentionsWidgetProviderProps> = ({ children, widget }) => (
  <IntentionsWidgetContext.Provider value={widget}>{children}</IntentionsWidgetContext.Provider>
)
