export enum PUBLICATION_FILTERS {
  PUBLISHED = 'published',
  SCHEDULED_AND_DRAFTS = 'scheduledAndDrafts',
  UNPUBLISHED = 'unpublished',
}

export enum PUBLICATION_SOURCES {
  NEWS_FEED = 'news_feed',
  COMMUNITY_PUBLICATIONS = 'communityPublications',
  FAVORITE_PUBLICATIONS = 'favoritePublications',
}

export enum PUBLICATION_ORDER {
  DRAFTS_FIRST = 'drafts_first',
  FRESHNESS = 'freshness',
  SEO_INDEX = 'seo_index',
}

export const PUBLICATION_MAX_WIDTH = '712px'
