import { withRouter } from 'next/router'
import React from 'react'

import type { TRouter } from '@hozana/router'
import { Sentry } from '@hozana/tracking/sentry'
import { deepEqual } from '@hozana/utils/functions/objects'

import { Error500PageSimple } from 'app/pages/Error500PageSimple'

import { getDeviceInfo } from 'modules/notification/functions'

type TErrorBoundaryState = {
  hasError: boolean
}

interface ErrorBoundaryManagerWithRouterProps {
  router: TRouter
  showHeader?: boolean
}

// ErrorBoundary component must be a class component. See https://reactjs.org/docs/error-boundaries.html?
class ErrorBoundaryManagerWithRouter extends React.Component<ErrorBoundaryManagerWithRouterProps, TErrorBoundaryState> {
  state: TErrorBoundaryState

  constructor(props: ErrorBoundaryManagerWithRouterProps) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    Sentry.captureMessage(`${error.name}: ${error.message}`, 'error', {
      deviceInfo: getDeviceInfo({ getObject: true }),
    })
  }

  componentDidUpdate(prevProps: ErrorBoundaryManagerWithRouterProps) {
    if (
      this.state.hasError &&
      (prevProps.router.pathname !== this.props.router.pathname ||
        !deepEqual(prevProps.router.query, this.props.router.query))
    ) {
      this.props.router.reload()
    }
  }

  render() {
    if (this.state.hasError) {
      return <Error500PageSimple showHeader={this.props.showHeader} />
    }
    return this.props.children
  }
}

export const ErrorBoundaryManager = withRouter(ErrorBoundaryManagerWithRouter)
