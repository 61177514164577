import React, { MouseEvent } from 'react'

import { useDispatch } from '@hozana/redux/hooks'
import { QA } from '@hozana/tests/constants'

import { Button, TButtonProps } from 'elements/button/Button'

import { openPopin } from 'app/actions'
import { POPINS } from 'app/managers/popins/constants'
import { TBackToPopin } from 'app/managers/popins/types'

import { SHARE_OBJECTS } from 'modules/share/constants'

import type { TInvitePopinOptions } from '../popins/InvitePopin'

export type TInviteButtonProps = TInvitePopinOptions &
  TButtonProps & {
    backToPopin?: TBackToPopin
  }

export const InviteButton: React.FC<TInviteButtonProps> = ({
  objectToShare = SHARE_OBJECTS.HOZANA,
  utmContent,
  trackingSource,
  community,
  publication,
  children = 'trans:share:share.invite-friends.short',
  onClick,
  backToPopin,
  ...otherProps
}) => {
  const dispatch = useDispatch()

  return (
    <Button
      {...otherProps}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        onClick?.(e)
        dispatch(
          openPopin(
            POPINS.InvitePopin,
            { objectToShare, utmContent, trackingSource, community, publication, backToPopin: backToPopin },
            backToPopin,
          ),
        )
      }}
      data-testid={QA.SHARE_COMMON.INVITE_BUTTON}
    >
      {children}
    </Button>
  )
}
