import { ICON } from 'config/icons'

export const ALERT_COLORS = {
  transparent: {
    bg: 'transparent',
    color: 'black',
  },
  neutral: {
    bg: 'black10',
    color: 'black70',
  },
  success: {
    bg: 'lightGreen',
    color: 'darkGreen',
  },
  info: {
    bg: 'lightBlue',
    color: 'blue',
  },
  important: {
    bg: 'whiteOrange',
    color: 'orange',
  },
  error: {
    bg: 'lightRed',
    color: 'red',
  },
  special: {
    bg: 'lightPurple',
    color: 'purple',
  },
} as const

export const FLASH_ICONS = {
  success: {
    icon: ICON.CHECK_SOLID,
    color: 'green',
  },
  info: {
    icon: ICON.INFO_SOLID,
    color: 'blue',
  },
  important: {
    icon: ICON.WARNING_SOLID,
    color: 'orange',
  },
  error: {
    icon: ICON.ERROR_SOLID,
    color: 'red',
  },
  debug: {
    icon: ICON.MAGNIFIER,
    color: 'orange',
  },
} as const

export const BUTTON_BASE_CLASSNAME = 'button-base'
