import { messageSW as rawMessageSW } from 'workbox-window'

import { SERVICE_WORKER } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'
import { Sentry } from '@hozana/tracking/sentry'
import {
  TAsyncRetryOptions,
  TAsyncWithTimeoutOptions,
  asyncRetryWithTimeout,
  withErrorDetails,
} from '@hozana/utils/functions/errors'

import { API_PATHNAME } from 'routes/constants'
import { SW_CONTEXT } from 'sw/constants'
import { swMessage, windowMessage } from 'sw/messages'

export { rawMessageSW }

type TAsyncOptions = {
  extras?: TAnyProps
  rethrow?: boolean
  errorHandling?: TAsyncRetryOptions & TAsyncWithTimeoutOptions
}

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const getServiceWorkerRegistration = () =>
  withErrorDetails(
    () =>
      asyncRetryWithTimeout(() => navigator.serviceWorker?.ready, {
        attempts: 3,
        attemptTime: 5000,
        delayBetweenAttempts: 2000,
        timeoutMessage: 'navigator.serviceWorker.ready - timeout',
      }),
    'an error occurred while trying to get navigator.serviceWorker.ready',
  )

export const getServiceWorkerSubscription = (registration: ServiceWorkerRegistration) =>
  withErrorDetails(
    () =>
      // Here, `registration.pushManager.getSubscription` cannot be given as is as asyncRetryWithTimeout first argument:
      // it would lose the pushManager context (this) implementing interface PushManager
      asyncRetryWithTimeout(() => registration.pushManager.getSubscription(), {
        attempts: 3,
        attemptTime: 2000,
        delayBetweenAttempts: 1000,
        timeoutMessage: 'registration.pushManager.getSubscription - timeout',
      }),
    'an error occurred while trying to registration.pushManager.getSubscription()',
  )

export const subscribePush = async () => {
  const registration = await getServiceWorkerRegistration()

  if (!registration?.active) {
    console.debug('No active registration found: cannot get subscription.', { registration })
    return null
  }

  // Subscribe again to update the endpoint, even it the subscription already exists, because is expires quickly
  return withErrorDetails(
    () =>
      asyncRetryWithTimeout(
        () =>
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              'BKFITWBJvDJBQyRqpNB17nIy0p4xyuvyc-HgS7O87Mb0dUkMWRePnWdk8DD-Mr6Z-6NBqqQfrr8gk2u3Feqtkq8',
            ),
          }),
        {
          attempts: 3,
          attemptTime: 5000,
          delayBetweenAttempts: 1000,
          timeoutMessage: 'registration.pushManager.subscribe - timeout',
        },
      ),
    'an error occurred while trying to registration.pushManager.subscribe({ ... })',
  )
}

export const getReloadCount = async (options?: TAsyncOptions) => {
  try {
    const response = await asyncRetryWithTimeout(() => fetch(API_PATHNAME.GET_RELOAD_COUNT), {
      attempts: 3,
      attemptTime: 5000,
      delayBetweenAttempts: 1000,
      timeoutMessage: 'fetch reloadCount - timeout',
      ...options?.errorHandling,
    })
    const { reloadCount } = (await response.json()) as { reloadCount: number }
    return reloadCount
  } catch (error) {
    if (options?.rethrow) {
      throw error
    } else {
      Sentry.captureException(error, {
        context: SW_CONTEXT.UPDATE,
        details: 'impossible to fetch reload count',
      })
      return CONF_KEYS.RELOAD_COUNT
    }
  }
}

type TSwMessage = ReturnType<TObjectValues<typeof swMessage>>

export const messageSomeSW = async <T>(
  getSw: () => Promise<ServiceWorker>,
  swName: string,
  message: TSwMessage,
  options?: TAsyncOptions,
) => {
  try {
    return await asyncRetryWithTimeout(
      async () => {
        console.debug(`Messaging ${swName} service worker:`, message.type)
        const sw = await getSw()
        if (sw) {
          return await rawMessageSW<T>(sw, message)
        } else {
          throw new Error(
            `cannot send message (type: ${message.type}) to ${swName} service worker: service worker undefined`,
          )
        }
      },
      { attemptTime: 1000, timeoutMessage: `timeout trying to send message to ${swName}`, ...options?.errorHandling },
    )
  } catch (error) {
    if (options?.rethrow) {
      throw error
    } else {
      Sentry.captureException(error, {
        context: SW_CONTEXT.UPDATE,
        details: `error while sending a ${message.type} message to the ${swName} service worker`,
        rate: 0.1,
        ...options?.extras,
      })
      return undefined
    }
  }
}

export const messageSW = {
  controller: <T>(message: TSwMessage, options?: TAsyncOptions) =>
    messageSomeSW<T>(async () => navigator.serviceWorker?.controller, 'controller', message, options),
  waiting: <T>(message: TSwMessage, options?: TAsyncOptions) =>
    messageSomeSW<T>(
      async () => {
        const registration = await navigator.serviceWorker.getRegistration()
        return registration.waiting
      },
      'waiting',
      message,
      options,
    ),
}

export const deleteAllCaches = async () => {
  try {
    const keys = await caches.keys()
    return (await Promise.all(keys.map((key) => caches.delete(key)))).every(Boolean)
  } catch (error) {
    if (error instanceof DOMException && error.message === 'The operation is insecure.') {
      // Known Firefox error
    } else {
      Sentry.captureException(error, {
        context: SW_CONTEXT.UNREGISTER,
        details: 'error while deleting caches',
      })
    }
    return false
  }
}

export const unregisterAllServiceWorkers = async () => {
  try {
    const registrations = await navigator.serviceWorker?.getRegistrations()
    return (await Promise.all(registrations.map((registration) => registration.unregister()))).every(Boolean)
  } catch (error) {
    Sentry.captureException(error, {
      context: SW_CONTEXT.UNREGISTER,
      details: 'error while unregistering all service workers',
    })
    return false
  }
}

export const isForceReloadNeeded = async () => {
  // Old service worker (born before 10/2022) don't support getReloadCount
  // and has different timings than messageSW
  const activeReloadCount = (await messageSW.controller<number | undefined>(swMessage.getReloadCount())) ?? 0
  const waitingReloadCount =
    (await messageSW.waiting<number | undefined>(swMessage.getReloadCount())) ?? (await getReloadCount())

  return waitingReloadCount > activeReloadCount
}

/** workbox.messageSkipWaiting seems not to work every time... */
export const skipWaiting = async () => {
  try {
    await messageSW.waiting(swMessage.skipWaiting(), { rethrow: true })
    return true
  } catch (error) {
    Sentry.captureException(error, {
      context: SW_CONTEXT.SKIP_WAITING,
      details: 'error while asking waiting sw to skip waiting',
    })
    return await unregisterAllServiceWorkers()
  }
}

export const skipWaitingAndReloadAllInvisibleWindows = async () => {
  await skipWaiting()
  await messageSW.controller(swMessage.messageAllWindows(windowMessage.reloadIfInvisible()))
}

export const registerServiceWorker = async () => {
  try {
    console.debug('Registering service worker...')
    const registration = await asyncRetryWithTimeout(
      async () => {
        if (!window?.workbox) {
          throw new Error('Cannot initialize service worker when workbox is not available')
        }
        return await window.workbox.register()
      },
      {
        attempts: 5,
        attemptTime: 10000,
        delayBetweenAttempts: 1000,
        timeoutMessage: 'window.workbox.register() - timeout',
      },
    )

    console.debug('Service worker registration was performed successfully', {
      scope: registration.scope,
    })
    GTM.push({ serviceWorker: SERVICE_WORKER.ACTIVE })
    Sentry.addBreadcrumb({
      message: 'service worker was successfully initialized',
      level: 'info',
      category: SW_CONTEXT.INIT,
    })
    return registration
  } catch (error) {
    if (CONF_KEYS.ENV === 'dev') {
      return undefined
    }
    // Many users will fall here as window.workbox often seems to be undefined
    Sentry.captureException(error, {
      context: SW_CONTEXT.INIT,
      details: 'an error occurred while trying to window.workbox.register()',
      rate: 0.1,
    })

    GTM.push({ serviceWorker: SERVICE_WORKER.INACTIVE })

    // Check if local reload count is in sync with server reload count: delete all caches and sw if not
    const reloadCount = await getReloadCount()
    if (reloadCount > CONF_KEYS.RELOAD_COUNT) {
      await Promise.all([deleteAllCaches(), unregisterAllServiceWorkers()])
    }

    return undefined
  }
}
