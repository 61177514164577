const API_PATHNAME = /** @type {const} */ ({
  GET_TEST_STATUS: '/api/front/dev/getTestStatus',
  LAUNCH_TESTS: '/api/front/dev/launchTests',
  REDIRECT_DIRECTLY_TO_URL: '/api/front/redirectDirectlyToUrl/[prefix]/[redirectId]',
  GET_RELOAD_COUNT: '/api/front/getReloadCount',
  IOS_START: '/api/front/iosStart',
  REDIRECT_AFTER_FACEBOOK_SHARE: '/api/front/redirectAfterFacebookShare',
  SAVE_TOKEN: '/api/front/saveToken',
  SHARE_ON_WHATSAPP: '/api/front/shareOnWhatsapp',
  METRICS_BUILD_PAGE: '/api/front/metrics/buildPage',
})

module.exports = { API_PATHNAME }
