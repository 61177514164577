import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useRouter } from '@hozana/router'
import { getFirstQueryValue } from '@hozana/router/functions'

import { Form } from 'elements/input/Form'
import { SearchInput, TSearchInputProps } from 'elements/ui/SearchInput'

export type TSearchFormProps = Partial<TSearchInputProps>
type TSearchFormFormData = {
  keyword?: string
}

export const SearchForm: React.FC<TSearchFormProps> = (props) => {
  const { query } = useRouter()

  const form = useForm({ values: { keyword: getFirstQueryValue(query.keyword) } })

  const { handleSubmit, register, setValue } = form

  const onClear = () => {
    props.onClear?.()
    window.location.href = '/communities'
    setValue('keyword', '')
  }

  const onSubmit: SubmitHandler<TSearchFormFormData> = ({ keyword }) => {
    window.location.href = `/communities?keyword=${keyword}`
    if (document.activeElement) {
      ;(document.activeElement as HTMLElement).blur?.()
    }
  }

  return (
    <Form id="SearchForm" form={form} onSubmit={handleSubmit(onSubmit)}>
      <SearchInput
        {...register('keyword')}
        placeholder="community:app.navigate.search-hozana.search"
        autoComplete="off"
        {...props}
        onClear={onClear}
      />
    </Form>
  )
}
