import React, { type ButtonHTMLAttributes, type FC } from 'react'
import styled, { type IntrinsicElementsKeys, keyframes } from 'styled-components'

import { Link, TLinkProps } from 'elements/text/Link'

const ripple = keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: .5;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
`

export type TRippledButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Link props
   * If valueLink is defined, the button will render as a Link.
   *
   * As long as styled-components is not updated to v6,
   * this prop must be named valueLink or any other name that is a valid attribute:
   * check if import('@emotion/is-prop-valid').validAttr(propName) is true.
   */
  valueLink?: TLinkProps | false
} & {
  as?: IntrinsicElementsKeys | FC
  'data-testid'?: string
} & Pick<TLinkProps, 'displayBlock'>

type TAsLinkProps = { valueLink: TLinkProps } & Pick<TLinkProps, TKeysMatching<TRippledButtonProps, keyof TLinkProps>>

const AsLink: React.FC<TAsLinkProps> = ({ valueLink, ...otherProps }) => <Link {...otherProps} {...valueLink} />

export const RippledButton = styled.button.attrs<TRippledButtonProps, TRippledButtonProps>(({ valueLink }) =>
  valueLink ? { as: AsLink } : {},
)`
  /* necessary */
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: currentColor;
    visibility: hidden;
    z-index: 2;
  }
  &:not(:active):before {
    animation: ${ripple} 0.4s cubic-bezier(0, 0, 0.2, 1);
    transition: visibility 0.4s step-end;
  }
  &:active:before {
    visibility: visible;
  }
`
