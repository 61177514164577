import 'regenerator-runtime/runtime'

import { produce } from 'immer'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import type { MakeStore } from 'next-redux-wrapper'
import { StoreEnhancer, applyMiddleware, combineReducers, compose, createStore } from 'redux'
import type { AnyAction, Middleware, Reducer, Store } from 'redux'
import type { Task } from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

import { all, createSagaMiddleware } from '@hozana/sagas'
import { cookie } from '@hozana/storage/cookies'
import { deepMerge } from '@hozana/utils/functions/objects'

import { COOKIES } from 'app/managers/cookies/constants'
import reducers from 'config/reducers'
import getAllSagas from 'config/sagas'
import type { TState } from 'config/types'

import { selectMe } from 'modules/user/selectors'

const bindMiddleware = (middlewares: Middleware[], enhancers: StoreEnhancer<any, TNoMoreProps>[] = []) => {
  if (CONF_KEYS.ENV !== 'prod' || cookie.load(COOKIES.debugMode)) {
    const { composeWithDevTools } = require('@redux-devtools/extension')
    return composeWithDevTools({ maxAge: 50 })(applyMiddleware(...middlewares), ...enhancers)
  }
  return compose(applyMiddleware(...middlewares), ...enhancers)
}

const rootReducer: Reducer = (state, action) => {
  if (action.type === HYDRATE) {
    return produce<TState>(state, (newState) => {
      if (typeof newState === 'object' && typeof action.payload === 'object') {
        const me = selectMe(newState)
        const prevPrayersCount = me?.intention && newState.api.entities.intention[me.intention]?.prayersCount

        // Merge new state in current state
        Object.assign(newState, deepMerge(newState, action.payload as TState))

        // Overwrite title
        newState.app.title = (action.payload as TState).app?.title
        // Restore prayersCount on my intention
        if (prevPrayersCount) {
          newState.api.entities.intention[me.intention].prayersCount = prevPrayersCount
        }
      }
    })
  } else {
    return combineReducers(reducers)(state, action)
  }
}

export const initializeStore = (...enhancers: StoreEnhancer<any, TNoMoreProps>[]): Store<TState> => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore<TState, AnyAction, { sagaTask: Task }, TNoMoreProps>(
    rootReducer,
    bindMiddleware([thunkMiddleware, sagaMiddleware], enhancers),
  )

  store.sagaTask = sagaMiddleware.run(function* () {
    yield* all(getAllSagas())
  })

  const { hot } = module as NodeModule & { hot?: { accept: (path: string, callback: VoidFunction) => void } }

  if (hot) {
    hot.accept('config/reducers', () => {
      const nextReducers = require('../../config/reducers').default
      store.replaceReducer(nextReducers)
    })
    hot.accept('config/sagas', () => {
      const getNewSagas = require('../../config/sagas').default
      store.sagaTask.cancel()
      store.sagaTask
        .toPromise()
        .then(() => {
          store.sagaTask = sagaMiddleware.run(function* () {
            yield* all(getNewSagas())
          })
        })
        .catch(() => {
          /* Do nothing */
        })
    })
  }

  if (__CLIENT__ && (cookie.load(COOKIES.debugMode) || 'Cypress' in window)) {
    window.store = store
  }

  return store
}

export const makeStore: MakeStore<Store<TState>> = (_context) => initializeStore()

export const wrapper = createWrapper<Store<TState>>(makeStore, { debug: false })
