import { removeEntity } from '@hozana/api/actions'
import type { TQueryConstructor } from '@hozana/api/types'
import { normalize } from '@hozana/redux/normalize'

import entities, { TRawIntention } from 'config/entities'
import { TLocale } from 'i18n/constants'

import type { TIntentionsWidget } from 'modules/intention/types'

export type TGetIntentionsPayload = {
  level?: number
  widgetId?: number
  exceptIntentionsIds?: number[]
  landingPageSlug?: string
}

export const getIntentionsQuery: TQueryConstructor<TGetIntentionsPayload, number[]> = ({
  level = 0,
  widgetId,
  exceptIntentionsIds,
  landingPageSlug,
} = {}) => ({
  queryKey: `${['getIntentions', landingPageSlug].filter(Boolean).join('/')}`,
  url: '/intentions',
  params: {
    level,
    widgetId,
    exceptIntentionsIds,
    landingPageSlug,
  },
  normalize: (data: TRawIntention[]) => normalize(data, [entities.intention]),
})

export const getIntentionQuery: TQueryConstructor<
  { intentionId: number; hash?: string; shareToken?: string },
  number
> = ({ intentionId, hash, shareToken }) => ({
  queryKey: `getIntention-${intentionId}`,
  url: `/intention/${intentionId}`,
  params: { hash, shareToken },
  normalize: (data: TRawIntention) => normalize(data, entities.intention),
})

export const getWidgetQuery: TQueryConstructor<{ widgetId: number }, TIntentionsWidget> = ({ widgetId }) => ({
  queryKey: `getWidget`,
  url: `/widget/${widgetId}`,
  normalize: (result) => ({ result: { id: widgetId, ...result } }),
})

/*
 * MUTATIONS
 */

export const postIntentionMutation: TQueryConstructor<
  {
    content: string
    guestName: string
    widgetId: number
    landingPageSlug: string
    email: string
    lang?: TLocale
  },
  number
> = ({ content, guestName, widgetId, landingPageSlug, email }) => ({
  queryKey: 'postIntention',
  url: '/intention',
  method: 'POST',
  params: { content, guestName, widgetId, landingPageSlug, email },
  normalize: (data: TRawIntention) => normalize(data, entities.intention),
})

export const editIntentionMutation: TQueryConstructor<
  {
    content: string
    guestName: string
    intentionId: number
    email: string
    broadcastOnHozana?: boolean
    hash: string
    lang?: TLocale
  },
  number
> = ({ content, guestName, intentionId, email, broadcastOnHozana, hash, lang }) => ({
  queryKey: `editIntention/${intentionId}`,
  url: `/intention/${intentionId}`,
  method: 'PATCH',
  params: { content, guestName, email, broadcastOnHozana, hash },
  meta: { lang },
  normalize: (data: TRawIntention) => normalize(data, entities.intention),
})

export const deleteIntentionMutation: TQueryConstructor<
  { intentionId?: number; hash?: string; lang?: TLocale },
  void
> = ({ intentionId, hash, lang } = {}) => ({
  queryKey: 'deleteIntention',
  url: `/intention${intentionId ? '/' + intentionId : ''}`,
  method: 'DELETE',
  params: { hash },
  meta: { lang },
  onSuccess: (dispatch) => {
    if (intentionId) {
      dispatch(removeEntity('intention', intentionId))
    }
  },
})

export const reportIntentionMutation: TQueryConstructor<{ intentionId: number; reason: any; lang: string }, void> = ({
  intentionId,
  reason,
  lang,
}) => ({
  queryKey: `reportAbuse`,
  url: `/intention/${intentionId}/report-abuse`,
  method: 'POST',
  params: { reason },
  meta: { lang },
  onSuccess: (dispatch) => dispatch(removeEntity('intention', intentionId)),
})

export const sendIntentionsViewsMutation: TQueryConstructor<{ intentionIds: number[] }, void> = ({ intentionIds }) => ({
  queryKey: 'sendIntentionsViews',
  url: '/intentions/views',
  method: 'POST',
  params: { ids: intentionIds },
})

export const sendIntentionsNewsletterMutation: TQueryConstructor<
  { email: string; name: string; lang: TLocale },
  void
> = ({ email, name, lang }) => ({
  queryKey: 'sendIntentionsNewsletter',
  url: `/newsletter`,
  method: 'PUT',
  params: { email, name },
  meta: { lang },
})

export const boostIntentionMutation: TQueryConstructor<void, void> = () => ({
  queryKey: 'boostIntention',
  url: `/me/intention/boost`,
  method: 'POST',
})
