import styled, { DefaultTheme } from 'styled-components'

import { Li, TLiProps } from 'elements/layout/Li'

type TStyledLiProps = {
  hoverBg?: keyof DefaultTheme['colors']
} & TLiProps

export const NavigationLi = styled(Li)<TStyledLiProps>`
  border-radius: 16px;
  &:hover {
    background: ${(p) => p.theme.colors[p.hoverBg]};
  }
`
