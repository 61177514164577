import { OS } from '@hozana/screen/constants'

import { getDeviceInfo } from 'modules/notification/functions'

export const getOs = (): OS | null => {
  if (!__CLIENT__) {
    return null
  }

  const { os } = getDeviceInfo({ getObject: true })

  if (os.name === 'macOS') {
    return OS.MAC_OS
  }
  if (os.name === 'iOS') {
    return OS.IOS
  }
  if (os.name === 'Android') {
    return OS.ANDROID
  }
  if (os.name === 'Windows') {
    return OS.WINDOWS
  }
  if (os.name === 'Linux') {
    return OS.LINUX
  }
  return null
}
