import type { Properties } from 'csstype'
import type { HTMLAttributes } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

import { defaultStyledConfig } from '@hozana/styling/config'
import { mergeStyles } from '@hozana/styling/mergeStyles'
import {
  TResponsiveProps,
  TResponsiveVisibilityProps,
  responsiveStyle,
  responsiveVisibility,
} from '@hozana/styling/responsive'

const responsiveProps = { m: 'margin', p: 'padding', w: 'width', h: 'height' } as const

export type TSpanProps = {
  left?: boolean
  right?: boolean
  vAlign?: Properties['verticalAlign']
  alignMiddle?: boolean
  minW?: Properties['minWidth']
  maxW?: Properties['maxWidth']
  minH?: Properties['minHeight']
  h?: Properties['height']
  bg?: keyof DefaultTheme['colors']
  color?: keyof DefaultTheme['colors']
  borderRadius?: Properties['borderRadius']
} & TResponsiveProps<typeof responsiveProps> &
  TResponsiveVisibilityProps &
  HTMLAttributes<HTMLSpanElement>

export const Span = styled('span')
  .withConfig<TSpanProps>(defaultStyledConfig())
  .attrs<TSpanProps, TSpanProps>(
    ({ theme, bg, borderRadius, color, h, left, maxW, minH, minW, right, style, vAlign }) => ({
      style: mergeStyles(style, {
        ...((left || right) && { float: left ? 'left' : 'right' }),
        ...(color && { color: theme.colors[color] }),
        ...(bg && { backgroundColor: theme.colors[bg] }),
        borderRadius,
        height: h,
        minWidth: minW,
        minHeight: minH,
        maxWidth: maxW,
        verticalAlign: vAlign,
      }),
    }),
  )`
  display: inline-block;
  position: relative;
  ${responsiveStyle(responsiveProps)}
  ${(props) =>
    props.alignMiddle &&
    css`
      > * {
        vertical-align: middle;
      }
    `};
  ${responsiveVisibility}
`
