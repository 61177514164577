import React, { FocusEventHandler, InputHTMLAttributes, MouseEventHandler } from 'react'
import type { WrappedFieldProps } from 'redux-form'
import styled, { DefaultTheme, css } from 'styled-components'

import { useTranslate } from '@hozana/intl/useTranslate'
import { defaultStyledConfig } from '@hozana/styling/config'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'
import { mergeRefs } from '@hozana/utils/functions/refs'
import { createUUID } from '@hozana/utils/functions/strings'

const responsiveProps = { fontSize: 'font-size', w: 'width', h: 'height', align: 'text-align' } as const

export type TStyledInputProps = {
  p?: string
  font?: keyof DefaultTheme['fontFamily']
  bold?: boolean
  light?: boolean
  bg?: keyof DefaultTheme['colors']
  noBorder?: boolean
  borderRadius?: string
  color?: keyof DefaultTheme['colors']
  borderColor?: keyof DefaultTheme['colors']
} & Partial<Partial<WrappedFieldProps>> &
  TResponsiveProps<typeof responsiveProps> &
  InputHTMLAttributes<HTMLInputElement>

export const StyledInput = styled('input')
  .withConfig<TStyledInputProps>(defaultStyledConfig(['width', 'size']))
  .attrs<TStyledInputProps, TStyledInputProps>((props) => ({
    type: props.type || 'text',
  }))`
  display: block;
  padding: ${({ p }) => p};
  font-family: ${({ font, theme }) => (font ? theme.fontFamily[font] : theme.fontFamily.openSans)};
  ${({ bold }) => bold && 'font-weight: bold'};
  ${({ light }) => light && 'font-weight: light'};
  ${({ bg, theme }) => bg && `background-color: ${theme.colors[bg]};`};
  border: ${({ meta, noBorder }) => `${noBorder ? '0' : '1'}px solid ${meta?.touched && meta?.error ? 'red' : '#ccc'}`};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ noBorder }) => !noBorder && 'inset 0 1px 1px rgba(0, 0, 0, 0.075)'};
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color]};
    `}
  &:focus {
    border-color: ${({ theme }) => theme.colors.blue};
    outline: 0;
  }
  && {
    // Increase specificity so that font-size is taken into account
    ${responsiveStyle(responsiveProps)}
  }
`
StyledInput.defaultProps = {
  fontSize: { xs: '1em', sm: '1em' },
  w: '100%',
  bg: 'white',
  p: '0.7em',
  borderRadius: '40px',
}

export type TInputProps = {
  onClick?: MouseEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  forwardedRef?: React.Ref<HTMLInputElement>
} & TStyledInputProps

export const Input = React.forwardRef<HTMLInputElement, TInputProps>(
  ({ id = createUUID(), forwardedRef, ...otherProps }, ref) => {
    const placeholder = useTranslate(otherProps.placeholder)

    return <StyledInput ref={mergeRefs(forwardedRef, ref)} {...otherProps} {...{ id, placeholder }} />
  },
)

Input.displayName = 'Input'
