/*
 * Cell which stretches through the whole available width
 */
import React from 'react'

import { Cell, TCellProps } from './Cell'

export const StretchCell: React.FC<TCellProps> = (props) => (
  <Cell flex="1" w="0" minW="0" minH="0" align="stretchLeft" {...props} />
)
