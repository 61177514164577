import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import React, { useCallback, useEffect, useState } from 'react'

import { selectMissingI18nFallbackNamespaces } from '@hozana/api/selectors'
import type { TCommonPageProps } from '@hozana/api/server/common'
import { useSelector } from '@hozana/redux/hooks'
import { Sentry } from '@hozana/tracking/sentry'
import { asyncRetryWithTimeout } from '@hozana/utils/functions/errors'

import { NAMESPACE, TLocale } from 'i18n/constants'
import { nextI18nextConfig } from 'i18n/next-i18next.config'
import type { TI18nStore } from 'i18n/types'
import { IMMUTABLE_PREFIX } from 'routes/constants'

/**
 * In the browser, the first initialI18nStore contains namespaces, added in the html from _document
 * The subsequent initialI18nStore, on page change, coming from the fetched new pages json props
 * don't contain any namespaces to lighten the prefetched props.
 *
 * On the server, even the initialI18nStore is empty in page props
 * because it does not benefit from the extension of __NEXT_DATA__ in _document
 * (__NEXT_DATA__ is the serialized server side props injected in the html page)
 * So we inject the whole extra complete king size initialI18nStore in the config from appWithTranslations
 *
 * #loadTranslations
 */
const fillInitialI18nStore = (AppWithTranslationComponent: React.FC<AppProps<TCommonPageProps>>) => {
  const NamespacesLoader: React.FC<AppProps<TCommonPageProps>> = (props) => {
    const {
      router,
      pageProps,
      pageProps: { _nextI18Next, _nextI18Next: { initialI18nStore = {}, initialLocale } = {} } = {},
    } = props
    const lang = (initialLocale || router.locale || 'fr') as TLocale

    /**
     * To avoid the new empty initialI18nStores to replace the filled one, we memoize it here,
     * we fetch the remaining namespaces, and overwrite the current initialI18nStore in the page props
     * with the first one extended with the fetched namespaces.
     */
    const [filledInitialI18nStore, setFilledInitialI18nStore] = useState<TI18nStore>(initialI18nStore)

    const [initialNamespaces] = useState(Object.keys(initialI18nStore[lang] || {}))
    const [hasAlreadyFetched, setHasAlreadyFetched] = useState(false)
    const fetchFallbackNamespaces = useSelector((state) => selectMissingI18nFallbackNamespaces(state))

    const fetchNamespaces = useCallback(
      (lang: TLocale, isFallback: boolean = false) => {
        setHasAlreadyFetched(true)
        Object.values(NAMESPACE).forEach((ns) => {
          if (initialNamespaces?.includes(ns) && !isFallback) {
            return
          }

          asyncRetryWithTimeout(
            () =>
              fetch(`${IMMUTABLE_PREFIX}/namespace/${lang}/${ns}.json`).then(
                (response) => response.json() as Promise<TI18nStore[TLocale]>,
              ),
            {
              attemptTime: 5000,
              timeoutMessage: 'Timeout while trying to fetch namepaces',
              delayBetweenAttempts: 3000,
            },
          )
            .then((data) => {
              if (data) {
                setFilledInitialI18nStore((prevInitialI18nStore) => ({
                  ...prevInitialI18nStore,
                  [lang]: {
                    ...prevInitialI18nStore?.[lang],
                    [ns]: data,
                  },
                }))
              }
            })
            .catch((error) =>
              Sentry.captureException(error, { context: 'fetching translations', details: `${ns} namespace` }),
            )
        })
      },
      [initialNamespaces, setFilledInitialI18nStore],
    )

    useEffect(() => {
      if (!hasAlreadyFetched) {
        fetchNamespaces(lang)
      }
    }, [fetchNamespaces, lang, hasAlreadyFetched])

    useEffect(() => {
      if (fetchFallbackNamespaces) {
        fetchNamespaces('en', true)
      }
    }, [fetchFallbackNamespaces, fetchNamespaces])

    return (
      <AppWithTranslationComponent
        {...{
          ...props,
          pageProps: {
            ...pageProps,
            _nextI18Next: {
              ..._nextI18Next,
              initialLocale: lang,
              initialI18nStore: filledInitialI18nStore,
              // All namespaces are loaded on the server
              ...(__SERVER__ && {
                ns: Object.values(NAMESPACE),
              }),
            },
          },
        }}
      />
    )
  }
  return NamespacesLoader
}

export const withI18n = (WrappedApp: React.FC<AppProps<TCommonPageProps>>) =>
  fillInitialI18nStore(
    appWithTranslation(
      WrappedApp as React.FC<AppProps<Required<TCommonPageProps>>>,
      /**
       * On the server, we require the full initialI18nStore because:
       * - it makes sure that no namespace will be missing on SSR rendered html
       * - it does not increase the weight of the page sent to the browser
       * - anyway the store is empty on the server because it is only injected
       * in the html __NEXT_DATA__ from _document so it is not available on SSR
       * #loadTranslations
       */
      __SERVER__ ? { ...nextI18nextConfig, resources: require('i18n/initialI18nStore') } : nextI18nextConfig,
    ),
  )
