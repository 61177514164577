import 'regenerator-runtime/runtime'

import { TRoutinePromiseAtion } from '@hozana/routines/actions'
import type { TRoutine, TRoutineAction } from '@hozana/routines/types'
import { all, call, put, race, take, takeEvery } from '@hozana/sagas'

import { ROUTINE_PROMISE_ACTION } from './constants'

export function* handleRoutinePromiseAction<R extends TRoutine>(action: TRoutinePromiseAtion<R>) {
  const {
    payload,
    meta: {
      routine,
      reduxFormCompatible,
      defer: { resolve, reject },
    },
  } = action

  const {
    result: { success, failure },
  } = yield* all({
    result: race({
      success: take<TRoutineAction<R, 'success'>>(routine.SUCCESS),
      failure: take<TRoutineAction<R, 'failure'>>(routine.FAILURE),
    }),
    trigger: put(routine.trigger(payload)),
  })

  if (success) {
    yield* reduxFormCompatible ? call(resolve) : call(resolve, success.payload)
  } else {
    yield* call(reject, failure.payload)
  }
}

export default function* routinePromiseWatcherSaga() {
  yield* takeEvery(ROUTINE_PROMISE_ACTION, handleRoutinePromiseAction)
}
