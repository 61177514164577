import { useEffect } from 'react'

import { fetchApi } from '@hozana/api/fetchApi'
import { selectMaintenanceStatus, selectQueryData } from '@hozana/api/selectors'
import type { TDateTime } from '@hozana/dates/types'
import { useInterval } from '@hozana/hooks/useInterval'
import { usePageVisibility } from '@hozana/hooks/usePageVisibility'
import { useDispatch, useSelector } from '@hozana/redux/hooks'

import { useMyIntention } from 'modules/intention/hooks/useMyIntention'
import { getStatusQuery } from 'modules/notification/queries'
import { useUser } from 'modules/user/hooks/useUser'

/**
 * Get status value
 */
export const useStatus = () => useSelector((state) => selectQueryData(state, getStatusQuery()))

/**
 * Get delay between 2 status requests, depending on the user intention creation date:
 * - without this date, the delay is 5 minutes
 * - if this date is more that 1000s ago, the delay is 5 minutes
 * - if this date is less than 1000s ago, the delay is `[date] / 10`, but not more less than 5s
 */
const getDelay = (userIntentionCreatedAt: TDateTime, isMaintenanceActive: boolean) => {
  const delay = 1000 * 60 * (isMaintenanceActive ? 1 : 5) //1 or 5 minutes

  if (!userIntentionCreatedAt) {
    return delay
  }

  const intentionAge = Date.now() - new Date(userIntentionCreatedAt).getTime()
  return intentionAge < 1000000 ? Math.max(intentionAge / 10, 5000) : delay
}

/**
 * Update the messages and notifications unread count every 5 minutes,
 * more often if the user just wrote an intention.
 * If the user is not logged or the screen not visible, do nothing.
 *
 * /!\ This hook must only be used once, in AppContainer
 */
export const useStatusManager = (): void => {
  const dispatch = useDispatch()
  const me = useUser()
  const { createdAt: userIntentionCreatedAt } = useMyIntention() || {}
  const isMaintenanceActive = useSelector((state) => selectMaintenanceStatus(state))
  const delay = getDelay(userIntentionCreatedAt, isMaintenanceActive)
  const pageVisible = usePageVisibility()

  // Initial fetchStatus
  useEffect(() => {
    if ((me.isLogged || isMaintenanceActive) && pageVisible) {
      dispatch(fetchApi(getStatusQuery()))
    }
  }, [pageVisible, me.isLogged, isMaintenanceActive, dispatch])

  // Recurrent fetchStatus
  useInterval(
    () => {
      dispatch(fetchApi(getStatusQuery()))
    },
    delay,
    (!me.isLogged && !isMaintenanceActive) || !pageVisible,
  )
}
