/**
 * Trigger a routine and listen for the result.
 * If success, it will return the payload, otherwise it will trigger an error.
 * You should use this saga instead of calling the routine saga directly.
 */
import 'regenerator-runtime/runtime'

import type { TRoutineAction, TRoutineData, TRoutinePayload } from '@hozana/routines/types'
import { all, put, race, take } from '@hozana/sagas'

import * as allRoutines from 'config/routines'

export function* runRoutine<R extends TObjectValues<typeof allRoutines>>(
  routine: R,
  payload: TRoutinePayload<R> = null,
) {
  const {
    response: { success, failure },
  } = yield* all({
    response: race({
      success: take<TRoutineAction<R, 'success'>>(routine.SUCCESS),
      failure: take<TRoutineAction<R, 'failure'>>(routine.FAILURE),
    }),
    request: put(routine.trigger(payload as any)),
  })

  if (success) {
    return success.payload as TRoutineData<R>
  } else {
    throw new Error(typeof failure.payload === 'string' ? failure.payload : failure.payload.message)
  }
}
