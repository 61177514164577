import { createRoutine } from '@hozana/routines/functions'

import type { TAddRoleFormData } from 'modules/community/pages/CommunityRolesPage/AddRoleForm'
import type {
  TCommunitySuccessData,
  TCreateCommunityPayload,
  TDeleteCommunityPayload,
  TEditCommunityPayload,
  TLeaveCommunityPayload,
  TSendAnnouncementFormSubmitPayload,
  TShowCommunityNotificationsPayload,
} from 'modules/community/sagas'

export const createCommunity = createRoutine<'CREATE_COMMUNITY', TCreateCommunityPayload, TCommunitySuccessData>(
  'CREATE_COMMUNITY',
)
export const editCommunity = createRoutine<'EDIT_COMMUNITY', TEditCommunityPayload, TCommunitySuccessData>(
  'EDIT_COMMUNITY',
)
export const deleteCommunity = createRoutine<'DELETE_COMMUNITY', TDeleteCommunityPayload>('DELETE_COMMUNITY')
export const leaveCommunity = createRoutine<'LEAVE_COMMUNITY', TLeaveCommunityPayload, TCommunitySuccessData>(
  'LEAVE_COMMUNITY',
)
export const showCommunityNotifications = createRoutine<
  'SHOW_COMMUNITY_NOTIFICATIONS',
  TShowCommunityNotificationsPayload
>('SHOW_COMMUNITY_NOTIFICATIONS')
export const sendAnnouncementFormSubmit = createRoutine<
  'SEND_ANNOUNCEMENT_FORM_SUBMIT',
  TSendAnnouncementFormSubmitPayload
>('SEND_ANNOUNCEMENT_FORM_SUBMIT')
export const addCommunityRole = createRoutine<'ADD_COMMUNITY_ROLE', TAddRoleFormData>('ADD_COMMUNITY_ROLE')
export const removeCommunityRole = createRoutine<'REMOVE_COMMUNITY_ROLE', TAddRoleFormData>('REMOVE_COMMUNITY_ROLE')
