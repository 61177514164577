import type { PropertiesHyphen } from 'csstype'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type TTransitionStyle = {
  height?: PropertiesHyphen['max-height']
  opacity?: PropertiesHyphen['opacity']
}

/**
 * This utility could be used to switch between more than two states.
 * We only have 'initial' and 'final' state for now, to make it easier to use this component.
 */
const parseTransitionStyles = ({ transitionStyles }: { transitionStyles: Record<string, TTransitionStyle> }) =>
  Object.keys(transitionStyles).map(
    (styleKey) => css`
      &.${styleKey} {
        ${transitionStyles[styleKey].height && `max-height: ${transitionStyles[styleKey].height};`}
        ${transitionStyles[styleKey].opacity && `opacity: ${transitionStyles[styleKey].opacity};`}
      }
    `,
  )

type TStyledDivProps = {
  duration?: number
  scrollable?: boolean
  transitionStyles: Record<'initial' | 'final', TTransitionStyle>
}

export const StyledDiv = styled.div<TStyledDivProps>`
  transition: all ${(props) => props.duration}ms;
  ${({ scrollable }) =>
    scrollable
      ? css`
          margin-top: 5px;
          overflow-y: auto;
          pointer-events: auto;
        `
      : css`
          overflow: hidden;
        `}
  ${parseTransitionStyles}
`

export type TTransitionProps = {
  /** If true, we will render the final state */
  active?: boolean
  renderWhenInvisible?: boolean
} & Omit<TStyledDivProps, 'transitionStyles'> &
  TStyledDivProps['transitionStyles']

export const Transition: React.FC<TTransitionProps> = ({
  initial,
  final,
  active,
  duration = 500,
  renderWhenInvisible = true,
  scrollable = false,
  children,
}) => {
  const [visible, setVisible] = useState(active)

  useEffect(() => {
    if (active && !visible) {
      setVisible(true)
    } else if (!active && visible) {
      setTimeout(() => setVisible(false), duration)
    }
  }, [active, visible, duration])

  return (
    <StyledDiv
      scrollable={scrollable}
      transitionStyles={{ initial, final }}
      className={active ? 'final' : 'initial'}
      duration={duration}
    >
      {renderWhenInvisible || visible ? children : null}
    </StyledDiv>
  )
}
