import styled from 'styled-components'

import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import { Div, TDivProps } from './Div'

const responsiveProps = { order: 'order', flex: 'flex', alignSelf: 'align-self' } as const

export type TCellProps = TResponsiveProps<typeof responsiveProps> & TDivProps

export const Cell = styled(Div)<TCellProps>`
  ${responsiveStyle(responsiveProps)}
`
