import React from 'react'

import { Icon, TIconProps } from 'elements/media/Icon'
import { SVG as Svg, TSVGProps } from 'elements/media/SVG'

import { ICON } from 'config/icons'

export type TLogoProps = {
  full?: boolean
} & Pick<TSVGProps, 'color' | 'fill' | 'label'> &
  Pick<TIconProps, 'size'>

export const Logo: React.FC<TLogoProps> = ({ full = false, size, color = 'white', fill = 'white', label }) =>
  full ? (
    <Svg
      path="/svg/hozana_logo.svg"
      w={size || '147px'}
      aria-label={label}
      color={color}
      fill={fill}
      aspectRatio="210/67"
    />
  ) : (
    <Icon name={ICON.HOZANA} size={size || '35px'} color={color} label={label} />
  )
