module.exports = {
  defaultLocale: 'fr',
  locales: ['fr', 'en', 'es', 'pt', 'it', 'pl'],
  /**
   * localeDetection has been set to false in the past to prevent SEO issues.
   * It could be set to true if the addressed issues are obsoletes now.
   */
  localeDetection: false,
  fallbackLng: 'en',
}
