import type { LinkProps } from 'next/link'
import React, { AnchorHTMLAttributes, AriaAttributes, HTMLAttributes, MouseEvent, MouseEventHandler } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { useTranslate } from '@hozana/intl/useTranslate'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { NextLink, TUrlObject, useRouter } from '@hozana/router'
import { isInternalUrl, translateUrl } from '@hozana/router/functions'
import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, mediaQuery, responsiveStyle } from '@hozana/styling/responsive'
import { EVENTS, TGTMVariables } from '@hozana/tracking/constants'
import { GTM, TGTMEventObjet } from '@hozana/tracking/gtm'
import { withEventDelay } from '@hozana/utils/functions/events'

import { BUTTON_BASE_CLASSNAME } from 'elements/constants'

import { setTargetPathname } from 'app/actions'
import { isReloadNeeded } from 'app/selectors'
import type { TLocale } from 'i18n/constants'
import { skipWaitingAndReloadAllInvisibleWindows } from 'sw/functions'

const isShareUrl = (url?: string): boolean => url?.startsWith('mailto') || url?.startsWith('sms')
const isAnchor = (url?: string): boolean => url?.startsWith('#')

const responsiveProps = { h: 'height', w: 'width', align: 'text-align', m: 'margin' } as const

type TLinkButtonProps = {
  bold?: boolean
  underline?: boolean
  color?: keyof DefaultTheme['colors']
  hoverColor?: keyof DefaultTheme['colors']
  hoverUnderline?: boolean
  displayBlock?: boolean
} & TResponsiveProps<typeof responsiveProps> &
  HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>

export const StyledA = styled.a.attrs<TLinkButtonProps, TLinkButtonProps>(
  ({ theme, bold, color, displayBlock, style, underline }) => ({
    style: mergeStyles(style, {
      textDecoration: underline ? 'underline' : 'inherit',
      ...(color && { color: theme.colors[color] }),
      ...(displayBlock && { display: 'block' }),
      ...(bold && { fontWeight: 'bold' }),
    }),
  }),
)`
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 0;

  ${responsiveStyle(responsiveProps)}

  /* To prevent the blue highlighting in Chrome on mobile */
  ${mediaQuery('xs', 'sm')} {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: default;
  }

  // Just leaving "color: inherit" without the condition would override the BaseButton color prop
  // when we use a button as link using to prop (see BaseButton attrs)
  // We cannot just use \${BaseButton} because it would create a circular dependency
  &:not(.${BUTTON_BASE_CLASSNAME}) {
    color: inherit;
  }

  &:hover,
  &:focus {
    ${(p) => p.hoverColor && `color: ${p.theme.colors[p.hoverColor]}`};
    text-decoration: ${(p) => (p.hoverUnderline ? 'underline' : 'none')};
  }
`
export const StyledForm = styled.form`
  display: inline;
  ${responsiveStyle(responsiveProps)}
`

export type TLinkProps = {
  /** Setting noReferrer to true will prevent the linked website to access the referrer (i.e. the origin: hozana.org) */
  noReferrer?: boolean
  noFollow?: boolean
  /**
   * If set to true, the link will be made of a form + submit button to block search engines.
   * **It should not be used inside an other form or button.**
   */
  asButton?: boolean
  /**
   * By default, Link adds a 300ms delay before performing the navigation so that a white overlay has time to appear
   * before undertaking the navigation calculations
   * shallow and disableDelay both disable this behavior
   */
  disableDelay?: boolean
  to?: string | TUrlObject
  target?: AnchorHTMLAttributes<HTMLAnchorElement | HTMLFormElement>['target']
  rel?: AnchorHTMLAttributes<HTMLAnchorElement>['rel']
  reload?: boolean
  tabIndex?: number
  'aria-label'?: AriaAttributes['aria-label']
  'data-testid'?: string
  locale?: TLocale
  gtm?: {
    event: EVENTS | TGTMEventObjet
    variables?: TGTMVariables
  }
  external?: boolean
} & Pick<LinkProps, 'replace' | 'shallow' | 'scroll' | 'prefetch'> &
  TLinkButtonProps

/**
 * ### Link ###
 *
 * - You can set an url target, an onClick function, a Google Analytics event {event, label}
 * - For links outside of Hozana, the <a> element will be used instead of ReactRouter Link
 * - Anchors (#) work too. If you use #section, you need to set an element with id="section"
 * - Children props will be translated.
 *
 *  Prefer this one over React router own Link for GA and translation.
 *
 * To create a link styled as a button, you just have to create a button and add a `to` prop:
 * `RippledButton` will take care of rendering this `Link` component
 */
export const Link: React.FC<TLinkProps> = ({
  to,
  gtm,
  children,
  noReferrer,
  noFollow,
  asButton,
  reload,
  replace,
  target: propTarget,
  rel: propRel,
  hoverColor,
  locale,
  prefetch,
  shallow,
  scroll,
  disableDelay,
  onClick: propOnClick,
  external,
  ...otherProps
}) => {
  const router = useRouter()
  const isHydrated = useIsHydrated()
  const reloadNeeded = useSelector(isReloadNeeded)
  const content = useTranslate(children)
  const dispatch = useDispatch()

  const trackEvent: TLinkProps['onClick'] =
    gtm &&
    ((e) => GTM.trackEvent(gtm.event, gtm.variables, typeof to === 'object' || isInternalUrl(to) ? undefined : e))

  const onClick: TLinkProps['onClick'] =
    shallow || disableDelay
      ? (e) => {
          trackEvent?.(e)
          propOnClick?.(e)
          if (reloadNeeded) skipWaitingAndReloadAllInvisibleWindows()
        }
      : withEventDelay<MouseEventHandler<HTMLAnchorElement>>({
          delay: 300,
          onDelay: (e) => {
            if (typeof to === 'object') {
              dispatch(setTargetPathname(to.pathname))
            }
            trackEvent?.(e)
            propOnClick?.(e)
          },
          onResume: () => {
            if (reloadNeeded) skipWaitingAndReloadAllInvisibleWindows()
          },
        })

  // If both to and onClick are undefined, we don't want to have a link here
  if (!to && !propOnClick) {
    return content
  }

  const mustReload = reloadNeeded || reload // #silentReload
  const target = propTarget ?? (typeof to === 'string' && (!isInternalUrl(to) || isShareUrl(to))) ? '_blank' : undefined
  const rel =
    propRel ??
    [
      ...(noFollow ? ['nofollow'] : []),
      ...(target === '_blank' ? ['noopener'] : []),
      ...(noReferrer ? ['noreferrer'] : []),
    ].join(' ')

  const href: TUrlObject | string =
    typeof to === 'string' && isAnchor(to) ? { pathname: router.pathname, query: router.query, hash: to } : to

  if (href && !asButton && !mustReload) {
    return (
      <NextLink
        href={href}
        replace={replace}
        locale={locale}
        shallow={shallow}
        scroll={scroll}
        prefetch={prefetch}
        passHref
      >
        <StyledA target={target} rel={rel} onClick={onClick} {...otherProps}>
          {content}
        </StyledA>
      </NextLink>
    )
  }

  const url = (() => {
    if (external && typeof href === 'string') return href
    return href ? translateUrl(href, locale || router.locale, { format: 'string' }) : undefined
  })()

  if (!url || (asButton && isHydrated)) {
    return (
      <StyledA
        as="button"
        onClick={
          url
            ? (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
                onClick?.(e)
                if (e.isDefaultPrevented()) {
                  return undefined
                }
                if (mustReload) {
                  window.location.href = url
                } else {
                  router[replace ? 'replace' : 'push'](href, undefined, { locale, shallow, scroll }) // #router.replace
                }
              }
            : onClick
        }
        hoverColor={hoverColor}
        {...otherProps}
      >
        {content}
      </StyledA>
    )
  }

  // As button non hydrated: hide the link from the search engines if asButton is set to true
  // but keep unhydrated navigation operational
  if (asButton) {
    const [urlWithoutQuery, query] = url.split('?')

    return (
      <StyledForm action={urlWithoutQuery} target={target} w={otherProps.w}>
        {query &&
          query.split('&').map((param) => {
            const [key, value] = param.split('=')
            return <input key={key} type="hidden" name={key} value={value} />
          })}
        <StyledA as="button" type="submit" hoverColor={hoverColor} {...otherProps}>
          {content}
        </StyledA>
      </StyledForm>
    )
  }

  return (
    <StyledA href={url} target={target} rel={rel} onClick={onClick} {...otherProps}>
      {content}
    </StyledA>
  )
}
