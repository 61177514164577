import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import styled from 'styled-components'

import { useIsHydrated } from '@hozana/hooks/useIsHydrated'

import { Div, TDivProps } from 'elements/layout/Div'
import { Icon } from 'elements/media/Icon'
import { Image } from 'elements/media/Image'

import { ICON } from 'config/icons'

const YouTube = dynamic(() => import('react-youtube'), { loading: () => null })

export const StyledDiv = styled(Div)`
  overflow: hidden;
  position: absolute;

  & > div {
    height: 100%;
    width: 100%;
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  opacity: 0.8;

  ${StyledDiv}:hover & {
    opacity: 1;
    & > svg {
      fill: ${({ theme }) => theme.colors.youtube};
    }
  }
`
const StyledWrapper = styled(Div)`
  &,
  & > div,
  & iframe {
    height: calc(100vw * 3 / 4);
    width: 100%;
  }

  & > div,
  && iframe {
    max-height: 100%;
  }
`

export type TYoutubePlayerProps = {
  source: string
  autoPlay?: boolean
  onPlay?: () => void
  maxH?: string
  isInPreviewMode?: boolean /** Sometimes, like in feed page outside modal, we don't want to be able to play the video on click. */
} & TDivProps

export const YoutubePlayer: React.FC<TYoutubePlayerProps> = ({
  source,
  autoPlay = false,
  onPlay,
  maxH = '500px', // #iframeMaxHeight
  isInPreviewMode = false,
  ...otherProps
}) => {
  const [isVideoDisplayed, setIsVideoDisplayed] = useState(autoPlay)
  const isHydrated = useIsHydrated()

  return (
    <StyledWrapper align="center" id="youtube-wrapper" maxH={maxH}>
      {isVideoDisplayed ? (
        <YouTube
          videoId={source}
          opts={{
            height: '100%',
            width: '100%',
            playerVars: {
              // Always autoplay here: the real autoplay is managed by isVideoDisplayed state
              autoplay: 1,
            },
          }}
          onPlay={onPlay}
        />
      ) : (
        <StyledDiv
          id="youtube-placeholder"
          as="button"
          // Write autoplay source in the dom for unhydrated toggle #unhydratedYoutubeToggle
          // Always autoplay here: the real autoplay is managed by isVideoDisplayed state
          data-video-id={`${source}${source?.includes('?') ? '&' : '?'}autoplay=1`}
          onClick={() => !isInPreviewMode && setIsVideoDisplayed(true)}
          align="middleCenter"
          w="100%"
          h="100%"
          fontSize="80px"
          {...otherProps}
        >
          <Image
            alt="trans:common:app.image.youtube.image-alt"
            src={`https://img.youtube.com/vi/${source}/hqdefault.jpg`}
          />
          <StyledIcon name={ICON.YOUTUBE_COLOR} color="black" size="80px" />
        </StyledDiv>
      )}
      {/*
       The below iframe is used on unhydrated pages #unhydratedYoutubeToggle
       It should be removed on hydration to not have 2 iframes
       */}
      {!isHydrated && (
        <iframe
          sandbox="true"
          id="youtube-player"
          // src set to null so that the iframe is rendered in the dom but its content is not loaded
          // Its value will be set to youtube-placeholder's data-video-id value #unhydratedYoutubeToggle
          src={null}
          width="100%"
          height="100%"
          title="YouTube video player"
          style={{ border: 0, display: 'none' }}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </StyledWrapper>
  )
}
