import { removeEntity } from '@hozana/api/actions'
import type { TQuery, TQueryConstructor } from '@hozana/api/types'
import type { TDateTime } from '@hozana/dates/types'
import { normalize } from '@hozana/redux/normalize'

import entities from 'config/entities'
import type { TRawCommunity } from 'config/entities'

import { COMMUNITY_AUTHORIZATIONS, COMMUNITY_ROLE, PUBLISH_ANNOUNCEMENT_AS } from 'modules/community/constants'
import {
  getRetreatStatus,
  setRetreatStatusToCommunities,
  setRetreatStatusToCommunity,
} from 'modules/community/functions'
import type { TLocalization } from 'modules/community/pages/CommunityPage/GoogleMapSection/GMap'
import { addNotification } from 'modules/notification/actions'
import { NOTIFICATION_TYPE } from 'modules/notification/constants'
import type { TCommunityNotificationSettings } from 'modules/notification/types'
import type { TPublicationStatistics } from 'modules/publication/types'

import { decreaseNumMembers, updateCommunity } from './actions'
import type {
  TAnnouncement,
  TCommunity,
  TCommunityPrayer,
  TCommunityRoles,
  TCommunityStatistics,
  TPeriod,
} from './types'

type TCommunityIdOnlyProp = { communityId: number }

export const getCommunityQuery: TQueryConstructor<TCommunityIdOnlyProp & { withRawDescription?: boolean }, number> = ({
  communityId,
  withRawDescription,
}) => ({
  queryKey: `getCommunity/${communityId}${withRawDescription ? '/withRawDescription' : ''}`,
  url: `/community/${communityId}`,
  params: { fields: ['sharesCount', 'communitiesPropositions'], ...(withRawDescription && { flavour: 'full' }) },
  normalize: (data: TRawCommunity) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunity = setRetreatStatusToCommunity(data)
    if (data.communitiesPropositions && data.communitiesPropositions.length > 0) {
      newCommunity.communitiesPropositions = setRetreatStatusToCommunities(data.communitiesPropositions)
    }
    return normalize(newCommunity, entities.community)
  },
})

export const getCommunityMembersLocationQuery: TQueryConstructor<TCommunityIdOnlyProp, TLocalization[]> = ({
  communityId,
}) => ({
  queryKey: `getCommunityMembersLocation/${communityId}`,
  url: `/community/${communityId}/members/location`,
  params: { fields: ['sharesCount'] },
  headers: { 'Cache-Control': 'public' },
})

export const getCommunityMembersQuery: TQueryConstructor<TCommunityIdOnlyProp, number[]> = ({ communityId }) => ({
  queryKey: `getCommunity/${communityId}/members`,
  url: `/community/${communityId}/members`,
  meta: {
    limit: 20,
  },
  normalize: (data: TRawCommunity[]) => normalize(data, [entities.user]),
})

export const getCommunitiesQuery: TQueryConstructor<
  {
    page?: number
    limit: number
    displayAll?: true
    order?: string
    ignoreWizard?: boolean
    filter?: string
    filterParam?: string
  },
  number[]
> = ({ page = 1, limit = 6, displayAll, order, ignoreWizard, filter, filterParam }) => ({
  queryKey: 'getCommunities' + (filter ? `/${filter}/${filterParam || ''}` : '') + (limit ? `/${limit}` : ''),
  url: `/communities`,
  params: {
    fields: ['lastPublication', 'hasJoined'],
    meta: ['hasMore', 'globalCount'],
    order,
    filter,
    filterParam,
    displayAll,
    ...(ignoreWizard && { ignoreWizard }),
  },
  meta: {
    limit,
    offset: (page - 1) * limit,
  },
  normalize: (data: TRawCommunity[]) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunities = setRetreatStatusToCommunities(data)
    return normalize(newCommunities, [entities.community])
  },
})

export const getCommunitiesListQuery: TQueryConstructor<void, number[]> = () => ({
  queryKey: 'getCommunitiesList',
  url: '/communities',
  params: {
    fields: ['lastPublication'],
    order: 'publications',
    filter: 'indexed',
    limit: 500,
  },
  normalize: (data: TRawCommunity[]) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunities = setRetreatStatusToCommunities(data)
    return normalize(newCommunities, [entities.community])
  },
})

export const getCommunityAuthorizationsQuery: TQueryConstructor<
  TCommunityIdOnlyProp,
  Record<COMMUNITY_AUTHORIZATIONS, boolean>
> = ({ communityId }) => ({
  queryKey: `getCommunityAuthorizations/${communityId}`,
  url: `/community/${communityId}/authorizations`,
})

export const getPrayerSampleQuery: TQueryConstructor<void, TCommunityPrayer[]> = () => ({
  queryKey: `getPrayerSample`,
  url: `/prayer-sample`,
})

export const getCommunityRolesQuery: TQueryConstructor<TCommunityIdOnlyProp, TCommunityRoles> = ({ communityId }) => ({
  queryKey: `getCommunityRoles/${communityId}`,
  url: `/community/${communityId}/roles`,
  normalize: (data) =>
    normalize(data, {
      creator: entities.user,
      administrators: [entities.user],
      animators: [entities.user],
    }),
})

export const getRecipientsCount: TQueryConstructor<{ communityIds: number[] }, { count: number }> = ({
  communityIds,
}) => ({
  queryKey: `getRecipientsCount/${communityIds?.join('-')}`,
  url: `/community/announcement/count`,
  params: { ids: communityIds },
})

export const getCommunityNotificationsQuery: TQueryConstructor<
  TCommunityIdOnlyProp,
  TCommunityNotificationSettings
> = ({ communityId }) => ({
  queryKey: `getCommunityNotifications/${communityId}`,
  url: `/community/${communityId}/notifications`,
  onSuccess: (dispatch, result) => {
    dispatch(
      updateCommunity(communityId, {
        notifyAnnouncements: result.announcements.email,
        notifyPublicationsByEmail: result.publications.email,
        notifyPublicationsByPush: result.publications.push,
      }),
    )
  },
})

export const getCommunitySubscriptionsStatisticsQuery: TQueryConstructor<
  TCommunityIdOnlyProp & { groupBy: TPeriod },
  TCommunityStatistics
> = ({ communityId, groupBy = 'week' }) => ({
  queryKey: `getCommunitySubscriptionsStatistics/${communityId}`,
  url: `/community/${communityId}/statistics/subscriptions`,
  params: {
    groupBy,
  },
})

export const getCommunityPublicationsStatisticsQuery: TQueryConstructor<
  TCommunityIdOnlyProp,
  TPublicationStatistics[]
> = ({ communityId }) => ({
  queryKey: `getCommunityPublicationsDetailsStatistics/${communityId}`,
  url: `/community/${communityId}/statistics/publications`,
})

export const getAnnouncements: TQueryConstructor<TCommunityIdOnlyProp, TAnnouncement[]> = ({ communityId }) => ({
  queryKey: `getAnnouncements/${communityId}`,
  url: `/community/${communityId}/announcements`,
})

/*
 * MUTATIONS
 */

export const createCommunityMutation: TQueryConstructor<
  Partial<TCommunity> & {
    imagesToAttach: string[]
  },
  number
> = ({
  isPrivate,
  lifetime,
  name,
  introduction,
  pictureFileId,
  publicationFrequencyType,
  publicationFrequencyValue,
  fromDate,
  toDate,
  prayerSampleId,
  imagesToAttach = [],
  isDraft,
}) => ({
  queryKey: `createCommunity`,
  url: `/community`,
  method: 'POST',
  params: {
    draft: isDraft,
    isPrivate,
    lifetime,
    name,
    introduction,
    pictureFileId,
    publicationFrequencyType,
    publicationFrequencyValue,
    fromDate,
    toDate,
    prayerSampleId,
    descriptionFilesToAttach: imagesToAttach,
  },
  normalize: (data: TRawCommunity) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunity = setRetreatStatusToCommunity(data)
    return normalize(newCommunity, entities.community)
  },
})

export const editCommunityMutation: TQueryConstructor<
  TCommunityIdOnlyProp &
    Partial<TCommunity> & {
      descriptionFilesToAttach?: string[]
      descriptionFilesToRemove?: string[]
    },
  number
> = ({
  communityId,
  isPrivate,
  isDraft,
  displayMode,
  lifetime,
  name,
  introduction,
  description,
  pictureFileId,
  publicationFrequencyType,
  publicationFrequencyValue,
  fromDate,
  toDate,
  prayerSampleId,
  prayerName,
  prayerContent,
  descriptionFilesToAttach,
  descriptionFilesToRemove,
  tags,
  mainTag,
  isLive,
}) => ({
  queryKey: `editCommunity/${communityId}`,
  url: `/community/${communityId}`,
  method: 'PATCH',
  params: {
    draft: isDraft,
    isPrivate,
    lifetime,
    name,
    introduction,
    description,
    displayMode,
    pictureFileId,
    publicationFrequencyType,
    publicationFrequencyValue,
    fromDate,
    toDate,
    prayerSampleId,
    prayerName,
    prayerContent,
    descriptionFilesToAttach,
    descriptionFilesToRemove,
    tags,
    mainTag,
    isLive,
  },
  normalize: (data: TRawCommunity) => {
    // set retreat status of community (getRetreatStatus) before adding to store
    const newCommunity = setRetreatStatusToCommunity(data)
    return normalize(newCommunity, entities.community)
  },
})

export const deleteCommunityMutation: TQueryConstructor<TCommunityIdOnlyProp, void> = ({ communityId }) => ({
  queryKey: `deleteCommunity/${communityId}`,
  url: `/community/${communityId}`,
  method: 'DELETE',
  onSuccess: (dispatch) => {
    dispatch(removeEntity('community', communityId))
  },
})

export const incrementCommunityViewsCountMutation: TQueryConstructor<TCommunityIdOnlyProp, void> = ({
  communityId,
}) => ({
  queryKey: `incrementCommunityViewCount/${communityId}`,
  url: `/community/${communityId}/view`,
  method: 'POST',
})

export const updateCommunitySubscriptionMutation: TQueryConstructor<
  {
    community: TCommunity
    scheduleDate?: TDateTime
  },
  {
    subscription: TCommunity['subscription']
  }
> = ({ community, scheduleDate }) => ({
  queryKey: `joinCommunity/${community.id}`,
  url: `/community/${community.id}/join`,
  method: 'PATCH',
  params: { scheduleDate },
  onSuccess: (dispatch, result) => {
    dispatch(
      updateCommunity(community.id, {
        subscription: result.subscription,
      }),
    )
  },
})

export const leaveCommunityMutation: TQueryConstructor<{ community: TCommunity }, void> = ({ community }) => ({
  queryKey: `leaveCommunity/${community.id}`,
  url: `/community/${community.id}/leave`,
  method: 'POST',
  onSuccess: (dispatch) => {
    // Update community
    const retreatStatus = getRetreatStatus(community)
    const subscription = community.subscription
    dispatch(
      updateCommunity(community.id, {
        hasJoined: false,
        subscription: retreatStatus && !retreatStatus.comingSoon && !retreatStatus.ongoing ? subscription : undefined,
        retreatStatus: retreatStatus && {
          ...retreatStatus,
          scheduled: false,
          reliving: false,
        },
      }),
    )
    dispatch(decreaseNumMembers(community.id))

    // Add left community block into the feed
    dispatch(
      addNotification<NOTIFICATION_TYPE.LEAVE_COMMUNITY>({
        id: Date.now(),
        date: new Date().toISOString() as TDateTime,
        type: NOTIFICATION_TYPE.LEAVE_COMMUNITY,
        objectId: community.id,
        objectType: 'community',
        objectKey: `community/${community.id}`,
      }),
    )
  },
})

export const kickstartSubscriptionMutation: TQueryConstructor<
  TCommunityIdOnlyProp,
  { success: boolean; hasContent?: boolean }
> = ({ communityId }) => ({
  queryKey: `kickstartSubscription/${communityId}`,
  url: `/community/${communityId}/subscription/kickstart`,
  method: 'POST',
  onSuccess: (dispatch, data) => {
    if (data.hasContent === false) {
      // This is a 204 response, so no publication is available
      dispatch(
        updateCommunity(communityId, {
          publicationIsAvailable: false,
        }),
      )
    }
  },
})

export const sendAnnouncementFormMutation = <IsTest extends boolean>({
  communityIds,
  subject,
  message,
  isTest,
  showReplyButton,
  publishAs,
}: {
  communityIds: number[]
  subject: string
  message: string
  isTest?: IsTest
  showReplyButton?: boolean
  publishAs: PUBLISH_ANNOUNCEMENT_AS
}): TQuery<IsTest extends true ? null : { count?: number }> => ({
  queryKey: `communityAnnouncement/${communityIds.join('-')}`,
  url: `/community/announcement`,
  method: 'POST',
  params: { ids: communityIds, publishAs, subject, message, isTest, showReplyButton },
})

export type TRoleMutationProps = TCommunityIdOnlyProp & { email: string; role: COMMUNITY_ROLE }

export const addCommunityRoleMutation: TQueryConstructor<TRoleMutationProps, void> = ({
  communityId,
  email,
  role,
}) => ({
  queryKey: `manageCommunityRole/${communityId}`,
  url: `/community/${communityId}/roles`,
  method: 'POST',
  params: { role, email },
})

export const removeCommunityRoleMutation: TQueryConstructor<TRoleMutationProps, void> = ({
  communityId,
  email,
  role,
}) => ({
  queryKey: `manageCommunityRole/${communityId}`,
  url: `/community/${communityId}/roles`,
  method: 'DELETE',
  params: { role, email },
})
