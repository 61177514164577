import stringify from 'json-stable-stringify'

import { getAppType } from '@hozana/screen/functions/getAppType'

import type { TEntities, TEntityType } from 'config/entities'

import { ApiError } from './ApiError'
import type { TMethod, TQuery } from './types'

export const isJSON = (response: Response): boolean =>
  response.headers?.get('content-type')?.includes('application/json')

export const checkApiErrors = (response: Response): Promise<Response> =>
  new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status < 300) {
      resolve(response)
    } else if (isJSON(response)) {
      // If we have an error message to extract
      response.json().then((parsedResponse) => {
        if (parsedResponse.errors?.[0]?.key) {
          reject(new ApiError(parsedResponse.errors[0].message, response.status))
        } else {
          // No error message was passed, just return the status code
          reject(new ApiError('common:common.error.api.default', response.status))
        }
      })
    } else {
      // Non-API error (most likely a 500 error)
      reject(new ApiError('common:common.error.unknown', response.status))
    }
  })

export type TQueryStringParam = string | number | boolean | (string | number | boolean)[]

export const buildQueryString = (params: Record<string, TQueryStringParam>): string =>
  Object.keys(params || {})
    .filter((key) => params[key] !== null && params[key] !== undefined)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((keyInArray) => `${key}[]=${encodeURIComponent(keyInArray)}`).join('&')
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .sort()
    .join('&')

export const fetchHeaders = ({
  method,
  additionalHeaders = {},
  isUpload,
  token,
}: {
  method: TMethod
  additionalHeaders?: HeadersInit
  isUpload?: boolean
  token?: string
}): HeadersInit => {
  // Shared headers: request & response format (JSON)
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache', // prefer default as "no-cache"
    'X-Hozana-App': getAppType(),
  }

  // When uploading, the browser automatically set the content type
  // https://stackoverflow.com/a/49510941/7900695
  if (!isUpload) {
    headers['Content-Type'] = 'application/json'
  }

  // Only add the token header if it won't override anything
  if (token) {
    headers['X-Auth-Token'] = token
  } else if (method.toLowerCase() === 'get') {
    // Change "Cache-Control" header as "public" when user is anonymous
    headers['Cache-Control'] = 'public'
  }

  return { ...headers, ...additionalHeaders }
}

export const getQueryKey = ({ url, params, queryKey }: TQuery): string => {
  if (queryKey !== null && queryKey !== undefined) {
    return queryKey
  } else {
    return stringify({ url, params })
  }
}

export const getApiUrl = (lang: string, useCloudFlareCache: boolean): string => {
  if (!lang) throw new Error(`Lang not provided to getApiUrl()`)
  return (useCloudFlareCache ? CONF_KEYS.API_CACHE_URL : CONF_KEYS.API_URL) + '/' + lang
}

export const addLangMeta = <Query extends TQuery>(parsedQuery: Query, lang: string): Query => ({
  ...parsedQuery,
  meta: {
    ...parsedQuery.meta,
    lang: parsedQuery.meta?.lang || lang,
  },
})

type TEntityModifier<ET extends TEntityType = TEntityType> = (entity: TEntities[ET]) => TEntities[ET]

export const modifyQueryEntities = <Query extends TQuery>(
  query: Query,
  modifiers: {
    [ET in TEntityType]?: TEntityModifier<ET>
  },
) => ({
  ...query,
  normalize: (data: any) => {
    const { result, entities } = query.normalize(data)
    return {
      result,
      entities: Object.keys(entities).reduce(
        (acc, entityType) => ({
          ...acc,
          [entityType]:
            entityType in modifiers
              ? Object.entries(entities[entityType]).reduce(
                  (acc, [entityId, entity]) => ({
                    ...acc,
                    [entityId]: (modifiers[entityType] as TEntityModifier)(entity),
                  }),
                  {},
                )
              : entities[entityType],
        }),
        {},
      ),
    }
  },
})
