import type { Properties } from 'csstype'
import type { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { TAlignStyleProps, alignStyle } from '@hozana/styling/alignStyle'
import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

const responsiveProps = { m: 'margin', maxW: 'max-width' } as const

export type TUlProps = {
  horizontal?: boolean
  listStyle?: Properties['listStyle']
} & TResponsiveProps<typeof responsiveProps> &
  TAlignStyleProps &
  HTMLAttributes<HTMLUListElement>

export const Ul = styled.ul.attrs<TUlProps, TUlProps>(({ align, column, horizontal, listStyle = 'none', style }) => ({
  style: mergeStyles(style, {
    listStyle,
    ...alignStyle({ align, column }),
    ...(horizontal ? { height: '100%' } : { with: '100%' }),
  }),
}))`
  padding: 0;
  position: relative;
  vertical-align: middle;
  ${responsiveStyle(responsiveProps)};
  > .styled-li {
    display: ${(p) => (p.horizontal ? 'inline-block' : p.listStyle ? 'list-item' : 'block')};
  }
`
