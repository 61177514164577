import React from 'react'
import { useTheme } from 'styled-components'

import { Div } from 'elements/layout/Div'
import { Icon } from 'elements/media/Icon'
import { Link, TLinkProps } from 'elements/text/Link'
import { Text } from 'elements/text/Text'

import { ICON } from 'config/icons'

type TGoBackProps = Pick<TLinkProps, 'to' | 'asButton' | 'reload'>

export const GoBack: React.FC<TGoBackProps> = ({ to, children, asButton, reload }) => {
  const theme = useTheme()
  const size = theme.sizes.headerItem

  return (
    <Link asButton={asButton} reload={reload} onClick={to ? null : () => window.history.back()} to={to}>
      <Div h={size} w={children ? 'auto' : size} align="middleCenter">
        <Icon size="1.5rem" color="black70" name={ICON.ARROW_LEFT} m={children ? '0 1rem 0 0.5rem' : undefined} />
        {children && (
          <Text upper color="black70" lineHeight="30px" invisible="xs">
            {children}
          </Text>
        )}
      </Div>
    </Link>
  )
}
