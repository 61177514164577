import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'

import { TTextProps, Text } from './Text'

export type TEllipsisProps = {
  wrap?: boolean
} & TTextProps

export const Ellipsis = styled(Text).attrs<TEllipsisProps, TEllipsisProps>(({ wrap, style }) => ({
  style: mergeStyles(style, { whiteSpace: wrap ? 'normal' : 'nowrap' }),
}))`
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`
