import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTheme } from 'styled-components'

import { openPopup } from '@hozana/dom/functions'
import { useAttributes } from '@hozana/dom/hooks/useAttributes'
import { APP_TYPE } from '@hozana/screen/constants'
import { getAppType } from '@hozana/screen/functions/getAppType'
import { IMAGE_SIZES, getImageUrl } from '@hozana/styling/image'
import { QA } from '@hozana/tests/constants'

import type { TButtonColorName } from 'elements/button/BaseButton'
import { RoundButton, TRoundButtonProps } from 'elements/button/RoundButton'
import { Div } from 'elements/layout/Div'

import {
  DEFAULT_SHARE_TYPES,
  SHARE_OBJECTS,
  SHARE_SOCIAL_SIZE,
  SHARE_TYPES,
  TSocialShareType,
  getSocialShareIcon,
} from 'modules/share/constants'
import { getSocialShareUrl } from 'modules/share/functions'
import { useShare } from 'modules/share/hook/useShare'

const PRIVATE_SHARE_TYPES = [SHARE_TYPES.WHATSAPP, SHARE_TYPES.MESSENGER]

export type TShareSocialProps = {
  shareTypes: TSocialShareType[]
  colors?: TButtonColorName
  vertical?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
} & Omit<TRoundButtonProps, 'icon' | 'onClick'>

export const ShareSocial: React.FC<TShareSocialProps> = ({
  shareTypes = DEFAULT_SHARE_TYPES,
  colors,
  vertical = false,
  children,
  size = SHARE_SOCIAL_SIZE.REGULAR,
  onClick,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const attrs = useAttributes()
  const share = useShare()

  const hasPicture = () => {
    switch (share.objectToShare) {
      case SHARE_OBJECTS.COMMUNITY:
        return !!share.community.picture
      case SHARE_OBJECTS.PUBLICATION:
        return !!share.publication.picture
      case SHARE_OBJECTS.TESTIMONIALS:
        return true
      default:
        return false
    }
  }

  const isValidShareType = (shareType: SHARE_TYPES) => {
    if (
      (shareType !== SHARE_TYPES.FACEBOOK &&
        shareType !== SHARE_TYPES.TWITTER &&
        shareType !== SHARE_TYPES.PINTEREST &&
        shareType !== SHARE_TYPES.MESSENGER &&
        shareType !== SHARE_TYPES.WHATSAPP) ||
      (shareType === SHARE_TYPES.PINTEREST && !hasPicture()) // Pinterest share is valid only if there is a picture
    ) {
      return false
    }
    return true
  }

  const shareOnSocialNetwork = (shareType: SHARE_TYPES) => {
    share.track(shareType)

    let message = ''
    let pictureUrl = ''
    switch (share.objectToShare) {
      case SHARE_OBJECTS.HOZANA: {
        message = t('share:app.hozana.discover')
        break
      }
      case SHARE_OBJECTS.COMMUNITY: {
        message = t(
          [SHARE_TYPES.MESSENGER, SHARE_TYPES.WHATSAPP].includes(shareType)
            ? 'share:community.invite.message.familiar'
            : 'share:community.invite.message.polite',
          { communityName: share.community.name },
        )
        pictureUrl = share.community.picture && getImageUrl(share.community.picture, IMAGE_SIZES.community.medium)
        break
      }
      case SHARE_OBJECTS.PUBLICATION: {
        message = t(
          [SHARE_TYPES.MESSENGER, SHARE_TYPES.WHATSAPP].includes(shareType)
            ? 'share:publication.discover.long.familiar'
            : 'share:publication.discover.long.polite',
          { publicationName: share.publication.name },
        )
        pictureUrl = share.publication.picture && getImageUrl(share.publication.picture, IMAGE_SIZES.publication.medium)
        break
      }
      case SHARE_OBJECTS.TESTIMONIALS: {
        message = t('share:testimony.discover-testimony')
        break
      }
      case SHARE_OBJECTS.PRAYER_MEETING: {
        message = t('share:event.meeting.share')
        break
      }
      case SHARE_OBJECTS.INTENTION: {
        message = t('share:intention.invite.message')
        break
      }
      default:
        break
    }

    share.generateInviteLink(shareType).then((url) => {
      const appType = getAppType()

      /** Only open a popup on desktop */
      const shouldOpenPopup = getAppType() === APP_TYPE.BROWSER_DESKTOP
      const socialShareUrl = getSocialShareUrl(shareType, url, message, pictureUrl, shouldOpenPopup)

      if (appType === APP_TYPE.IOS_NATIVE) {
        window.postMessage(JSON.stringify({ action: 'open-external-url', url: socialShareUrl }), '*')
      } else {
        const w = shouldOpenPopup ? openPopup(640, 360) : window
        w.location.href = socialShareUrl
      }
    })
  }

  const isPrivacyAutorizedShare = (shareType: SHARE_TYPES) =>
    share.community?.isPrivate && !PRIVATE_SHARE_TYPES.includes(shareType) ? false : true

  if (children && share && isValidShareType(shareTypes[0])) {
    return isPrivacyAutorizedShare(shareTypes[0]) ? (
      <Div
        onClick={(e) => {
          onClick?.(e)
          shareOnSocialNetwork(shareTypes[0])
        }}
      >
        {children}
      </Div>
    ) : null
  } else {
    return share ? (
      <Div align="topLeft" column={vertical}>
        {shareTypes.map(
          (shareType) =>
            isValidShareType(shareType) &&
            isPrivacyAutorizedShare(shareType) && (
              <Div key={shareType} p="3px" m="0 3px">
                <RoundButton
                  icon={getSocialShareIcon(shareType)}
                  colors={colors || 'white'}
                  {...(!colors && {
                    bg: shareType as keyof DefaultTheme['colors'],
                    color: 'white',
                    hoverBg: `${shareType}Dark` as keyof DefaultTheme['colors'],
                  })}
                  onClick={() => {
                    onClick?.(undefined)
                    shareOnSocialNetwork(shareType)
                  }}
                  data-testid={QA.SHARE_COMMON.SOCIAL_BUTTON(shareType)}
                  label={
                    {
                      [SHARE_TYPES.FACEBOOK]: 'share:share.by-facebook',
                      [SHARE_TYPES.INSTAGRAM]: 'share:share.by-instagram',
                      [SHARE_TYPES.MESSENGER]: 'share:share.by-messenger',
                      [SHARE_TYPES.PINTEREST]: 'share:share.by-pinterest',
                      [SHARE_TYPES.TWITTER]: 'share:share.by-twitter',
                      [SHARE_TYPES.WHATSAPP]: 'share:share.by-whatsapp',
                      [SHARE_TYPES.YOUTUBE]: 'share:share.by-youtube',
                      [SHARE_TYPES.TIKTOK]: 'share:share.by-tiktok',
                    }[shareType]
                  }
                  size={size}
                  {...otherProps}
                  {...attrs}
                />
              </Div>
            ),
        )}
      </Div>
    ) : null
  }
}
