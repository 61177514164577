import React, { useState } from 'react'
import styled from 'styled-components'

import type { TButtonProps } from 'elements/button/Button'
import { Cell } from 'elements/layout/Cell'
import { Li } from 'elements/layout/Li'
import { Row, TRowProps } from 'elements/layout/Row'
import { StretchCell } from 'elements/layout/StretchCell'
import { Icon, TIconProps } from 'elements/media/Icon'
import { EllipsisMultiLines } from 'elements/text/EllipsisMultiLines'
import { Link, TLinkProps } from 'elements/text/Link'
import { Alert } from 'elements/ui/Alert'
import { Bubble } from 'elements/ui/Bubble'
import { Help } from 'elements/ui/Help'

type TStyledRowProps = {
  isLinkContainer?: boolean
} & TRowProps

const StyledRow = styled(Row)<TStyledRowProps>`
  font-weight: normal;

  ${(p) =>
    p.isLinkContainer &&
    `& a:hover {
    display: inline-block; // inline-block is necessary to display a background-color
    background-color: ${p.theme.colors.background};
    }`};
`

const StyledButton = styled.button<TButtonProps>`
  display: block;
  line-height: 0;
  border-radius: ${(props) => props.theme.borderRadius.normal};
  color: ${(props) => props.theme.colors[props.color]};
  background-color: transparent;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0;
  font-weight: normal;
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.background};
  }
`

type TPopoverButtonProps = {
  icon?: TIconProps['name']
  iconColor?: TIconProps['color']
  bubble?: number
  color?: TIconProps['color']
  disabled?: boolean | string
} & (TButtonProps | TLinkProps)

export const PopoverButton: React.FC<TPopoverButtonProps> = ({
  icon,
  iconColor,
  bubble,
  disabled,
  children,
  ...otherProps
}) => {
  const [openExplanations, setOpenExplanations] = useState(false)

  // use the color red to display the buttons to delete
  const color = disabled ? 'black30' : icon === 'trash' ? 'red' : 'black'

  const toggleDisabledAlert = () => setOpenExplanations(!openExplanations)
  const isLinkContainer = 'to' in otherProps && !disabled

  return (
    <Li className="popoverButton">
      <StyledRow align="middleBetween" gutter="5px" noMargin isLinkContainer={isLinkContainer}>
        <Cell flex="1">
          <StyledButton
            {...(isLinkContainer && { as: Link })}
            color={disabled ? 'black30' : 'black'}
            disabled={!!disabled}
            {...otherProps}
            w="100%"
          >
            <Row align="middleCenter" p={{ xs: '1em 20px', md: '12px 1em' }}>
              {icon && (
                <Cell>
                  <Icon name={icon} color={iconColor || color} m="0 1em 0 0" />
                </Cell>
              )}
              <Cell as={bubble ? StretchCell : undefined} flex="1">
                <EllipsisMultiLines lines="1" align="left" bold={false} fontSize={{ xs: '1em', md: '1em' }}>
                  {children}
                </EllipsisMultiLines>
              </Cell>
              {bubble > 0 && (
                <Cell m="0 0 0 5px">
                  <Bubble number={bubble} labelColor="yellow" />
                </Cell>
              )}
            </Row>
          </StyledButton>
        </Cell>
        {typeof disabled === 'string' && (
          <Cell>
            <Help size="1.5em" onClick={toggleDisabledAlert} />
          </Cell>
        )}
        {openExplanations && (
          <Alert status="info" m="5px 0" onClose={toggleDisabledAlert}>
            {disabled}
          </Alert>
        )}
      </StyledRow>
    </Li>
  )
}
