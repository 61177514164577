import React from 'react'

import { BorderedDiv } from 'elements/layout/BorderedDiv'
import { Div } from 'elements/layout/Div'
import { Link } from 'elements/text/Link'
import { P } from 'elements/text/P'
import { Text } from 'elements/text/Text'

import { PAGE } from 'routes/constants'

import { SHARE_TYPES } from 'modules/share/constants'

import { SocialLink } from './SocialLink'

type TFooterSocialLinksProps = {
  light?: boolean
}
export const FooterSocialLinks: React.FC<TFooterSocialLinksProps> = ({ light }) => (
  <>
    <P align="center" color={light ? 'white' : 'black50'} italic fontSize="0.9em" m="30px 0 10px 0">
      trans:common:share.wording.discover-on-socials
    </P>
    <Div align="middleCenter">
      <SocialLink socialName={SHARE_TYPES.FACEBOOK} />
      <SocialLink socialName={SHARE_TYPES.YOUTUBE} />
      <SocialLink socialName={SHARE_TYPES.INSTAGRAM} />
      <SocialLink socialName={SHARE_TYPES.TIKTOK} />
    </Div>
    <Link
      to={{ pathname: PAGE.RELEASES }}
      color={light ? 'white' : 'black50'}
      hoverColor={light ? 'white' : 'black70'}
      w="100%"
    >
      <BorderedDiv
        bg={light ? 'fadedWhite10' : 'fadedBlack10'}
        borderRadius="20px"
        m="20px auto 0"
        p="5px 10px"
        w="fit-content"
      >
        <Text italic fontSize="0.8em">
          Hozana {CONF_KEYS.APP_VERSION} -{' '}
          <Text>{/dev|preprod|staging/.test(CONF_KEYS.ENV) ? CONF_KEYS.GIT_BRANCH : 'trans:common:word.news'}</Text>
        </Text>
      </BorderedDiv>
    </Link>
  </>
)
