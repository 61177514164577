import { useEffect, useState } from 'react'

export const useOnline = (): boolean => {
  const [online, setOnline] = useState(__CLIENT__ && navigator ? navigator.onLine : true)

  useEffect(() => {
    const updateOnlineState = () =>
      setOnline((prevOnline) => (navigator.onLine !== prevOnline ? navigator.onLine : prevOnline))

    window.addEventListener('online', updateOnlineState)
    window.addEventListener('offline', updateOnlineState)

    return () => {
      window.removeEventListener('online', updateOnlineState)
      window.removeEventListener('offline', updateOnlineState)
    }
  }, [])

  return online
}
