import { incrementEntityValue, updateEntity } from '@hozana/api/actions'
import type { TQueryConstructor } from '@hozana/api/types'
import type { TDateTime } from '@hozana/dates/types'
import { camelToKebab } from '@hozana/utils/functions/strings'

import { PRAY_OBJECT_TYPE } from './constants'

export type TPrayMutationPayload = {
  objectId: number
  objectType: Exclude<PRAY_OBJECT_TYPE, PRAY_OBJECT_TYPE.USER>
  userId?: number
  widgetId?: number
  guestName?: string
}

export const prayMutation: TQueryConstructor<
  TPrayMutationPayload,
  {
    id: number
    createdAt: TDateTime
    fromUser: number
    toCommunity?: number
    toPublication?: number
    toComment?: number
    toIntention?: number
    toBirthdayIntention?: number
    toArrivalIntention?: number
    toUser?: number
  }
> = ({ objectId, objectType, userId, widgetId, guestName }) => ({
  queryKey: `pray/${objectType}/${objectId}`,
  url: `/${camelToKebab(objectType)}/${objectId}/pray`,
  method: 'POST',
  params: { userId, fromWidgetId: widgetId, fromGuestName: guestName },
  onSuccess: (dispatch) => {
    if (objectType === PRAY_OBJECT_TYPE.COMMUNITY) {
      dispatch(incrementEntityValue(objectType, objectId, 'prayersCount'))
    }
    if ([PRAY_OBJECT_TYPE.COMMUNITY, PRAY_OBJECT_TYPE.INTENTION].includes(objectType)) {
      dispatch(updateEntity(objectType, objectId, { hasPrayed: true }))
    }
  },
})
