'use client'

import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef } from 'react'

const SCRIPT_SRC_BASE = 'https://app.termly.io'
const WEBSITE_UUID = 'cbff7a95-e176-4e34-8795-03cc231afa11'
const UTM_SOURCE_REQUIRED = 'YT_ad'

// See : https://support.termly.io/en/articles/9994284-how-to-install-termly-s-consent-management-platform-in-a-next-js-app?_gl=1*1gzzjcz*_gcl_au*MzcyNjEyMDYyLjE3Mjk4NTAwNjc.
const TermlyCMP: React.FC = () => {
  const scriptSrc = useMemo(() => {
    const src = new URL(SCRIPT_SRC_BASE)
    src.pathname = `/resource-blocker/${WEBSITE_UUID}`
    return src.toString()
  }, [])

  const isScriptAdded = useRef(false)

  useEffect(() => {
    if (isScriptAdded.current) return
    const script = document.createElement('script')
    script.src = scriptSrc
    document.head.appendChild(script)
    isScriptAdded.current = true
  }, [scriptSrc])

  const router = useRouter()

  const pathname = router.pathname

  const searchParams = router.query

  useEffect(() => {
    ;(window as Window & { Termly?: { initialize: () => void } }).Termly?.initialize()
  }, [pathname, searchParams])

  return null
}

export const ContentTermlyToggler: React.FC<{ children: React.ReactElement<any, any> }> = ({ children }) => {
  const router = useRouter()
  const isLandingPage = router.pathname.endsWith('/landing')
  const utmYT = router.query.utm_source === UTM_SOURCE_REQUIRED

  return isLandingPage && utmYT /*&& CONF_KEYS.ENV === 'prod'*/ ? children : null
}

export default TermlyCMP
