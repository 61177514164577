import React from 'react'
import styled, { css } from 'styled-components'

import { TResponsiveProps, calcOnResponsiveValue, responsiveStyle } from '@hozana/styling/responsive'

import { RoundButton, TRoundButtonProps } from 'elements/button/RoundButton'
import { Input, TInputProps } from 'elements/input/Input'
import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { Div } from 'elements/layout/Div'

const responsiveProps = { inputPaddingRight: 'padding-right' } as const

type TStyledInputProps = TInputProps &
  TResponsiveProps<typeof responsiveProps> & {
    hideButtonOnEmptyValue?: boolean
  }

export const StyledInput = styled(Input)<TStyledInputProps>`
  ${({ hideButtonOnEmptyValue }) =>
    hideButtonOnEmptyValue &&
    css`
      &:placeholder-shown + ${AbsoluteDiv} > button {
        display: none;
      }
    `}
  ${responsiveStyle(responsiveProps)}
`

export type TWithInnerButtonInputProps = {
  buttonIcon: TRoundButtonProps['icon']
  buttonAriaLabel?: TRoundButtonProps['ariaLabel']
  buttonLabel?: TRoundButtonProps['label']
  tabIndex?: number
  isButtonActive?: boolean
  hideButton?: boolean
  onButtonClick: TRoundButtonProps['onClick']
  buttonProps?: Partial<TRoundButtonProps>
  /** If you change this, try to fill the input with text, then adjust inputPaddingRight */
  p?: TStyledInputProps['p']
} & TStyledInputProps

/**
 * ### WithInnerButtonInput ###
 *
 * Input with a button inside on the right side.
 *
 * By default, props goes to the input. To pass other props to the button, use the buttonProps prop.
 *
 * /!\ Warning: if you alter the input padding, using p prop, you must adjust inputPaddingRight:
 * otherwise, when the input is full of text, it won't be clean.
 */
export const WithInnerButtonInput = React.forwardRef<HTMLInputElement, TWithInnerButtonInputProps>(
  (
    {
      buttonIcon,
      buttonLabel,
      buttonAriaLabel,
      disabled,
      hideButton,
      isButtonActive,
      onButtonClick,
      buttonProps = {},
      fontSize,
      color,
      inputPaddingRight = '2.7em',
      p,
      value,
      tabIndex,
      ...otherProps
    },
    ref,
  ) => (
    <Div fontSize={fontSize}>
      <StyledInput
        fontSize="inherit !important"
        {...{ ref, color, inputPaddingRight, p, disabled, value, tabIndex }}
        {...otherProps}
      />
      {!hideButton && (
        <AbsoluteDiv top bottom right align="middleCenter" opacity={isButtonActive ? 0.8 : 0.5}>
          <RoundButton
            icon={buttonIcon}
            label={buttonLabel}
            size="1.6em"
            m={p ? calcOnResponsiveValue(p, '* 0.5 - 0.25em') : '0.2em'}
            ariaLabel={buttonAriaLabel}
            onClick={onButtonClick}
            color={buttonProps.color || color || 'black'}
            type="button"
            disabled={disabled}
            tabIndex={tabIndex}
            {...buttonProps}
          />
        </AbsoluteDiv>
      )}
    </Div>
  ),
)

WithInnerButtonInput.displayName = 'WithInnerButtonInput'
