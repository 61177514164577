/**
 * Util functions whose job is to save the landing information in cookies.
 */
import type { TDateTime } from '@hozana/dates/types'
import { cookie } from '@hozana/storage/cookies'

import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'

export type TLandingInfo = {
  referrer: string
  landingUrl: string
  landingDate: Date
}

/**
 * Saves landing info as a cookie to pass it to API on register.
 * @param landingInfo
 */
export function saveLandingInfo(landingInfo: TLandingInfo): void {
  cookie.save(COOKIES.landingInfo, JSON.stringify(landingInfo), COOKIES_CONFIG.ONE_MONTH)
}

/**
 * Returns boolean if landing information has already been set or not.
 */
export function hasLandingInfo(): boolean {
  return !!cookie.load(COOKIES.landingInfo)
}

/**
 * Get landing information previously stored in cookies.
 */
export function getLandingInfo(): (Omit<TLandingInfo, 'landingDate'> & { landingDate: TDateTime }) | undefined {
  return cookie.load(COOKIES.landingInfo)
}
