import jwtDecode from 'jwt-decode'

import { buildQueryString, getApiUrl } from '@hozana/api/functions'
import { cookie } from '@hozana/storage/cookies'
import { GTM } from '@hozana/tracking/gtm'
import { getLandingInfo } from '@hozana/tracking/landingInfo'
import { getSponsorInfos } from '@hozana/tracking/sponsor'

import { updateConnectDetails } from 'app/functions'
import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'
import type { TCookieName } from 'app/managers/cookies/types'

import { CONNECT_METHOD } from 'modules/auth/constants'

import type { TTokenPayload } from './type'

export function saveTokenCookie(token: string) {
  if (!token) {
    throw new Error('Must not save an empty token: to remove it, use removeToken function.')
  }

  cookie.save(COOKIES.token, token, COOKIES_CONFIG.ONE_YEAR)
}

export const loadToken = (): string => cookie.load(COOKIES.token)

/**
 * @returns the payload inside a JWT token, false if payload cannot be read
 * We use a try/catch because the jwtDecode function throws an exception.
 * Attention: a JWT can be invalid on the server-side and still be decoded correctly,
 * because only the last part of the token is the actual signature (see www.jwt.io)
 */
export function decodeToken(token: string): TTokenPayload | null {
  try {
    return jwtDecode(token)
  } catch (e) {
    return null
  }
}

/**
 * Checks if the given expiration time is within the next 30 days
 */
export function isTokenExpiringSoon(tokenExp: number) {
  const currentTime = Date.now() / 1000
  const oneMonthInterval = 2592000
  return tokenExp - currentTime < oneMonthInterval
}

/**
 * Checks if we are beyond the given expiration time
 */
export function isTokenExpired(tokenExp: number) {
  const currentTime = Date.now() / 1000
  return tokenExp < currentTime
}

export function removeToken() {
  cookie.remove(COOKIES.token, COOKIES_CONFIG.ONE_YEAR)
  cookie.remove(COOKIES.token, { path: '/', domain: '.preprod.hozana.org' }) // remove token set by the server
  cookie.remove(COOKIES.token, { path: '/', domain: '.hozana.org' }) // remove token set by the server
  localStorage.removeItem('hozana\\me') // remove frontend-5 me data
  localStorage.removeItem('hozana\\token') // remove frontend-5 token

  // Clear all cookies starting with 'session' (to get all cookies, omit regex argument)
  Object.keys(cookie.select(/^session.*/i) as unknown as Partial<Record<TCookieName, string>>).forEach((name) =>
    cookie.remove(name, COOKIES_CONFIG.ONE_YEAR),
  )
}

/**
 * Returns url for popup (desktop) or redirect (mobile).
 */
export const getSocialAuthUrl = (socialName: string, lang: string, redirectToLocal: boolean) =>
  `${getApiUrl(lang, false)}/auth/${socialName}?${redirectToLocal ? 'redirectToLocalFrontend&' : ''}${buildQueryString({
    ...getLandingInfo(),
    sponsorKey: getSponsorInfos(),
  })}`

type TConnectSuccessProps = {
  method: CONNECT_METHOD
  newUser: boolean
}

export const trackMobileConnectSuccess = ({ method, newUser }: TConnectSuccessProps) => {
  /**
   * Avoid referrer being m.facebook.com after Facebook connect, GA won't start a new session.
   * @see https://medium.com/fatlama-nerds/excluding-facebook-and-google-social-sign-in-from-your-referrals-with-google-analytics-ff81588e519
   */
  GTM.push({ referrer: window.location.origin })

  updateConnectDetails({
    gtmData: [
      {
        event: newUser ? GTM.EVENTS.SIGNUP_FORM_SUBMIT_SUCCESS : GTM.EVENTS.LOGIN_FORM_SUBMIT_SUCCESS,
        method,
        referrer: window.location.origin,
      },
    ],
  })

  cookie.save(COOKIES.connectMethod, newUser ? 'Signup' : 'Login', COOKIES_CONFIG.SESSION)
}
