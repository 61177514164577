import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import { BaseButton, TBaseButtonProps } from 'elements/button/BaseButton'
import { Icon, TIconProps } from 'elements/media/Icon'
import { TTooltipProps, Tooltip } from 'elements/ui/Tooltip'

const responsiveProps = { size: 'font-size', h: 'height', w: 'width', m: 'margin' } as const

type TStyledBaseButtonProps = {
  shadow?: boolean
} & TResponsiveProps<typeof responsiveProps> &
  TBaseButtonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className' | 'value'>

export const StyledBaseButton = styled(BaseButton).attrs<TStyledBaseButtonProps, TStyledBaseButtonProps>(
  ({ shadow, style }) =>
    shadow
      ? {
          style: mergeStyles(style, {
            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 8px 0px',
          }),
        }
      : {},
)`
  line-height: 0;
  border-radius: 50%;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0;

  & + & {
    margin-left: 10px;
  }

  /* width, margin, padding */
  ${responsiveStyle(responsiveProps)}
`

export type TRoundButtonProps = {
  reverseColors?: boolean
  icon: TIconProps['name']
  color?: TIconProps['color']
  iconSize?: TIconProps['size']
} & TStyledBaseButtonProps &
  TTooltipProps

/**
 * ### RoundButton ###
 */
export const RoundButton: React.FC<TRoundButtonProps> = ({
  icon,
  colors = 'greyTransparent',
  size = '1.7rem',
  iconSize,
  onClick,
  label,
  ariaLabel,
  h = '1.4em',
  w = '1.4em',
  ...otherProps
}) => (
  // padding 0 to prevent user agent style
  <Tooltip label={label} ariaLabel={ariaLabel}>
    <StyledBaseButton colors={colors} size={size} onClick={onClick} h={h} w={w} {...otherProps}>
      <Icon name={icon} size={iconSize ?? '0.9em'} />
    </StyledBaseButton>
  </Tooltip>
)
