import { produce } from 'immer'

import type { TAllAction, TState } from 'config/types'

import { IntentionActionTypes } from './constants'

const INTENTION_API_INITIAL_STATE = {} as TState['api']['entities']['intention']

export const intentionApiReducer = (state = INTENTION_API_INITIAL_STATE, action: TAllAction) =>
  produce(state, (newState) => {
    switch (action.type) {
      case IntentionActionTypes.ADD_PRAYER:
        if (
          newState[action.id]?.prayers &&
          !newState[action.id].prayers.find((prayer) => prayer.prayerId === action.prayer.prayerId)
        ) {
          newState[action.id].prayers.unshift(action.prayer)
          newState[action.id].prayersCount++
        }
        break

      default:
        break
    }
  })
