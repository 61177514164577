import { ICON } from 'config/icons'

export enum SOCIAL_NAME {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
}

export const getSocialIcon = (socialName: SOCIAL_NAME) =>
  ((
    {
      [SOCIAL_NAME.FACEBOOK]: ICON.FACEBOOK,
      [SOCIAL_NAME.GOOGLE]: ICON.GOOGLE,
    } as Record<SOCIAL_NAME, ICON>
  )[socialName])

export enum CONNECT_TAB {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

export const CONNECT_TAB_NAME = 'connect-tab'

export enum CONNECT_METHOD {
  PASSWORD = 'password',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  NATIVE = 'native',
}
