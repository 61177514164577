import type { TLocale } from 'i18n/constants'

import { SOCIAL_NAME } from 'modules/auth/constants'
import { COMMUNITY_PUBLICATION_FREQUENCY } from 'modules/community/constants'
import { NOTIFICATION_CATEGORY, NOTIFICATION_MEDIUM } from 'modules/notification/constants'
import { SHARE_TYPES } from 'modules/share/constants'

export const QA = {
  ELEMENTS: {
    LOCATION_INPUT: {
      POPOVER: 'location-input-popover',
      OPTION: 'location-input-option',
    },
  },
  COMMON: {
    LOCALE_TOGGLE_BUTTON: 'locale-toggle-button',
    LOCALES_WRAPPER: 'locales-wrapper',
    LOCALE_ITEM_BUTTON: (locale: TLocale) => `locale-item-${locale.toLowerCase()}-button`,
    ALERT_CLOSE_BUTTON: 'alert-close-button',
    HELP_LINK: 'help-link',
    FLASH_MESSAGE: 'flash-message',
    BREADCRUMB_BUTTON: 'breadcrumb-button',
    BREADCRUMB_CONTENT: 'breadcrumb-content',
    ROUNDED_SEARCH_INPUT: 'rounded-search-input',
    RADIO_INPUT: 'radio-input',
    RADIO_INPUT_LABEL: 'radio-input-label',
    INPUT_FILE: 'input-file',
    DIV_FILE: 'div-file',
    VALIDATE_FILE_BUTTON: 'validate-file-button',
    ERROR_MESSAGE_UPLOAD: 'error-message-upload',
    FROALA_EDITOR: 'froala-editor',
    DATE_PICKER: 'date-picker',
    TAB_LOGIN_LINK: 'tab-login-link',
    AVATAR_IMAGE: 'avatar-image',
    PRAY_BUTTON: 'pray-button',
    BANNER: {
      PUBLICATION: {
        WRAPPER: 'banner-publication-wrapper',
        CLOSE_BUTTON: 'banner-publication-close',
      },
      COOKIES: {
        WRAPPER: 'banner-accept-cookies',
        ACCEPT_BUTTON: 'banner-accept-cookies-button',
        CLOSE_BUTTON: 'banner-close-cookies-button',
      },
      LANG: {
        WRAPPER: 'banner-lang-wrapper',
        CHOOSE_SUGGESTED_BUTTON: 'banner-lang-choose-selected',
        CLOSE_BUTTON: 'banner-lang-close',
      },
    },
    TAB_LINK: (tabId: string) => `tab-${tabId}-link`,
    SIDEBAR: 'sidebar',
    HEADER: {
      LOGIN_BUTTON: 'header-login-button',
      SIGNUP_BUTTON: 'header-signup-button',
      NAVIGATION_OPEN_BUTTON: 'header-navigation-open-button',
      LOGO_BUTTON: 'header-logo-button',
      DONATE_BUTTON: 'header-donate-button',
      FEED_PAGE_LINK: 'header-feed-page-link',
      COMMUNITIES_PAGE_LINK: 'header-communities-page-link',
      MESSAGES_PAGE_LINK: 'header-messages-page-link',
    },
    NAVIGATION: {
      CLOSE_BUTTON: 'navigation-close-button',
      NAVIGATION_PANEL: 'navigation-panel',
      DONATE_LINK: 'navigation-donate-link',
      HOME_LINK: 'navigation-home-button',
      COMMUNITIES_LINK: 'navigation-discover-communities-link',
      PROFILE_LINK: 'navigation-profile-link',
      FAVORITES_LINK: 'navigation-favorites-link',
      WIDGET_LINK: 'navigation-widget-link',
      SETTINGS_LINK: 'navigation-settings-link',
      LIVE_LINK: 'navigation-live-link',
      LOGIN_LINK: 'navigation-login-link',
      SIGNUP_LINK: 'navigation-signup-link',
      APP_STORE_LINK: 'navigation-app-store-link',
      MISC_LINKS: (name: string) => `navigation-misc-${name}-link`,
      SOCIAL_LINK: (name: string) => `navigation-social-${name}-link`,
    },
    POPIN: {
      POPIN_WRAPPER: 'popin-wrapper',
      POPIN_BACK: 'popin-wrapper-back',
    },
    CONFIRM_POPIN: {
      CONFIRM_BUTTON: 'confirm-popin-confirm-button',
      CANCEL_BUTTON: 'confirm-popin-cancel-button',
      MESSAGE: 'confirmation-popin-message',
    },
    INPUT_POPIN: {
      FORM: 'input-popin-form',
      INPUT: 'input-popin-form-input',
      CONFIRM: 'input-popin-form-confirm',
      CANCEL: 'input-popin-form-cancel',
    },
    MESSAGE_POPIN: {
      MESSAGE: 'message-popin-message',
      BUTTON: 'message-popin-button',
    },
    PUSH: {
      ASK: 'push-ask',
      PUSH_ASK_ACCEPT: 'push-ask-accept',
      PUSH_ASK_REFUSE: 'push-ask-refuse',
      MESSAGE: 'push-message',
    },
    PUSH_BLOCKED_POPIN: {
      ENABLE_NOTIFICATIONS_BUTTON: 'push-blocked-popin-enable-notifications-button',
      NO_THANKS_BUTTON: 'push-blocked-popin-no-thanks-button',
      CONFIRM_BUTTON: 'push-blocked-popin-confirm-button',
    },
    TUTORIAL_POPIN: {
      CHAPTER: 'tutorial-popin-chapter',
      NEXT_BUTTON: 'tutorial-popin-next-button',
    },
    LINUX_DEV_POPIN: {
      TEXT: 'linux-dev-popin-text',
      BUTTON: 'linux-dev-popin-button',
    },
    APP_PROMPT_POPIN: {
      STORE_LINK: 'app-prompt-popin-store-link',
    },
    RATE_PROMPT_POPIN: {
      CONFIRM_BUTTON: 'rate-prompt-popin-confirm-button',
      REFUSE_BUTTON: 'rate-prompt-popin-refuse-button',
    },
  },
  AUTH: {
    EMAIL: {
      INPUT: 'auth-email-input',
      SUBMIT_BUTTON: 'auth-email-submit-button',
    },
    PASSWORD: {
      INPUT: 'auth-password-input',
      SUBMIT_BUTTON: 'auth-password-submit-button',
    },
    NAME: {
      INPUT: 'auth-name-input',
      SUBMIT_BUTTON: 'auth-name-submit-button',
    },
    SOCIAL_CONNECT_BUTTON: (socialName: SOCIAL_NAME) => `social-connect-${socialName}-button`,
  },
  PUBLICATION_COMMON: {
    PUBLICATION_BLOCK: 'publication-block',
    READ_MORE_BUTTON: 'read-more-button',
    LIST_OPTIONS_CHEVRON: 'list-options-chevron',
    BIG_SHARE_BUTTON: 'big-share-button',
  },
  PUBLICATION_OPTIONS: {
    EDIT_PUBLICATION: 'edit-publication',
    REMOVE_FROM_FAVORITES: 'remove-from-favorites',
    DELETE_PUBLICATION: 'delete-publication',
  },
  PUBLICATION_CREATE_PAGE: {
    CREATING_PUBLICATION_TITLE: 'creating-publication-title',
    COPYRIGHT_CHECKBOX: 'copyright-checkbox',
    NEXT_BUTTON: 'next-button',
  },
  PUBLICATION_FORM: {
    TITLE_INPUT: 'publication-form-title-input',
    VALIDATE_BUTTON: 'publication-form-validate-button',
  },
  PUBLICATION_PAGE: {
    TITLE: 'publication-page-title',
    IMAGE: 'publication-page-image',
    PRAYER: 'publication-page-prayer',
  },
  COMMUNITY_CHAPTER: {
    PRAY_NOW: 'community-chapter-pray-now',
    WRITE_INTENTION: 'community-chapter-write-intention',
    DISCOVER: 'community-chapter-discover',
    ICON_CHECKED: 'community-chapter-icon-checked',
    INVITE: 'community-chapter-invite',
  },
  COMMUNITY_COMMON: {
    DATES: 'community-dates',
    NB_MEMBERS: 'community-nb-members',
    JOIN_BUTTON: 'community-join-button',
    LEAVE_BUTTON: 'community-leave-button',
    JOIN_POPIN_FORM: 'community-join-popin-form',
    JOIN_POPIN_DATE_INPUT: 'community-join-popin-date-input',
    JOIN_POPIN_DATE_BUTTON: 'community-join-date-button',
    JOIN_POPIN_TITLE: 'community-join-popin-title',
    JOIN_POPIN_VALIDATE_DATE: 'community-join-validate-date',
    JOIN_POPIN_ONGOING: 'community-join-popin-ongoing',
    JOINED_POPIN_EMAIL_ACCEPT_BUTTON: 'community-joined-email-accept',
    JOINED_POPIN_EMAIL_REFUSE_BUTTON: 'community-joined-email-refuse',
    JOINED_POPIN_SHARE_COPY_LINK: 'community-joined-share-copy-link',
    JOINED_POPIN_SHARE_BY_MAIL: 'community-joined-share-by-mail',
    JOINED_POPIN_VALIDATE_PROFILE: 'community-joined-validate-profile',
    JOINED_POPIN_CLOSE: 'community-joined-close',
    LEAVE_POPIN_CONFIRM_BUTTON: 'community-leave-popin-confirm',
    LEAVE_POPIN_CANCEL_BUTTON: 'community-leave-popin-cancel',
    NOTIFICATION_PUSH: 'community-notification-popin-push',
    NOTIFICATION_EMAIL_PUBLICATIONS: 'community-notification-popin-email-publication',
    NOTIFICATION_EMAIL_ANNOUNCEMENTS: 'community-notification-popin-email-annoucements',
    NOTIFICATION_BUTTON: 'community-notifications-button',
    SHOW_ACTIONS_BUTTON: 'community-show-actions-button',
    ADMIN_BUTTON: 'community-admin-button',
    COMMUNITY_THUMBNAIL: 'community-thumbnail',
    SHARE_BUTTON: 'community-share-button',
    START_TODAY_BUTTON: 'community-start-today-button',
    SUBMITTED_POPIN_EXPLANATIONS_TEXT: 'community-submitted-popin-explanations-text',
    SUBMITTED_POPIN_CONFIRM_BUTTON: 'community-submitted-popin-confirm-button',
    SUBMITTED_POPIN_I_UNDERSTAND_BUTTON: 'community-submitted-popin-i-understand-button',
    COMMUNITY_MEMBERS_LIST_POPIN: {
      USERS_LIST: 'community-members-list-popin-users-list',
    },
  },
  COMMUNITY_WIDGET: {
    JOIN_BUTTON: 'community-widget-join-button',
    TABS_BUTTON: (i: number) => `community-widget-tabs-button-${i}`,
  },
  SHARE_COMMON: {
    SOCIAL_BUTTON: (shareType: SHARE_TYPES) => `share-social-button-${shareType}`,
    NB_SHARES: 'share-nb-shares',
    INVITE_BUTTON: 'share-invite-button',
    COPY_MAIL_BUTTON: 'share-copy-mail-button',
    COPY_LINK_BUTTON: 'share-copy-link-button',
  },
  USER_COMMON: {
    AVATAR: `user-avatar`,
    NAME: `user-name`,
    AVATAR_POPIN: {
      DESCRIPTION: 'user-common-avatar-popin-description',
      CHOOSE_IMAGE_BUTTON: 'user-common-avatar-popin-choose-image-button',
    },
    AVATARS_LIST_POPIN: {
      LIST_ITEMS: 'avatars-list-popin-list-items',
    },
    UPLOAD_LOGO_POPIN: {
      SAVE_BUTTON: 'upload-logo-popin-save-button',
      UPLOAD_LOGO: 'upload-logo-popin-upload-logo',
    },
    PRESUBSCRIBER_DELETED_POPIN: {
      DELETED_MESSAGE: 'presubscriber-deleted-popin-deleted-message',
      COMMUNITIES_DISCOVER_BUTTON: 'presubscriber-deleted-popin-communities-discover-button',
    },
    MAIL_DELIVERY_ISSUE: {
      EMAIL_INPUT: 'mail-delivery-issue-prompt-popin-email-input',
      SAVE_BUTTON: 'mail-delivery-issue-prompt-popin-save-bouton',
    },
  },
  USER_PROFIL: {
    CREATE_COMMUNITY_BUTTON: 'create-community-button',
  },
  SETTINGS_PAGE: {
    SWITCH_BUTTON: (medium: NOTIFICATION_MEDIUM, type: NOTIFICATION_CATEGORY) =>
      `settings-${medium}-${type}-switch-button`,
    ACCOUNT: {
      TAB: 'settings-account-tab-link',
      EMAIL_ACCORDION_LINK: 'settings-account-email-accordion-link',
      EMAIL_FORM: 'settings-account-email-form',
      PASSWORD_ACCORDION_LINK: 'settings-account-password-accordion-link',
      PASSWORD_FORM: 'settings-account-password-form',
      LOCALE_ACCORDION_LINK: 'settings-account-locale-accordion-link',
      LOCALE_FORM: 'settings-account-locale-form',
      DELETE_ACCOUNT_ACCORDION_LINK: 'settings-account-delete-account-accordion-link',
      DELETE_ACCOUNT_BUTTON: 'settings-account-delete-account-button',
      DELETE_ACCOUNT_FORM: 'settings-account-delete-account-form',
      LOGOUT_BUTTON: 'settings-account-email-logout-button',
      EMAIL_INPUT: 'settings-page-account-email-input',
      NOTIFICATION_EMAIL_INPUT: 'settings-page-account-notifications-email-input',
      OLD_PASSWORD_INPUT: 'settings-page-account-old-password-input',
      PASSWORD_INPUT: 'settings-page-account-password-input',
      CONFIRM_PASSWORD_INPUT: 'settings-page-account-confirm-password-input',
      SAVE_PASSWORD_BUTTON: 'settings-page-account-save-password-button',
      LANGUAGE_DROPDOWN: 'settings-page-account-language-dropdown',
      SAVE_LANGUAGE_BUTTON: 'settings-page-account-save-language-button',
    },
    EMAIL: {
      TAB: 'settings-emails-tab-link',
      NOTIFICATIONS_FORM: (type: NOTIFICATION_CATEGORY) => `settings-email-notifications-${type}-form`,
      GENERAL: {
        ACCORDION_LINK: 'settings-email-general-accordion-link',
      },
      PUBLICATIONS: {
        ACCORDION_LINK: 'settings-email-publications-accordion-link',
        SWITCH_ON_BUTTON: 'settings-email-publications-switch-on',
        SWITCH_OFF_BUTTON: 'settings-email-publications-switch-off',
      },
      ANNOUNCEMENTS: {
        ACCORDION_LINK: 'settings-email-announcements-accordion-link',
        SWITCH_ON_BUTTON: 'settings-email-announcements-switch-on',
        SWITCH_OFF_BUTTON: 'settings-email-announcements-switch-off',
      },
    },
    PUSH: {
      TAB: 'settings-push-tab-link',
      ACTIVE_DEVICE: 'settings-push-active-device',
      AVAILABLE_DEVICE: 'settings-push-available-device',
      TRASH_DEVICE: 'settings-push-trash-active-device',
      ACTIVATE_DEVICE: 'settings-push-activate-device',
      DEVICE: {
        ACCORDION_LINK: 'settings-push-device-accordion-link',
      },
      GENERAL: {
        ACCORDION_LINK: 'settings-push-general-accordion-link',
      },
      PUBLICATIONS: {
        ACCORDION_LINK: 'settings-push-publications-accordion-link',
        SWITCH_ON_BUTTON: 'settings-push-publications-switch-on',
        SWITCH_OFF_BUTTON: 'settings-push-publications-switch-off',
      },
      ANNOUNCEMENTS: {
        ACCORDION_LINK: 'settings-push-announcements-accordion-link',
        SWITCH_ON_BUTTON: 'settings-push-announcements-switch-on',
        SWITCH_OFF_BUTTON: 'settings-push-announcements-switch-off',
      },
    },
  },
  RESET_PASSWORD_PAGE: {
    PASSWORD_FORM: 'reset-password-password-form',
  },
  HOMEPAGE: {
    TOP_SECTION: (name: string) => `homepage-top-section-${name}`,
    CREATOR_SECTION_IMAGES: 'homepage-creator-section-images',
    CREATOR_SECTION_USERS: 'homepage-creator-section-users',
    TAGS_SECTION: 'homepage-tags-section',
  },
  COMMUNITIES_PAGE: {
    SEARCH_FILTER_BUTTON: 'communities-filter-button',
    COMMUNITY_RESULT: 'communities-community-result',
    SUGGESTED_TAG: 'communities-suggested-tag-card',
    EDIT_WIZARD_BUTTON: 'communities-edit-wizard-button',
    CURRENT_TAG_TITLE: 'current-tag-title',
    LABEL: 'communities-page-label',
  },
  COMMUNITY_PAGE: {
    TITLE: 'community-page-title',
    INTRO: 'community-page-intro',
    IMAGE: 'community-page-image',
    TABS_BAR: 'community-page-tabs-bar',
    TABS: <TL extends Record<string, string>>(tabList: TL): TL =>
      Object.keys(tabList).reduce<TL>(
        (acc, tabName: string) => ({
          ...acc,
          [tabName]: `tab-${tabName}-link`,
        }),
        {} as TL,
      ),
    SHARE_BLOCK: 'community-page-share-block',
    PRAYER_BLOCK: 'community-page-prayer-block',
    ACTION_BUTTONS: 'community-page-action-buttons',
    PUBLICATIONS: {
      TAB: 'tab-publications-link',
      WRAPPER: 'community-page-publications-wrapper',
    },
    SHARE_POPIN: {
      COPY_BUTTON: 'share-community-copy-button',
    },
    DESCRIPTION: {
      TAB: 'tab-description-link',
      WRAPPER: 'community-page-description-wrapper',
      TAG: 'community-page-tag',
      CONTENT: 'community-page-content',
      PRAYER: 'community-page-prayer',
      MOST_COMMENTED_PUBLICATIONS: 'community-page-most-commented-publications',
      COMMUNITIES_PROPOSITIONS: 'community-page-communities-propositions',
      GOOGLE_MAP: 'community-page-google-map',
    },
    SIDEBAR: {
      MAIN_BLOCK: 'community-page-sidebar-main-block',
      ADMIN_BLOCK: 'community-page-sidebar-admin-block',
    },
    SUPER_ADMIN_ACTIONS: {
      ADD_TAGS_BUTTON: 'community-page-add-tags-button',
      DISPLAY_MODE_BUTTON: 'community-display-mode-button',
      LIVE_FORM: 'community-live-form',
    },
  },
  COMMUNITY_CREATE_PAGE: {
    TITLE_SECTION_STATUS: 'title-section-status',
    TITLE_SECTION_TYPE: 'title-section-type',
    TITLE_SECTION_PRESENTATION: 'title-section-presentation',
    TITLE_SECTION_DESCRIPTION: 'title-section-description',
    TITLE_SECTION_PRAYER: 'title-section-prayer',
    INPUT_COMMUNITY_TITLE: 'input-community-title',
    COMMUNITY_SUMMARY_INPUT: 'community-summary-input',
    NEXT_BUTTON: 'next-button',
    PRAYER_BLOCK: 'prayer-block',
    PRAYER_LIBRARY_SELECT_INPUT: 'prayer-library-form',
    CUSTOM_PRAYER_TITLE_INPUT: 'custom-prayer-form',
    CUSTOM_PRAYER_TEXTAREA_INPUT: 'custom-prayer-textarea-input',
    LIBRARY_TAB: 'library-tab',
    CUSTOM_TAB: 'custom-tab',
    FROALA_EDITOR_INPUT: 'froala-editor-input',
    SAVE_TO_DRAFT_BUTTON: 'save-to-draft-button',
    SUBMIT_TO_VALIDATION_BUTTON: 'submit-to-validation-button',
    RETREAT_FROM_DATE_INPUT: 'retreat-from-date-input',
    RETREAT_TO_DATE_INPUT: 'retreat-to-date-input',
    FREQUENCY_RADIO_INPUT: 'community-frequency-radio-input',
    FREQUENCY_PUBLICATION: (frequency: COMMUNITY_PUBLICATION_FREQUENCY) => `${frequency}-input`,
    STATUS_INPUT: 'community-status-input',
    LIFETIME_INPUT: 'community-lifetime-input',
  },
  COMMUNITY_ROLE_PAGE: {
    ANIMATOR_DASHBOARD_BUTTON: 'community-role-animator-dashboard-button',
    ROLE_MEMBER_BLOCK: 'community-role-block',
    ADD_TO_ADMINISTRATORS: 'add-to-administrators-button',
    ADD_TO_ANIMATORS: 'add-to-animators-button',
    REMOVE_ROLE: 'remove-role-button',
    ADD_ROLE_FORM: {
      EMAIL_INPUT: 'add-role-form-email-input',
      SUBMIT_BUTTON: 'add-role-form-submit-button',
    },
  },
  COMMUNITY_CREATE_PUBLICATION: {
    SAVE_TO_DRAFT_BUTTON: 'publication-save-to-draft-button',
    SCHEDULE_BUTTON: 'publication-schedule-button',
    SCHEDULE_TO_DRAFT_BUTTON: 'publication-schedule-to-draft-button',
    PUBLISH_NOW_BUTTON: 'publication-publish-now-button',
    VALIDATION_SCHEDULE_BUTTON: 'publication-validation-schedule-button',
    EDIT_BUTTON: 'publication-edit-button',
  },
  INTENTION: {
    FORM: 'intention-form',
    OPTIONS: 'intention-options',
    EDIT: 'intention-edit',
    REMOVE: 'intention-remove',
    SHARE: 'intention-share',
    NAME_INPUT: 'intention-name-input',
    EMAIL_INPUT: 'intention-email-input',
    CONTENT_INPUT: 'intention-content-input',
    YOUR_INTENTION: 'intention-your-intention',
    LIST_INTENTION_ITEM: 'intention-list-intention-item',
    LIST_ARRIVAL_ITEM: 'intention-list-arrival-item',
    PRAY_BUTTON: 'intention-pray-button',
    PANEL: 'intention-panel',
    PANEL_TOGGLE: 'intention-panel-toggle',
    PRAYER_LIST_LINK: 'intention-prayers-list-link',
    PUBLISH_BUTTON: 'intention-publish-button',
    BROADCAST_POPIN: {
      POPIN: 'intention-broadcast-popin',
      NO: 'intention-broadcast-popin-no',
      YES: 'intention-broadcast-popin-yes',
    },
    SUCCESS_POPIN: {
      POPIN: 'intention-success-popin',
      NO: 'intention-success-popin-no',
      YES: 'intention-success-popin-yes',
      CONFIRM: 'intention-success-popin-confirm',
    },
    PRAYERS_LIST_POPIN: {
      POPIN: 'intention-prayers-list-popin',
      THANK_BUTTON: 'intention-prayers-list-thank-button',
      MESSAGE_BUTTON: 'intention-prayers-list-message-button',
    },
    PRAYER_WATCHMAN: {
      POPIN: {
        NO: 'prayer-watchman-popin-no-button',
      },
    },
    BROADCAST_INTENTION_WIDGET_POPIN: {
      BROADCAST_CHECKBOX: 'broadcast-intention-widget-popin-broadcast-checkbox',
      NEXT_BUTTON: 'broadcast-intention-widget-popin-next-button',
    },
    NEWSLETTER_INTENTION_WIDGET_POPIN: {
      NEWSLETTER_CHECKBOX: 'newsletter-intention-widget-popin-broadcast-checkbox',
      NEXT_BUTTON: 'newsletter-intention-widget-popin-next-button',
    },
    VALIDATION_INTENTION_WIDGET_POPIN: {
      CHECK_ICON: 'validation-intention-widget-popin-check-icon',
      UNDERSTOOD: 'validation-intention-widget-popin-understood',
    },
    INTENTION_END_PUSH_POPIN: {
      END_OF_INTENTION_TEXT: 'intention-end-push-popin-end-of-intention-text',
    },
  },
  PRAY: {
    AMEN_BUTTON: 'pray-amen-button',
    PRAYED_BUTTON: 'prayed-amen-button',
    PUBLICATION_PRAY_SUCCESS_POPIN: 'publication-pray-success-popin',
    USER_PRAY_POPIN: {
      CANDLE: 'user-pray-popin-candle',
    },
  },
  WIZARD: {
    CHAPTER: 'wizard-chapter',
    CHAPTER_TITLE: 'wizard-chapter-title',
    PREVIOUS_BUTTON: 'wizard-previous-button',
    NEXT_BUTTON: 'wizard-next-button',
    CLOSE_BUTTON: 'wizard-close-button',
    DURATION_VALUE: 'wizard-duration-value',
    TAG: 'wizard-chapter-tag',
    FORMAT: {
      TEXTUAL: 'wizard-chapter-format-textual',
      AUDIO: 'wizard-chapter-format-audio',
      VIDEO: 'wizard-chapter-format-video',
      ANY: 'wizard-chapter-any-format',
      TYPE: 'wizard-chapter-format-type',
    },
  },
  PROFILE_QUESTIONS: {
    GENDER: {
      M: 'profile-questions-gender-m',
      F: 'profile-questions-gender-f',
      CONTINUE: 'profile-questions-gender-continue',
    },
    LOCATION: {
      INPUT: 'profile-questions-location-input',
      CONTINUE: 'profile-questions-location-continue',
    },
    BIRTHDATE: {
      INPUT: 'profile-questions-birthdate-input',
    },
    FINISH_BUTTON: 'profile-questions-finish-button',
  },
  EDIT_PROFILE: {
    EDITBUTTON: 'edit-profile-edit-button',
    NAMEINPUT: 'edit-profile-name-input',
    SELECTINPUT: 'edit-profile-select-input',
    FORM: 'edit-profile-form',
    BIRTHDATE_INPUT: 'edit-profile-birthdate-input',
    LOCATION_INPUT: 'edit-profile-location-input',
    SAVE_BUTTON: 'edit-profile-save-button',
  },
  PROFILE: {
    SEND_MESSAGE_BUTTON: 'send-message-button',
  },
  FAQ: {
    QUESTION_HEADER: 'faq-question-header',
    CATEGORY_TITLE: 'faq-category-title',
    ACCORDION_CONTENT: 'faq-accordion-content',
  },
  GUIDE_PAGE: {
    TITLE: 'guide-page-title',
  },
  TESTIMONIES_PAGE: {
    TITLE: 'testimonies-page-title',
    SHARE_BUTTON: 'testimonies-page-share-button',
    WRITE_TESTIMONY_BUTTON: 'testimonies-page-write-testimony-button',
    WRITE_TESTIMONY_PLUS_BUTTON: 'testimonies-page-write-testimony-plus-button',
    TESTIMONIES_LIST: 'testimonies-page-testimonies-list',
    OWN_TESTIMONY_CARD: 'testimonies-pages-own-testimony-card',
    TESTIMONY_CARD: 'testimonies-pages-testimony-card',
    TESTIMONY_CARD_TITLE: 'testimonies-pages-testimony-card-title',
    TESTIMONY_CARD_CONTENT: 'testimonies-pages-testimony-card-content',
    TESTIMONY_CARD_EDIT_BUTTON: 'testimonies-pages-testimony-card-edit-button',
    FORM: 'testimonies-page-form',
    INPUT: 'testimonies-page-input',
    FORM_REMOVE_BUTTON: 'testimonies-page-form-remove-button',
    FORM_SUBMIT_BUTTON: 'testimonies-page-form-submit-button',
  },
  COMMUNITY_LIST: {
    PAGE_TITLE: 'communitylist-page-title',
  },
  ANNOUNCEMENT_PAGE: {
    SUBJECT_INPUT: 'announcement-page-subject-input',
    SEND_BUTTON: 'announcement-page-send-button',
    SEND_EMAIL_TEST_BUTTON: 'announcement-page-send-email-test-button',
    ANNOUNCEMENT_BLOCK: 'announcement-page-annoucement-block',
  },
  CONTACT_PAGE: {
    EMAIL_INPUT: 'contact-page-email-input',
    FIRSTNAME_INPUT: 'contact-page-firstane-input',
    NAME_INPUT: 'contact-page-name-input',
    SUBJET_INPUT: 'contact-page-subject-input',
    MESSAGE_INPUT: 'contact-page-message-input',
    CAPTCHA: 'contact-page-captcha',
    SUBMIT_BUTTON: 'contact-page-submit-button',
  },
  STATISTIC_PAGE: {
    PAGE_TITLE: 'statistic-page-title',
  },

  LOST_PWD_POPIN: {
    EMAIL_INPUT: 'lost-pwd-popin-email-input',
    RESET_PASSWORD_BUTTON: 'lost-pwd-popin-reset-password-button',
    AUTH_RECOVER_SENT: 'lost-pwd-popin-auth-recover-sent',
  },
  REPORT_ABUSE_POPIN: {
    REPORT_BUTTON: 'report-abuse-popin-report-button',
    TEXTAREA: 'report-abuse-popin-textarea',
    REPORT_PUBLICATION_TITLE: 'report-abuse-popin-publication-title',
    REPORT_INTENTION_TITLE: 'report-abuse-popin-intention-title',
  },
} as const
