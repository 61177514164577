export enum ICON {
  AMEN = 'amen',
  ANNOUNCEMENT = 'announcement',
  APPLE = 'apple',
  ARROW_IN = 'arrow-in',
  ARROW_LEFT = 'arrow-left',
  ARROW_OUT = 'arrow-out',
  ARROW_RIGHT = 'arrow-right',
  AUDIO = 'audio',
  BELL = 'bell',
  BIG_HANDS = 'big-hands',
  BOOKMARK_OFF = 'bookmark-off',
  BOOKMARK_ON = 'bookmark-on',
  BRANCH = 'branch',
  BURGER = 'burger',
  CALENDAR = 'calendar',
  CHECK_DASH = 'check-dash',
  CHECK_SOLID = 'check-solid',
  CHECK = 'check',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CHEVRON_DOWN = 'chevron-down',
  CLOSE = 'close',
  COMMENT = 'comment',
  COMMUNITY = 'community',
  CONTACTS = 'contacts',
  COPY = 'copy',
  ELLIPSIS_HORIZONTAL = 'ellipsis-horizontal',
  ELLIPSIS_VERTICAL = 'ellipsis-vertical',
  EMBED = 'embed',
  ERROR_SOLID = 'error-solid',
  EVENT = 'event',
  EXPLORER = 'explorer',
  EYE = 'eye',
  FACEBOOK = 'facebook',
  FILTER = 'filter',
  FIRE = 'fire',
  HOZANA_PRAYER_SPACE = 'hozana-prayer-space',
  HOZANA_SEARCH = 'hozana-search',
  HOZANA_INTENTION = 'hozana-intention',
  HOZANA_MESSAGE = 'hozana-message',
  GLOBE = 'globe',
  GOOGLE = 'google',
  HAND_HI = 'hand-hi',
  HANDS = 'hands',
  HEART = 'heart',
  HELP_OFF = 'help-off',
  HELP_ON = 'help-on',
  HOME = 'home',
  HOURGLASS_END = 'hourglass-end',
  HOURGLASS_HALF = 'hourglass-half',
  HOURGLASS_START = 'hourglass-start',
  HOZANA = 'hozana',
  HOZANA_APP = 'hozana-app',
  IMAGE = 'image',
  INFO_SOLID = 'info-solid',
  INSTAGRAM = 'instagram',
  INTENTION = 'intention',
  JOBS = 'jobs',
  LABEL = 'label',
  LEAVE = 'leave',
  LETTER = 'letter',
  LETTER_SOLID = 'letter-solid',
  LINK = 'link',
  LIVE = 'live',
  LOADER = 'loader',
  LOCK = 'lock',
  MAGNIFIER = 'magnifier',
  MESSENGER = 'messenger',
  MOBILE = 'mobile',
  NOTIFICATION = 'notification',
  PARTY = 'party',
  PENCIL = 'pencil',
  PINTEREST = 'pinterest',
  PLACE = 'place',
  PLAY = 'play',
  PLUS = 'plus',
  PRINT = 'print',
  PRIVATE = 'private',
  PROFIL = 'profil',
  RADIO_OFF = 'radio-off',
  RADIO_ON = 'radio-on',
  REPLY = 'reply',
  ROLE = 'role',
  ROSARIO_SOLID_GRADIENT = 'rosario-solid-gradient',
  SEND = 'send',
  SEND_SOLID = 'send-solid',
  SETTINGS = 'settings',
  SHARE = 'share',
  STAR_OFF = 'star-off',
  STAR_ON = 'star-on',
  STATISTICS = 'statistics',
  STOP = 'stop',
  TESTIMONY = 'testimony',
  TEXT = 'text',
  TRASH = 'trash',
  TWITTER = 'twitter',
  WARNING_SOLID = 'warning-solid',
  WHATSAPP = 'whatsapp',
  WRITER = 'writer',
  YOUTUBE = 'youtube',
  YOUTUBE_COLOR = 'youtube-color',
  LANTERN = 'lantern',
  TIKTOK = 'tiktok',
  USER_EDIT = 'user-edit',
}
