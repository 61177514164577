import parseHtmlToJSX, { DOMNode, attributesToProps, domToReact } from 'html-react-parser'
import React, { HTMLAttributes, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { isInternalUrl } from '@hozana/router/functions'

import { DefaultEmbedPlayer } from 'elements/media/DefaultEmbedPlayer'
import { YoutubePlayer } from 'elements/media/YoutubePlayer'
import { Link, TLinkProps } from 'elements/text/Link'

import type { TCommunity } from 'modules/community/types'
import type { TPublication } from 'modules/publication/types'

export type TTransformerOptions = { community?: TCommunity; publication?: TPublication; isInPreviewMode?: boolean }

export type TContent = string | (string | ReactNode)[]
export type TTransformer = (
  domNode: DOMNode,
  options?: TTransformerOptions,
) => DOMNode | JSX.Element | object | void | undefined | null | false

// Creates a sandbox from raw HTML
export function createHTMLSandbox(rawHtml: string): HTMLDivElement {
  if (__SERVER__) {
    const { JSDOM } = require('jsdom')
    const frag = JSDOM.fragment(`<div>${rawHtml}</div>`)
    return frag.firstChild as HTMLDivElement
  }

  const sandbox = document.createElement('div')
  sandbox.innerHTML = rawHtml
  return sandbox
}

// Strip HTML tags from a string
export const stripHtmlTags = (rawHtml: string) => rawHtml?.replace(/(<([^<>]+)>)/gi, '')

// Get youtube ID from url
export const getYoutubeIdFromUrl = (url: string): string => {
  const result = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return result[2] !== undefined ? result[2].split(/[^0-9a-z_-]/i)[0] : result[0] // eslint-disable-line no-useless-escape
}

const getFirstDomNodeChildrenData = (domNode: DOMNode): string => {
  if ('data' in domNode && domNode.data) {
    return domNode.data
  }
  if ('children' in domNode && domNode.children.length > 0) {
    return getFirstDomNodeChildrenData(domNode.children[0])
  }
  return ''
}

// Adds a target="_blank" attribute to external links in the raw html content passed as parameter
// Note: this will treat hozana.org links as external if you're browsing the dev/test/staging environment.
export const setTargetToBlankOnExternalLink: TTransformer = (domNode) => {
  if ('attribs' in domNode && domNode.name === 'a' && domNode.attribs.href) {
    if (isInternalUrl(domNode.attribs.href)) {
      const { href, ...otherProps } = attributesToProps(domNode.attribs)
      const to = href.startsWith(CONF_KEYS.HOME)
        ? // Prevent path-to-regexp to break with ':' in the pathname
          href.slice(CONF_KEYS.HOME.length).replace(/:(\W)/g, '$1')
        : href
      const isLandingPage = to.includes('/landing') || to.includes('/landing?')
      return (
        <Link to={to} color="blue" asButton={isLandingPage} {...otherProps}>
          {domToReact(domNode.children)}
        </Link>
      )
    }
    // TODO: merge this in the <Link /> above when next-translate-routes will be fixed about external links
    domNode.attribs.target = '_blank'
    domNode.attribs.class = `${domNode.attribs.class ? domNode.attribs.class + ' ' : ''}external`
  }
  return domNode
}

const ImageWithAlt: React.FC<
  HTMLAttributes<HTMLImageElement> & { fallbackAlt: string; options: TTransformerOptions }
> = ({ options = {}, fallbackAlt, ...otherProps }) => {
  const { t } = useTranslation()

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      {...otherProps}
      alt={
        'publication' in options
          ? t('common:publication.content.image-alt')
          : 'community' in options
          ? t('common:community.description.image-alt')
          : fallbackAlt
      }
    />
  )
}

// Add filename to alt attribute of image
export const addContentToImgAltAttr: TTransformer = (domNode, options) => {
  if ('attribs' in domNode && domNode.name === 'img' && domNode.attribs.alt === '' && domNode.attribs.src) {
    const fullFilename = domNode.attribs.src.split(/[\\/]/).slice(-1)[0]
    const filename = fullFilename?.split('.')[0].slice(0, 40)
    return <ImageWithAlt options={options} fallbackAlt={filename} {...attributesToProps(domNode.attribs)} />
  }
  return domNode
}

export const formatTextToId = (text: string): string =>
  text
    .replace(/<[^<>]+>/g, '') // Remove markup
    .replace(/&[^&]+;/g, '') // Remove escaped characters
    .normalize('NFD') // Decompose combined graphemes (è = e ̀̀ )
    .replace(/[^\sa-zA-Z0-9-]/g, '') // Remove special caracters
    .replace(/[\s'-]+/g, '-') // Replace spaces, apostrophes and prevent '-' repetitions
    .replace(/(?:^-)|(?:-$)/g, '') // Remove starting and ending '-'
    .toLowerCase()

// Add id to titles
export const addIdToTitle: TTransformer = (domNode) => {
  if ('attribs' in domNode && ['h1', 'h2', 'h3'].includes(domNode.name)) {
    const titleId = getFirstDomNodeChildrenData(domNode)
    const formattedId = formatTextToId(titleId)
    if (formattedId !== '') {
      domNode.attribs.id = formattedId
    }
  }
  return domNode
}

// Replace iframes by optimized components
export const replaceIframeByComponent: TTransformer = (domNode, options) => {
  if ('attribs' in domNode && domNode.name === 'iframe') {
    const { src } = domNode.attribs
    if (src?.includes('youtube')) {
      return <YoutubePlayer source={getYoutubeIdFromUrl(src)} {...options} />
    } else if (
      src?.includes('rcf.proxycast.org') ||
      src?.includes('rcf.fr') ||
      src?.includes('soundcloud') ||
      src?.includes('ausha.co')
    ) {
      return <DefaultEmbedPlayer source={src} {...options} />
    }
  }
  return domNode
}

const defaultTransformers: TTransformer[] = [
  replaceIframeByComponent,
  addContentToImgAltAttr,
  setTargetToBlankOnExternalLink,
  addIdToTitle,
]

export const convertHtmlStringToReactComponents = (
  content: TContent,
  transformers: TTransformer[] = [],
  options?: TTransformerOptions,
) =>
  (typeof content === 'string' ? [content] : content).map((pieceOfContent) =>
    typeof pieceOfContent !== 'string'
      ? pieceOfContent
      : parseHtmlToJSX(pieceOfContent ?? '', {
          replace: (domNode) => {
            let result: ReturnType<TTransformer> = domNode
            for (const transformer of [...transformers, ...defaultTransformers]) {
              if (typeof result === 'object' && 'parent' in result) {
                result = transformer(result, options)
              } else {
                return result
              }
            }
            return result
          },
        }),
  )

export function scrollToTop(element: HTMLElement | Window = __CLIENT__ && (window as Window)) {
  if (element) {
    if (element.scrollTo) {
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } else if ('scrollTop' in element) {
      element.scrollTop = 0
    }
  }
}

/**
 * Inspired from https://stackoverflow.com/a/25821576/7900695
 *
 * Regex by @stephenHay from https://mathiasbynens.be/demo/url-regex:
 * - the shortest
 * - among the most accurate
 * - safe (checked on https://devina.io/redos-checker)
 * Extended:
 * - to include www.truc.com urls
 * - to work inline
 * - to prevent ":" to be included in the pathname, which breaks with path-to-regexp
 */
const urlRegex = /((?:(?:https?|ftp):\/\/|www.)[^\s/$.?#].[^\s:]*[^\s.,:;])/

const isHandledByNewFrontend = (url: string) => {
  console.log({ url })
  const regexes = [
    /^.*\/((?:en\/publication\/|es\/publicacion\/|pt\/publicacao\/|publication\/)(?!.*\/(?:editer|editar|edit)$).*)((.*\/reward)*)$/,
    /^.*\/((?:en\/intentions|es\/intenciones|pt\/intencaoes|intentions)(\?*.*))$/,
    /^.*\/((?:en\/intention|es\/intencion|pt\/intencao|intention)(\/.*))$/,
    /^.*\/((?:en\/prayer-space|es\/lugar-de-oracion|pt\/espaco-de-oracao|coin-priere)(\?*.*))$/,
  ]
  // Check for each regex if the urls match it
  for (const regex of regexes) {
    if (regex.test(url)) {
      return true
    }
  }
  return false
}

export const linkify = (text: string, options?: Pick<TLinkProps, 'rel'>) =>
  text.split(urlRegex).map((maybeUrl) => {
    if (!urlRegex.test(maybeUrl)) {
      return maybeUrl
    }
    return isInternalUrl(maybeUrl) && !isHandledByNewFrontend(maybeUrl) ? (
      <Link
        key={maybeUrl}
        underline
        to={maybeUrl.startsWith(CONF_KEYS.HOME) ? maybeUrl.slice(CONF_KEYS.HOME.length) : maybeUrl}
        {...options}
      >
        {maybeUrl}
      </Link>
    ) : (
      // TODO: merge this in the <Link /> above when next-translate-routes will be fixed about external links
      <a href={maybeUrl.startsWith('www.') ? `http://${maybeUrl}` : maybeUrl} target="_blank" {...options}>
        {maybeUrl}
      </a>
    )
  })

export const openPopup = (width: number, height: number, url?: string): Window => {
  const left = (screen.width - width) / 2
  const top = (screen.height - height) / 3
  const popupWindow = window.open(
    url,
    null,
    `toolbar=no, location=yes, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, left=${left}, top=${top}`,
  )
  return popupWindow
}
