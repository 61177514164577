import { ICON } from 'config/icons'

// What are you sharing ?
export enum SHARE_OBJECTS {
  HOZANA = 'hozana',
  COMMUNITY = 'community',
  PUBLICATION = 'publication',
  TESTIMONIALS = 'testimonials',
  INTENTION = 'intention',
  PRAYER_MEETING = 'prayer-meeting',
}

// Where are you sharing ?
export enum SHARE_TYPES {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  MESSENGER = 'messenger',
  PINTEREST = 'pinterest',
  TWITTER = 'twitter',
  WHATSAPP = 'whatsapp',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  EMBED = 'embed',
  COPY = 'copy',
  COPY_LINK = 'copylink',
  EMAIL = 'email',
  EMAIL_BUTTON = 'email-button',
  EMAIL_ICON = 'email-icon',
  EMAIL_TEXTAREA = 'email-textarea',
  CLOSE = 'close',
  MORE = 'more',
}

export type TSocialShareType =
  | SHARE_TYPES.FACEBOOK
  | SHARE_TYPES.INSTAGRAM
  | SHARE_TYPES.MESSENGER
  | SHARE_TYPES.PINTEREST
  | SHARE_TYPES.TWITTER
  | SHARE_TYPES.WHATSAPP
  | SHARE_TYPES.YOUTUBE
  | SHARE_TYPES.TIKTOK

export const getSocialShareIcon = (socialShareType: TSocialShareType) =>
  ((
    {
      [SHARE_TYPES.FACEBOOK]: ICON.FACEBOOK,
      [SHARE_TYPES.TWITTER]: ICON.TWITTER,
      [SHARE_TYPES.PINTEREST]: ICON.PINTEREST,
      [SHARE_TYPES.WHATSAPP]: ICON.WHATSAPP,
      [SHARE_TYPES.MESSENGER]: ICON.MESSENGER,
      [SHARE_TYPES.YOUTUBE]: ICON.YOUTUBE,
      [SHARE_TYPES.INSTAGRAM]: ICON.INSTAGRAM,
      [SHARE_TYPES.TIKTOK]: ICON.TIKTOK,
    } as Record<TSocialShareType, ICON>
  )[socialShareType])

// Button size
export enum SHARE_SOCIAL_SIZE {
  REGULAR = '1.7rem', // default
  BIG = '2.1rem',
}

export const DEFAULT_SHARE_TYPES: TSocialShareType[] = [SHARE_TYPES.FACEBOOK, SHARE_TYPES.TWITTER]

export const WIDGET_DEFAULT_WIDTH = 300
export const WIDGET_DEFAULT_HEIGHT = 470
export const WIDGET_MIN_WIDTH = 200
export const WIDGET_MIN_HEIGHT = 370
export const WIDGET_MAX_WIDTH = 450
export const WIDGET_MAX_HEIGHT = 700

export enum WIDGET_TABS {
  community = 'community',
  publications = 'publications',
}
