import styled, { DefaultTheme } from 'styled-components'

import { Div, TDivProps } from 'elements/layout/Div'

export type TGradientDivProps = {
  firstColor?: keyof DefaultTheme['colors']
  middleColor?: keyof DefaultTheme['colors']
  lastColor?: keyof DefaultTheme['colors']
  angle?: number
  borderRadius?: string
} & TDivProps

export const GradientDiv = styled(Div)<TGradientDivProps>`
  background: ${(p) => p.theme.colors[p.firstColor]};
  background: linear-gradient(
    ${(p) => `${p.angle}deg`},
    ${(p) => p.theme.colors[p.firstColor]} 0%,
    ${(p) => (p.middleColor ? `${p.theme.colors[p.middleColor]} 50%,` : '')} ${(p) => p.theme.colors[p.lastColor]} 100%
  );
  border-radius: ${({ borderRadius }) => borderRadius};
`

GradientDiv.defaultProps = {
  firstColor: 'orange',
  lastColor: 'yellow',
  angle: 45,
}
