import { NOTIFICATION_TYPE } from 'modules/notification/constants'
import type { TNotificationObjectType } from 'modules/notification/types'

import { useStatus } from './useStatus'

export const useNotificationsCount = (
  notificationTypes: NOTIFICATION_TYPE[] = Object.values(NOTIFICATION_TYPE),
  { objectId, objectType }: { objectId?: number; objectType?: TNotificationObjectType } = {},
) => {
  const { notificationsCount } = useStatus() || {}

  if (!notificationsCount) {
    return 0
  }

  return notificationTypes.reduce(
    (acc, type) =>
      acc +
      (notificationsCount[type]
        ? Object.entries(notificationsCount[type]).reduce(
            (subAcc, [key, value]) =>
              subAcc +
              ((!objectType || key.startsWith(`${objectType}/`)) && (!objectId || key.endsWith(`/${objectId}`))
                ? value
                : 0),
            0,
          )
        : 0),
    0,
  )
}
