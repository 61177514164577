import { NOTIFICATION_TYPE } from 'modules/notification/constants'
import { useNotificationsCount } from 'modules/notification/hooks/useNotificationsCount'

export const useIntentionNotificationsCount = () =>
  useNotificationsCount([
    NOTIFICATION_TYPE.NEW_PRAYER_ON_INTENTION,
    NOTIFICATION_TYPE.BIRTHDAY_INTENTION_FINISHED,
    NOTIFICATION_TYPE.ARRIVAL_INTENTION_FINISHED,
    NOTIFICATION_TYPE.INTENTION_FINISHED,
  ])
