const NAMESPACES = /** @type {const} */ ({
  AUTH: 'auth',
  COMMON: 'common',
  COMMUNITY: 'community',
  EVENT: 'event',
  MEETING: 'meeting',
  GUIDE: 'guide',
  HOME: 'home',
  INTENTION: 'intention',
  NOTIFICATION: 'notification',
  PRAY: 'pray',
  PUBLICATION: 'publication',
  SHARE: 'share',
  TAG: 'tag',
  TESTIMONY: 'testimony',
  UPLOAD: 'upload',
  USER: 'user',
})

module.exports = { NAMESPACES }
