import { addQueryData, removeFromQueryData } from '@hozana/api/actions'
import type { TQueryConstructor } from '@hozana/api/types'
import type { TDateTime } from '@hozana/dates/types'
import { normalize } from '@hozana/redux/normalize'

import entities, { TRawPublication } from 'config/entities'
import { type TState } from 'config/types'

import {
  decreaseNumPublications,
  decreaseNumTotalPublications,
  increaseNumPublications,
  increaseNumTotalPublications,
} from 'modules/community/actions'

import { updatePublication } from './actions'
import { PUBLICATION_FILTERS, PUBLICATION_ORDER } from './constants'

// Append the community ID to each publications
const appendCommunityId = (
  { result, entities }: { result: any } & Partial<Pick<TState['api'], 'entities'>>,
  communityId: number,
) => {
  if (entities?.publication) {
    Object.keys(entities.publication).forEach(
      (publicationId) => (entities.publication[publicationId].community = communityId),
    )
  }

  return { result, entities }
}

export const getPublicationQuery: TQueryConstructor<{ publicationId: number; withRawContent?: boolean }, number> = ({
  publicationId,
  withRawContent = false,
}) => ({
  queryKey: `getPublication/${publicationId}${withRawContent ? '/withRawContent' : ''}`,
  url: `/publication/${publicationId}`,
  params: {
    fields: ['sharesCount'],
    ...(withRawContent && { flavour: 'full' }),
  },
  normalize: (data: TRawPublication) => normalize(data, entities.publication),
})

export const getPublicationsQuery: TQueryConstructor<
  {
    communityId: number
    filter?: PUBLICATION_FILTERS
    order?: PUBLICATION_ORDER
  },
  number[]
> = ({ communityId, filter, order = PUBLICATION_ORDER.FRESHNESS }) => ({
  queryKey: `getPublications/${communityId}` + (filter ? `/${filter}` : ''),
  url: `/publications`,
  params: {
    community: communityId,
    filter,
    order,
    fields: ['sharesCount'].concat(filter === PUBLICATION_FILTERS.PUBLISHED ? ['isRead'] : []),
  },
  meta: { limit: 5 },
  normalize: (data: TRawPublication[]) => appendCommunityId(normalize(data, [entities.publication]), communityId),
})

export const getFavoritePublicationsListQuery: TQueryConstructor<void, { publication: number }[]> = () => ({
  queryKey: `getFavoritePublicationsListQuery`,
  url: `/me/favorites`,
  meta: { limit: 10 },
  normalize: (data: TRawPublication[]) => normalize(data, [{ publication: entities.publication }]),
})

/*
 * MUTATIONS
 */

export type TCreatePublicationMutationPayload = {
  communityId: number
  title: string
  image: number
  content?: string
  isDraft?: boolean
  scheduledTime?: number
  imagesToAttach?: number[]
}

export const createPublicationMutation: TQueryConstructor<TCreatePublicationMutationPayload, number> = ({
  communityId,
  title,
  image,
  content = '',
  isDraft,
  scheduledTime,
  imagesToAttach = [],
}) => ({
  queryKey: `publicationCreate`,
  url: `/publication`,
  method: 'POST',
  params: {
    community: communityId,
    name: title,
    content,
    isDraft,
    scheduledTime,
    pictureFileId: image,
    contentFilesToAttach: imagesToAttach,
  },
  normalize: (data: TRawPublication) => normalize(data, entities.publication),
  onSuccess: (dispatch) => {
    const action = isDraft || scheduledTime ? increaseNumTotalPublications : increaseNumPublications
    dispatch(action(communityId))
  },
})

export type TEditPublicationMutationPayload = {
  publicationId: number
  title?: string
  image?: number
  content?: string
  isDraft?: boolean
  scheduledTime?: Date
  imagesToAttach?: number[]
  imagesToRemove?: number[]
}

export const editPublicationMutation: TQueryConstructor<TEditPublicationMutationPayload, number> = ({
  publicationId,
  title,
  image,
  content,
  isDraft,
  scheduledTime,
  imagesToAttach = [],
  imagesToRemove = [],
}) => ({
  queryKey: `publicationEdit/${publicationId}`,
  url: `/publication/${publicationId}`,
  method: 'PATCH',
  params: {
    name: title,
    content,
    isDraft,
    scheduledTime,
    pictureFileId: image,
    contentFilesToAttach: imagesToAttach,
    contentFilesToRemove: imagesToRemove,
  },
  normalize: (data: TRawPublication) => normalize(data, entities.publication),
})

export const duplicatePublicationMutation: TQueryConstructor<
  { publicationId: number; communityId: number },
  number
> = ({ publicationId, communityId }) => ({
  queryKey: `publicationDuplicate/${publicationId}`,
  url: `/publication/${publicationId}/duplicate`,
  method: 'POST',
  normalize: (data: TRawPublication) => normalize(data, entities.publication),
  onSuccess: (dispatch, result) => {
    dispatch(increaseNumTotalPublications(communityId))
    dispatch(addQueryData(`getPublications/${communityId}/unpublished`, result))
  },
})

export const deletePublicationMutation: TQueryConstructor<
  {
    publicationId: number
    communityId: number
    filter: PUBLICATION_FILTERS
  },
  void
> = ({ publicationId, communityId, filter }) => ({
  queryKey: `publicationDelete/${publicationId}`,
  url: `/publication/${publicationId}`,
  method: 'DELETE',
  onSuccess: (dispatch) => {
    dispatch(filter === 'published' ? decreaseNumPublications(communityId) : decreaseNumTotalPublications(communityId))
    // Remove the publication if it was on the list of its community
    dispatch(removeFromQueryData(`getPublications/${communityId}` + (filter ? `/${filter}` : ''), publicationId))
  },
})

export const reportPublicationMutation: TQueryConstructor<{ publicationId: number; reason: string }, void> = ({
  publicationId,
  reason,
}) => ({
  queryKey: `reportAbuse`,
  url: `/publication/${publicationId}/report-abuse`,
  method: 'POST',
  params: { reason },
})

export const addPublicationFavoriteMutation: TQueryConstructor<
  { publicationId: number },
  { favorite: { id: number; createdAt: TDateTime; user: number; publication: TRawPublication } }
> = ({ publicationId }) => ({
  queryKey: `addPublicationFavoriteMutation`,
  url: `/publication/${publicationId}/favorite`,
  method: 'POST',
  onSuccess: (dispatch) => {
    // Update publication
    dispatch(updatePublication(publicationId, { isFavorite: true }))
  },
})

export const deletePublicationFavoriteMutation: TQueryConstructor<{ publicationId: number }, void> = ({
  publicationId,
}) => ({
  queryKey: `removePublicationFromFavorite`,
  url: `/publication/${publicationId}/favorite`,
  method: 'DELETE',
  onSuccess: (dispatch) => {
    // Update Publication
    dispatch(updatePublication(publicationId, { isFavorite: false }))
  },
})

export const incrementPublicationViewsCountMutation: TQueryConstructor<{ publicationId: number }, void> = ({
  publicationId,
}) => ({
  queryKey: `addPublicationViewCount/${publicationId}`,
  url: `/publication/${publicationId}/view`,
  method: 'POST',
})
