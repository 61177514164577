import type { TRouter } from '@hozana/router'
import { cookie } from '@hozana/storage/cookies'
import { Sentry } from '@hozana/tracking/sentry'

import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'
import { Locale } from 'config/types'
import { TLocale, UNTRANSLATABLE_PAGE_FALLBACKS } from 'i18n/constants'

export const detectLangFromNavigator = (): Locale => {
  if (__CLIENT__) {
    const language = navigator.languages?.[0] || navigator.language
    if (language) {
      const localeKeys = language.split('-')
      const locale = localeKeys[1] ? localeKeys[0] : navigator.language

      if (CONF_KEYS.SUPPORTED_LOCALES.includes(locale)) {
        return locale as Locale
      }
    }
  }

  return Locale.fr
}

export const getAllLocalesStatus = (): Record<Locale, string> => {
  // We are forced to check what kind of data is in LOCALES_STATUS and act accordingly
  // because webpack DefinePlugin handles object differently on the server and on dev env
  return typeof CONF_KEYS.LOCALES_STATUS === 'string' ? JSON.parse(CONF_KEYS.LOCALES_STATUS) : CONF_KEYS.LOCALES_STATUS
}

export const getAllActiveLocales = (): Locale[] =>
  (Object.entries(getAllLocalesStatus()) as [Locale, string][])
    .filter(([, value]) => value === 'active')
    .map(([key]) => key)

export const getLocaleStatus = (lang: Locale): string => {
  if (!lang) {
    Sentry.captureMessage('No lang provided to getLocaleStatus function', 'error')
    return 'empty'
  }
  const localStatus = getAllLocalesStatus()
  return localStatus[lang]
}

// Get navigator timezone in string (eg. Europe/Paris)
export const getTimezone = (): string => {
  const defaultTz = 'Europe/Paris'
  const tz = __CLIENT__ ? Intl.DateTimeFormat().resolvedOptions().timeZone : defaultTz
  if (!tz?.includes('/')) {
    if (!cookie.load(COOKIES.invalidTimezone)) {
      Sentry.captureMessage('Invalid timezone', 'error', {
        timeZone: tz,
        resolvedOptions: Intl.DateTimeFormat().resolvedOptions(),
      })
      cookie.save(COOKIES.invalidTimezone, true, COOKIES_CONFIG.ONE_MONTH)
    }
    return defaultTz
  }
  return tz
}

// Get (positive or negative) offset from GMT in hours
export const getTimezoneOffset = (): string => {
  const offset = -(new Date().getTimezoneOffset() / 60)
  if (offset === 0) return ''
  return (offset > 0 ? '+' : '') + offset
}

const REGION_LANG = {
  es: 'es_ES',
  fr: 'fr_FR',
  pt: 'pt_BR',
  en: 'en_US',
  it: 'it_IT',
  pl: 'pl_PL',
}

export const getRegionLocale = (locale: TLocale): string =>
  Object.values(REGION_LANG).includes(locale) ? locale : REGION_LANG[locale]

export const getNewLocaleUrlObject = ({ pathname, query }: Pick<TRouter, 'pathname' | 'query'>) =>
  pathname in UNTRANSLATABLE_PAGE_FALLBACKS
    ? { pathname: UNTRANSLATABLE_PAGE_FALLBACKS[pathname as keyof typeof UNTRANSLATABLE_PAGE_FALLBACKS] }
    : { pathname, query }

export const detectLangFromPath = (pathProp?: string): string => {
  const path = pathProp || (__CLIENT__ ? window.location.href : '')
  const pathChunk = path.split(/[/?]/)
  const langPath = pathChunk[1]

  if (CONF_KEYS.SUPPORTED_LOCALES.includes(langPath)) {
    return langPath
  } else {
    return 'fr'
  }
}

export const getI18nKey = (input: any) => {
  if (typeof input !== 'string') {
    return undefined
  }

  const [, fullKey] = /^(?:trans:)?([a-z]+:[-_.a-zA-Z0-9]+)/.exec(input) || []

  return fullKey
}
