import type { Properties } from 'csstype'
import styled, { css } from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { mediaQuery } from '@hozana/styling/responsive'

import { Textarea } from 'elements/input/Textarea'
import { Div, TDivProps } from 'elements/layout/Div'

export type TScrollDivProps = {
  scrollbarWidth?: string
} & Partial<Pick<Properties, 'flex' | 'overflowY' | 'overflowX'>> &
  TDivProps

export const ScrollDiv = styled(Div).attrs<TScrollDivProps, TScrollDivProps>(({ flex, style }) =>
  flex
    ? {
        style: mergeStyles(style, { flex }),
      }
    : {},
)`
  &,
  .fr-wrapper, /* froala wysiwyg */
  ${Textarea} {
    overflow-y: ${(props) => props.overflowY || 'auto'};
    overflow-x: ${(props) => props.overflowX || 'hidden'};
  }

  /* Hide scrollbar on mobile for vertical scrolling */
  ${mediaQuery('xs', 'sm')} {
    & {
      overflow-x: ${(props) => props.overflowX || 'auto'};
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${mediaQuery('sm', 'xl')} {
    &,
    .fr-wrapper, /* froala wysiwyg */
    ${Textarea} {
      &::-webkit-scrollbar {
        ${({ scrollbarWidth }) => css`
          width: ${scrollbarWidth};
          height: ${scrollbarWidth};
        `}
        padding: 5px 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.08);
        border-left: none;
        border-radius: 10px;
      }
    }
  }
`

ScrollDiv.defaultProps = {
  scrollbarWidth: '6px',
  h: '100%',
}
