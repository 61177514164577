import React from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

const responsiveProps = { w: 'width', h: 'height', size: 'font-size' } as const

type TStyledSpanProps = {
  aspectRatio: string
  color?: keyof DefaultTheme['colors']
  fill?: keyof DefaultTheme['colors']
} & TResponsiveProps<typeof responsiveProps>

export type TSVGProps = TStyledSpanProps & { path: string; id?: string; label?: string } & TResponsiveProps<
    typeof responsiveProps
  >

export const StyledSvg = styled.svg<TStyledSpanProps>`
  ${responsiveStyle(responsiveProps)}
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: top;
  transition: all 0.1s;
  ${(props) => props.color && `color: ${props.theme.colors[props.color]};`}
  ${(props) => props.color && `--color-fill: ${props.theme.colors[props.fill]};`}
  ${(props) => props.aspectRatio && `aspect-ratio: ${props.aspectRatio};`}
  stroke: currentColor;
  stroke-width: 0%;
  pointer-events: none;
`

export const SVG: React.FC<TSVGProps> = ({
  path,
  label,
  color,
  fill,
  id = path.replace(/\.svg/, '').replace(/^.+\//, '').slice(0, 40),
  ...otherProps
}) => (
  <StyledSvg color={color} fill={fill} aria-label={label} {...otherProps}>
    <use href={`${path}#${id}`}></use>
  </StyledSvg>
)
