import type { Properties } from 'csstype'
import React, { FocusEventHandler, MutableRefObject, Ref, TextareaHTMLAttributes } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

import { useTranslate } from '@hozana/intl/useTranslate'
import { defaultStyledConfig } from '@hozana/styling/config'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'
import { mergeRefs } from '@hozana/utils/functions/refs'

const responsiveProps = { fontSize: 'font-size', w: 'width', h: 'height', p: 'padding', maxH: 'max-height' } as const

type TStyledTextareaProps = {
  bold?: boolean
  bg?: keyof DefaultTheme['colors']
  color?: keyof DefaultTheme['colors']
  noBorder?: boolean
  borderRadius?: keyof DefaultTheme['borderRadius']
  resize?: Properties['resize']
} & TResponsiveProps<typeof responsiveProps> &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export const StyledTextarea = styled('textarea')
  .withConfig<TTextareaProps>(defaultStyledConfig(['wrap']))
  .attrs<TStyledTextareaProps, TStyledTextareaProps>(({ bg, bold, color, h, noBorder, resize, rows, theme }) => ({
    rows: !h ? rows : undefined,
    style: {
      resize,
      ...(!noBorder && {
        border: 'border: 1px solid #ccc',
        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
      }),
      ...(bold && { fontWeight: 'bold' }),
      ...(color && { color: theme.colors[color] }),
      ...(bg && { backgroundColor: theme.colors[bg] }),
    },
  }))`
  touch-action: pan-x pan-y; // Prevent Firefox to zoom on the textarea on focus when it does not have a 100% width (MessageForm)
  ${(props) => props.resize && `resize: ${props.resize};`}
  display: block;
  ${responsiveStyle(responsiveProps)};
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  line-height: 1.5em;
  border-radius: ${({ borderRadius, theme }) => theme.borderRadius[borderRadius]};
  ${(props) => !props.noBorder && 'border: 1px solid #ccc'};
  ${(props) => props.bold && 'font-weight: bold'};
  ${(props) =>
    !props.noBorder &&
    'box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);'} transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `} &:focus {
    ${(props) => !props.noBorder && `border-color: ${props.theme.colors.blue}`};
    outline: 0;
  }
`

StyledTextarea.defaultProps = {
  fontSize: {
    xs: '1em',
    sm: '1em',
  },
  rows: 5,
  w: '100%',
  p: '10px',
  bg: 'white',
  borderRadius: 'normal',
}

export type TTextareaProps = {
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  forwardedRef?: Ref<HTMLTextAreaElement> | MutableRefObject<HTMLTextAreaElement>
} & TStyledTextareaProps

const ForwardRefTextarea = React.forwardRef<HTMLTextAreaElement, TTextareaProps>((props, ref) => {
  const placeholder = useTranslate(props.placeholder)

  return <StyledTextarea {...props} ref={mergeRefs(props.forwardedRef, ref)} placeholder={placeholder} />
})

// ESList obliges to set a displayName for a ForwardRef Component ?
ForwardRefTextarea.displayName = 'ForwardRefTextarea'

export const Textarea = styled(ForwardRefTextarea)``
