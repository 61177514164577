import type { TAllAction } from 'config/types'

/* WINDOW MESSAGES */

export enum WINDOW_MESSAGE_TYPE {
  SYN_GA_ID = 'SYN_GA_ID',
  DISPATCH = 'DISPATCH',
  RELOAD_IF_INVISIBLE = 'RELOAD_IF_INVISIBLE',
  CAPTURE_EXCEPTION = 'CAPTURE_EXCEPTION',
}

export const windowMessage = {
  synchronizeGAId: () => ({ type: WINDOW_MESSAGE_TYPE.SYN_GA_ID }),
  dispatch: (action: TAllAction) => ({ type: WINDOW_MESSAGE_TYPE.DISPATCH, payload: action }),
  reloadIfInvisible: () => ({ type: WINDOW_MESSAGE_TYPE.RELOAD_IF_INVISIBLE }),
  captureException: (payload: { error: Error | any; options?: unknown }) => ({
    type: WINDOW_MESSAGE_TYPE.CAPTURE_EXCEPTION,
    payload,
  }),
}

export type TAllWindowMessages = ReturnType<TObjectValues<typeof windowMessage>>

/* SW MESSAGES */

export enum SW_MESSAGE_TYPE {
  ACK_GA_ID = 'ACK_GA_ID',
  CLEAR_API_CACHE = 'CLEAR_API_CACHE',
  GET_VERSION = 'GET_VERSION',
  GET_NB_WINDOWS = 'GET_NB_WINDOWS',
  SKIP_WAITING = 'SKIP_WAITING',
  MESSAGE_ALL_WINDOWS = 'MESSAGE_ALL_WINDOWS',
  GET_RELOAD_COUNT = 'GET_RELOAD_COUNT',
}

export const swMessage = {
  skipWaiting: () => ({ type: SW_MESSAGE_TYPE.SKIP_WAITING }),
  sendGAId: (gaId: string) => ({ type: SW_MESSAGE_TYPE.ACK_GA_ID, payload: gaId }),
  getVersion: () => ({ type: SW_MESSAGE_TYPE.GET_VERSION }),
  getNbWindows: () => ({ type: SW_MESSAGE_TYPE.GET_NB_WINDOWS }),
  messageAllWindows: (message: TAllWindowMessages) => ({
    type: SW_MESSAGE_TYPE.MESSAGE_ALL_WINDOWS,
    payload: message,
  }),
  getReloadCount: () => ({ type: SW_MESSAGE_TYPE.GET_RELOAD_COUNT }),
}

export type TAllSwMessages = ReturnType<TObjectValues<typeof swMessage>>
