import React from 'react'
import { DefaultTheme } from 'styled-components'

import { BorderedSpan } from 'elements/layout/BorderedSpan'
import { Text } from 'elements/text/Text'

export type TBubbleProps = {
  number: number
  labelColor?: keyof DefaultTheme['colors']
}

export const Bubble: React.FC<TBubbleProps> = ({ number, labelColor }) =>
  number > 0 ? (
    <BorderedSpan noBorder align="middleCenter" p="0 6px" bg={number <= 0 ? 'white' : labelColor} borderRadius="1em">
      <Text
        bold
        color={number <= 0 ? 'black30' : labelColor === 'white' ? 'darkBlue' : 'white'}
        fontSize="0.9em"
        italic={number <= 0}
      >
        {number}
      </Text>
    </BorderedSpan>
  ) : null
