import { type TTitleGetter } from '@hozana/api/server/common'
import { dayjs, setToDefaultTimeZone } from '@hozana/dates'
import { type TUrlObject, singletonRouter } from '@hozana/router'

import type { TRawCommunity } from 'config/entities'
import { PAGE } from 'routes/pages'

import { RETREAT_STATUS } from 'modules/community/constants'
import { selectCommunity } from 'modules/community/selectors'

import {
  COMMUNITY_ACTIVITY,
  COMMUNITY_DISPLAYMODE,
  COMMUNITY_FILTERS,
  COMMUNITY_LIFETIME,
  COMMUNITY_STATUS,
} from './constants'
import type { TCommunity, TCommunityJoinParams } from './types'

export const isValidCommunity = (community: { status: COMMUNITY_STATUS }) =>
  community && community.status === COMMUNITY_STATUS.VALID

export const getCommunityMembers = (community: TCommunity) => community?.nbMembers || 0

export const isNovenaOrRetreat = (community: TCommunity | TRawCommunity) =>
  community && [COMMUNITY_LIFETIME.NOVENA, COMMUNITY_LIFETIME.RETREAT].includes(community.lifetime)

export const isCommunityPublished = (community: TCommunity | TRawCommunity) =>
  community ? ![COMMUNITY_STATUS.DRAFT, COMMUNITY_STATUS.CHECK].includes(community?.status) : true

export const getCommunityMembersText = (community: TCommunity) => {
  if (isNovenaOrRetreat(community)) {
    const { retreatStatus = {} } = community
    return retreatStatus.ongoing || retreatStatus.comingSoon
      ? 'trans:community:community.participants-count.count'
      : 'trans:community:community.participants-count.passed'
  }
  return 'trans:community:community.members-count'
}

export const getRetreatStatus = (community: TCommunity | TRawCommunity): Partial<Record<RETREAT_STATUS, boolean>> => {
  const now = dayjs()
  if (!isNovenaOrRetreat(community) || !(community.fromDate && community.toDate)) return {} // type error : Type '{}' is missing the following properties from type 'Record<RETREAT_STATUS, boolean>
  const fromDate = community.fromDate && setToDefaultTimeZone(community.fromDate)
  const toDate = community.toDate && setToDefaultTimeZone(community.toDate)
  const subscription = 'subscription' in community ? community.subscription : null // has joined
  const subsFromDate = subscription?.fromDate && setToDefaultTimeZone(subscription.fromDate)
  const subsToDate = subscription?.toDate && setToDefaultTimeZone(subscription.toDate)
  const subsFinishedAt = subscription?.finishedAt && setToDefaultTimeZone(subscription.finishedAt)
  const hasJoined = community.hasJoined
  const offset = subscription?.offset

  return {
    [RETREAT_STATUS.COMING_SOON]: now.isBefore(fromDate),
    [RETREAT_STATUS.ONGOING]: now.isBetween(fromDate, toDate),
    [RETREAT_STATUS.TO_REDISCOVER]: now.isAfter(toDate),
    [RETREAT_STATUS.SCHEDULED]:
      hasJoined &&
      subsToDate &&
      subsFromDate &&
      fromDate.isBefore(subsFromDate) &&
      now.isBefore(subsToDate) &&
      offset !== 0 /* to set scheduled === false if user join an ongoing retreat from today */,
    [RETREAT_STATUS.RELIVING]:
      hasJoined && subsToDate && subsFromDate && now.isBetween(subsFromDate, subsToDate) && now.isAfter(toDate),
    [RETREAT_STATUS.FINISHED]: Boolean(subsFinishedAt),
  }
}

export const setRetreatStatusToCommunity = <C extends TCommunity | TRawCommunity>(community: C): C =>
  isNovenaOrRetreat(community)
    ? {
        ...community,
        retreatStatus: getRetreatStatus(community),
      }
    : community

export const setRetreatStatusToCommunities = (data: TRawCommunity[]) =>
  data.map((community) => setRetreatStatusToCommunity(community))

export const getOurFatherPrayerId = (lang: string) => {
  switch (lang) {
    case 'en':
      return 27
    case 'es':
      return 39
    case 'pt':
      return 40
    default:
      // fr
      return 1
  }
}

/**
 * Status / Activity of communities:
 * - valid / coming_soon
 * - valid / to_rediscover
 * - valid / ongoing
 * - valid / active
 * - valid / inactive
 * - draft / inactive
 * - check / inactive
 * - ended / inactive
 * - accomplished / inactive
 */
export const getSortedCommunities = (communities: TCommunity[]): Record<COMMUNITY_FILTERS, TCommunity[]> => ({
  [COMMUNITY_FILTERS.ON_GOING_ACTIVE]: communities.filter(
    (c) =>
      c.status === COMMUNITY_STATUS.VALID &&
      (c.lifetime === COMMUNITY_LIFETIME.EVERLASTING
        ? c.activity === COMMUNITY_ACTIVITY.ACTIVE
        : c.retreatStatus?.reliving || c.retreatStatus?.ongoing || c.retreatStatus?.scheduled),
  ),
  [COMMUNITY_FILTERS.COMING_SOON]: communities.filter(
    (c) => c.status === COMMUNITY_STATUS.VALID && c.retreatStatus?.comingSoon,
  ),
  [COMMUNITY_FILTERS.FINISHED_INACTIVE]: communities.filter(
    (c) =>
      c.status === COMMUNITY_STATUS.VALID &&
      (c.lifetime === COMMUNITY_LIFETIME.EVERLASTING
        ? c.activity === COMMUNITY_ACTIVITY.INACTIVE
        : !(
            c.retreatStatus.reliving ||
            c.retreatStatus.ongoing ||
            c.retreatStatus.comingSoon ||
            c.retreatStatus?.scheduled
          )),
  ),
  [COMMUNITY_FILTERS.CHECK]: communities.filter((c) => c.status === COMMUNITY_STATUS.CHECK),
  [COMMUNITY_FILTERS.DRAFT]: communities.filter((c) => c.status === COMMUNITY_STATUS.DRAFT),
  [COMMUNITY_FILTERS.ALL]: communities,
})

export const getUserRole = (community: TCommunity) => {
  const { isCreator = false, isAdmin = false, isAnimator = false, hasJoined: isMember = false } = community || {}
  return {
    isCreator,
    isAdmin,
    isAnimator,
    isMember,
    hasRole: isCreator || isAdmin || isAnimator,
  }
}

export const getSubscribeOffer = (community: TCommunity) => {
  const novenaOrRetreat = community && isNovenaOrRetreat(community)
  const isMember = community?.hasJoined
  const { finished } = (community && getRetreatStatus(community)) || {}
  const active = community?.activity === COMMUNITY_ACTIVITY.ACTIVE
  const isDisplayed =
    community &&
    [COMMUNITY_DISPLAYMODE.FAVORITE, COMMUNITY_DISPLAYMODE.DISPLAYED, COMMUNITY_DISPLAYMODE.TO_REWORK].includes(
      community.displayMode,
    )

  return (
    !isMember && // the user is not member of the community yet and...
    ((novenaOrRetreat && !finished && isDisplayed) || // - either the community is a relevant novena/retreat
      (!novenaOrRetreat && active)) // - either it is an active everlasting community
  )
}

export const getDisplayCommunityPropositionsPopin = (community: TCommunity) => {
  const novenaOrRetreat = community && isNovenaOrRetreat(community)
  const isMember = community?.hasJoined
  const { comingSoon, ongoing, scheduled, reliving } = (community && getRetreatStatus(community)) || {}
  const active = community?.activity === COMMUNITY_ACTIVITY.ACTIVE

  return (
    (!isMember || //                                                                - either the user is not member
      (!novenaOrRetreat && !active) || //                                             - either he is member but the community is everlasting without being active any more
      (novenaOrRetreat && !(comingSoon || ongoing || scheduled || reliving))) && // - either he is member but the community is a novena/retreat that is not relevant.
    (getSubscribeOffer(community) || community?.communitiesPropositions?.length)
  ) // But in any case, it must not be displayed if we intend to show propositions that do not exist.
}

export const getCommunityDates = (community: TCommunity) => {
  const novenaOrRetreat = isNovenaOrRetreat(community)
  const retreatStatus = community.retreatStatus
  const lifetime = community.lifetime
  const { isMember } = getUserRole(community)
  const subscription = community.subscription

  const subscriptionFinishedAt = subscription?.finishedAt && setToDefaultTimeZone(subscription.finishedAt)
  const communityFromDate = community.fromDate && setToDefaultTimeZone(community.fromDate)
  const communityToDate = community.toDate && setToDefaultTimeZone(community.toDate)
  const communityDuration = communityToDate?.diff(dayjs(communityFromDate), 'day')
  const subscriptionFromDate = subscription?.fromDate && setToDefaultTimeZone(subscription.fromDate)

  // Dates
  const outputFromDate = novenaOrRetreat && dayjs(subscription ? subscriptionFromDate : communityFromDate).format('ll') // 5 dec. 2019
  const outputToDate =
    novenaOrRetreat &&
    dayjs(subscription ? subscriptionFromDate.add(communityDuration, 'day') : communityToDate).format('ll') // 10 dec. 2019
  const finishedAt =
    novenaOrRetreat && subscriptionFinishedAt ? dayjs(subscriptionFinishedAt).format('MMMM YYYY') : null // dec. 2019

  return {
    novenaOrRetreat,
    isMember,
    lifetime,
    fromDate: outputFromDate,
    toDate: outputToDate,
    finishedAt,
    retreatStatus,
  }
}

export const communityNameTitleGetter: TTitleGetter = ({ store, params }) =>
  selectCommunity(store.getState(), Number(params.communityId))?.name

export function getCommunitySubscriptionUrl(community: TCommunity, isLogged: true, params: TCommunityJoinParams): string
export function getCommunitySubscriptionUrl(
  community: TCommunity,
  isLogged: boolean,
  params: TCommunityJoinParams,
): string | TUrlObject

export function getCommunitySubscriptionUrl(
  community: TCommunity,
  isLogged: boolean,
  params: TCommunityJoinParams,
): string | TUrlObject {
  if (!isLogged) {
    return {
      pathname: PAGE.COMMUNITY_VIEW_LANDING,
      query: { communityId: community.id, communitySlug: community.slug, ...params },
    }
  }

  const isRetreatOrNovena =
    community?.lifetime === COMMUNITY_LIFETIME.NOVENA || community?.lifetime === COMMUNITY_LIFETIME.RETREAT
  const activityRequiringDateSelection =
    community?.activity === COMMUNITY_ACTIVITY.TO_REDISCOVER || community?.activity === COMMUNITY_ACTIVITY.ONGOING

  const searchParams = new URLSearchParams(params)

  if (isRetreatOrNovena && activityRequiringDateSelection) {
    return `/community/${community.id}-${community.slug}/join/date?${searchParams}`
  }
  return `/community/${community.id}-${community.slug}/join/notifications?${searchParams}`
}

export const redirectToCommunitySubscription = (
  community: TCommunity,
  isLogged: boolean,
  params: TCommunityJoinParams,
) => {
  const href = getCommunitySubscriptionUrl(community, isLogged, params)
  if (typeof href === 'string') {
    window.location.href = href
  } else {
    singletonRouter.push(href)
  }
}
