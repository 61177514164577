import React from 'react'
import { useTranslation } from 'react-i18next'

import { getAllActiveLocales, getNewLocaleUrlObject } from '@hozana/intl/functions'
import { useRouter } from '@hozana/router'

import { Button } from 'elements/button/Button'
import { Div } from 'elements/layout/Div'
import { Space } from 'elements/layout/Space'
import { Ul } from 'elements/layout/Ul'
import { Icon } from 'elements/media/Icon'
import { Ellipsis } from 'elements/text/Ellipsis'
import { Link } from 'elements/text/Link'
import { Accordion } from 'elements/ui/Accordion'

import { NavigationLi } from 'app/components/NavigationLi'
import { setLangCookie } from 'app/functions'
import { ICON } from 'config/icons'
import { LANG_NAMES } from 'i18n/constants'
import { PAGE } from 'routes/constants'

type TSwitchLangButttonProps = {
  isOpen?: boolean
}

export const SwitchLangButton: React.FC<TSwitchLangButttonProps> = ({ isOpen }) => {
  const { pathname, query, isFallback } = useRouter()
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const activeLocales = getAllActiveLocales()

  return activeLocales.length > 1 ? (
    <Accordion>
      <Accordion.Header
        render={({ isOpen: isAccordionOpened, toggle }) => (
          <Button
            onClick={toggle}
            colors="greyTransparent"
            bg="fadedBlack10"
            color="black50"
            w="100%"
            icon={ICON.GLOBE}
            size="big"
            tabIndex={isOpen ? null : -1}
          >
            <Ellipsis>
              {t('common:word.language')} : {LANG_NAMES[language]}
            </Ellipsis>
            <Icon name={isAccordionOpened ? ICON.CHEVRON_UP : ICON.CHEVRON_DOWN} vAlign="top" m="0 0 0 5px" />
          </Button>
        )}
      />
      <Accordion.Content maxHeight="200px">
        <Space h="10px" />
        <Ul m="-3px">
          {activeLocales.map(
            (locale) =>
              locale !== language &&
              // The !isFallback condition is needed to make sure the page params (ex: [communityId]) are available in query - #isFallbackCondition
              !isFallback && (
                <NavigationLi hoverBg="background" key={locale}>
                  <Link
                    color="black50"
                    hoverColor="black70"
                    key={locale}
                    to={['it', 'pl'].includes(locale) ? PAGE.HOME : getNewLocaleUrlObject({ pathname, query })}
                    locale={locale}
                    reload
                    tabIndex={isOpen ? null : -1}
                    onClick={() => setLangCookie(locale)}
                  >
                    <Div>
                      <Ellipsis bold fontSize="1em" lineHeight="2em">
                        {LANG_NAMES[locale]}
                      </Ellipsis>
                    </Div>
                  </Link>
                </NavigationLi>
              ),
          )}
        </Ul>
      </Accordion.Content>
    </Accordion>
  ) : null
}
