import type { TApiState } from '@hozana/api/types'
import type { TFormState } from '@hozana/form/types'
import type { TGetAllActions } from '@hozana/redux/types'
import type { TRoutineActionTypes } from '@hozana/routines/types'

import type { TAppState } from 'app/types'

import type { TIntentionState } from 'modules/intention/types'

import * as allActions from './actions'
import * as allRoutines from './routines'

export enum Locale {
  fr = 'fr',
  en = 'en',
  pt = 'pt',
  es = 'es',
}

export type TState = {
  api: TApiState
  app: TAppState
  form: TFormState
  intention: TIntentionState
}

type TAllActionsActionType = ReturnType<TObjectValues<typeof allActions>>['type']
type TAllRoutinesActionType = TRoutineActionTypes<TObjectValues<typeof allRoutines>>
export type TAllActionType = TAllActionsActionType | TAllRoutinesActionType

export type TAllAction<
  T extends TAllActionsActionType | TAllRoutinesActionType = TAllActionsActionType | TAllRoutinesActionType,
> = TGetAllActions<T, typeof allActions, typeof allRoutines>
