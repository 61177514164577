import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Audio } from 'elements/layout/Audio'
import { BorderedDiv } from 'elements/layout/BorderedDiv'
import { Cell } from 'elements/layout/Cell'
import { Iframe, TIframeProps } from 'elements/layout/Iframe'
import { Row, TRowProps } from 'elements/layout/Row'
import { Icon } from 'elements/media/Icon'
import { EllipsisMultiLines } from 'elements/text/EllipsisMultiLines'

import { ICON } from 'config/icons'

import type { TCommunity } from 'modules/community/types'
import type { TPublication } from 'modules/publication/types'

const StyledBorderedDiv = styled(BorderedDiv)`
  transition: all 300ms ease-in-out;
  & * {
    color: ${(p) => p.theme.colors.black70} !important;
  }
  &:hover * {
    color: ${(p) => p.theme.colors.black} !important;
    transition: all 300ms ease-in-out;
  }
`

export type TDefaultEmbedPlayerProps = {
  source: string
  community?: TCommunity
  publication?: TPublication
  isInPreviewMode?: boolean
} & TRowProps

export const DefaultEmbedPlayer: React.FC<TDefaultEmbedPlayerProps> = ({
  source,
  community,
  publication,
  isInPreviewMode = false,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const [isContentDisplayed, setIsContentDisplayed] = useState(false)

  const sourceObject = new URL(source)
  let h: TIframeProps['h'] = undefined
  if (source.includes('ausha.co')) {
    // Ausha SmartPlayer is responsive by default
    // and the height occupied by the player is 500px on xs screens and 220px on others
    h = { xs: '500px', sm: '220px' }
    // Both playlist and display params break responsiveness
    sourceObject.searchParams.delete('playlist')
    sourceObject.searchParams.delete('display')
  }
  // Unfortunately, RCF player and Ausha player don't support autoplay
  sourceObject.searchParams.set('auto_play', 'true')
  // sandbox? other attributes?

  return isContentDisplayed ? (
    sourceObject.toString().includes('.mp3') ? (
      <Audio h={h} controls src={sourceObject.toString()}>
        {/* //available if browser cannot display audio */}
        <a href={sourceObject.toString()}>{t('common:publication.download-audio')}</a>
      </Audio>
    ) : (
      <Iframe h={h} src={sourceObject.toString()} />
    )
  ) : (
    <StyledBorderedDiv borderRadius="50px" bg="black10" m="0 0 20px 0 !important">
      <Row
        as="button"
        gutter="1em"
        onClick={() => !isInPreviewMode && setIsContentDisplayed(true)}
        w="100%"
        p="10px 1em"
        align="middleLeft"
        {...otherProps}
      >
        <Cell m="0 !important">
          <Icon name={ICON.PLAY} size="45px !important" />
        </Cell>
        <Cell flex="1" m="0 !important" align="middleLeft">
          <EllipsisMultiLines bold lines="2" m="0 !important">
            {publication?.name || community?.name || 'RCF Player'}
          </EllipsisMultiLines>
        </Cell>
      </Row>
    </StyledBorderedDiv>
  )
}
