import 'regenerator-runtime/runtime'

import { select, take } from 'typed-redux-saga'

import type { TState } from 'config/types'

/*
 * Wait for a value in the state to be equal to expectedValue
 * Check for value change at each action received
 */
export function* waitFor(selector: (state: TState) => any, expectedValue = true) {
  let stateSlice = yield* select(selector)
  while (stateSlice !== expectedValue) {
    yield* take('*')
    stateSlice = yield* select(selector)
  }
}
