export const PRESENTATION_VIDEO_LINKS = {
  fr: '8r8cHi9vVnE',
  en: 'dPI_4w6o__4',
  es: '9lDQlGOMTA4',
  pt: '4-7F2icGWUs',
  it: '',
  pl: '',
}

export const APPLE_STORE_LINK = 'https://apps.apple.com/app/hozana/id1477461388'
export const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=org.hozana.app'

export enum STATS {
  USERS_COUNT = 'usersCount',
  PRAYERS_COUNT = 'prayersCount',
  COMMUNITIES_COUNT = 'communitiesCount',
}
