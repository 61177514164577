import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEntity } from '@hozana/api/hooks/useEntity'
import { useRouter } from '@hozana/router'
import { getRedirectUrl } from '@hozana/router/functions'

import { getConnectDetails, redirect } from 'app/functions'
import { PAGE } from 'routes/constants'

import { CONNECT_TAB, CONNECT_TAB_NAME } from 'modules/auth/constants'
import { loadToken } from 'modules/auth/functions'
import type { TMe, TUser } from 'modules/user/types'

import { MeContext } from './MeProvider'

export type TUseUserOptionsWithoutUserId = { redirectIf?: 'anonymous' | 'loggedIn' }
export type TUseUserOptionswithMeId = { userId: number } & TUseUserOptionsWithoutUserId
export type TUseUserOptions = TUseUserOptionsWithoutUserId | TUseUserOptionswithMeId

const hasUserId = (
  options?: TUseUserOptionsWithoutUserId | TUseUserOptionswithMeId,
): options is TUseUserOptionswithMeId => options && 'userId' in options

export function useUser(options?: TUseUserOptionsWithoutUserId): TMe
export function useUser(options?: TUseUserOptionswithMeId): TUser

export function useUser(options?: TUseUserOptions) {
  const { redirectIf } = options || {}
  const userId = hasUserId(options) ? options.userId : null
  const { replace, query } = useRouter() // router.replace
  const {
    i18n: { language },
    t,
  } = useTranslation()

  // If an userId is provided, check in the store
  const user = useEntity('user', userId)
  const enhancedUser = useMemo(
    () =>
      user && {
        ...user,
        name: user.name || t('user:intention.anonymous-praying'),
        linkToProfile: user.id > 0 ? `/communities?action=show-user&userid=${user.id}` : undefined,
      },
    [t, user],
  )

  // Else, use myself as user
  const me = useContext(MeContext)

  useEffect(() => {
    // If anonymous && __CLIENT__ (router doesn't work on SSR)
    if (redirectIf === 'anonymous' && !loadToken() && __CLIENT__) {
      console.debug('No existing token: redirecting...')
      replace({
        pathname: PAGE.CONNECT,
        query: {
          [CONNECT_TAB_NAME]: CONNECT_TAB.LOGIN,
          redirectTo: window.location.pathname + window.location.search,
        },
      })
    }
  }, [redirectIf, replace])

  useEffect(() => {
    // If logged in && __CLIENT__ (router doesn't work on SSR)
    if (redirectIf === 'loggedIn' && loadToken() && __CLIENT__) {
      console.debug('Token existing: redirecting...')
      redirect({
        redirectUrl:
          getConnectDetails().redirectUrl || getRedirectUrl(query.redirectTo, language, { pathname: PAGE.USER_FEED }),
        reload: me.lang !== language,
        locale: language,
      })
    }
  }, [language, me.lang, query.redirectTo, redirectIf, replace])

  if (hasUserId(options)) {
    return enhancedUser
  }

  if (!me) {
    console.error('No me data on useUser render.')
    return null
  }

  return me
}
