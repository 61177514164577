import TooltipReact from '@reach/tooltip'
import React from 'react'

import '@reach/tooltip/styles.css'

import { useTranslate } from '@hozana/intl/useTranslate'

export type TTooltipProps = {
  label?: string
  ariaLabel?: string
}

export const Tooltip: React.FC<TTooltipProps> = ({ label, ariaLabel, children }) => {
  const translatedLabel = useTranslate(label)
  const translatedAriaLabel = useTranslate(ariaLabel)

  if (!(translatedLabel || translatedAriaLabel)) {
    return <>{children}</>
  }

  return (
    <TooltipReact
      label={translatedLabel || translatedAriaLabel}
      aria-label={translatedAriaLabel || translatedLabel}
      style={{
        background: 'hsla(201, 87%, 18%, 0.85)',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '0.5em .8em',
        zIndex: 1000,
      }}
    >
      {children}
    </TooltipReact>
  )
}
