import { produce } from 'immer'

import { GlobalActionTypes } from '@hozana/redux/constants'
import type { TGlobalAction } from '@hozana/redux/types'
import { getCookiesConsent, saveCookiesConsent } from '@hozana/tracking/cookiesConsent'
import { createUUID } from '@hozana/utils/functions/strings'

import { AppActionTypes } from './constants'
import type { TAppAction, TAppState } from './types'

const APP_INITIAL_STATE: TAppState = {
  flashMessages: [],
  savingIndicator: null,
  ipInfo: {},
  prompts: [],
  hasAcceptedCookies: getCookiesConsent(),
  isHydrated: false,
}

export const appReducer = (state = APP_INITIAL_STATE, action: TAppAction | TGlobalAction): TAppState =>
  action.type === GlobalActionTypes.CLEAR_STATE
    ? APP_INITIAL_STATE
    : produce(state, (newState) => {
        switch (action.type) {
          case AppActionTypes.ADD_FLASH:
            newState.flashMessages.push({
              id: createUUID(),
              status: action.status,
              message: action.message,
              subMessage: action.subMessage,
            })
            break

          case AppActionTypes.DESTROY_FLASH:
            newState.flashMessages = newState.flashMessages.filter((message) => message.id !== action.id)
            break

          case AppActionTypes.OPEN_POPIN:
            newState.popin = {
              isOpen: true,
              name: action.name,
              options: action.options,
              backToPopin: action.backToPopin,
            }
            break

          case AppActionTypes.CLOSE_POPIN:
            // Only close if the popin name is the same as the one currently open
            if (!action.name || action.name === newState.popin?.name) {
              if (state.popin.backToPopin && action.name) {
                newState.popin = {
                  isOpen: true,
                  name: state.popin.backToPopin?.name,
                  options: state.popin.backToPopin?.options,
                }
              } else {
                newState.popin = {
                  isOpen: false,
                }
              }
            }
            break

          case AppActionTypes.DISPLAY_SAVING_INDICATOR:
            newState.savingIndicator = {
              lastWasErrored: newState.savingIndicator?.error || false,
              error: action.error,
              label: action.label,
              savedAt: action.savedAt,
              icon: action.icon,
              firstSavedAt: newState.savingIndicator?.firstSavedAt || action.savedAt,
            }
            break

          case AppActionTypes.DESTROY_SAVING_INDICATOR:
            newState.savingIndicator = null
            break

          case AppActionTypes.TOGGLE_LANG_BAR:
            newState.isLangBarOpen = action.open ?? !newState.isLangBarOpen
            break

          case AppActionTypes.ACCEPT_COOKIES:
            saveCookiesConsent()
            newState.hasAcceptedCookies = true
            break

          case AppActionTypes.OPEN_ALERT_BANNER:
            newState.isAlertBannerOpen = true
            break

          case AppActionTypes.CLOSE_ALERT_BANNER:
            newState.isAlertBannerOpen = false
            break

          case AppActionTypes.OPEN_NAVIGATION:
            newState.isNavigationOpen = true
            break

          case AppActionTypes.CLOSE_NAVIGATION:
            newState.isNavigationOpen = false
            break

          case AppActionTypes.ADD_PROMPT:
            newState.prompts.push({ type: action.promptType, state: 'pending' })
            break

          case AppActionTypes.UPDATE_PROMPT_STATE:
            newState.prompts[action.index].state = action.state
            break

          case AppActionTypes.UNMOUNT_PROMPT:
            if (newState.prompts[action.index] && newState.prompts[action.index].state === 'open') {
              newState.prompts[action.index].state = 'closed'
            } else if (newState.prompts[action.index] && newState.prompts[action.index].state !== 'closed') {
              newState.prompts[action.index].state = 'unmounted'
            }
            break

          case AppActionTypes.TOGGLE_HIDDEN_COMMUNITIES: {
            newState.displayHiddenCommunities = action.displayHiddenCommunities ?? !newState.displayHiddenCommunities
            break
          }

          case AppActionTypes.HYDRATE:
            newState.isHydrated = true
            break

          case AppActionTypes.NEED_RELOAD:
            newState.needReload = true
            break

          case AppActionTypes.SET_TITLE:
            newState.title = action.title
            break

          case AppActionTypes.SET_TARGET_PATHNAME:
            newState.pathname = action.pathname
            break

          default:
            break
        }
      })
