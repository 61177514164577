import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'
import { QA } from '@hozana/tests/constants'

import { RoundButton, TRoundButtonProps } from 'elements/button/RoundButton'

import type { TLocale } from 'i18n/constants'

import { SHARE_TYPES, TSocialShareType, getSocialShareIcon } from 'modules/share/constants'

const SOCIAL_URL: Record<TLocale, Partial<Record<SHARE_TYPES, string>>> = {
  fr: {
    [SHARE_TYPES.FACEBOOK]: 'https://www.facebook.com/hozana.org',
    [SHARE_TYPES.YOUTUBE]: 'https://www.youtube.com/c/HozanaFR',
    [SHARE_TYPES.INSTAGRAM]: 'https://www.instagram.com/hozana_fr',
    [SHARE_TYPES.TIKTOK]: 'https://www.tiktok.com/@hozana.fr',
  },
  pt: {
    [SHARE_TYPES.FACEBOOK]: 'https://www.facebook.com/hozana.org',
    [SHARE_TYPES.INSTAGRAM]: 'https://www.instagram.com/hozana_pt/',
  },
  en: {
    [SHARE_TYPES.FACEBOOK]: 'https://www.facebook.com/hozana.org',
    [SHARE_TYPES.INSTAGRAM]: 'https://www.instagram.com/hozana_en/',
  },
  es: {
    [SHARE_TYPES.FACEBOOK]: 'https://www.facebook.com/hozana.org',
  },
  it: {
    [SHARE_TYPES.FACEBOOK]: 'https://www.facebook.com/hozana.org',
  },
  pl: {
    [SHARE_TYPES.FACEBOOK]: 'https://www.facebook.com/hozana.org',
  },
}

const StyledRoundButton = styled(RoundButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`

type TSocialLinkProps = {
  socialName: TSocialShareType
  light?: boolean
} & Pick<TRoundButtonProps, 'm'>

export const SocialLink: React.FC<TSocialLinkProps> = ({ socialName }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const attrs = useAttributes() // used for accessibility to pass "tabindex = -1" to avoid focus

  return SOCIAL_URL[language][socialName] ? (
    <StyledRoundButton
      data-testid={QA.COMMON.NAVIGATION.SOCIAL_LINK(socialName)}
      valueLink={{
        to: SOCIAL_URL[language][socialName],
        target: '_blank',
        rel: 'noopener noreferrer',
      }}
      colors="greyTransparent"
      hoverColor="white"
      hoverBg={socialName}
      label={t('common:share.wording.discover-on', { socialName })}
      icon={getSocialShareIcon(socialName)}
      {...attrs}
    />
  ) : null
}
