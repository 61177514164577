import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { formatSubmissionError } from '@hozana/form/functions'
import { runRoutine } from '@hozana/routines/runRoutine'
import { all, put, select, take, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { LOCAL_STORAGE, localStorage } from '@hozana/storage/browserStorages'
import { GTM } from '@hozana/tracking/gtm'

import { addFlash, openPopin } from 'app/actions'
import { AppActionTypes } from 'app/constants'
import { POPINS } from 'app/managers/popins/constants'
import { runConfirm } from 'app/sagas'
import type { TLocale } from 'i18n/constants'

import type { TIntentionWidgetLocalIntention, TIntentionsWidget } from 'modules/intention/types'

import { clearWidgetIntention, setWidgetIntention } from './actions'
import { deleteIntentionMutation, editIntentionMutation, postIntentionMutation } from './queries'
import { clearIntention, editIntention, postIntention } from './routines'
import { selectIntention } from './selectors'

export type TPostIntentionPaylad = {
  content: string
  email?: string
  guestName?: string
  widget: TIntentionsWidget
  landingPageSlug?: string
  locale: TLocale
  flashMessage?: string
}

function* postIntentionSaga({
  payload: { content, email, guestName, widget, landingPageSlug, flashMessage = undefined },
}: TSagaProps<TPostIntentionPaylad>) {
  try {
    const intentionId = yield* fetchApiSaga(
      postIntentionMutation({
        content,
        guestName,
        widgetId: widget?.id,
        lang: widget?.lang,
        landingPageSlug,
        email,
      }),
    )

    GTM.trackEvent(GTM.EVENTS.INTENTION_ADDED)

    const intention = yield* select((state) => selectIntention(state, intentionId))

    // Store current intention id and hash on a cookie + on the redux store (so that it is immediately available)
    yield* put(setWidgetIntention(intentionId, intention.hash))
    localStorage.setItem(LOCAL_STORAGE.WIDGET_INTENTION(widget.id), { id: intentionId, hash: intention.hash })

    yield* put(openPopin(POPINS.BroadcastIntentionWidgetPopin, { intentionId, widget }))

    yield* take(AppActionTypes.CLOSE_POPIN)

    if (flashMessage) {
      yield* put(addFlash({ message: flashMessage, status: 'success' }))
    }

    return yield* put(postIntention.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    return yield* put(postIntention.failure(formatSubmissionError(error.message)))
  }
}

export type TEditIntentionPayload = TOptionalValues<
  Pick<TPostIntentionPaylad, 'content' | 'email' | 'guestName' | 'widget'>,
  'content'
> & {
  intentionId: number
  broadcastOnHozana?: boolean
  flashMessage?: string
}

function* editIntentionSaga({
  payload: { intentionId, content, guestName, email, broadcastOnHozana, widget, flashMessage },
}: TSagaProps<TEditIntentionPayload>) {
  try {
    if (!content && !broadcastOnHozana && !email) {
      // If intention is empty, clear it
      // Ff it's not updated from the BroadcastIntentionWidgetPopin (content is not passed), clear it
      yield* runRoutine(clearIntention, { widget })
    } else {
      yield* fetchApiSaga(
        editIntentionMutation({
          intentionId,
          content,
          guestName,
          email,
          broadcastOnHozana,
          // If this is a widget, we need the hash from the cookie
          hash: localStorage.getItem(LOCAL_STORAGE.WIDGET_INTENTION(widget.id))?.hash,
          lang: widget.lang,
        }),
      )
    }

    if (flashMessage) {
      yield* put(addFlash({ message: flashMessage, status: 'success' }))
    }

    yield* put(editIntention.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(editIntention.failure(formatSubmissionError(error.message)))
  }
}

export type TClearIntentionPayload = { widget: TIntentionsWidget }

function* clearIntentionSaga({ payload: { widget } }: TSagaProps<TClearIntentionPayload>) {
  try {
    const deleteConfirm = yield* runConfirm(
      'trans:intention:intention.delete.delete',
      'trans:intention:intention.delete.confirm',
    )
    if (!deleteConfirm) return yield* put(clearIntention.failure('cancel'))

    // Delete widget intention on the backend
    const { id: intentionId, hash } =
      localStorage.getItem(LOCAL_STORAGE.WIDGET_INTENTION(widget.id)) || ({} as Partial<TIntentionWidgetLocalIntention>)
    yield* fetchApiSaga(deleteIntentionMutation({ intentionId, hash, lang: widget.lang }))

    // Clear local cookie and redux store
    yield* put(clearWidgetIntention())
    localStorage.removeItem(LOCAL_STORAGE.WIDGET_INTENTION(widget.id))

    GTM.trackEvent(GTM.EVENTS.INTENTION_DELETED)

    yield* put(clearIntention.success())
    yield* put(addFlash({ message: 'intention:intention.deleted', status: 'success' }))
  } catch (error) {
    yield* put(clearIntention.failure(error.message))
  }
  return undefined
}

/*
 * LISTENER
 */

export default function* intentionSagasListener() {
  yield* all([
    takeLatest(postIntention, postIntentionSaga),
    takeLatest(editIntention, editIntentionSaga),
    takeLatest(clearIntention, clearIntentionSaga),
  ])
}
