import type { TQueryConstructor } from '@hozana/api/types'
import type { TDateTime } from '@hozana/dates/types'

import { CONNECT_METHOD } from 'modules/auth/constants'
import type { TMe } from 'modules/user/types'

export const getRefreshTokenQuery: TQueryConstructor<void, { token: string }> = () => ({
  queryKey: `refreshToken`,
  url: `/token/refresh`,
})

/*
 * MUTATIONS
 */

export type TLoginMutationPayload = { email: string; password: string; method: 'email' | 'totp' | null }
export type TConnectMutationData = { id: number; token: string; '2faCompleted'?: boolean; method?: 'email' | 'totp' }

export const loginMutation: TQueryConstructor<TLoginMutationPayload, TConnectMutationData> = ({
  email,
  password,
  method,
}) => ({
  queryKey: `login`,
  url: `/login`,
  method: 'POST',
  headers: process.env.ENV === 'dev' ? { skip2fa: 'true' } : {},
  params: { email, password, method },
  credentials: process.env.ENV === 'prod' || process.env.ENV === 'preprod' ? 'include' : 'omit',
})

export type TSignupMutationPayload = {
  email: string
  password: string
  name: string
  captcha?: string
  sponsorKey: string
  referrer: string
  landingUrl: string
  landingDate: TDateTime
}

export const signupMutation: TQueryConstructor<TSignupMutationPayload, TConnectMutationData> = ({
  email,
  password,
  name,
  captcha,
  sponsorKey,
  referrer,
  landingUrl,
  landingDate,
}) => ({
  queryKey: `signup`,
  url: `/signup${
    ['preprod.hozana.org', 'localhost', '127.0.0.1', 'hozana-qa.lan', 'hozana.local'].includes(window.location.hostname)
      ? '?skipCaptcha=true'
      : ''
  }`,
  method: 'POST',
  params: {
    email,
    password,
    name,
    captcha,
    sponsorKey,
    referrer,
    landingUrl,
    landingDate,
  },
})

export type TRequestPasswordRecoveryMutationPayload = { email: string }
export type TRequestPasswordRecoveryMutationData = { emailSentAt: string }

export const requestPasswordRecoveryMutation: TQueryConstructor<
  { email: string },
  TRequestPasswordRecoveryMutationData
> = ({ email }) => ({
  queryKey: `recoverPassword`,
  url: `/users/reset-password`,
  method: 'POST',
  params: { email },
})

export type TResetPasswordMutationPayload = { email: string; password: string; token: string }
export type TResetPasswordMutationData = { token: string; user: TMe }

export const resetPasswordMutation: TQueryConstructor<TResetPasswordMutationPayload, TResetPasswordMutationData> = ({
  email,
  password,
  token,
}) => ({
  queryKey: `resetPassword`,
  url: `/users/reset-password/confirm`,
  method: 'POST',
  params: {
    email,
    password,
    token,
  },
})

export type TDoubleFaMutationPayload = { code: string; method: 'email' | 'totp' }
export const checkAuthCodeDoubleFaFetch = ({ code }: TDoubleFaMutationPayload) =>
  fetch(`${CONF_KEYS.API_URL}/login/2fa_check`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      _auth_code: code,
    }),
  })

export type TLogEmailMutationPayload = {
  email: string
  interestedByCommunity: any
  sponsorKey: string
  referrer: string
  landingUrl: string
  landingDate: TDateTime
}

export const logEmailMutation: TQueryConstructor<TLogEmailMutationPayload, null> = ({
  email,
  interestedByCommunity,
  sponsorKey,
  referrer,
  landingUrl,
  landingDate,
}) => ({
  queryKey: `presubscriber`,
  url: `/presubscriber`,
  method: 'POST',
  params: { email, interestedByCommunity, sponsorKey, referrer, landingUrl, landingDate },
})

export type TGetLoginMethodsPayload = { email: string }

export const getLoginMethodsQuery: TQueryConstructor<TGetLoginMethodsPayload, CONNECT_METHOD[]> = ({ email }) => ({
  queryKey: `getLoginMethods/${email}`,
  url: '/auth/login/methods',
  params: { email },
})
