import { useState } from 'react'

import { useEventListener } from '@hozana/hooks/useEventListener'

export const usePageVisibility = () => {
  const [pageVisible, setPageVisible] = useState(true)

  useEventListener(
    'visibilitychange',
    () => setPageVisible(document.visibilityState === 'visible'),
    __CLIENT__ ? document : undefined,
  )

  return pageVisible
}
