import { createRoutine } from '@hozana/routines/functions'

import type {
  TAddRemoveFavoritePayload,
  TCreatePublicationPayload,
  TDeletePublicationPayload,
  TDuplicatePublicationPayload,
  TEditPublicationPayload,
} from 'modules/publication/sagas'
import type { TPublication } from 'modules/publication/types'

export const createPublication = createRoutine<'CREATE_PUBLICATION', TCreatePublicationPayload, TPublication>(
  'CREATE_PUBLICATION',
)
export const editPublication = createRoutine<'EDIT_PUBLICATION', TEditPublicationPayload, TPublication>(
  'EDIT_PUBLICATION',
)
export const duplicatePublication = createRoutine<'DUPLICATE_PUBLICATION', TDuplicatePublicationPayload>(
  'DUPLICATE_PUBLICATION',
)
export const deletePublication = createRoutine<'DELETE_PUBLICATION', TDeletePublicationPayload>('DELETE_PUBLICATION')
export const addFavorite = createRoutine<'ADD_FAVORITE', TAddRemoveFavoritePayload>('ADD_FAVORITE')
export const removeFavorite = createRoutine<'REMOVE_FAVORITE', TAddRemoveFavoritePayload>('REMOVE_FAVORITE')
