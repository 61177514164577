const PAGE = /** @type {const} */ ({
  ANIMATOR_SPACE: '/animator-space',
  APP_LANDING_PAGE: '/app',
  AUTH_SOCIAL: '/auth/social/callback',
  COMMUNITY_ANNOUNCEMENTS: '/community/[communityId]/[communitySlug]/manager',
  COMMUNITY_CREATE: '/community/create',
  COMMUNITY_EDIT: '/community/[communityId]/[communitySlug]/edit',
  COMMUNITY_LIST: '/communities/[[...tagSlugs]]',
  COMMUNITY_LIST_SEO: '/communities-list',
  COMMUNITY_NOTIFICATIONS: '/community/[communityId]/[communitySlug]/notifications',
  COMMUNITY_ROLES: '/community/[communityId]/[communitySlug]/roles',
  COMMUNITY_STATISTICS: '/community/[communityId]/[communitySlug]/statistics',
  COMMUNITY_VIEW: '/community/[communityId]/[communitySlug]',
  COMMUNITY_VIEW_ADMIN: '/community/[communityId]/[communitySlug]/admin',
  COMMUNITY_VIEW_LANDING: '/community/[communityId]/[communitySlug]/landing',
  COMMUNITY_VIEW_NO_SLUG: '/community/[communityId]/noslug',
  COMMUNITY_VIEW_UNPUBLISHED: '/community/[communityId]/[communitySlug]/unpublished',
  COMMUNITY_WIDGET: '/widget/communaute/[communityId]',
  CONNECT: '/connect',
  CONTACT: '/contact',
  CONVERSATION: '/my-account/messaging/[conversationId]',
  CONVERSATIONS: '/my-account/messaging',
  DEV: '/dev',
  EVENT_GOOGLE_FORM: '/event/[prayerEventSlug]/form',
  EVENT_MAP: '/event/[prayerEventId]/[prayerEventSlug]/map',
  MEETING_CREATE: '/event/[prayerEventId]/[prayerEventSlug]/create',
  MEETING_EDIT: '/meeting/[prayerMeetingId]/[prayerMeetingToken]/edit',
  FAQ: '/faq',
  FAQ_HIGHLIGHT: '/faq/[id]',
  GUIDE: '/guides/[guideName]/[[...subPathParts]]',
  GUIDES_LIST: '/guides-list',
  HOME: '/',
  INTENTION: '/intention/[intentionId]/[shareToken]',
  INTENTION_PRAYERS: '/intention/intention/[intentionId]/prayers',
  INTENTIONS: '/intentions',
  INTENTIONS_LANDING: '/intentions/[landingPageSlug]',
  INTENTIONS_WIDGET: '/widget/intentions/[widgetId]',
  INTENTIONS_WIDGET_FORM: '/widget/intentions/[widgetId]/form',
  LEAVE: '/leave',
  PUBLICATION_CREATE: '/community/[communityId]/[communitySlug]/publish',
  PUBLICATION_EDIT: '/publication/[publicationId]/[publicationSlug]/edit',
  PUBLICATION_FAVORITES: '/my-account/favorites',
  PUBLICATION_VIEW: '/publication/[publicationId]/[publicationSlug]',
  PUBLICATION_VIEW_NO_SLUG: '/publication/[publicationId]/noslug',
  RESET_PASSWORD: '/new-password',
  RELEASES: '/releases',
  SETTINGS: '/my-account/parameters',
  STATIC: '/page/[pageSlug]',
  TESTIMONIES: '/testimonies',
  UPLOAD_AVATAR: '/upload-avatar',
  USER_FEED: '/prayer-space',
  USER_VIEW: '/user/[userId]',
  WIZARD: '/my-account/preferences',
  WIZARD_CHAPTER: '/my-account/preferences/[chapter]',
  OFFLINE: '_offline',
  ERROR: '_error',
  ERROR_404: '/404',
  ERROR_410: '/410',
  ERROR_500: '/500',
})

const REVALIDATE = /** @type {const} */ ({
  NONE: 0,
  FIVE_SEC: 5,
  HALF_HOUR: 1800,
  ONE_HOUR: 3600,
  TWO_HOURS: 7200,
  ONE_DAY: 86400,
})

const STATIC_PAGES_REVALIDATE = {
  [PAGE.APP_LANDING_PAGE]: REVALIDATE.NONE,
  [PAGE.AUTH_SOCIAL]: REVALIDATE.NONE,
  [PAGE.ERROR_404]: REVALIDATE.NONE,
  [PAGE.ERROR_500]: REVALIDATE.NONE,
  [PAGE.COMMUNITY_CREATE]: REVALIDATE.NONE,
  [PAGE.COMMUNITY_LIST_SEO]: REVALIDATE.ONE_HOUR,
  [PAGE.COMMUNITY_VIEW_LANDING]: REVALIDATE.ONE_HOUR,
  [PAGE.COMMUNITY_LIST]: REVALIDATE.ONE_HOUR,
  [PAGE.COMMUNITY_VIEW]: REVALIDATE.ONE_HOUR,
  [PAGE.COMMUNITY_WIDGET]: REVALIDATE.TWO_HOURS,
  [PAGE.DEV]: REVALIDATE.NONE,
  [PAGE.EVENT_GOOGLE_FORM]: REVALIDATE.NONE,
  [PAGE.EVENT_MAP]: REVALIDATE.FIVE_SEC,
  [PAGE.GUIDE]: REVALIDATE.HALF_HOUR,
  [PAGE.GUIDES_LIST]: REVALIDATE.ONE_HOUR,
  [PAGE.CONNECT]: REVALIDATE.NONE,
  [PAGE.LEAVE]: REVALIDATE.NONE,
  [PAGE.OFFLINE]: REVALIDATE.NONE,
  [PAGE.RELEASES]: REVALIDATE.ONE_DAY,
  [PAGE.STATIC]: REVALIDATE.HALF_HOUR,
  [PAGE.CONTACT]: REVALIDATE.NONE,
  [PAGE.FAQ]: REVALIDATE.ONE_DAY,
  [PAGE.HOME]: REVALIDATE.ONE_HOUR,
  [PAGE.INTENTIONS]: REVALIDATE.FIVE_SEC,
  [PAGE.INTENTIONS_LANDING]: REVALIDATE.FIVE_SEC,
  [PAGE.INTENTIONS_WIDGET]: REVALIDATE.NONE,
  [PAGE.INTENTIONS_WIDGET_FORM]: REVALIDATE.HALF_HOUR,
  [PAGE.PUBLICATION_VIEW]: REVALIDATE.ONE_HOUR,
  [PAGE.RESET_PASSWORD]: REVALIDATE.NONE,
  [PAGE.TESTIMONIES]: REVALIDATE.ONE_DAY,
  [PAGE.UPLOAD_AVATAR]: REVALIDATE.NONE,
  [PAGE.WIZARD]: REVALIDATE.ONE_DAY,
  [PAGE.WIZARD_CHAPTER]: REVALIDATE.ONE_DAY,
}

/** Pages that are now handled by the new frontend... and should probably be deleted from here */
const REWRITTEN_PAGES = [
  PAGE.COMMUNITY_VIEW_NO_SLUG,
  PAGE.COMMUNITY_VIEW,
  PAGE.PUBLICATION_VIEW_NO_SLUG,
  PAGE.PUBLICATION,
  PAGE.INTENTION,
  PAGE.INTENTION_PRAYERS,
  PAGE.INTENTIONS,
  PAGE.USER_FEED,
]

module.exports = { PAGE, REWRITTEN_PAGES, STATIC_PAGES_REVALIDATE }
