import type { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

const responsiveProps = { w: 'width', h: 'height', m: 'margin', maxW: 'max-width', p: 'padding' } as const

export type TLiProps = {
  invisible?: boolean
  className?: string
} & HTMLAttributes<HTMLLIElement> &
  TResponsiveProps<typeof responsiveProps>

export const Li = styled.li.attrs<TLiProps, TLiProps>(({ invisible, style }) => ({
  className: 'styled-li',
  ...(invisible && { style: mergeStyles(style, { display: 'none !important' }) }),
}))`
  ${responsiveStyle(responsiveProps, { p: { important: true } })}
`
