/**
 * Normalizr (https://github.com/paularmstrong/normalizr) is a library which allow to normalize entities inside the Redux store.
 * Data normalization allows to more easily treat data inside the store, as all entities are stored in the same place.
 *
 * Warning: Not all entities need to be normalized. Please ask if you're not sure of what to do.
 *
 * See https://github.com/hozana/hozana.org-v2/blob/develop/docs/3-functionnalities/api-fetching.md
 */
import { schema } from 'normalizr'

import type { TCommunity } from 'modules/community/types'
import { TPrayerEvent } from 'modules/event/types'
import type { TIntention } from 'modules/intention/types'
import { TPrayerMeeting } from 'modules/meeting/types'
import type { TNotification } from 'modules/notification/types'
import type { TPublication } from 'modules/publication/types'
import type { TTag } from 'modules/tag/types'
import type { TTestimony } from 'modules/testimony/types'
import type { TUser } from 'modules/user/types'

const community = new schema.Entity<TCommunity>('community')
const intention = new schema.Entity<TIntention>('intention')
const publication = new schema.Entity<TPublication>('publication')
const tag = new schema.Entity<TTag>('tag')
const testimony = new schema.Entity<TTag>('testimony')
const prayerEvent = new schema.Entity<TPrayerEvent>('prayerEvent')
const prayerMeeting = new schema.Entity<TPrayerMeeting>('prayerMeeting')
const notification = new schema.Entity<TNotification>('notification')
const user = new schema.Entity<TUser>(
  'user',
  {},
  {
    processStrategy: (value, parent) => {
      // If the parent is an intention, save the ID
      // Not very clean to determine this based on publishedUntil but nothing better found
      if (parent?.publishedUntil) {
        return { ...value, intention: parent.id }
      }
      // If the user comes from a prayer intention widget, use prayerId to identify him
      // We use negative id to avoid conflict between hozana and widget users.
      if (value.id === null) {
        return { ...value, id: value.prayerId * -1 }
      }
      return { ...value }
    },
    idAttribute: (value) => {
      if (value.id === null) {
        return value.prayerId * -1
      }
      return value.id
    },
  },
)

type TDenormalize<Normalized, Denormalizer> = Omit<Normalized, keyof Denormalizer> & Denormalizer

// Define the relationships between entities here so that we avoid circular references

community.define({
  user,
  lastPublication: publication,
  tags: [tag],
  communitiesPropositions: [community],
})
export type TRawCommunity = TDenormalize<
  Omit<TCommunity, 'subscription'>,
  {
    user: TRawUser
    lastPublication: TRawPublication
    tags?: TTag[]
    communitiesPropositions?: TRawCommunity[]
  }
>

intention.define({ user })
export type TRawIntention = TDenormalize<TIntention, { user: TRawUser }>

testimony.define({ user })
export type TRawTestimony = TDenormalize<TTestimony, { user: TRawUser }>

publication.define({ community })
export type TRawPublication = TDenormalize<TPublication, { community?: TRawCommunity }>

user.define({
  intention,
})
export type TRawUser = TDenormalize<TUser, { intention?: TRawIntention }>

prayerMeeting.define({
  prayerEvent,
})
export type TRawPrayerMeeting = TDenormalize<
  TPrayerMeeting,
  {
    prayerEvent: TPrayerEvent
  }
>

const entities = {
  community,
  intention,
  publication,
  tag,
  user,
  testimony,
  notification,
  prayerEvent,
  prayerMeeting,
}
export type TEntities = {
  community: TCommunity
  intention: TIntention
  publication: TPublication
  tag: TTag
  user: TUser
  testimony: TTestimony
  notification: TNotification
  prayerEvent: TPrayerEvent
  prayerMeeting: TPrayerMeeting
}
export type TRawEntities = {
  community: TRawCommunity
  intention: TRawIntention
  publication: TRawPublication
  tag: TTag
  user: TRawUser
  testimony: TRawTestimony
  notification: TNotification
  prayerEvent: TPrayerEvent
  prayerMeeting: TPrayerMeeting
}
export type TEntityType = keyof TEntities
export type TEntity = TEntities[TEntityType]

export default entities
