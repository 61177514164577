import { useMemo, useRef } from 'react'

import { deepEqual } from '@hozana/utils/functions/objects'

/**
 * Convert an object into a memoized object that only change when the source content change
 */
export const useDeepCompare = <T extends any[]>(value: T) => {
  const ref = useRef(value)
  const signalRef = useRef(0)

  if (!deepEqual(value, ref.current)) {
    ref.current = value
    signalRef.current += 1
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current])
}
