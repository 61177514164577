import type { NextPage } from 'next'
import React, { useCallback, useEffect, useState } from 'react'

import { fetchApi } from '@hozana/api/fetchApi'
import { useApiData } from '@hozana/api/hooks/useApiData'
import { selectMaintenanceStatus } from '@hozana/api/selectors'
import { usePrevious } from '@hozana/hooks/usePrevious'
import { useTimeout } from '@hozana/hooks/useTimeout'
import { detectLangFromNavigator } from '@hozana/intl/functions'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { getAppType } from '@hozana/screen/functions/getAppType'
import { Sentry } from '@hozana/tracking/sentry'

import type { TLocale } from 'i18n/constants'
import { PAGE } from 'routes/constants'
import { STATIC_PAGES_REVALIDATE } from 'routes/pages'

import { decodeToken, isTokenExpired, isTokenExpiringSoon, loadToken, removeToken } from 'modules/auth/functions'
import { refreshToken } from 'modules/auth/routines'
import { getMeQuery, updateUserSessionAppTypeMutation } from 'modules/user/queries'
import { selectUser } from 'modules/user/selectors'
import type { TMe } from 'modules/user/types'

const MAX_RETRIES = 5

export const MeContext = React.createContext<TMe>(null)

const getVerifiedToken = () => {
  const token = loadToken()
  if (!token) return null
  const tokenPayload = decodeToken(token)
  if (!tokenPayload?.exp) {
    console.warn('Invalid token', token, tokenPayload)
    removeToken()
    return null
  } else if (isTokenExpired(tokenPayload.exp)) {
    console.warn('Expired token', token, tokenPayload)
    removeToken()
    return null
  } else {
    return token
  }
}

export type TMeProviderProps = {
  locale: TLocale
}

export const MeProvider: NextPage<TMeProviderProps> = ({ children, locale }) => {
  const dispatch = useDispatch()
  const token = getVerifiedToken()
  const { pathname } = useRouter()

  const isMaintenanceActive = useSelector((state) => selectMaintenanceStatus(state))

  const [nbRetries, setNbRetries] = useState(0)

  const [meId, meQuery] = useApiData(
    () => ({
      query: getMeQuery(),
      disabled: !token || pathname === PAGE.INTENTIONS_WIDGET_FORM,
    }),
    [pathname, token],
  )

  // Retry meQuery if failed
  useTimeout(
    () => {
      meQuery.refetch()
      setNbRetries((prevNbRetries) => prevNbRetries + 1)
    },
    1500,
    !meQuery.error ||
      meQuery.error === 'invalid' ||
      !token ||
      meQuery.loading ||
      isMaintenanceActive ||
      nbRetries >= MAX_RETRIES,
  )

  useEffect(() => {
    // This function must be executed without any condition to set the appType cookie:
    // it must stay outside the if condition
    const appType = getAppType()
    if (token) {
      dispatch(fetchApi(updateUserSessionAppTypeMutation({ appType })))
    }
  }, [dispatch, token])

  const me = useSelector((state) => selectUser(state, meId))

  const prevMe = usePrevious(me)

  const isStaticPage = (Object.keys(STATIC_PAGES_REVALIDATE) as string[]).includes(pathname)

  // If a token is found, mark user as connected and waiting for info loading
  // If the token is invalid, an error will occur and disconnect() will be called
  const [user, setUser] = useState<TMe>(() => {
    const hasVerifiedToken = !!getVerifiedToken()
    return {
      loading: pathname !== PAGE.INTENTIONS_WIDGET_FORM && (isStaticPage || !hasVerifiedToken),
      isLogged: !isStaticPage && hasVerifiedToken,
      lang: locale || detectLangFromNavigator(),
      ...(me as TMe),
    }
  })

  // Update isLogged on static pages
  useEffect(() => {
    if (isStaticPage) {
      setUser((prevUser) => ({ ...prevUser, isLogged: !!getVerifiedToken() }))
    }
  }, [isStaticPage])

  // Set loading to false on static pages for disconnected users
  useEffect(() => {
    if (isStaticPage && !token) {
      setUser((prevUser) => ({ ...prevUser, loading: false }))
    }
  }, [isStaticPage, token])

  // Check if token needs refresh on mount
  useEffect(() => {
    if (token) {
      const tokenPayload = decodeToken(token)
      if (isTokenExpiringSoon(tokenPayload?.exp)) {
        console.warn('Token expiring soon, refreshing', token, tokenPayload)
        // Refresh token and save new token on cookies
        // We can keep the current token during this session since it is not expired yet
        dispatch(refreshToken({ oldToken: token }))
      }
    }
  }, [dispatch, token])

  const disconnect = useCallback(() => {
    removeToken()
    setUser((prevUser) => ({
      ...prevUser,
      loading: false,
      isLogged: false,
    }))
  }, [])

  const updateUser = useCallback(
    (newUser: Partial<TMe>) =>
      setUser((prevUser) => ({
        ...prevUser,
        ...newUser,
        loading: false,
        isLogged: true,
      })),
    [],
  )

  // If the query has finished loading
  useEffect(() => {
    if (!meQuery.loading) {
      if (!meQuery.error) {
        updateUser(me)
      } else if (meQuery.error === 'invalid') {
        Sentry.captureMessage('Token invalid. Client side disconnection.', 'debug', {
          token: loadToken(),
        })
        disconnect()
      } else if (getVerifiedToken()) {
        updateUser({
          /* Empty me: if meQuery fail but verified token exist, we consider that user is logged in */
        })
      }
    }
  }, [disconnect, me, meQuery.loading, meQuery.error, updateUser])

  // If the query data changed
  useEffect(() => {
    if (prevMe && !me) {
      // If user info have been cleared
      disconnect()
    } else if (prevMe && me && prevMe !== me) {
      // If user info have been updated
      updateUser(me)
    }
  }, [disconnect, prevMe, updateUser, me])

  return <MeContext.Provider value={user}>{children}</MeContext.Provider>
}

export const meProviderServerApiQueries = {
  me: {
    query: getMeQuery(),
    disabled: !getVerifiedToken(),
  },
}
