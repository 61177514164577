import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Cell } from 'elements/layout/Cell'
import { Grid } from 'elements/layout/Grid'

import { GuideLinksByLang } from 'modules/guide/constants'

import { GuideLink } from './GuideLink'

const StyledGrid = styled(Grid)`
  &:has(${Cell}:last-of-type:nth-of-type(odd)) ${Cell}:last-of-type {
    grid-column: 1 / -1;
  }
`

type TGuidesLinkGridProps = {
  onClick?: MouseEventHandler
}

export const GuidesLinkGrid: React.FC<TGuidesLinkGridProps> = ({ onClick }) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <StyledGrid gutter="10px" w="100%">
      {GuideLinksByLang[language].map((guideType) => (
        <Cell key={guideType}>
          <GuideLink onClick={onClick} guideType={guideType} />
        </Cell>
      ))}
    </StyledGrid>
  )
}
