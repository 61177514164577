import React from 'react'

import { useDispatch, useSelector } from '@hozana/redux/hooks'

import { Checkbox } from 'elements/input/Checkbox'
import { Div } from 'elements/layout/Div'

import { toggleHiddenCommunities } from 'app/actions'
import { selectDisplayHiddenCommunities } from 'app/selectors'
import { Popover, TPopoverProps } from 'app/structure/Popover'

export type TCommunitiesFilterPopoverProps = {
  isOpenPopover?: boolean
} & Pick<TPopoverProps, 'onClosing' | 'triggerComponent'>

export const CommunitiesFilterPopover: React.FC<TCommunitiesFilterPopoverProps> = ({
  isOpenPopover,
  triggerComponent,
  onClosing,
}) => {
  const dispatch = useDispatch()
  const displayHiddenCommunities = useSelector(selectDisplayHiddenCommunities)

  return (
    <Popover
      slideDown
      right
      w="250px"
      top="40px"
      open={isOpenPopover}
      triggerComponent={triggerComponent}
      onClosing={onClosing}
    >
      <Div p={{ xs: '10px 20px 30px', md: '10px 1em' }}>
        <Checkbox
          name="displayHiddenCommunities"
          onChange={(value) => {
            dispatch(toggleHiddenCommunities(value))
          }}
          value={displayHiddenCommunities}
        >
          trans:community:communities.filter.display-inactives
        </Checkbox>
      </Div>
    </Popover>
  )
}
