/**
 * Custom Error to handle API error
 * Allows to keep track of the status code
 */
export class ApiError extends Error {
  statusCode: number

  constructor(message: string, statusCode: number) {
    super(message)

    this.name = 'ApiError'
    this.message = message
    this.statusCode = statusCode
  }
}
