import React, { ButtonHTMLAttributes } from 'react'
import styled, { DefaultTheme, css, keyframes } from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import { BaseButton, TBaseButtonProps } from 'elements/button/BaseButton'
import { Icon, TIconProps } from 'elements/media/Icon'
import { Ellipsis } from 'elements/text/Ellipsis'
import { Text } from 'elements/text/Text'

const buttonSizes = {
  tiny: {
    fontSize: '15px', // ~ 1em
    lineHeight: '16px', // ~ 1em
    padding: '4px 8px',
  },
  small: {
    fontSize: '17px', // ~ 1em
    lineHeight: '18px', // ~ 1em
    padding: '6px 12px',
  },
  normal: {
    fontSize: '17px', // ~ 1em
    lineHeight: '24px', // ~ 1em
    padding: '8px 16px', // ~ 39px
  },
  big: {
    fontSize: '17px',
    lineHeight: '24px', // ~ 1em
    padding: '12px 20px',
  },
  bigger: {
    fontSize: '19px',
    lineHeight: '28px', // ~ 1em
    padding: '14px 24px',
  },
} as const

type TButtonContentProps = {
  icon?: TIconProps['name']
  nowrap?: boolean
  lineHeight?: string
  fontSize?: string
  rightSideIcon?: boolean
}

const ButtonContent: React.FC<TButtonContentProps> = ({
  children,
  icon,
  nowrap,
  rightSideIcon,
  lineHeight,
  fontSize,
}) =>
  icon ? (
    <Text as={nowrap ? Ellipsis : null} vAlign="unset" lineHeight={lineHeight} fontSize={fontSize}>
      <Icon
        name={icon}
        size={lineHeight}
        m={children ? (rightSideIcon ? '0 0 0 6px' : '0 6px 0 0') : 0}
        align={rightSideIcon ? 'right' : 'left'}
        vAlign="unset"
      />
      <Text vAlign="unset" inline lineHeight={lineHeight} fontSize={fontSize}>
        {children}
      </Text>
    </Text>
  ) : (
    <Text as={nowrap ? Ellipsis : null} vAlign="unset" w="100%" lineHeight={lineHeight} fontSize={fontSize}>
      {children}
    </Text>
  )

const opacityAnimation = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0.5 }
  100% { opacity: 1 }
`

const responsiveProps = {
  w: 'width',
  p: 'padding',
  m: 'margin',
  minW: 'min-width',
  maxW: 'max-width',
} as const

export type TButtonProps = {
  bold?: boolean | 'semi'
  borderRadius?: keyof DefaultTheme['borderRadius']
  size?: keyof typeof buttonSizes
  underline?: boolean
  shadow?: boolean
  'data-testid'?: string
} & TResponsiveProps<typeof responsiveProps> &
  TBaseButtonProps &
  TButtonContentProps &
  Pick<ButtonHTMLAttributes<HTMLButtonElement>['style'], 'pointerEvents'>

export const Button = styled(BaseButton).attrs<TButtonProps, TButtonProps>(
  ({
    children,
    bold = true,
    disabled,
    icon,
    loading,
    nowrap = true,
    pointerEvents,
    p,
    rightSideIcon,
    size = 'normal',
    shadow,
    style,
    underline = false,
  }) => ({
    style: mergeStyles(style, {
      borderRadius: '50px',
      cursor: disabled || loading ? 'not-allowed' : 'pointer',
      fontSize: buttonSizes[size].fontSize,
      lineHeight: buttonSizes[size].lineHeight,
      opacity: disabled || loading ? '0.5' : '1',
      pointerEvents,
      textDecoration: underline ? 'underline' : 'none',
      ...(!p && { padding: buttonSizes[size].padding }),
      ...(shadow && { boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px' }),
      ...(bold && (bold === 'semi' ? { fontWeight: 600 } : { fontWeight: 'bold' })),
    }),
    children: (
      <ButtonContent
        {...{ icon, nowrap, rightSideIcon }}
        lineHeight={buttonSizes[size].lineHeight}
        fontSize={buttonSizes[size].fontSize}
      >
        {children}
      </ButtonContent>
    ),
  }),
)`
  &,
  &:active,
  &:focus {
    outline: none;
  }

  flex: 0 0 auto; /* prevent height to be impacted by flex */
  display: inline-block;
  transition: all 0.1s ease-in-out;
  vertical-align: middle;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
  /* main style */
  border-width: 1px;
  border-style: solid;

  ${({ loading }) =>
    loading &&
    css`
      animation: ${opacityAnimation} 1.2s ease infinite;
    `}

  & + & {
    margin-left: 5px;
  }

  /* width, margin, padding */
  ${responsiveStyle(responsiveProps)}

  /* Print */
  @media print {
    display: none;
  }
`
