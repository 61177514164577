import 'regenerator-runtime/runtime'

import type { TQuery, TQueryData } from '@hozana/api/types'
import type { TApiAction } from '@hozana/api/types'
import type { TAction } from '@hozana/redux/types'
import { call, delay, put, race, take } from '@hozana/sagas'

import { ApiActionTypes } from './constants'
import { fetchApi } from './fetchApi'
import { getQueryKey, isJSON } from './functions'

// This is necessary for fetch
require('es6-promise').polyfill()
require('isomorphic-fetch')

export function* fetchApiSaga<Query extends TQuery = TQuery>(query: Query): Generator<unknown, TQueryData<Query>> {
  const queryKey = getQueryKey(query)
  yield* put(fetchApi(query))
  const { success, failure } = yield* race({
    failure: take<TApiAction<ApiActionTypes.FETCH_API_FAILURE>>(
      (action: TAction) => action.type === ApiActionTypes.FETCH_API_FAILURE && action.queryKey === queryKey,
    ),
    success: take<TApiAction<ApiActionTypes.FETCH_API_SUCCESS>>(
      (action: TAction) => action.type === ApiActionTypes.FETCH_API_SUCCESS && action.queryKey === queryKey,
    ),
  })
  if (success) {
    return success.data as TQueryData<Query>
  } else {
    throw new Error(failure.error)
  }
}

/**
 * Based on @hozana/utils/functions/errors:asyncRetry
 *
 * @param query            The query to fetch
 * @param options
 * @param options.attempts The maximum number of times an attempt to resolve the promise will be performed (default to 3)
 * @param options.delay    Optional delay between attempts (default to 0)
 */
export function* fetchApiWithRetrySaga<Query extends TQuery = TQuery>(
  query: Query,
  { attempts = 3, delay: delayMs = 0 } = {},
): Generator<unknown, TQueryData<Query>> {
  let attempt = 0

  do {
    ++attempt
    try {
      return yield* fetchApiSaga(query)
    } catch (error: Error | any) {
      if (attempt === attempts) {
        throw error
      } else if (delayMs) {
        yield* delay(delayMs)
      }
    }
  } while (attempt < attempts)

  return null
}

export function* fetchRemoteUrlSaga(url: RequestInfo, options?: RequestInit) {
  try {
    const response = yield* call(fetch, url, options)
    if (response.status >= 200 && response.status < 300) {
      if (isJSON(response)) {
        return yield* call([response, response.json])
      } else {
        return yield* call([response, response.text])
      }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
