import 'regenerator-runtime/runtime'

import { fetchApiSaga, fetchApiWithRetrySaga } from '@hozana/api/sagas'
import { getTimezone } from '@hozana/intl/functions'
import { runRoutine } from '@hozana/routines/runRoutine'
import { all, call, delay, put, race, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { getAppType } from '@hozana/screen/functions/getAppType'
import { isIOSWebview } from '@hozana/screen/functions/isIOSWebview'
import { PUSH_EVENT_LABEL } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'
import { Sentry } from '@hozana/tracking/sentry'

import { addFlash } from 'app/actions'
import { AppActionTypes } from 'app/constants'
import { POPINS } from 'app/managers/popins/constants'
import { runPopin } from 'app/sagas'
import { subscribePush } from 'sw/functions'

import { loadToken } from 'modules/auth/functions'
import type {
  TNewsletterData,
  TNotificationsSettingsData,
} from 'modules/notification/components/NotificationsSettingsForm'
import { NOTIFICATION_CATEGORY, NOTIFICATION_MEDIUM } from 'modules/notification/constants'
import { arrayBufferToString, getNotificationsAvailable } from 'modules/notification/functions'
import {
  TDeleteDeviceMutationPayload,
  deleteDeviceMutation,
  editNotificationSettingsMutation,
  getPushSettingsDevicesQuery,
  postDeviceMutation,
} from 'modules/notification/queries'
import {
  deleteDevice,
  editNotificationsSettings,
  offerPushNotifications,
  requestAndSaveSubscription,
} from 'modules/notification/routines'
import type { TSingleNotificationSettings } from 'modules/notification/types'
import { GENERAL_SETTINGS } from 'modules/user/constants'

export type TEditNotificationSettingsPayload = {
  type: NOTIFICATION_CATEGORY
  medium: NOTIFICATION_MEDIUM
  noFlash?: boolean
  updateParametersToken?: string
  onRequestNewsletter?: (newsletterData: TNewsletterData) => void
} & TNotificationsSettingsData

function* editNotificationsSettingsSaga({
  payload: {
    type = NOTIFICATION_CATEGORY.PUBLICATIONS,
    medium = NOTIFICATION_MEDIUM.EMAIL,
    noFlash,
    updateParametersToken = null,
    onRequestNewsletter,
    ...values
  },
}: TSagaProps<TEditNotificationSettingsPayload>) {
  try {
    const formattedValues = Object.entries(values).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: { [medium]: value },
      }),
      {} as Record<GENERAL_SETTINGS | number, TSingleNotificationSettings>,
    )

    if ([NOTIFICATION_CATEGORY.PUBLICATIONS, NOTIFICATION_CATEGORY.ANNOUNCEMENTS].includes(type)) {
      yield* fetchApiSaga(
        editNotificationSettingsMutation({
          [type]: formattedValues,
          updateParametersToken,
        }),
      )
    } else {
      const data = yield* fetchApiSaga(editNotificationSettingsMutation({ ...formattedValues, updateParametersToken }))
      // return data for newsletter setting
      onRequestNewsletter?.(data?.newsletter)
    }

    if (!noFlash) {
      yield* put(addFlash({ message: 'notification:user.settings.applied' }))
    }
    yield* put(editNotificationsSettings.success())
  } catch (error) {
    if (error.message?.endsWith('updateParametersToken.required')) {
      Sentry.captureMessage('updateParametersToken required', 'warning', {
        updateParametersToken,
        noFlash,
        medium,
        hasToken: !!loadToken(),
      })
    }
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(editNotificationsSettings.failure(error.message))
  }
}

/*
 * Request a subscription to the web push server
 * Update the subscription data on our own server
 * If backgroundUpdate is true, we will not send a push notification to the user in case of new device
 *
 * Returns true if the device is subscribed
 */
function* requestAndSaveSubscriptionSaga() {
  try {
    console.debug(`Trying to get a push subscription...`)

    const pushSubscription = yield* call(subscribePush)

    if (!pushSubscription) {
      yield* put(requestAndSaveSubscription.failure())
      return false
    }

    console.debug('Succeeded to get a push subscription. Saving it into database...', pushSubscription)

    try {
      // Add or update the device
      yield* fetchApiWithRetrySaga(
        postDeviceMutation({
          endpoint: pushSubscription.endpoint,
          expirationTime: pushSubscription.expirationTime,
          keys: {
            p256dh: arrayBufferToString(pushSubscription.getKey('p256dh')),
            auth: arrayBufferToString(pushSubscription.getKey('auth')),
          },
          timezone: getTimezone(),
          userAgent: window.navigator.userAgent,
          appType: getAppType(),
        }),
        {
          attempts: 3,
          delay: 1000,
        },
      )
    } catch (fetchError) {
      fetchError.details = 'post device mutation request failed'
      throw fetchError
    }

    yield* put(requestAndSaveSubscription.success())
    return true
  } catch (error) {
    Sentry.captureException(error, {
      context: 'service worker request and save push subscription',
      details: error.details || 'an error occurred while requesting or saving push subscription',
    })
    yield* put(requestAndSaveSubscription.failure(error.message))
    return false
  }
}

export type TOfferPushNotificationsPayload = {
  eventLabel: PUSH_EVENT_LABEL
  onAccept?: VoidFunction
  onRefuse?: VoidFunction
  onBlocked?: (unblocked: boolean) => void
}

/*
 * Trigger an authorization request and save the subscription
 * If the authorization was already granted, it will update the device.
 */
function* offerPushNotificationsSaga({
  payload: { eventLabel, onAccept, onRefuse, onBlocked },
}: TSagaProps<TOfferPushNotificationsPayload>) {
  try {
    const isNative = isIOSWebview()
    if (getNotificationsAvailable()) {
      if (isNative) {
        window.postMessage(
          JSON.stringify({
            action: 'notifications-accepted',
          }),
        )
        yield* put(offerPushNotifications.success())
        onAccept?.()
      }
      const wasPermissionDefault = Notification.permission === 'default'

      // Use [Notification, Notification.requestPermission] to add Notification context
      // See https://redux-saga.js.org/docs/api/#callcontext-fn-args
      const permission = yield* call([Notification, Notification.requestPermission])

      if (wasPermissionDefault) {
        GTM.trackEvent(GTM.EVENTS.PUSH_PERMISSION_TRIGGERED, { eventLabel })
      }

      switch (permission) {
        case 'granted': {
          onAccept?.()
          if (wasPermissionDefault) {
            GTM.trackEvent(GTM.EVENTS.PUSH_PERMISSION_GRANTED, { eventLabel })
          }
          const { success } = yield* race({
            timeout: delay(10000),
            success: runRoutine(requestAndSaveSubscription),
          })
          if (success) {
            yield* put(offerPushNotifications.success())
          } else {
            yield* put(offerPushNotifications.failure('notifications-subscription.timeout'))
          }
          break
        }

        case 'denied': {
          if (wasPermissionDefault) {
            GTM.trackEvent(GTM.EVENTS.PUSH_PERMISSION_DENIED, { eventLabel })
          }
          const retry = yield* runPopin(POPINS.PushBlockedPopin, {}, AppActionTypes.CONFIRM)
          onBlocked?.(!!retry)
          yield* put(offerPushNotifications.failure('notification:notifications.denied'))
          // If user said he has unblocked notifications, retry
          if (!retry) {
            onRefuse?.()
          }
          break
        }

        default:
          if (wasPermissionDefault) {
            GTM.trackEvent(GTM.EVENTS.PUSH_PERMISSION_IGNORED, { eventLabel })
          }
          yield* put(offerPushNotifications.failure('notification:notifications.ignored'))
      }
    } else {
      yield* put(offerPushNotifications.failure('notification:notifications.unavailable'))
    }
  } catch (error) {
    yield* put(offerPushNotifications.failure(error.message))
  }
}

function* deleteDeviceSaga({ payload: { deviceId } }: TSagaProps<TDeleteDeviceMutationPayload>) {
  try {
    yield* fetchApiSaga(deleteDeviceMutation({ deviceId }))
    yield* fetchApiSaga(getPushSettingsDevicesQuery())

    yield* put(deleteDevice.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(deleteDevice.failure(error.message))
  }
}

/*
 * LISTENER
 */

export default function* notificationSagasListener() {
  yield* all([
    takeLatest(editNotificationsSettings, editNotificationsSettingsSaga),
    takeLatest(requestAndSaveSubscription, requestAndSaveSubscriptionSaga),
    takeLatest(offerPushNotifications, offerPushNotificationsSaga),
    takeLatest(deleteDevice, deleteDeviceSaga),
  ])
}
