import { MutableRefObject, useRef } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const useSyncedRef = <T>(value: T): MutableRefObject<T> => {
  const savedValue = useRef(value)

  useIsomorphicLayoutEffect(() => {
    savedValue.current = value
  }, [value])

  return savedValue
}
