import React, { ReactNode } from 'react'

import { Div } from 'elements/layout/Div'
import { TFixedDivProps } from 'elements/layout/FixedDiv'

type THeaderWrapperProps = {
  children: ReactNode
} & Pick<TFixedDivProps, 'bg'>

export const HeaderWrapper: React.FC<THeaderWrapperProps> = ({ children, bg = 'transparent' }) => (
  <Div
    as="header"
    // in SSR, always appear
    bg={bg}
    id="header-wrapper"
    noPrint
  >
    {children}
  </Div>
)
