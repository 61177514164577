import { GTM_AUTH_SOURCE } from '@hozana/tracking/constants'

import { PAGE } from 'routes/constants'

export enum AppActionTypes {
  ADD_FLASH = 'ADD_FLASH',
  DESTROY_FLASH = 'DESTROY_FLASH',
  OPEN_POPIN = 'OPEN_POPIN',
  CLOSE_POPIN = 'CLOSE_POPIN',
  TOGGLE_BANNER = 'TOGGLE_BANNER',
  CONFIRM = 'CONFIRM',
  TOGGLE_LANG_BAR = 'TOGGLE_LANG_BAR',
  ACCEPT_COOKIES = 'ACCEPT_COOKIES',
  TOGGLE_SUB_HEADER = 'TOGGLE_SUB_HEADER',
  OPEN_ALERT_BANNER = 'OPEN_ALERT_BANNER',
  CLOSE_ALERT_BANNER = 'CLOSE_ALERT_BANNER',
  TOGGLE_HIDDEN_COMMUNITIES = 'TOGGLE_HIDDEN_COMMUNITIES',
  DISPLAY_SAVING_INDICATOR = 'DISPLAY_SAVING_INDICATOR',
  DESTROY_SAVING_INDICATOR = 'DESTROY_SAVING_INDICATOR',
  OPEN_NAVIGATION = 'OPEN_NAVIGATION',
  CLOSE_NAVIGATION = 'CLOSE_NAVIGATION',
  ADD_PROMPT = 'ADD_PROMPT',
  UPDATE_PROMPT_STATE = 'UPDATE_PROMPT_STATE',
  UNMOUNT_PROMPT = 'UNMOUNT_PROMPT',
  HYDRATE = 'HYDRATE',
  NEED_RELOAD = 'NEED_RELOAD',
  SET_TITLE = 'SET_TITLE',
  SET_TARGET_PATHNAME = 'SET_TARGET_PATHNAME',
}

export enum QUERY_ACTION {
  IGNORE_EXHORTATION = 'ignore-exhortation',
  INVITE = 'invite',
  INSTALL_APP = 'install-app-prompt',
  MAIL_DELIVERY_ISSUE = 'mail-delivery-issue-prompt',
  JOIN = 'join',
  LEAVE = 'leave',
  LINUX = 'linux-prompt',
  LOGIN = 'login',
  MARK_AS_READ = 'mark-as-read',
  ADD_FAVORITE = 'add-favorite',
  PRAY = 'pray',
  POST = 'post',
  RATE_APP = 'rate-app-prompt',
  REQUEST_PUSH = 'request-push',
  SHARE_MAIL_PUBLI = 'share-mail-publi',
  SHOW_FLASH = 'show-flash',
  SHOW_NOTIFICATIONS = 'show-notifications',
  SHOW_DONATION_BANNER = 'show-donation-banner',
  UNSUBSCRIBE_PRESUBSCRIBER = 'unsubscribe-presubscriber',
  THANK_PRAYER = 'thanks-prayer',
  TRACK = 'track',
}

/** Autentication source tracking in database (Cf. @hozana/tracking - AUTH_SOURCE for authentication source tracking through Analytics) */
export enum QUERY_AUTH_SOURCE {
  HEADER_LOGIN = 'header-login',
  HEADER_SIGNUP = 'header-signup',
  HOME_PAGE = 'home-page',
}
export const AUTH_SOURCE_EQUIVALENTS: Record<QUERY_AUTH_SOURCE, GTM_AUTH_SOURCE> = {
  [QUERY_AUTH_SOURCE.HEADER_LOGIN]: GTM_AUTH_SOURCE.HEADER_LOGIN,
  [QUERY_AUTH_SOURCE.HEADER_SIGNUP]: GTM_AUTH_SOURCE.HEADER_SIGNUP,
  [QUERY_AUTH_SOURCE.HOME_PAGE]: GTM_AUTH_SOURCE.HOME_PAGE,
}

export enum FORM_NAMES {
  SEARCH_KEYWORD = 'SEARCH_KEYWORD',
  CONVERSATIONS_LIST_OPTIONS = 'CONVERSATIONS_LIST_OPTIONS',
  COMMUNITY_ANNOUNCEMENT_FORM = 'COMMUNITY_ANNOUNCEMENT_FORM',
  COMMUNITY_CREATE_FORM = 'COMMUNITY_CREATE_FORM',
  COMMUNITY_EDIT_FORM = 'COMMUNITY_EDIT_FORM',
  ADD_ROLE_FORM = 'ADD_ROLE_FORM',
}

export enum STATUS_TYPE {
  MESSAGES = 'messages',
  INTENTIONS = 'intentions',
}

export enum HEADER_ITEM {
  BACK_TO_COMMUNITY_ADMIN = 'BackToCommunity',
  BACK_TO_PUBLICATION = 'BackToPublication',
  COMMUNITIES_TAB = 'CommunitiesTab',
  COMMUNITY_JOIN_BUTTONS = 'CommunityJoinButtons',
  DONATION = 'Donation',
  FEED_PAGE_TAB = 'FeedPageTab',
  GO_BACK = 'GoBack',
  INVITE_BLOCK = 'InviteBlock',
  LOGO = 'Logo',
  LANGUAGE_ITEM_BLOCK = 'LanguageItemBlock',
  LOGIN_BUTTON = 'LoginButton',
  LOGIN_LINK = 'LoginLink',
  MESSAGE_TAB = 'MessageTab',
  INTENTION_TAB = 'IntentionTab',
  NAVIGATION_BUTTON = 'NavigationButton',
  OPEN_IN_APP = 'OpenInApp',
  SEARCH_FORM = 'SearchForm',
  SIGN_UP_BUTTON = 'SignupButton',
  SHARE_SOCIAL_BLOCK = 'ShareSocialBlock',
  TO_HOZANA_LINK = 'ToHozanaLink',
  TITLE_PAGE = 'TitlePage',
}

export const CONTENT_ID = 'content-id'
export const NAVIGATION_ID = 'navigation-id'
export const HEADER_ID = 'header-id'
export const HEADER_SUB_ID = 'header-sub-id'
export const NAVIGATION_CLASS = 'navigation-class'
export const SLIDER_CLASS = 'slider-class'
export const POPOVER_CLASS = 'popover-class'

// Only one popin may be displayed in the same time
export const POPIN_ID = 'popin-id'
export const POPIN_TITLE_ID = 'popin-title-id'
export const POPIN_CONTAINER_ID = 'popin-container'
export const POPIN_OVERLAY_ID = 'popin-overlay'
export const POPIN_CLOSE_BUTTON_ID = 'popin-close-button'

export const enum DONATION_SOURCE {
  AUTOMATIC = 'automatic',
  HEADER = 'header',
  MENU = 'menu',
  HOME_PAGE = 'home-page',
}

export const FULL_STATIC_PAGES: PAGE[] = [
  PAGE.GUIDE,
  PAGE.GUIDES_LIST,
  PAGE.COMMUNITY_LIST_SEO,
  PAGE.HOME,
  PAGE.COMMUNITY_WIDGET,
  PAGE.COMMUNITY_VIEW_LANDING,
  PAGE.LEAVE,
]

export const NOTION_ABOUT_PAGES = {
  fr: 'https://hozana.notion.site/qui-sommes-nous?pvs=4',
  en: 'https://hozana.notion.site/who-are-we?pvs=4',
  es: 'https://hozana.notion.site/quienes-somos?pvs=4',
  pt: 'https://hozana.notion.site/quem-somos-nos?pvs=4',
  it: 'https://hozana.notion.site/who-are-we?pvs=4',
  pl: 'https://hozana.notion.site/who-are-we?pvs=4',
}

export const JOBS_PAGE_LINK = 'https://www.welcometothejungle.com/fr/companies/hozana/jobs'
