import type { NextComponentType, NextPageContext } from 'next'
import nextSingletonRouter from 'next-translate-routes/router'
import {
  NextRouter,
  SingletonRouter as TNextSingletonRouter,
  useRouter as useNextRouter,
  withRouter as withNextRouter,
} from 'next/router'
import type { ComponentType } from 'react'
import { UrlObject } from 'url'

import { TLocale } from 'i18n/constants'
import { PAGE } from 'routes/constants'

export { default as ErrorPage } from 'next/error'

export { Link as NextLink } from 'next-translate-routes'

export type TUrlObject = TOverwrite<UrlObject, { pathname: PAGE }>

type TChangePage = (
  url: TUrlObject | string,
  as?: string | TUrlObject,
  options?: {
    shallow?: boolean
    locale?: TLocale | false
    scroll?: boolean
  },
) => Promise<boolean>

type TRouterOverwrite = {
  pathname: PAGE
  locale: TLocale
  push: TChangePage
  replace: TChangePage
}

export type TRouter = TOverwrite<NextRouter, TRouterOverwrite>
export declare type WithRouterProps = {
  router: TRouter
}
export declare type ExcludeRouterProps<P> = Pick<P, Exclude<keyof P, keyof WithRouterProps>>
export const useRouter = useNextRouter as () => TRouter
export const withRouter = withNextRouter as <P extends WithRouterProps, C = NextPageContext>(
  ComposedComponent: NextComponentType<C, any, P>,
) => ComponentType<ExcludeRouterProps<P>>

export type TSingletonRouter = TOverwrite<TNextSingletonRouter, TRouterOverwrite>

export const singletonRouter = nextSingletonRouter as TSingletonRouter
