/**
 * Throw an error in dev, print an error message in prod
 */
export const throwDevError = (errorMessage: string, otherEnvs?: ('preprod' | 'staging')[]) => {
  if (['dev', ...(otherEnvs || [])].includes(CONF_KEYS.ENV)) {
    throw new Error(errorMessage)
  } else {
    console.error(errorMessage)
  }
}

/**
 * Function retry pattern.
 *
 * @param fn    The function to call
 * @param times The maximum number of times the function is called (default to 3)
 *
 * @throws Error Any error encountered during function execution
 *
 * @return The result returned by the function
 */
export function retry<F extends () => any>(fn: F, times: number = 3): ReturnType<F> {
  do {
    --times
    try {
      return fn()
    } catch (error: Error | any) {
      if (!times) {
        throw error
      }
    }
  } while (times)
  return null
}

export type TAsyncRetryOptions = {
  /** The maximum number of times an attempt to resolve the promise will be performed (default to 3) */
  attempts?: number
  /** Optional delay between attempts (default to 0) */
  delayBetweenAttempts?: number
}

/**
 * Promise retry pattern
 *
 * @throws Error Any error encountered during promise resolution
 *
 * @return The result returned by the promise resolution
 *
 * @see fetchApiWithRetrySaga based on this logic
 */
export async function asyncRetry<F extends (attempt?: number) => Promise<any>>(
  fn: F,
  { attempts = 3, delayBetweenAttempts = 0 }: TAsyncRetryOptions = {},
): Promise<Awaited<ReturnType<F>>> {
  let attempt = 0

  do {
    ++attempt
    try {
      return await fn(attempt)
    } catch (error: Error | any) {
      if (attempt === attempts) {
        throw error
      } else if (delayBetweenAttempts) {
        // add a delay between each retry
        await new Promise((resolve) => setTimeout(resolve, delayBetweenAttempts))
      }
    }
  } while (attempt < attempts)

  return null
}

export type TAsyncWithTimeoutOptions = {
  /** The time given to the promise to resolve before timeout */
  attemptTime: number
  /** Optional timeout error message (default to 'Timeout') */
  timeoutMessage?: string
}

/**
 * Promise timeout pattern
 *
 * @throws Error Any error encountered during promise resolution or timeout error
 *
 * @return The result returned by the promise resolution
 */
export const asyncWithTimeout = <F extends () => Promise<any>>(
  fn: F,
  { attemptTime, timeoutMessage = 'Timeout' }: TAsyncWithTimeoutOptions,
) =>
  new Promise<Awaited<ReturnType<F>>>((resolve, reject) => {
    const timeout = setTimeout(() => reject(new Error(timeoutMessage)), attemptTime)

    fn()
      .then((result) => {
        clearTimeout(timeout)
        resolve(result)
      })
      .catch(reject)
  })

/**
 * Promise retry + timeout
 *
 * Combination or asyncRetry and asyncWithTimeout
 *
 * @see {@link asyncRetry}
 * @see {@link asyncWithTimeout}
 *
 * @throws Error Any error encountered during promise resolution or timeout error
 *
 * @return The result returned by the promise resolution
 */
export const asyncRetryWithTimeout = <F extends (time?: number) => Promise<any>>(
  fn: F,
  { attemptTime, timeoutMessage, attempts, delayBetweenAttempts }: TAsyncRetryOptions & TAsyncWithTimeoutOptions,
): Promise<Awaited<ReturnType<F>>> =>
  asyncRetry(() => asyncWithTimeout(async () => await fn(), { attemptTime, timeoutMessage }), {
    attempts,
    delayBetweenAttempts,
  })

export const withErrorDetails = async <F extends () => Promise<any>>(
  fn: F,
  details: string,
): Promise<Awaited<ReturnType<F>>> => {
  try {
    return await fn()
  } catch (error) {
    error.details = details
    throw error
  }
}
