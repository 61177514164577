import type { TAction } from '@hozana/redux/types'

import type { TBackToPopin, TPopinState, TPopinsOptions } from 'app/managers/popins/types'
import { PAGE } from 'routes/constants'

import { AppActionTypes } from './constants'
import type { TFlashMessageState } from './managers/flashs/types'
import type { PromptType } from './managers/prompts/constants'
import type { TPromptState } from './managers/prompts/types'
import type { TSavingIndicator, TTitle } from './types'

export const confirm = (value?: string | boolean): TAction<AppActionTypes.CONFIRM, { value?: string | boolean }> => ({
  type: AppActionTypes.CONFIRM,
  ...(typeof value !== 'undefined' && { value }),
})

export const addFlash = ({
  message,
  subMessage = undefined,
  status = 'success' as const,
}: TOptionalValues<Pick<TFlashMessageState, 'message' | 'subMessage' | 'status'>, 'status'>): TAction<
  AppActionTypes.ADD_FLASH,
  Omit<TFlashMessageState, 'id'>
> => ({
  type: AppActionTypes.ADD_FLASH,
  status,
  message,
  subMessage,
})

export const destroyFlash = (id: string): TAction<AppActionTypes.DESTROY_FLASH, Pick<TFlashMessageState, 'id'>> => ({
  type: AppActionTypes.DESTROY_FLASH,
  id,
})

type TOpenpopinParams<PN extends keyof TPopinsOptions = keyof TPopinsOptions> = TNoMoreProps extends TPopinsOptions[PN]
  ? [name: PN, options?: TPopinsOptions[PN], backToPopin?: TBackToPopin]
  : [name: PN, options: TPopinsOptions[PN], backToPopin?: TBackToPopin]

export const openPopin = <PN extends keyof TPopinsOptions = keyof TPopinsOptions>(
  ...args: TOpenpopinParams<PN>
): TAction<AppActionTypes.OPEN_POPIN, Omit<TPopinState, 'isOpen'>> => ({
  type: AppActionTypes.OPEN_POPIN,
  name: args[0],
  options: args?.[1],
  backToPopin: args?.[2],
})

export const closePopin = (
  name?: TPopinState['name'],
): TAction<AppActionTypes.CLOSE_POPIN, Pick<TPopinState, 'name'>> => ({
  type: AppActionTypes.CLOSE_POPIN,
  name,
})

export const displaySavingIndicator = ({
  icon,
  label,
  savedAt = undefined,
  error = false,
}: TOptionalValues<TSavingIndicator, 'savedAt'>): TAction<
  AppActionTypes.DISPLAY_SAVING_INDICATOR,
  Pick<TSavingIndicator, 'icon' | 'error' | 'savedAt' | 'label'>
> => ({
  type: AppActionTypes.DISPLAY_SAVING_INDICATOR,
  label,
  error,
  savedAt,
  icon,
})

export const destroySavingIndicator = (): TAction<AppActionTypes.DESTROY_SAVING_INDICATOR> => ({
  type: AppActionTypes.DESTROY_SAVING_INDICATOR,
})

export const toggleLangBar = (open?: boolean): TAction<AppActionTypes.TOGGLE_LANG_BAR, { open?: boolean }> => ({
  type: AppActionTypes.TOGGLE_LANG_BAR,
  open,
})

export const acceptCookies = (): TAction<AppActionTypes.ACCEPT_COOKIES> => ({ type: AppActionTypes.ACCEPT_COOKIES })

export const toggleSubHeader = (): TAction<AppActionTypes.TOGGLE_SUB_HEADER> => ({
  type: AppActionTypes.TOGGLE_SUB_HEADER,
})

export const openAlertBanner = (): TAction<AppActionTypes.OPEN_ALERT_BANNER> => ({
  type: AppActionTypes.OPEN_ALERT_BANNER,
})

export const closeAlertBanner = (): TAction<AppActionTypes.CLOSE_ALERT_BANNER> => ({
  type: AppActionTypes.CLOSE_ALERT_BANNER,
})

export const openNavigation = (): TAction<AppActionTypes.OPEN_NAVIGATION> => ({ type: AppActionTypes.OPEN_NAVIGATION })

export const closeNavigation = (): TAction<AppActionTypes.CLOSE_NAVIGATION> => ({
  type: AppActionTypes.CLOSE_NAVIGATION,
})

export const addPrompt = (promptType: PromptType): TAction<AppActionTypes.ADD_PROMPT, { promptType: PromptType }> => ({
  type: AppActionTypes.ADD_PROMPT,
  promptType,
})

export const updatePromptState = (
  index: number,
  state: TPromptState['state'],
): TAction<AppActionTypes.UPDATE_PROMPT_STATE, { index: number; state: TPromptState['state'] }> => ({
  type: AppActionTypes.UPDATE_PROMPT_STATE,
  index,
  state,
})

export const unmountPrompt = (index: number): TAction<AppActionTypes.UNMOUNT_PROMPT, { index: number }> => ({
  type: AppActionTypes.UNMOUNT_PROMPT,
  index,
})

export const toggleHiddenCommunities = (
  displayHiddenCommunities?: boolean,
): TAction<AppActionTypes.TOGGLE_HIDDEN_COMMUNITIES, { displayHiddenCommunities?: boolean }> => ({
  type: AppActionTypes.TOGGLE_HIDDEN_COMMUNITIES,
  displayHiddenCommunities,
})

export const setIsHydrated = (): TAction<AppActionTypes.HYDRATE> => ({ type: AppActionTypes.HYDRATE })

export const setNeedReload = (): TAction<AppActionTypes.NEED_RELOAD> => ({ type: AppActionTypes.NEED_RELOAD })

export const setTitle = (title?: TTitle): TAction<AppActionTypes.SET_TITLE, { title?: TTitle }> => ({
  type: AppActionTypes.SET_TITLE,
  title,
})

export const setTargetPathname = (pathname: PAGE): TAction<AppActionTypes.SET_TARGET_PATHNAME, { pathname: PAGE }> => ({
  type: AppActionTypes.SET_TARGET_PATHNAME,
  pathname,
})
