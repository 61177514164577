import { useTranslation } from 'react-i18next'

import { getI18nKey } from '@hozana/intl/functions'
import { Sentry } from '@hozana/tracking/sentry'

export const useTranslate = (input: any, values: TAnyProps = {}) => {
  const { t } = useTranslation()

  const key = getI18nKey(input)

  if (!key) {
    return input
  }

  const translation = t(key, values)

  if (typeof translation === 'object' && !Array.isArray(translation)) {
    Sentry.captureMessage(`Incomplete key: ${input}`, 'error', {
      input,
      values,
      translation,
    })
  }

  return translation
}
