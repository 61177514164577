import { APP_TYPE } from '@hozana/screen/constants'
import { getAppType } from '@hozana/screen/functions/getAppType'

export { createCookieName } from './cookieNames'

/**
 * Get cookies bar open status from cookies consent
 * Don't display cookie banner on native app and PWA since the user has already accepted the terms
 */
export const getCookieBarOpened = (hasAcceptedCookies: boolean) =>
  !hasAcceptedCookies &&
  __CLIENT__ &&
  ![APP_TYPE.IOS_NATIVE, APP_TYPE.ANDROID_TWA, APP_TYPE.IOS_PWA, APP_TYPE.ANDROID_PWA].includes(getAppType())
