import { selectAllEntities, selectEntities } from '@hozana/api/selectors'

import type { TState } from 'config/types'

import { NOTIFICATION_TYPE } from './constants'
import type { TNotification } from './types'

export const selectNotifications = <NT extends NOTIFICATION_TYPE = NOTIFICATION_TYPE>(
  state: TState,
  notificationIds: number[],
) => selectEntities(state, 'notification', notificationIds) as TNotification<NT>[]

export const selectNotificationsByType = <NT extends NOTIFICATION_TYPE>(state: TState, ...types: NT[]) =>
  selectAllEntities(state, 'notification').filter((notification) =>
    (types as NOTIFICATION_TYPE[])?.includes(notification.type),
  ) as TNotification<NT>[]
