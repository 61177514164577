import { APP_TYPE } from '@hozana/screen/constants'
import { mobileDetect } from '@hozana/screen/functions/mobileDetect'
import { cookie } from '@hozana/storage/cookies'

import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'

import { isIOSWebview } from './isIOSWebview'

/**
 * Get appType, momoize it in a session cookie, return the memoized value if it exists, even on the server
 *
 * This function must be executed on the first page load because it uses document.referrer value
 */
export const getAppType = (): APP_TYPE => {
  const cookieDevice = cookie.load<APP_TYPE>(COOKIES.appType)
  if (cookieDevice && (__SERVER__ || !isIOSWebview())) {
    return cookieDevice
  }
  if (!__CLIENT__) {
    return undefined
  }

  let appType
  if (isIOSWebview()) {
    appType = APP_TYPE.IOS_NATIVE
  } else if (document?.referrer?.includes('android-app://org.hozana.app')) {
    appType = APP_TYPE.ANDROID_TWA
  } else if ((window.navigator as { standalone?: boolean })?.standalone === true) {
    appType = APP_TYPE.IOS_PWA
  } else if (window.matchMedia('(display-mode: standalone)')?.matches) {
    appType = APP_TYPE.ANDROID_PWA
  } else if (window.navigator?.maxTouchPoints === 1) {
    appType = APP_TYPE.EMULATION
  } else if (window?.navigator.userAgent && mobileDetect(window.navigator.userAgent)) {
    appType = APP_TYPE.BROWSER_MOBILE
  } else {
    appType = APP_TYPE.BROWSER_DESKTOP
  }
  cookie.save(COOKIES.appType, appType, COOKIES_CONFIG.SESSION)
  return appType
}
