import React from 'react'
import styled from 'styled-components'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'

import { Div } from 'elements/layout/Div'
import { Icon } from 'elements/media/Icon'
import { Ellipsis } from 'elements/text/Ellipsis'
import { Link, TLinkProps } from 'elements/text/Link'
import { Bubble } from 'elements/ui/Bubble'

import { ICON } from 'config/icons'

import { NavigationLi } from './NavigationLi'

const StyledEllipsis = styled(Ellipsis)`
  flex: 1;
`

type TMainLinkProps = {
  icon: ICON
  light?: boolean
  statusNumber?: number
} & TLinkProps

export const MainLink: React.FC<TMainLinkProps> = ({ children, icon, light, statusNumber, ...otherProps }) => {
  const attrs = useAttributes() // used for accessibility to pass "tabindex = -1" to avoid focus
  const color = light ? 'white' : 'black70'
  const hoverColor = light ? 'white' : 'black'
  const hoverBg = light ? 'fadedWhite10' : 'background'

  return (
    <NavigationLi data-testid={otherProps['data-testid']} hoverBg={hoverBg}>
      <Link color={color} hoverColor={hoverColor} w="100%" {...attrs} {...otherProps}>
        <Div p="16px 12px" align="middleLeft">
          <Icon name={icon} size="24px" m="0 16px 0 0" />
          <StyledEllipsis bold>{children}</StyledEllipsis>
          <Bubble number={statusNumber ?? 0} labelColor="darkRed" />
        </Div>
      </Link>
    </NavigationLi>
  )
}
