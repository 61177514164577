import type { TQueryConstructor } from '@hozana/api/types'

import type { TQuestion, TRelease } from 'modules/home/types'

export type TStaticPage = { title: string; content: string }

export const getStaticPageQuery: TQueryConstructor<{ slug: string }, TStaticPage> = ({ slug }) => ({
  queryKey: `getStaticPage/${slug}`,
  url: `/page/${slug}`,
})

export const getAllQuestionsQuery: TQueryConstructor<void, TQuestion[]> = () => ({
  queryKey: `getAllQuestions`,
  url: `/questions`,
})

/*
 * MUTATIONS
 */

export type TSendContactFormMutationPayload = {
  email: string
  firstname: string
  lastname: string
  subject: string
  message: string
  captcha: string
}

export const sendContactFormMutation: TQueryConstructor<TSendContactFormMutationPayload, void> = ({
  email,
  firstname,
  lastname,
  subject,
  message,
  captcha,
}) => ({
  queryKey: `contact`,
  url: `/contact${
    ['preprod.hozana.org', 'localhost', '127.0.0.1', 'hozana-qa.lan'].includes(window.location.hostname)
      ? '?skipCaptcha=true'
      : ''
  }`,
  method: 'POST',
  params: { email, firstname, lastname, subject, message, captcha },
  credentials: 'include',
})

type TGetReleasesData = Record<number, TRelease>

export const getReleasesQuery: TQueryConstructor<void, TGetReleasesData> = () => ({
  queryKey: `release-notes`,
  url: `/release-notes`,
  meta: { limit: 10 },
  headers: { 'Cache-Control': 'public' },
})
