import { Properties } from 'csstype'
import React, { HTMLAttributes } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

import { Translate } from '@hozana/intl/Translate'
import { defaultStyledConfig } from '@hozana/styling/config'
import { mergeStyles } from '@hozana/styling/mergeStyles'
import {
  TResponsiveProps,
  TResponsiveVisibilityProps,
  responsiveStyle,
  responsiveVisibility,
} from '@hozana/styling/responsive'

type TBaseTextProps = HTMLAttributes<HTMLSpanElement> & { 'data-testid'?: string }

const responsivePropsDef = {
  m: 'margin',
  p: 'padding',
  w: 'width',
  fontSize: 'font-size',
  align: 'text-align',
  lineHeight: 'line-height',
} as const

export const BaseText = styled('span')
  .withConfig<TTextProps>(defaultStyledConfig())
  .attrs<TBaseTextProps, TBaseTextProps>(({ children, className, 'data-testid': dataTestId }) => ({
    className,
    'data-testid': dataTestId,
    children: <Translate>{children}</Translate>,
  }))`
  word-break: break-word;
  word-wrap: break-word;
  text-decoration: inherit;
`

export type TTextProps = {
  as?: string | React.ComponentType<any>
  htmlFor?: string
  vAlign?: Properties['verticalAlign']
  block?: boolean
  bold?: boolean | 'semi'
  color?: keyof DefaultTheme['colors']
  firstUpper?: boolean
  float?: Properties['float']
  font?: keyof DefaultTheme['fontFamily']
  inline?: boolean
  italic?: boolean
  light?: boolean
  lowercase?: boolean
  nowrap?: boolean
  underline?: boolean
  upper?: boolean
} & TResponsiveVisibilityProps &
  TResponsiveProps<typeof responsivePropsDef> &
  TBaseTextProps

export const Text = styled(BaseText).attrs<TTextProps, TTextProps>(
  ({
    theme,
    block,
    bold,
    color,
    float,
    font = 'openSans',
    inline,
    italic,
    light,
    lowercase,
    nowrap,
    style,
    underline,
    upper,
    vAlign = 'text-bottom',
  }) => ({
    style: mergeStyles(style, {
      fontFamily: theme.fontFamily[font],
      verticalAlign: vAlign,
      float,
      display: block ? 'block' : inline ? 'inline' : 'inline-block',
      ...(color && { color: theme.colors[color] }),
      ...(italic && { fontStyle: 'italic' }),
      ...(underline && { textDecoration: 'underline' }),
      ...(nowrap && { whiteSpace: 'nowrap' }),
      ...((upper || lowercase) && { textTransform: upper ? 'uppercase' : 'lowercase' }),
      ...(bold && (bold === 'semi' ? { fontWeight: 600 } : { fontWeight: 'bold' })),
      ...(light && { fontWeight: 200 }),
    }),
  }),
)`
  ${({ firstUpper }) =>
    firstUpper &&
    css`
      &:first-letter {
        text-transform: uppercase;
      }
    `}
  ${({ lineHeight = '1.4em', ...otherProps }) => responsiveStyle(responsivePropsDef)({ lineHeight, ...otherProps })}
  ${responsiveVisibility}
`
