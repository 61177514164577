import { FieldPath, SubmitHandler, UseFormSetError } from 'react-hook-form'
import type { Action, AnyAction } from 'redux'
import { createAction } from 'redux-actions'
import type { ThunkAction } from 'redux-thunk'

import type { TDispatch } from '@hozana/redux/types'
import { routinePromise } from '@hozana/routines/actions'

import * as allRoutines from 'config/routines'
import type { TState } from 'config/types'

import { ROUTINE_STAGE, routineStages } from './constants'
import type { TRoutine, TRoutineData, TRoutinePayload } from './types'

/**
 * ```
 * createRoutine(MY_PREFIX) = {
 *  (): { type: 'MY_PREFIX/TRIGGER' },
 *  trigger: (payload) => { type: 'MY_PREFIX/TRIGGER', payload },
 *  request: (payload) => { type: 'MY_PREFIX/REQUEST', payload },
 *  success: (payload) => { type: 'MY_PREFIX/SUCCESS', payload },
 *  failure: (payload) => { type: 'MY_PREFIX/FAILURE', payload },
 *  fulfill: (payload) => { type: 'MY_PREFIX/FULFILL', payload },
 *  TRIGGER: 'MY_PREFIX/TRIGGER',
 *  REQUEST: 'MY_PREFIX/REQUEST',
 *  SUCCESS: 'MY_PREFIX/SUCCESS',
 *  FAILURE: 'MY_PREFIX/FAILURE',
 *  FULFILL: 'MY_PREFIX/FULFILL',
 * }
 * ```
 * @param typePrefix string (Ex: MY_PREFIX)
 */
export function createRoutine<TP extends string, Payload extends TAnyProps = TNoMoreProps, Data = undefined>(
  typePrefix: TP,
): TRoutine<TP, Payload, Data> {
  const createActionCreator = (type: ROUTINE_STAGE) => createAction<AnyAction>(`${typePrefix}/${type}`)

  return routineStages.reduce((acc, stage) => {
    const actionCreator = createActionCreator(stage)
    return Object.assign(acc, {
      [stage.toLowerCase()]: actionCreator,
      [stage.toUpperCase()]: actionCreator.toString(),
    })
  }, createActionCreator(routineStages[0]) as Partial<TRoutine<TP, Payload, Data>>) as TRoutine<TP, Payload, Data>
}

export const routineToThunk =
  <R extends TObjectValues<typeof allRoutines>>(
    routine: R,
    payload: TRoutinePayload<R>,
    { reduxFormCompatible = false } = {},
  ): ThunkAction<Promise<TRoutineData<R>>, TState, void, Action<string>> =>
  (dispatch) =>
    new Promise<TRoutineData<R>>((resolve, reject) =>
      dispatch(
        routinePromise({
          payload,
          meta: {
            defer: { resolve, reject },
            reduxFormCompatible,
            routine,
          },
        }),
      ),
    )

export const bindRoutineToForm =
  <R extends TObjectValues<typeof allRoutines>>(routine: R) =>
  (payload: TRoutinePayload<R>, dispatch: TDispatch) =>
    dispatch(routineToThunk(routine, payload, { reduxFormCompatible: true }))

export const bindRoutineToHookForm =
  <R extends TObjectValues<typeof allRoutines>, FieldValues extends TAnyProps>(
    routine: R,
    {
      dispatch,
      setError,
      onSuccess,
      onFailure,
      additionalProps,
    }: {
      dispatch: TDispatch
      setError: UseFormSetError<FieldValues>
      onSuccess?: (data: TRoutineData<R>) => void
      additionalProps?: Omit<TRoutinePayload<R>, keyof FieldValues> & Partial<FieldValues>
      onFailure?: (error: any) => void
    },
  ): SubmitHandler<FieldValues> =>
  async (payload) => {
    try {
      const data = await dispatch(
        routineToThunk(routine, { ...payload, ...additionalProps }, { reduxFormCompatible: false }),
      )
      onSuccess?.(data)
      return data
    } catch (error) {
      const { errors: { _error, ...errors } = { _error: undefined } } = error || {}
      const [[fieldName, message] = []] = Object.entries(errors as Record<FieldPath<FieldValues>, string>)
      /* console.log('From bindRoutineToHookForm, catch.', {
        error,
        errors,
        fieldName,
        message,
        setError,
      }) */
      if (fieldName) {
        setError(fieldName, { message }, { shouldFocus: true })
        setError('root', { message })
      }
      onFailure?.(error)

      return errors
    }
  }
