import type { TState } from 'config/types'

export const selectFlashMessages = (state: TState) => state.app.flashMessages

export const selectPopin = (state: TState) => state.app.popin || {}

export const selectSavingIndicator = (state: TState) => state.app.savingIndicator

export const isLangBarOpen = (state: TState) => !!state.app.isLangBarOpen

export const hasAcceptedCookies = (state: TState) => !!state.app.hasAcceptedCookies

export const isAlertBannerOpen = (state: TState) => !!state.app.isAlertBannerOpen

export const isConversationOptionsOpen = (state: TState) => !!state.app.isConversationOptionsOpen

export const isConversationsListOptionsOpen = (state: TState) => !!state.app.isConversationsListOptionsOpen

export const isNavigationOpen = (state: TState) => !!state.app.isNavigationOpen

export const selectPrompts = (state: TState) => state.app.prompts

export const selectDisplayHiddenCommunities = (state: TState) => state.app.displayHiddenCommunities

export const isHydrated = (state: TState) => state.app.isHydrated

export const isReloadNeeded = (state: TState) => state.app.needReload

export const selectTitle = (state: TState) => state.app.title

export const selectTargetPathname = (state: TState) => state.app.pathname
