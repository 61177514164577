export { dequal as deepEqual } from 'dequal/lite'

/**
 * Group object items by key, using the key as the label for the groups
 *
 * Ex:
 * ```
 * [{ a: 1, b: 2 }, { a: 11, b: 22 }] => { a: [1, 11], b: [2, 22] }
 * ```
 */
export const arrayGroupBy = (xs: Record<string, any>[], key: string): Record<string, any> =>
  xs.reduce(
    (acc, x) => ({
      ...acc,
      [x[key]]: [...(acc[x[key]] || []), x],
    }),
    {},
  )

export function deepMerge<S, U>(
  source: S,
  updates: U,
): U extends Record<any, any> ? (S extends Record<any, any> ? U & S : U) : U
export function deepMerge(source: any, updates: any) {
  if (!updates || !source || typeof updates !== 'object' || typeof source !== 'object') {
    return updates
  }

  if (Array.isArray(updates) && Array.isArray(source)) {
    return [
      ...source.filter(
        (sourceItem) =>
          !updates.some((updatesItem) =>
            sourceItem.id ? updatesItem.id === sourceItem.id : updatesItem === sourceItem,
          ),
      ),
      ...updates,
    ]
  }

  return Object.keys(updates || {}).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        typeof updates[key] === 'object' && typeof source[key] === 'object'
          ? deepMerge(updates[key], source[key])
          : updates[key],
    }),
    source,
  )
}

// Compare two objects or two arrays one-level deep and returns true if they are different
// See https://github.com/tkh44/shallow-compare/
export const shallowDiffers = (a: any, b: any): boolean => {
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    for (const i in a) if (!(i in b)) return true
    for (const i in b) if (a[i] !== b[i]) return true
    return false
  } else {
    return a !== b
  }
}

/**
 * Remove undefined values
 */
export const sanitize = <T extends Record<any, any>>(content: T): T =>
  content === undefined ? null : JSON.parse(JSON.stringify(content))
