import { useEffect } from 'react'
import { useIsMounted } from 'usehooks-ts'

/**
 * A kind of async useEffect hook that only execute onSuccess and onError callbacks if the component is still mounted
 *
 * Usage:
 *
 * ```js
 * useAsyncEffect(
 *   () => [
 *     async () => {
 *       // Async effect
 *       return res
 *     },
 *     {
 *       disable: shouldDisableCondition, // If true, the async effect won't be executed
 *       onSuccess: (res) => console.log(res),
 *       onError: (error) => console.log(error),
 *     },
 *   ],
 *   [shouldDisableCondition], // Dependencies array
 * )
 * ```
 */
export const useAsyncEffect = <Data>(
  callback: () => [
    () => Promise<Data>,
    { disabled?: boolean; onSuccess?: (data: Data) => void; onError?: (error: Error | any) => void },
  ],
  dependencies?: any[],
) => {
  const isMounted = useIsMounted()
  useEffect(() => {
    const [asyncCallback, { disabled, onSuccess, onError }] = callback()

    if (!disabled) {
      asyncCallback()
        ?.then((data) => {
          if (isMounted()) {
            onSuccess?.(data)
          }
        })
        ?.catch((error) => {
          if (isMounted()) {
            onError?.(error)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
