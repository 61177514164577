import { createRoutine } from '@hozana/routines/functions'

import type { TDeleteDeviceMutationPayload } from 'modules/notification/queries'
import type { TEditNotificationSettingsPayload, TOfferPushNotificationsPayload } from 'modules/notification/sagas'

export const editNotificationsSettings = createRoutine<'EDIT_NOTIFICATIONS_SETTINGS', TEditNotificationSettingsPayload>(
  'EDIT_NOTIFICATIONS_SETTINGS',
)
export const requestAndSaveSubscription = createRoutine<'REQUEST_AND_SAVE_SUBSCRIPTION'>(
  'REQUEST_AND_SAVE_SUBSCRIPTION',
)
export const offerPushNotifications = createRoutine<'OFFER_PUSH_NOTIFICATIONS', TOfferPushNotificationsPayload>(
  'OFFER_PUSH_NOTIFICATIONS',
)
export const deleteDevice = createRoutine<'DELETE_DEVICE', TDeleteDeviceMutationPayload>('DELETE_DEVICE')
