import React from 'react'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'
import { QA } from '@hozana/tests/constants'

import { Div } from 'elements/layout/Div'
import { Icon } from 'elements/media/Icon'
import { Ellipsis } from 'elements/text/Ellipsis'
import { Link, TLinkProps } from 'elements/text/Link'

import { DONATION_SOURCE, QUERY_ACTION } from 'app/constants'
import { ICON } from 'config/icons'
import { PAGE } from 'routes/pages'

import { NavigationLi } from './NavigationLi'

export const MainLinkDonate: React.FC<TLinkProps & { light?: boolean; source: DONATION_SOURCE }> = ({
  light,
  source,
  ...otherProps
}) => {
  const attrs = useAttributes() // used for accessibility to pass "tabindex = -1" to avoid focus
  const hoverBg = light ? 'fadedWhite10' : 'background'

  return (
    <NavigationLi hoverBg={hoverBg}>
      <Link
        color={light ? 'white' : 'darkRed'}
        hoverColor="darkRed"
        w="100%"
        data-testid={QA.COMMON.NAVIGATION.DONATE_LINK}
        to={{
          pathname: PAGE.INTENTIONS,
          query: { action: QUERY_ACTION.SHOW_DONATION_BANNER, source },
        }}
        reload={true}
        {...attrs}
        {...otherProps}
      >
        <Div p="16px 12px" align="middleLeft">
          <Icon name={ICON.HEART} size="24px" color="darkRed" m="0 16px 0 0" />
          <Ellipsis bold>trans:common:app.i-donate</Ellipsis>
        </Div>
      </Link>
    </NavigationLi>
  )
}
