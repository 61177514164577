import styled from 'styled-components'

import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import type { TTextProps } from 'elements/text/Text'

import { P } from './P'

const responsiveProps = {
  h: 'height',
  lines: '-webkit-line-clamp',
} as const

export type TEllipsisMultiLinesProps = {
  lines?: string
} & TResponsiveProps<typeof responsiveProps> &
  TTextProps

export const EllipsisMultiLines = styled(P)<TEllipsisMultiLinesProps>`
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${({ lines = 3, ...otherProps }) => responsiveStyle(responsiveProps)({ lines, ...otherProps })}
  ${(p) => p.lines === '1' && 'word-break: break-all;'}
`
