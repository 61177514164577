import type { TQueryConstructor } from '@hozana/api/types'

import { SHARE_TYPES } from './constants'

export const shortenMutation: TQueryConstructor<{ url: string }, { hozanaShortUrl: string }> = ({ url }) => ({
  queryKey: 'shorten',
  url: '/shorten',
  method: 'POST',
  params: { url },
})

export const incrementSharesMutation: TQueryConstructor<
  {
    shareType: SHARE_TYPES
    objectKey: string
  },
  Record<SHARE_TYPES, number>
> = ({ shareType, objectKey }) => ({
  queryKey: 'incrementShare',
  url: '/shares/increment',
  method: 'POST',
  params: { objectId: objectKey, counter: shareType },
})
