import styled from 'styled-components'

import {
  TResponsiveProps,
  TResponsiveVisibilityProps,
  responsiveStyle,
  responsiveVisibility,
} from '@hozana/styling/responsive'

const responsiveProps = { h: 'height' } as const

export type TSpaceProps = {
  noPrint?: boolean
} & TResponsiveProps<typeof responsiveProps> &
  TResponsiveVisibilityProps

export const Space = styled.div<TSpaceProps>`
  width: 100%;
  ${({ h = '1em', ...otherProps }) => responsiveStyle(responsiveProps)({ h, ...otherProps })};
  ${responsiveVisibility};

  /* Print */
  ${({ noPrint }) => noPrint && '@media print {display: none}'}
`
