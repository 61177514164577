export { CACHE_KEYS } from './cacheKeys'

export enum SW_CONTEXT {
  INIT = 'service worker initialization',
  UPDATE = 'service worker update',
  TRIGGER_UPDATE = 'service worker trigger update',
  SKIP_WAITING = 'service worker skip waiting',
  MESSAGE = 'service worker message',
  UNREGISTER = 'service worker unregister',
}
