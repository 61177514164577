import { useSelector } from '@hozana/redux/hooks'

import { useIntentionsWidget } from 'modules/intention/hooks/useIntentionsWidget'
import { selectIntention, selectWidgetIntention } from 'modules/intention/selectors'
import { useUser } from 'modules/user/hooks/useUser'

export const useMyIntention = () => {
  const { intention } = useUser()
  const widget = useIntentionsWidget()
  return useSelector((state) => (widget && selectWidgetIntention(state)) || selectIntention(state, intention))
}
