import React, { MouseEvent } from 'react'

import { ICON } from 'config/icons'

import { TWithInnerButtonInputProps, WithInnerButtonInput } from './WithInnerButtonInput'

export type TClearableInputProps = {
  onClear: (e?: MouseEvent<HTMLButtonElement>) => void
} & Partial<TWithInnerButtonInputProps>

/**
 * ### Clearable Input ###
 *
 * An input where a clear icon button appear on the right when not empty.
 *
 * /!\ Warning: if you alter the input padding, using p prop, you must adjust inputPaddingRight:
 * otherwise, when the input is full of text, it won't be clean.
 */
export const ClearableInput = React.forwardRef<HTMLInputElement, TClearableInputProps>(
  ({ onClear, ...otherProps }, ref) => (
    <WithInnerButtonInput
      ref={ref}
      buttonIcon={ICON.CLOSE}
      buttonProps={{ iconSize: '0.6em' }}
      buttonLabel="trans:common:word.clear"
      hideButtonOnEmptyValue
      onButtonClick={onClear}
      {...otherProps}
    />
  ),
)

ClearableInput.displayName = 'ClearableInput'
