import styled from 'styled-components'

import { TTextProps, Text } from './Text'

export type TPProps = TTextProps

// Using P = Text and P.defaultProps does not work: it would set Text.defaultProps
export const P = styled(Text)``

P.defaultProps = {
  as: 'p',
  block: true,
}
