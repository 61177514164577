import type { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

const responsiveProps = {
  m: 'margin',
  p: 'padding',
  w: 'width',
  h: 'height',
  maxW: 'max-width',
  maxH: 'max-height',
  minW: 'min-width',
  minH: 'min-height',
  fontSize: 'font-size',
} as const

export type TGridProps = {
  gutter?: string
  columns?: string
} & TResponsiveProps<typeof responsiveProps> &
  HTMLAttributes<HTMLDivElement>

export const Grid = styled.div.attrs<TGridProps, TGridProps>(({ gutter, columns = '1fr 1fr', style }) => ({
  style: mergeStyles(style, {
    gridGap: gutter,
    gridTemplateColumns: columns,
  }),
}))`
  display: grid;
  ${responsiveStyle(responsiveProps)}
`
