import { PAGE } from '../routes/constants'
import { default as rawLocales } from './locales'
import { NAMESPACES } from './namespaces'

export const NAMESPACE = NAMESPACES
export type NAMESPACE = TObjectValues<typeof NAMESPACES>

export const LANG_NAMES = {
  fr: 'Français',
  es: 'Español',
  en: 'English',
  pt: 'Português',
  it: 'Italiano',
  pl: 'Polski',
}

export type TLocale = keyof typeof LANG_NAMES

export const LOCALES = rawLocales

export const UNTRANSLATABLE_PAGE_FALLBACKS = {
  [PAGE.COMMUNITY_VIEW]: PAGE.COMMUNITY_LIST,
  [PAGE.COMMUNITY_VIEW_ADMIN]: PAGE.COMMUNITY_LIST,
  [PAGE.COMMUNITY_VIEW_LANDING]: PAGE.COMMUNITY_LIST,
  [PAGE.COMMUNITY_VIEW_UNPUBLISHED]: PAGE.COMMUNITY_LIST,
  [PAGE.PUBLICATION_VIEW]: PAGE.COMMUNITY_LIST,
  [PAGE.STATIC]: PAGE.HOME,
  [PAGE.GUIDE]: PAGE.HOME,
}

export const APP_LOCALE_PATHS = LOCALES.map((locale) => `./i18n/${locale}/app.json`)

export const SOURCE_PATH = '.'

export const COMMON_NAMESPACES = [NAMESPACE.COMMON, NAMESPACE.AUTH, NAMESPACE.SHARE, NAMESPACE.INTENTION]
