import ICU from 'i18next-icu'
import type { UserConfig } from 'next-i18next'

import { NAMESPACE } from 'i18n/constants'
import i18n from 'i18n/i18n.config'

export const nextI18nextConfig: UserConfig = {
  i18n,
  defaultNS: NAMESPACE.COMMON,
  fallbackNS: NAMESPACE.COMMON,
  react: { useSuspense: false },
  localePath: './public/namespace',
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  serializeConfig: false,
  use: [ICU],
}
