import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'

import { useQuery } from '@hozana/hooks/useQuery'
import { getFirstQueryValue } from '@hozana/router/functions'

import { TSetAppModeInput, TSetDebugModeInput, setAppMode, setDebugMode } from 'app/managers/debugger/functions'

const Debugger = dynamic(() => import('./Debugger').then((m) => m.Debugger))

/**
 * ### DebuggerManager ###
 *
 * Load the debugger in the browser console using `window.getDebugger()`.
 *
 * In prod, the password must be used: `window.getDebugger('comment-est-votre-blanquette')`.
 */
export const DebuggerManager: React.FC = () => {
  const [loadDebugger, setLoadDebugger] = useState(false)

  useQuery(
    ({ debugMode, hours }) => setDebugMode(getFirstQueryValue(debugMode) as TSetDebugModeInput, hours && Number(hours)),
    [],
    'debugMode',
    { queryKeysToRemove: ['hours'] },
  )
  useQuery(
    ({ appMode, hours }) => setAppMode(getFirstQueryValue(appMode) as TSetAppModeInput, hours && Number(hours)),
    [],
    'appMode',
    {
      queryKeysToRemove: ['hours'],
    },
  )

  useEffect(() => {
    window.getDebugger = function () {
      // eslint-disable-next-line prefer-rest-params
      if (/dev|preprod|staging/.test(CONF_KEYS.ENV) || arguments[0] === 'comment-est-votre-blanquette') {
        setLoadDebugger(true)
        console.log('Loading debugger...')
      } else {
        console.log('Debug mode on. ;)')
      }
    }
  }, [])

  return loadDebugger ? <Debugger /> : null
}
