import { TUrlQueryValue, buildQueryString } from '@hozana/router/functions'
import { OS } from '@hozana/screen/constants'
import { isIOSWebview } from '@hozana/screen/functions/isIOSWebview'
import { UTM } from '@hozana/tracking/constants'

import { APPLE_STORE_LINK, PLAY_STORE_LINK } from 'modules/home/constants'

export const getAppStoresLinks = (utmMedium: TUrlQueryValue, operatingSystemValue: OS) => {
  const isNative = isIOSWebview()

  const utmQueryString = buildQueryString({
    utm_campaign:
      operatingSystemValue === OS.IOS || isNative ? UTM.CAMPAIGN.APP_STORE_HOZANA : UTM.CAMPAIGN.PLAY_STORE_HOZANA,
    utm_source: UTM.SOURCE.SITE_HOZANA,
    utm_medium: utmMedium,
  })
  if (operatingSystemValue === OS.IOS || isNative) return `${APPLE_STORE_LINK}?${utmQueryString}`
  return `${PLAY_STORE_LINK}&${utmQueryString}`
}
