/**
 * All saga listeners must be added here to that Redux-Saga may run them.
 *
 * See https://github.com/hozana/hozana.org-v2/blob/develop/docs/3-functionnalities/redux.md
 */
import 'regenerator-runtime/runtime'

import routinePromiseWatcherSaga from '@hozana/routines/routinePromiseWatcherSaga'

import appSagasListener from 'app/sagas'

import authSagasListener from 'modules/auth/sagas'
import communitySagasListener from 'modules/community/sagas'
import homeSagasListener from 'modules/home/sagas'
import intentionSagasListener from 'modules/intention/sagas'
import notificationSagasListener from 'modules/notification/sagas'
import praySagasListener from 'modules/pray/sagas'
import publicationSagasListener from 'modules/publication/sagas'
import testimonySagasListener from 'modules/testimony/sagas'
import userSagasListener from 'modules/user/sagas'

const getAllSagas = () => [
  // App
  appSagasListener(),
  // Modules
  authSagasListener(),
  communitySagasListener(),
  homeSagasListener(),
  intentionSagasListener(),
  notificationSagasListener(),
  praySagasListener(),
  publicationSagasListener(),
  testimonySagasListener(),
  userSagasListener(),
  // Utils
  routinePromiseWatcherSaga(),
]

export default getAllSagas
