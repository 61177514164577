import {
  captureException as SentryCaptureException,
  captureMessage as SentryCaptureMessage,
  SeverityLevel,
  User,
  addBreadcrumb,
  setUser,
  withScope,
} from '@sentry/nextjs'

const isActive = () => CONF_KEYS.ENV === 'prod' || CONF_KEYS.ENV === 'test'

const captureException = (error: Error & { details?: string }, extras: TAnyProps = {}) => {
  if (isActive()) {
    const extendedExtras = error.details ? { ...extras, details: error.details } : extras
    if (!extendedExtras.rate || extendedExtras.rate > Math.random()) {
      withScope((scope) => {
        scope.setExtras(extendedExtras)
        SentryCaptureException(error)
      })
    }
  }
  console.error('Sentry exception', extras, error)
}

const captureMessage = (message: string, level: SeverityLevel = 'warning', extras = {}) => {
  if (isActive()) {
    withScope((scope) => {
      scope.setExtras(extras)
      SentryCaptureMessage(message, level)
    })
  }
  console.log('Sentry message', message, level, extras)
}

const setMeContext = (user: User) => {
  if (isActive()) {
    setUser(user)
  }
}

export const Sentry = {
  captureException,
  captureMessage,
  setMeContext,
  addBreadcrumb: addBreadcrumb,
}
