import { selectEntities, selectEntity } from '@hozana/api/selectors'

import type { TState } from 'config/types'

import { selectCommunity } from 'modules/community/selectors'
import type { TPublication } from 'modules/publication/types'

export const selectPublication = (state: TState, publicationId: number) =>
  selectEntity(state, 'publication', publicationId)

export const selectPublications = (state: TState, publicationsArray: number[]) =>
  selectEntities(state, 'publication', publicationsArray)

export const selectPublicationCommunity = (state: TState, publicationId: number) => {
  const publication = selectPublication(state, +publicationId)
  return publication && publication.community && selectCommunity(state, publication.community)
}

export const selectDraftPublicationsFirst = (state: TState, publicationsArray: number[]): number[] => {
  const publications = selectEntities(state, 'publication', publicationsArray).filter((p) => !p.published)
  const scheduled = publications.filter((publication) => publication.isScheduled)
  const drafts = publications.filter((publication) => publication.isDraft)

  const sortDrafts = (d1: TPublication, d2: TPublication) =>
    new Date(d2.updatedAt).getTime() - new Date(d1.updatedAt).getTime()
  const sortScheduled = (p1: TPublication, p2: TPublication) =>
    new Date(p1.publishedDate).getTime() - new Date(p2.publishedDate).getTime()

  drafts.sort(sortDrafts)
  scheduled.sort(sortScheduled)

  return drafts.concat(scheduled).map((p) => p.id)
}

export const selectCommunityLastPublication = (state: TState, communityId: number): TPublication | undefined => {
  const community = selectCommunity(state, communityId)
  return community?.lastPublication && selectPublication(state, community.lastPublication)
}
