import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { RoundButton } from 'elements/button/RoundButton'
import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { Cell } from 'elements/layout/Cell'
import { Div } from 'elements/layout/Div'
import { Row } from 'elements/layout/Row'
import { Icon } from 'elements/media/Icon'
import { EllipsisMultiLines, TEllipsisMultiLinesProps } from 'elements/text/EllipsisMultiLines'
import { StyledA, TLinkProps } from 'elements/text/Link'

import { ICON } from 'config/icons'

export const ALERT_BANNER_DESKTOP_HEIGHT = 32

const StyledDiv = styled(Div)`
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.fadedBlack20};
`

type TAlertBannerProps = {
  onClick: MouseEventHandler<HTMLAnchorElement>
  onClose: VoidFunction
} & Pick<TEllipsisMultiLinesProps, 'bold'> &
  Pick<TLinkProps, 'to'>

export const AlertBanner: React.FC<TAlertBannerProps> = ({ to, onClick, onClose, bold, children }) => (
  <Div p="8px 1em 0">
    <StyledDiv
      align="middleCenter"
      p="20px"
      minH={{ xs: '45px', sm: `${ALERT_BANNER_DESKTOP_HEIGHT}px` }}
      role="banner"
      bg="lightBlue"
      radius="big"
    >
      <StyledA href={to as unknown as string} color="darkBlue" onClick={onClick} target="_blank">
        <Row align="middle" gutter="8px">
          <Cell flex="1">
            <EllipsisMultiLines lines="2" lineHeight="24px" bold={bold}>
              {children}
            </EllipsisMultiLines>
          </Cell>
          <Cell>
            <Icon name={ICON.CHEVRON_RIGHT} color="darkBlue" />
          </Cell>
        </Row>
      </StyledA>
      <AbsoluteDiv right="4px" top="4px">
        <RoundButton
          onClick={onClose}
          icon={ICON.CLOSE}
          color="blue"
          hoverColor="blue"
          size="1.3em"
          label="trans:common:word.close"
          ariaLabel="trans:common:word.close"
        />
      </AbsoluteDiv>
    </StyledDiv>
  </Div>
)
