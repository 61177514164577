import { cookie } from '@hozana/storage/cookies'

import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'

export const saveCookiesConsent = () => {
  cookie.save(COOKIES.hasConsent, 'true', {
    ...COOKIES_CONFIG.ONE_YEAR,
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
  })
}

export const getCookiesConsent = () => cookie.load<boolean>(COOKIES.hasConsent)
