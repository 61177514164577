import React, { ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'

export { escapeString } from './escapeString'

export const toTitleCase = (s: string): string =>
  typeof s === 'string' ? s.toLowerCase().replace(/(?:[^\w']|_|^)(\w)/g, (f) => f.toUpperCase()) : s

export function nl2br<TR = false>(str: string, toRawHtml?: TR): true extends TR ? string : string | ReactNode[]
export function nl2br(str: string, toRawHtml?: boolean): string | ReactNode[] {
  if (typeof str !== 'string') {
    return str
  }

  const newlineRegex = /(\r\n|\r|\n)/g

  const result = str.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return toRawHtml ? '<br/>' : React.createElement('br', { key: index })
    }
    return line
  })
  return toRawHtml ? result.join('') : result
}

/** 'camelToKebab' => 'camel-to-kebab' */
export const camelToKebab = (str: string) => str.replace(/[A-Z]/g, (l) => `-${l.toLowerCase()}`)

export const createUUID = uuidv4
