import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'
import { QA } from '@hozana/tests/constants'

import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { BorderedDiv } from 'elements/layout/BorderedDiv'
import { Shadow } from 'elements/layout/Shadow'
import { BgImage } from 'elements/media/BgImage'
import { Link } from 'elements/text/Link'
import type { TLinkProps } from 'elements/text/Link'
import { P } from 'elements/text/P'

import { PAGE } from 'routes/constants'

import { GUIDES, GUIDE_TYPE } from 'modules/guide/constants'

const StyledBorderedDiv = styled(BorderedDiv)`
  box-shadow: inset 0px -80px 50px -50px rgb(0 0 0 / 20%);
  &:hover {
    ${BgImage} {
      transition: all 2s ease;
      transform: scale(1.1);
    }
  }
`
const StyledShadow = styled(Shadow)`
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 40%);
  }
`

const IMAGES: Partial<Record<GUIDE_TYPE, string>> = {
  [GUIDE_TYPE.BIBLE]: '/img/guides/bible.jpg',
  [GUIDE_TYPE.FEAST]: '/img/guides/feasts.jpg',
  [GUIDE_TYPE.PRAYER]: '/img/guides/prayer.jpg',
  [GUIDE_TYPE.SAINTS]: '/img/guides/saints.jpg',
  [GUIDE_TYPE.CATECHISM]: '/img/guides/catechism.jpg',
  [GUIDE_TYPE.MIRACLES]: '/img/guides/miracles.jpg',
  [GUIDE_TYPE.ROSARY]: '/img/guides/rosary.jpg',
  [GUIDE_TYPE.MEDITATION]: '/img/guides/meditation.jpg',
}

type TGuideLinkProps = {
  guideType: GUIDE_TYPE
  onClick?: MouseEventHandler
  large?: boolean
} & TLinkProps

export const GuideLink: React.FC<TGuideLinkProps> = ({ guideType, onClick, large, ...otherProps }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const attrs = useAttributes() // used for accessibility to pass "tabindex = -1" to avoid focus

  const getGuideLinkTranslation = (guideType: GUIDE_TYPE) => {
    switch (guideType) {
      case GUIDE_TYPE.BIBLE:
        return 'trans:common:app.navigation.footer.guide.bible'
      case GUIDE_TYPE.FEAST:
        return 'trans:common:app.navigation.footer.guide.feast-days'
      case GUIDE_TYPE.LIVE:
        return 'trans:common:navigation.live-page-link'
      case GUIDE_TYPE.PRAYER:
        return 'trans:common:app.navigation.footer.guide.prayer'
      case GUIDE_TYPE.SAINTS:
        return 'trans:common:app.navigation.footer.guide.saints'
      case GUIDE_TYPE.CATECHISM:
        return 'trans:common:app.navigation.footer.guide.catechism'
      case GUIDE_TYPE.MIRACLES:
        return 'trans:common:app.navigation.footer.guide.miracles'
      case GUIDE_TYPE.ROSARY:
        return 'trans:common:app.navigation.footer.guide.rosary'
      case GUIDE_TYPE.MEDITATION:
        return 'trans:common:app.navigation.footer.guide.meditation'
      default:
        return 'trans:common:app.navigation.footer.guide.all'
    }
  }

  return (
    <Link
      data-testid={QA.COMMON.NAVIGATION.MISC_LINKS(guideType)}
      to={{ pathname: PAGE.GUIDE, query: { guideName: GUIDES[language][guideType] } }}
      reload={guideType === GUIDE_TYPE.PRAYER && language === 'es'}
      onClick={onClick}
      {...attrs}
      {...otherProps}
      w="100%"
    >
      <StyledShadow
        borderRadius="10px"
        y="4px"
        depth="6px"
        opacity="0.2"
        w={large ? { xs: '250px', sm: '280px', md: '300px' } : '100%'}
      >
        <StyledBorderedDiv
          borderWidth="0"
          borderRadius="10px"
          overflow="hidden"
          h={large ? '20vmax' : '120px'}
          bg="fadedBlack20"
        >
          <BgImage src={IMAGES[guideType]} w="100%" h="100%" p="10px" bgPosition="center">
            {/* use align="center" to display alt text on center and prevent his truncation */}
          </BgImage>
          <AbsoluteDiv bottom>
            <P
              align="left"
              bold
              fontSize={large ? { xs: '1em', sm: '1.5em', md: '1.8em' } : '1em'}
              color="white"
              m="0.9em"
            >
              {getGuideLinkTranslation(guideType)}
            </P>
          </AbsoluteDiv>
        </StyledBorderedDiv>
      </StyledShadow>
    </Link>
  )
}
