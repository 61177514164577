export { COOKIES } from './cookieNames'

const SESSION = {
  path: '/',
  domain: new URL(CONF_KEYS.HOME).hostname,
}

export const COOKIES_CONFIG = {
  SESSION,
  THIRTY_MINUTES_SECURE: {
    ...SESSION,
    maxAge: 1000 * 1800, // 30 minutes (in milliseconds)
    // Not setting the domain prevents most browsers from reusing that cookie for subdomains
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie (Domain=<domain-value> (optional))

    // Prevent CSRF attacks
    sameSite: true, // equates to strict

    // httpOnly and secure cannot be used in our environment!
    // See https://community.cloudflare.com/t/adding-secure-httponly-to-set-cookie-header/78668
    httpOnly: false,
    secure: false,
  },
  ONE_YEAR: {
    ...SESSION,
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  },
  ONE_MONTH: {
    ...SESSION,
    expires: new Date(new Date().setDate(new Date().getDate() + 30)),
  },
}
