import React, { useState } from 'react'
import styled from 'styled-components'

import { TTranslateProps, Translate } from '@hozana/intl/Translate'
import { QA } from '@hozana/tests/constants'

import { RoundButton } from 'elements/button/RoundButton'
import { ALERT_COLORS } from 'elements/constants'
import { BorderedDiv, TBorderedDivProps } from 'elements/layout/BorderedDiv'
import { Cell } from 'elements/layout/Cell'
import { Row } from 'elements/layout/Row'
import { Icon } from 'elements/media/Icon'
import { TTextProps } from 'elements/text/Text'

import { ICON } from 'config/icons'

export const StyledCell = styled(Cell)`
  a {
    color: inherit !important;
    font-weight: bold;
  }
`

export type TAlertProps = {
  status?: keyof typeof ALERT_COLORS
  icon?: ICON
  onClose?: VoidFunction
  hideCloseButton?: boolean
  fontSize?: TTextProps['fontSize']
  align?: TBorderedDivProps['align']
} & TBorderedDivProps &
  Pick<TTranslateProps, 'values'>

export const Alert: React.FC<TAlertProps> = ({
  children,
  status = 'success',
  onClose,
  hideCloseButton,
  icon,
  p = '12px 10px 12px 1em',
  align = 'left',
  fontSize,
  onClick,
  values,
  ...otherProps
}) => {
  const [hidden, setHidden] = useState(false)

  return !hidden ? (
    <BorderedDiv
      as={onClick ? 'button' : null}
      bg={ALERT_COLORS[status].bg}
      noBorder
      p={hideCloseButton ? '12px' : p}
      onClick={onClick}
      align={align}
      {...otherProps}
    >
      <Row gutter="10px">
        {icon && (
          <Cell fontSize={fontSize}>
            <Icon name={icon} color={ALERT_COLORS[status].color} />
          </Cell>
        )}
        <StyledCell flex="1" m="auto 0" color={ALERT_COLORS[status].color} fontSize={fontSize}>
          <Translate values={values}>{children}</Translate>
        </StyledCell>
        {!hideCloseButton && (
          <RoundButton
            data-testid={QA.COMMON.ALERT_CLOSE_BUTTON}
            onClick={() => {
              onClose?.()
              setHidden(true)
            }}
            icon={ICON.CLOSE}
            size="1em"
            color={ALERT_COLORS[status].color}
            label="trans:common:word.close"
          />
        )}
      </Row>
    </BorderedDiv>
  ) : null
}
