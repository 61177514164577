import React, { MouseEventHandler } from 'react'

import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { QA } from '@hozana/tests/constants'
import { EVENT_ORIGIN } from '@hozana/tracking/constants'

import { Button, TButtonProps } from 'elements/button/Button'

import { PAGE } from 'routes/constants'

import type { TCommunity } from 'modules/community/types'
import { useUser } from 'modules/user/hooks/useUser'

import { redirectToCommunitySubscription } from '../functions'

export type TJoinButtonProps = {
  community: TCommunity
  trackingSource?: string
  eventOrigin?: EVENT_ORIGIN
} & TButtonProps

export const JoinButton: React.FC<TJoinButtonProps> = ({
  community,
  onClick,
  trackingSource,
  eventOrigin,
  children,
  ...otherProps
}) => {
  const { isLogged } = useUser()
  const isHydrated = useIsHydrated()

  const communityJoinParams = {
    course: 'direct' as const,
    origin: eventOrigin,
    source: trackingSource,
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick?.(e)
    redirectToCommunitySubscription(community, isLogged, communityJoinParams)
  }

  return (
    <Button
      onClick={handleClick}
      valueLink={
        !isHydrated && {
          to: {
            pathname: PAGE.COMMUNITY_VIEW_LANDING,
            query: {
              communityId: community.id,
              communitySlug: community.slug,
              ...communityJoinParams,
            },
          },
        }
      }
      data-testid={QA.COMMUNITY_COMMON.JOIN_BUTTON}
      {...otherProps}
    >
      {children || 'trans:community:words.i-subscribe'}
    </Button>
  )
}
