/**
 * KeyboardEvents have:
 * - a keyCode property which is deprecated but well supported
 * - a key preperty that should be used but is not as well supported
 *
 * To use the first as a fallback of the second, they are both stored in arrays.
 *
 * Use it like this:
 * ```javascript
 * if (KEYS.ESC.includes(event.key || event.keyCode)) {
 *   doStuff();
 * }
 * ```
 */
export const KEYS = {
  BACKSPACE: ['Backspace', 8],
  CTRL: ['Control', 16],
  MAJ: ['Shift', 17],
  ALT: ['Alt', 18],
  ENTER: ['Enter', 13, 10],
  ESC: ['Escape', 27],
}
