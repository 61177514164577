import { useEffect } from 'react'

import { useSyncedRef } from '@hozana/hooks/useSyncedRef'

interface IWindowEventMap extends WindowEventMap {
  progress: never
  readystatechange: never
}

interface IDocumentEventMap extends DocumentEventMap {
  progress: never
  readystatechange: never
}

type TAddRemoveEL = (type: string, listener: (e: any) => void, options?: boolean | AddEventListenerOptions) => void
type TTarget = Record<'addEventListener' | 'removeEventListener', TAddRemoveEL>

export function useEventListener<K extends keyof IWindowEventMap>(
  eventName: K,
  handler: (event: IWindowEventMap[K]) => void,
  element?: undefined,
  disabled?: boolean,
  options?: boolean | AddEventListenerOptions,
): void
export function useEventListener<K extends keyof IDocumentEventMap>(
  eventName: K,
  handler: (event: IDocumentEventMap[K]) => void,
  element: Document,
  disabled?: boolean,
  options?: boolean | AddEventListenerOptions,
  debug?: boolean,
): void
export function useEventListener<T extends TTarget>(
  eventName: Parameters<T['addEventListener']>[0],
  handler: Parameters<T['addEventListener']>[1],
  element: T,
  disabled?: boolean,
  options?: boolean | AddEventListenerOptions,
  debug?: boolean,
): void
export function useEventListener(
  eventName: string,
  handler: (event: Record<any, any>) => void,
  element: TTarget = __CLIENT__ ? window : undefined,
  disabled = false,
  options?: boolean | AddEventListenerOptions,
  debug?: boolean,
): void {
  const handlerRef = useSyncedRef(handler)

  useEffect(
    () => {
      // Make sure element supports addEventListener
      if (disabled || !element?.addEventListener) return undefined

      // Create event listener that calls handler function stored in ref
      const eventListener: EventListener = (event) => handlerRef.current(event)

      // Add event listener
      element.addEventListener(eventName, eventListener, options)
      if (debug) {
        console.log('From useEventListener.', `${eventName} event listener added.`)
      }

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener, options)
        if (debug) {
          console.log('From useEventListener.', `${eventName} event listener removed.`)
        }
      }
    },
    [disabled, eventName, element, options, debug, handlerRef], // Re-run if eventName or element changes
  )
}
