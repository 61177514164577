import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import type { TSwipeableBottomSheetProps } from 'react-swipeable-bottom-sheet'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { useTimeout } from '@hozana/hooks/useTimeout'

import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { Div } from 'elements/layout/Div'
import { RoundDiv } from 'elements/layout/RoundDiv'
import { Shadow } from 'elements/layout/Shadow'
import { Space } from 'elements/layout/Space'
import { Icon, TIconProps } from 'elements/media/Icon'

import { ICON } from 'config/icons'

const ShadowRoundDiv = styled(RoundDiv)`
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.fadedBlack20};
`

const SwipeableBottomSheet = dynamic<TSwipeableBottomSheetProps>(() => import('react-swipeable-bottom-sheet'), {
  ssr: false,
  loading: () => null,
})

export type TMobilePopoverProps = {
  /** Function executed on click on the overlay */
  onClickOverlay?: React.MouseEventHandler<HTMLElement>
  /** Optional icon props to be displayed on top of the popover instead of the regular horizontal bar */
  ornament?: {
    icon: ICON
    color: TIconProps['color']
    isAboveCircleDiv?: boolean
    bgCircleDiv?: keyof DefaultTheme['colors']
  }
}

/**
 * ### MobilePopover ###
 */
export const MobilePopover: React.FC<TMobilePopoverProps> = ({ children, onClickOverlay, ornament, ...otherProps }) => {
  const theme = useTheme()
  const [isUnfolded, setIsUnfolded] = useState(false)

  // Wait 200ms before unfolding the popover on mobile so that is is rendered before.
  useTimeout(() => setIsUnfolded(true), 200)

  return (
    <SwipeableBottomSheet
      onChange={onClickOverlay}
      open={isUnfolded}
      style={{ zIndex: theme.zIndex.popover }}
      shadowTip={false}
      marginTop={55} // Header height
      bodyStyle={{ borderRadius: '10px 10px 0 0', padding: '0 0 10px 0' }}
      {...otherProps}
    >
      {ornament ? (
        <>
          <AbsoluteDiv
            left="0"
            right="0"
            top={ornament.isAboveCircleDiv ? '-2.5em' : '-3.5em'}
            align="middleCenter"
            opacity={isUnfolded ? 1 : 0}
          >
            {ornament.isAboveCircleDiv ? (
              <ShadowRoundDiv
                borderColor={ornament.bgCircleDiv}
                size={{ xs: '70px', sm: '70px' }}
                align="middleCenter"
                bg={ornament.bgCircleDiv}
              >
                <Icon name={ornament.icon} color={ornament.color} size="2.5em" />
              </ShadowRoundDiv>
            ) : (
              <Icon name={ornament.icon} color={ornament.color} size="6em" />
            )}
          </AbsoluteDiv>
          <Space h="30px" />
        </>
      ) : (
        <Div align="middleCenter" h="1em">
          <Shadow
            h="5px"
            borderRadius="5px"
            bg="black10"
            maxW="80px"
            w="100%"
            inset
            opacity="0.1"
            y="1px"
            x="0"
            depth="0"
          />
        </Div>
      )}
      {children}
    </SwipeableBottomSheet>
  )
}
