import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { all, delay, put, race, take, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { cookie } from '@hozana/storage/cookies'
import { GTM } from '@hozana/tracking/gtm'

import { openPopin } from 'app/actions'
import { AppActionTypes } from 'app/constants'
import { COOKIES_CONFIG } from 'app/managers/cookies/constants'
import { COOKIES } from 'app/managers/cookies/cookieNames'
import { POPINS } from 'app/managers/popins/constants'
import { TBackToPopin } from 'app/managers/popins/types'

import type { TCommunity } from 'modules/community/types'
import { TUserPrayPopinOptions } from 'modules/pray/popins/UserPrayPopin'

import { PRAY_OBJECT_TYPE, TIME_TO_PRAY } from './constants'
import { TPrayMutationPayload, prayMutation } from './queries'
import { prayUser } from './routines'

/*
 * UTILS
 */

export type TRunUserPrayProps = TUserPrayPopinOptions & {
  trackingSource: string
  backToPopin?: TBackToPopin
}

export function* runUserPray({
  backToPopin,
  trackingSource,
  ...prayPopinOptions
}: TRunUserPrayProps): Generator<unknown, boolean> {
  GTM.trackEvent(GTM.EVENTS.PRIERE_START, { trackingSource })

  yield* put(openPopin(POPINS.UserPrayPopin, prayPopinOptions, backToPopin))

  const { onTimeout } = yield* race({
    onTimeout: delay(TIME_TO_PRAY),
    onClose: take(AppActionTypes.CLOSE_POPIN),
  })

  if (onTimeout) {
    GTM.trackEvent(GTM.EVENTS.PRIERE_FINISHED, { trackingSource })
    return true
  } else {
    GTM.trackEvent(GTM.EVENTS.PRIERE_INTERRUPTED, { trackingSource })
    return false
  }
}

export function* runCommunityPray(community: TCommunity, trackingSource: string): Generator<unknown, boolean> {
  yield* put(openPopin(POPINS.CommunityPrayPopin, { community }))

  GTM.trackEvent(GTM.EVENTS.PRIERE_START, { trackingSource })

  const { onAmen } = yield* race({
    onAmen: take(AppActionTypes.CONFIRM),
    onClose: take(AppActionTypes.CLOSE_POPIN),
  })

  if (onAmen) {
    GTM.trackEvent(GTM.EVENTS.PRIERE_FINISHED, { trackingSource })
    return true
  } else {
    return false
  }
}

/*
 * SAGAS
 */

export type TPrayUserPayload = Pick<TPrayMutationPayload, 'widgetId' | 'guestName'> &
  Omit<TRunUserPrayProps, 'objectType'> & {
    objectType: Exclude<TRunUserPrayProps['objectType'], PRAY_OBJECT_TYPE.USER>
  }

function* prayUserSaga({
  payload: { objectId, objectType, trackingSource, widgetId, guestName, backToPopin },
}: TSagaProps<TPrayUserPayload>) {
  try {
    const hasPrayed = yield* runUserPray({ trackingSource, objectId, objectType, backToPopin })

    if (hasPrayed) {
      cookie.save(COOKIES.hasPrayedForAnIntention, true, COOKIES_CONFIG.ONE_YEAR)
      yield* fetchApiSaga(prayMutation({ objectId, objectType, widgetId, guestName }))
    } else {
      yield* put(prayUser.failure('cancel'))
    }

    yield* put(prayUser.success({ objectId, objectType }))
  } catch (error) {
    yield* put(prayUser.failure(error.message || error))
  }
}

/*
 * LISTENER
 */

export default function* praySagasListener() {
  yield* all([takeLatest(prayUser, prayUserSaga)])
}
