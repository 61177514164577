import React, { HTMLAttributes } from 'react'
import styled, { DefaultTheme, css } from 'styled-components'

import { TResponsiveProps, responsiveStyle } from '@hozana/styling/responsive'

import { ICON } from 'config/icons'

const responsiveProps = { m: 'margin', size: 'font-size' } as const

export type TStyledSpanProps = {
  color?: keyof DefaultTheme['colors']
  fill?: keyof DefaultTheme['colors']
  hoverColor?: keyof DefaultTheme['colors']
  hoverFill?: keyof DefaultTheme['colors']
  align?: 'left' | 'right'
  vAlign?: string | 0 // specific style used for white icon in white background
  light?: boolean // specific style used for white icon in white background;
} & TResponsiveProps<typeof responsiveProps> &
  HTMLAttributes<HTMLSpanElement>

export const StyledSpan = styled.span<TStyledSpanProps>`
  vertical-align: ${(props) => props.vAlign};
  ${(props) =>
    props.align &&
    css`
      float: ${props.align};
    `}

  ${responsiveStyle(responsiveProps)}

  &,
  & span,
  & svg {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    display: inline-block;
  }

  & svg {
    vertical-align: top;
    transition: all 0.1s;
    ${(props) =>
      props.color &&
      css`
        color: ${props.theme.colors[props.color]};
      `}
    ${(props) =>
      props.fill &&
      css`
        fill: ${props.theme.colors[props.fill]};
      `}
    stroke: currentColor;
    stroke-width: 0%;
    pointer-events: none;
  }

  &:hover svg {
    ${(props) =>
      props.hoverColor &&
      css`
        color: ${props.theme.colors[props.hoverColor]};
      `}
    ${(props) =>
      props.hoverFill &&
      css`
        fill: ${props.theme.colors[props.hoverFill]};
      `}
  }
  & svg {
    ${(props) =>
      props.light &&
      css`
        stroke: #ccc;
        stroke-width: 7%;
      `}
  }
`
export type TIconProps = {
  name: ICON
  label?: string
} & TStyledSpanProps

/**
 * ### Icon ###
 */
export const Icon: React.FC<TIconProps> = ({ name, label, size = '1.5em', vAlign = 'middle', ...otherProps }) => (
  <StyledSpan size={size} vAlign={vAlign} {...otherProps} aria-label={label}>
    <svg>
      <use href={`/svg/icons/${name}.svg#${name}`}></use>
    </svg>
  </StyledSpan>
)
