export enum COMMUNITY_ACTIVITY {
  ONGOING = 'ongoing',
  COMING_SOON = 'coming_soon',
  ACTIVE = 'active',
  TO_REDISCOVER = 'to_rediscover',
  INACTIVE = 'inactive',
}

export enum COMMUNITY_LIFETIME {
  RETREAT = 'retreat',
  NOVENA = 'novena',
  EVERLASTING = 'everlasting',
}

export enum COMMUNITY_PUBLICATION_FREQUENCY {
  SPORADICALLY = 'sporadically',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  UNKNOWN = 'unknown',
}

export enum COMMUNITY_FORMAT {
  TEXTUAL = 'textual',
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum COMMUNITY_STATUS {
  DRAFT = 'draft',
  CHECK = 'check',
  // TODO: check if we can delete these statuses
  VALID = 'valid', // unused
}

// displayMode enum
export enum COMMUNITY_DISPLAYMODE {
  TO_REWORK = 'to_rework',
  UNTREATED = 'untreated',
  DISPLAYED = 'displayed',
  HIDDEN = 'hidden',
  FAVORITE = 'favorite',
}

export enum COMMUNITY_FILTERS {
  ON_GOING_ACTIVE = 'on_going_active',
  COMING_SOON = 'coming_soon',
  FINISHED_INACTIVE = 'finished_inactive',
  CHECK = 'check',
  DRAFT = 'draft',
  ALL = 'all',
}

export enum COMMUNITY_AUTHORIZATIONS {
  DEFAULT = 'default',
  CAN_CREATE_PUBLICATIONS = 'canCreatePublications',
  CAN_EDIT_PUBLICATIONS = 'canEditPublications',
  CAN_EDIT_COMMUNITY = 'canEdit',
  CAN_MANAGE_ROLES = 'canManageRoles',
  CAN_SEND_ANNOUNCEMENTS = 'canSendAnnouncements',
  CAN_DELETE = 'canDelete',
  CAN_VIEW_STATISTICS = 'canViewStatistics',
}

export enum COMMUNITY_ACTIONS {
  VIEW_COMMUNITY = 'VIEW',
  CREATE_PUBLICATION = 'CREATE_PUBLICATION',
  EDIT_COMMUNITY = 'EDIT_COMMUNITY',
  MANAGE_ROLES = 'MANAGE_ROLES',
  SEND_ANNOUNCEMENTS = 'SEND_ANNOUNCEMENTS',
  DELETE = 'DELETE',
  VIEW_COMMENTS = 'VIEW_COMMENTS',
  VIEW_STATISTICS = 'VIEW_STATISTICS',
}

export enum COMMUNITY_ROLE {
  MEMBER = 'member',
  ANIMATOR = 'animator',
  ADMINISTRATOR = 'administrator',
  CREATOR = 'creator',
}

export const COMMUNITY_AVATAR_SIZES = {
  SMALL: 60,
  MEDIUM: 70,
  BIG: 285,
} as const

export enum ALGO {
  RANDOM = 'Random',
  DEFAULT = 'Default',
  WIZARD = 'Wizard',
}

export enum PUBLISH_ANNOUNCEMENT_AS {
  USER = 'user',
  CREATOR = 'creator',
  ADMIN = 'admin',
}

export const LIST_ITEMS_PER_PAGE = 6

export enum COMMUNITY_PAGE_TABS {
  DESCRIPTION = 'description',
  PUBLICATIONS = 'publications',
  SCHEDULED_AND_DRAFT = 'scheduledAndDrafts',
}

export enum RETREAT_STATUS {
  /** today is before the community fromDate */
  COMING_SOON = 'comingSoon',
  /** today is between community fromDate and toDate */
  ONGOING = 'ongoing',
  /** today is after community toDate */
  TO_REDISCOVER = 'toRediscover',
  /** Scheduled by the user, but not started yet (today is before user subscription fromDate) */
  SCHEDULED = 'scheduled',
  /** Scheduled by the user, and started (today is between subscription fromDate and toDate) */
  RELIVING = 'reliving',
  /** already done one time the the user (even if he unsuscribed afterwards) */
  FINISHED = 'finished',
}
