import { type ComponentType } from 'react'
import { type StyledComponentPropsWithRef, type StyledConfig } from 'styled-components'

const FORBIDDEN_PROPS = [
  'fontSize',
  'margin',
  'padding',
  'width',
  'maxHeight',
  'align',
  'lineHeight',
  'vAlign',
  'firstUpper',
  'italic',
  'bold',
  'inline',
  'upper',
  'size',
  'right',
  'noBorder',
  'borderRadius',
  'meta',
  'forwardedRef',
  'innerRef',
  'src',
  'loading',
  'wrap',
]

export const defaultStyledConfig = <
  Props extends TAnyProps,
  C extends keyof JSX.IntrinsicElements | ComponentType<any>,
>(
  whiteListProps: string[] = [],
): StyledConfig<StyledComponentPropsWithRef<C> & Props> => ({
  shouldForwardProp: (prop: string, defaultValidator: (prop: string) => boolean) =>
    (!FORBIDDEN_PROPS.includes(prop) || whiteListProps.includes(prop)) && defaultValidator(prop),
})
