import styled, { DefaultTheme, css } from 'styled-components'

import { defaultStyledConfig } from '@hozana/styling/config'

import { BUTTON_BASE_CLASSNAME } from 'elements/constants'

import { RippledButton, TRippledButtonProps } from './RippleButton'

export type TButtonColor = Record<
  'color' | 'bg' | 'borderColor' | 'hoverColor' | 'hoverBg' | 'hoverBorderColor',
  keyof DefaultTheme['colors']
> &
  Partial<Record<'focusColor' | 'focusBorderColor' | 'focusBg', keyof DefaultTheme['colors']>>

export type TButtonColorName =
  | 'greyTransparent'
  | 'darkBlue'
  | 'white'
  | 'whiteYellow'
  | 'yellow'
  | 'orangeLight'
  | 'black30'
  | 'greyLight'
  | 'darkRed'

export const buttonColors: Record<TButtonColorName, TButtonColor> = {
  greyTransparent: {
    color: 'black50',
    bg: 'transparent',
    borderColor: 'transparent',
    hoverColor: 'black70',
    hoverBg: 'fadedBlack10',
    hoverBorderColor: 'transparent',
  },
  darkBlue: {
    // default
    color: 'white',
    bg: 'blue',
    borderColor: 'blue',
    hoverColor: 'white',
    hoverBg: 'darkBlue',
    hoverBorderColor: 'darkBlue',
  },
  white: {
    color: 'darkBlue',
    bg: 'white',
    borderColor: 'white',
    hoverColor: 'white',
    hoverBg: 'transparent',
    hoverBorderColor: 'white',
  },
  yellow: {
    color: 'white',
    bg: 'yellow',
    borderColor: 'yellow',
    hoverColor: 'white',
    hoverBg: 'orange',
    hoverBorderColor: 'orange',
  },
  whiteYellow: {
    color: 'yellow',
    bg: 'whiteOrange',
    borderColor: 'whiteOrange',
    hoverColor: 'white',
    hoverBg: 'orange',
    hoverBorderColor: 'orange',
  },
  orangeLight: {
    color: 'yellow',
    bg: 'whiteOrange',
    borderColor: 'whiteOrange',
    hoverColor: 'white',
    hoverBg: 'orange',
    hoverBorderColor: 'orange',
  },
  black30: {
    color: 'white',
    bg: 'black30',
    borderColor: 'black30',
    hoverColor: 'black30',
    hoverBg: 'transparent',
    hoverBorderColor: 'black30',
  },
  greyLight: {
    color: 'black50',
    bg: 'black10',
    borderColor: 'black10',
    hoverColor: 'black70',
    hoverBg: 'black30',
    hoverBorderColor: 'black30',
  },
  darkRed: {
    color: 'white',
    bg: 'darkRed',
    borderColor: 'darkRed',
    hoverColor: 'darkRed',
    hoverBg: 'transparent',
    hoverBorderColor: 'darkRed',
  },
}

export type TBaseButtonProps = Omit<TRippledButtonProps, 'color'> & {
  reverseColors?: boolean
  colors?: TButtonColorName
  disabled?: boolean
  loading?: boolean
} & Partial<TButtonColor>

export const BaseButton = styled(RippledButton)
  .withConfig<TBaseButtonProps>(defaultStyledConfig())
  .attrs<TBaseButtonProps, TBaseButtonProps & { buttonColor: TButtonColor }>(({ className, colors = 'darkBlue' }) => ({
    className: [BUTTON_BASE_CLASSNAME, className].join(' '),
    buttonColor: buttonColors[colors],
  }))`
  ${(p) => css`
    color: ${p.theme.colors[
      p.reverseColors ? p.hoverColor || p.buttonColor?.hoverColor : p.color || p.buttonColor?.color
    ]};
    background-color: ${p.theme.colors[
      p.reverseColors ? p.hoverBg || p.buttonColor?.hoverBg : p.bg || p.buttonColor?.bg
    ]};
    border-color: ${p.theme.colors[
      p.reverseColors
        ? p.hoverBorderColor || p.buttonColor?.hoverBorderColor
        : p.borderColor || p.buttonColor?.borderColor
    ]};

    ${!(p.disabled || p.loading) &&
    css`
      /* Hover colors */
      &:hover {
        color: ${p.theme.colors[
          p.reverseColors ? p.color || p.buttonColor?.color : p.hoverColor || p.buttonColor?.hoverColor
        ]};
        background-color: ${p.theme.colors[
          p.reverseColors ? p.bg || p.buttonColor?.bg : p.hoverBg || p.buttonColor?.hoverBg
        ]};
        border-color: ${p.theme.colors[
          p.reverseColors
            ? p.borderColor || p.buttonColor?.borderColor
            : p.hoverBorderColor || p.buttonColor?.hoverBorderColor
        ]};
      }

      /* Focus colors */
      &:focus {
        color: ${p.theme.colors[
          p.reverseColors ? p.color || p.buttonColor?.color : p.focusColor || p.hoverColor || p.buttonColor?.hoverColor
        ]};
        background-color: ${p.theme.colors[
          p.reverseColors ? p.bg || p.buttonColor?.bg : p.focusBg || p.hoverBg || p.buttonColor?.hoverBg
        ]};
        border-color: ${p.theme.colors[
          p.reverseColors
            ? p.borderColor || p.buttonColor?.borderColor
            : p.focusBorderColor || p.hoverBorderColor || p.buttonColor?.hoverBorderColor
        ]};
      }
    `}
  `}
`
