import { shallowEqual, useSelector } from 'react-redux'

import type { TEntities, TEntityType } from 'config/entities'
import type { TState } from 'config/types'

import { selectEntity } from '../selectors'

export const useEntity = <ET extends TEntityType>(
  entityType: ET,
  entityId: number,
  equalityFn = shallowEqual as (left: TEntities[ET], right: TEntities[ET]) => boolean,
): TEntities[ET] | undefined => useSelector((state: TState) => selectEntity(state, entityType, entityId), equalityFn)
