import type { TQueryConstructor } from '@hozana/api/types'

export const uploadFileMutation: TQueryConstructor<{ file?: string | Blob }, { id: number; link: string }> = ({
  file,
} = {}) => ({
  queryKey: `uploadFile`,
  url: `/file/draft`,
  method: 'POST',
  file,
})
