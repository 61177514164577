import { fileUrlToUrl, translateUrl, urlToFileUrl } from 'next-translate-routes'
import { parse as parseQuery } from 'querystring'

import { deepEqual } from '@hozana/utils/functions/objects'

import { PAGE } from 'routes/constants'

import type { TRouter, TUrlObject } from '.'

export { fileUrlToUrl, urlToFileUrl }

export type TUrlQueryValue = string | number | boolean
type TFirstValue<T extends TValueOrArrayOfValues<string | boolean | number>> = T extends (string | boolean | number)[]
  ? T[0]
  : T

export type TParsedUrlQuery = Record<string, TValueOrArrayOfValues<TUrlQueryValue>>

export function buildQueryString(params: TAnyProps) {
  return Object.keys(params || {})
    .filter((key) => params[key] !== null && params[key] !== undefined)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((keyInArray) => `${key}[]=${encodeURIComponent(keyInArray)}`).join('&')
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&')
}

/**
 * Get query values from React router location object
 * @param location
 * @returns object
 */
export const getQueryValues = (location: Location): TParsedUrlQuery => {
  if (!location || location.search.length === 0) return {}

  const hashes = location.search.slice(location.search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=') as [string, string]
    let value: TValueOrArrayOfValues<TUrlQueryValue> = decodeURIComponent(val)
    if (value === 'undefined') {
      value = true
    }
    if (typeof params[key] !== 'undefined') {
      if (Array.isArray(params.key)) {
        value = [...(params[key] as TUrlQueryValue[]), value]
      } else {
        value = [params[key] as TUrlQueryValue, value]
      }
    }
    return {
      ...params,
      [key]: value,
    }
  }, {} as Record<string, TValueOrArrayOfValues<TUrlQueryValue>>)
}

/** Get parsed query from TUrlObject query */
export const getParsedQuery = (query: TUrlObject['query']) => (typeof query === 'string' ? parseQuery(query) : query)

export function getFirstQueryValue<
  QA extends TValueOrArrayOfValues<TUrlQueryValue> | TParsedUrlQuery =
    | TValueOrArrayOfValues<TUrlQueryValue>
    | TParsedUrlQuery,
>(
  queryArg: QA,
): QA extends TValueOrArrayOfValues<string | boolean | number>
  ? TFirstValue<QA>
  : QA extends TParsedUrlQuery
  ? {
      [K in keyof QA]: TFirstValue<QA[K]>
    }
  : never

/**
 * A query parameter can be assigned several times and becomes a array of string.
 * This function makes sure to get a single value.
 *
 * @param queryArg Either a query value (string or array), either a query url object
 * @return The first value of the array if it is an array, the bare value if not,
 * as a value if the argument is a value, as an object if it is an object
 * */
export function getFirstQueryValue(
  queryArg: TValueOrArrayOfValues<TUrlQueryValue> | TParsedUrlQuery,
): TUrlQueryValue | Record<string, TUrlQueryValue> {
  if (Array.isArray(queryArg)) return queryArg[0]
  if (typeof queryArg === 'object')
    return Object.entries(queryArg).reduce(
      (result, [queryKey, queryValues]) => ({
        ...result,
        [queryKey]: getFirstQueryValue(queryValues),
      }),
      {},
    )
  return queryArg
}

export const removeQueryValuesFromUrl = (
  { pathname, query, replace }: Pick<TRouter, 'pathname' | 'query' | 'replace'>,
  keysToRemove: string[],
): Promise<boolean> => {
  if (__SERVER__) {
    return Promise.resolve(false)
  }
  if (keysToRemove.length === 0) {
    return Promise.resolve(true)
  }
  const newQuery = Object.entries(query).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(!keysToRemove.includes(key) && { [key]: value }),
    }),
    {},
  )
  // Avoid any useless replace as it update all the router context
  if (deepEqual(newQuery, query)) {
    return Promise.resolve(true)
  }

  return replace({ pathname, query: newQuery }, undefined, { shallow: true })
}

export const getFullUrl = (url: TUrlObject, locale: string): string =>
  `${CONF_KEYS.HOME}${translateUrl(url, locale, { format: 'string' })}`

/** Returns the redirectUrl from the redirectTo query parameter */
export const getRedirectUrl = (
  redirectTo: string | string[] | undefined,
  locale: string,
  defaultUrl: TUrlObject = { pathname: PAGE.USER_FEED },
) => {
  if (redirectTo) {
    return translateUrl(getFirstQueryValue(redirectTo), locale, { format: 'object' }) as TUrlObject
  }
  return defaultUrl
}

/**
 * Test if a path match one of the given patterns
 */
export const pathIsOrStartsWith = (path?: string, ...patterns: (string | RegExp)[]): boolean =>
  path &&
  patterns.some((pattern) =>
    typeof pattern === 'string' ? path === pattern || path.startsWith(`${pattern}/`) : pattern.test(path),
  )

export const isInternalUrl = (url: string) =>
  url?.startsWith(CONF_KEYS.HOME) || url?.startsWith('/') || url?.startsWith('#')

export { translateUrl }
