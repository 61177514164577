import React from 'react'

import type { TCommunity } from 'modules/community/types'
import type { TIntention } from 'modules/intention/types'
import type { TPublication } from 'modules/publication/types'

import { SHARE_OBJECTS, SHARE_TYPES } from './constants'

export type TShareContext = {
  generateInviteLink: (shareType: SHARE_TYPES, objectToShare?: SHARE_OBJECTS) => Promise<string>
  generateEmailBody: (url: string, shareType: SHARE_TYPES) => Promise<string>
  track: (eventType: SHARE_TYPES) => void
  trackingSource: string
  objectToShare: SHARE_OBJECTS
  community?: TCommunity
  publication?: TPublication
  intention?: TIntention
  utmContent?: string
  shareOptions: {
    tomorrow?: boolean
  }
}

export const ShareContext = React.createContext<TShareContext>({
  generateInviteLink: () => Promise.resolve(''),
  generateEmailBody: () => Promise.resolve(''),
  track: () => {
    /* Empty */
  },
  trackingSource: null,
  objectToShare: null,
  shareOptions: {},
})
