import React from 'react'
import styled from 'styled-components'

import { QA } from '@hozana/tests/constants'

import { ICON } from 'config/icons'

import { Icon, TIconProps } from '../media/Icon'

export const StyledButton = styled.button`
  display: block;
  line-height: 0;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.black30};
  transition: all 0.2s;
  cursor: pointer;
  padding: 2px;
  margin: 0 0 0 5px;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.black50};
  }
`

export type THelpProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
} & Pick<TIconProps, 'size'>

export const Help: React.FC<THelpProps> = ({ onClick, size = '1.9em' }) => (
  <StyledButton onClick={onClick} type="button" data-testid={QA.COMMON.HELP_LINK}>
    <Icon name={ICON.HELP_ON} size={size} />
  </StyledButton>
)
