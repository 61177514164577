import { cookie } from '@hozana/storage/cookies'
import { toTitleCase } from '@hozana/utils/functions/strings'

import { addFlash } from 'app/actions'
import { COOKIES } from 'app/managers/cookies/cookieNames'

import { EVENTS, GTM_DATALAYER_NAME, LOGGED, TGTMVariables } from './constants'
import { getCookie } from './functions'
import { Sentry } from './sentry'

type TGTMElements = TGTMVariables & {
  event?: EVENTS
}

export type TGTMEventObjet = {
  event: EVENTS
} & TGTMVariables

/**
 * Get Google Analytics Client ID.
 *
 * Google Analytics Client ID is retrieved from '_ga' cookie. The '_ga' cookie
 * may be undefined if Google Analytics was blocked by an Ad blocker or if
 * user didn't allow cookies.
 *
 * @see https://www.journaldunet.fr/web-tech/tutoriels-analytics/1203109-universal-analytics-quel-est-le-client-id-pour-envoyer-des-mesures-a-google-analytics/
 *
 * @param defaultValue - The default value to return when cookie is not set
 *
 * @return gaClientId - The Google Analytics Client ID
 */
export const getGoogleAnalyticsClientId = (defaultValue: string | null = null): string | null =>
  getCookie('_ga', defaultValue)

const gtmPush = (...elements: TGTMElements[]): void => {
  if (__CLIENT__) {
    if (!window[GTM_DATALAYER_NAME]) {
      Sentry.captureMessage(`window.${GTM_DATALAYER_NAME} undefined`)
      window[GTM_DATALAYER_NAME] = [
        {
          'gtm.start': new Date().getTime(),
          undefinedOnStart: true,
          event: 'gtm.js',
        },
      ]
    }
    window[GTM_DATALAYER_NAME].push(...elements)
  }
}

/**
 * trackEvent send an event to Google Tag Manager
 * @param event object : the event config from the GTM.EVENTS enum
 * @param variables object: some variables to send along with the event
 * @param nativeEvent object : browser event like a click or touch that has to be delayed after sending the tracking
 *
 * - to track in a "normal" context : configure the event in GTM, add its name in the GTM.EVENTS enum then use trackEvent passing only the event in parameter
 * - to track from a link onClick, pass the event AND the resulting nativeEvent from the onClick
 * - to track an event with a special value, create a new GTM variable, and send it along with the event
 * - to track with a recurring dynamic label (reuse the label along a flow - login or signup for instance),
 *    create a new GTM variable, and send it along with the first event in the variables parameter
 * */
const trackEvent = (
  event: EVENTS | TGTMEventObjet,
  variables: TGTMElements = {},
  nativeEvent?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  preserveCase = false,
): void => {
  if (cookie.load<{ gtm: boolean }>(COOKIES.debugMode)?.gtm) {
    window.store?.dispatch?.(
      addFlash({ message: 'GTM Event', subMessage: JSON.stringify(event, null, 4), status: 'debug' }),
    )
  }

  if (nativeEvent?.currentTarget && 'href' in nativeEvent.currentTarget && nativeEvent.currentTarget.href) {
    // Add small delay before navigating to ensure request to GA is not cancelled by the browser
    const href = nativeEvent.currentTarget.href
    nativeEvent.persist()
    nativeEvent.preventDefault()
    nativeEvent.stopPropagation()
    setTimeout(() => {
      window.location.href = href
    }, 200)
  }

  gtmPush({
    ...(typeof event === 'string' ? { event } : event),
    ...(preserveCase
      ? variables
      : Object.entries(variables || {}).reduce(
          (titleCasedVariables, [key, value]) => ({
            ...titleCasedVariables,
            [key]: typeof value === 'string' ? toTitleCase(value) : value,
          }),
          {},
        )),
  })
}

const pageView = (path: string, pageTitle: string): void =>
  trackEvent(EVENTS.PAGEVIEW, pageTitle ? { pageTitle, path } : { path }, null, true)

const trackLogin = (): void => {
  gtmPush({ isLoggedIn: LOGGED.IN })
}

const trackLogout = (shouldSendEvent?: boolean): void => {
  gtmPush({
    ...(shouldSendEvent ? { event: EVENTS.LOGOUT } : {}),
    isLoggedIn: LOGGED.OUT,
  })
}

export const GTM = {
  pageView,
  push: gtmPush,
  trackEvent,
  trackLogin,
  trackLogout,
  EVENTS,
}
