import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'

import { AbsoluteDiv, TAbsoluteDivProps } from 'elements/layout/AbsoluteDiv'

export type TFixedDivProps = TAbsoluteDivProps &
  Pick<TAbsoluteDivProps['style'], 'pointerEvents'> & {
    scroll?: boolean
  }

export const FixedDiv = styled(AbsoluteDiv).attrs<TFixedDivProps, TFixedDivProps>(
  ({ pointerEvents, position = 'fixed', scroll, style }) => ({
    style: mergeStyles(style, {
      position,
      pointerEvents,
      ...(scroll && { overflow: 'auto' }),
    }),
  }),
)``
