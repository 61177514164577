import 'regenerator-runtime/runtime'

import { fetchApiSaga } from '@hozana/api/sagas'
import { formatSubmissionError } from '@hozana/form/functions'
import { runRoutine } from '@hozana/routines/runRoutine'
import { all, put, select, takeLatest } from '@hozana/sagas'
import type { TSagaProps } from '@hozana/sagas/types'
import { Sentry } from '@hozana/tracking/sentry'

import { addFlash, openPopin } from 'app/actions'
import { POPINS } from 'app/managers/popins/constants'
import { runInput } from 'app/sagas'

import { saveTokenCookie } from 'modules/auth/functions'
import { loginMutation } from 'modules/auth/queries'
import { logout } from 'modules/auth/routines'
import {
  TDeletePresubscriberMutationPayload,
  TEditAccountSettingsMutationPayload,
  TIgnoreExhortationMutationPayload,
  deleteAccountMutation,
  deletePresubscriberMutation,
  editAccountSettingsMutation,
  ignoreExhortationMutation,
  saveWizardMutation,
} from 'modules/user/queries'
import { selectUser } from 'modules/user/selectors'
import type { TMe } from 'modules/user/types'

import { getCommunitiesQuery } from '../community/queries'
import { deleteAccount, deletePresubscriber, editAccountSettings, ignoreExhortation, saveWizard } from './routines'

export type TEditAccountSettingsPayload = TEditAccountSettingsMutationPayload & {
  socialConnect?: boolean
  userId?: number
}

function* editAccountSettingsSaga({
  payload: {
    socialConnect,
    email,
    notificationEmail,
    oldPassword,
    password,
    lang,
    name,
    title,
    birthdate,
    location,
    userId,
  },
}: TSagaProps<TEditAccountSettingsPayload>) {
  try {
    if (password) {
      if (socialConnect) {
        yield* fetchApiSaga(editAccountSettingsMutation({ password }))
      } else {
        yield* fetchApiSaga(editAccountSettingsMutation({ oldPassword, password }))
      }

      // Log user with new informations
      let data

      try {
        data = yield* fetchApiSaga(loginMutation({ email, password, method: null }))
      } catch (loginError) {
        Sentry.captureMessage('Login error in editAccountSettingsSaga', 'debug', {
          hasEmail: !!email,
          message: typeof loginError === 'object' && loginError.message,
        })
        throw loginError
      }

      saveTokenCookie(data.token)
    } else if (email || notificationEmail) {
      const user = yield* select((state) => selectUser(state, userId))
      const oldNotificationEmail = user?.notificationEmail

      if (!user) {
        throw new Error('user:common.form.error.fail')
      }

      if (notificationEmail !== oldNotificationEmail) {
        // notificationEmail
        yield* fetchApiSaga(editAccountSettingsMutation({ notificationEmail }))
      } else {
        // email
        yield* fetchApiSaga(editAccountSettingsMutation({ email }))
      }
    } else if (lang || name || title || birthdate !== undefined || location !== undefined) {
      yield* fetchApiSaga(
        editAccountSettingsMutation({
          lang,
          name,
          title,
          location,
          birthdate,
        }),
      )
    }
    yield* put(addFlash({ message: 'user:user.settings.applied' }))
    yield* put(editAccountSettings.success())
  } catch (error) {
    // On profilePage, the form is closed on submit, so we can show errors by flashMessage
    if (name || title || birthdate || location) {
      yield* put(addFlash({ message: error.message, status: 'error' }))
    }
    yield* put(editAccountSettings.failure(formatSubmissionError(error.message)))
  }
}

function* deleteAccountSaga() {
  try {
    const deleteConfirm = yield* runInput(
      'trans:user:user.settings.account.delete.delete',
      'trans:user:user.settings.account.delete.confirm',
    )

    if (!deleteConfirm) {
      // Avoid the display of error if user close the InputPopin without delete his account
      yield* put(deleteAccount.failure())
    } else if (
      typeof deleteConfirm === 'string' &&
      ['SUPPRIMER', 'DELETE', 'SUPRIMIR', 'DELETAR'].includes(deleteConfirm.trim())
    ) {
      yield* fetchApiSaga(deleteAccountMutation())
      yield* put(deleteAccount.success())
      yield* runRoutine(logout, { isDeleted: true })
    } else {
      throw new Error('user:user.settings.account.delete.error')
    }
  } catch ({ message }) {
    yield* put(addFlash({ message, status: 'error' }))
    yield* put(deleteAccount.failure(formatSubmissionError(message)))
  }
}

function* ignoreExhortationSaga({ payload: { email } }: TSagaProps<TIgnoreExhortationMutationPayload>) {
  try {
    yield* fetchApiSaga(ignoreExhortationMutation({ email }))

    yield* put(addFlash({ message: 'user:home.exhortation.ignore.success' }))
    yield* put(ignoreExhortation.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(ignoreExhortation.failure(error.message))
  }
}

function* deletePresubscriberSaga({ payload: { email } }: TSagaProps<TDeletePresubscriberMutationPayload>) {
  try {
    yield* fetchApiSaga(deletePresubscriberMutation({ email }))

    yield* put(openPopin(POPINS.PresubscriberDeletedPopin))

    yield* put(deletePresubscriber.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(deletePresubscriber.failure(error.message))
  }
}

function* saveWizardSaga({ payload: wizard }: TSagaProps<TMe['wizard']>) {
  try {
    yield* fetchApiSaga(saveWizardMutation(wizard))

    // Just refetch communities to match new wizard rules
    yield* fetchApiSaga(getCommunitiesQuery({ page: 1, limit: 39 }))

    yield* put(saveWizard.success())
  } catch (error) {
    yield* put(addFlash({ message: error.message, status: 'error' }))
    yield* put(saveWizard.failure(error.message))
  }
}

/*
 * LISTENER
 */

export default function* userSagasListener() {
  yield* all([
    takeLatest(editAccountSettings, editAccountSettingsSaga),
    takeLatest(deleteAccount, deleteAccountSaga),
    takeLatest(ignoreExhortation, ignoreExhortationSaga),
    takeLatest(deletePresubscriber, deletePresubscriberSaga),
    takeLatest(saveWizard, saveWizardSaga),
  ])
}
