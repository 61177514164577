import type { MutableRefObject } from 'react'
import { FormErrors, SubmissionError } from 'redux-form'
import type { FieldArrayFieldsProps as ReduxFieldArrayFieldsProps } from 'redux-form'

/**
 * Format the error message so that it can be handled by Redux-Form.
 *
 * The fieldKeysMap paramters allows to map the field key returned by the API (eg. name.invalid) with the field key
 * used by Redux-Form, when they are different.
 */
export const formatSubmissionError = (
  errorMessage: string | FormErrors,
  fieldKeysMap: Record<string, string> = {},
  prefix = '',
) => {
  let formattedMessage: FormErrors
  if (typeof errorMessage === 'string') {
    const [apiKey] = errorMessage.split('.')
    const fieldKey = fieldKeysMap[apiKey] || apiKey
    const [translationKey, namespace] = errorMessage.split(':').reverse()
    const message = `trans:${!namespace || namespace === 'trans' ? 'common' : namespace}:${
      prefix ? prefix + '.' : ''
    }${translationKey}`
    formattedMessage = {
      _error: message,
      [fieldKey]: message,
    }
  } else {
    formattedMessage = errorMessage
  }
  return new SubmissionError(formattedMessage)
}

export interface FieldArrayFieldsProps<FieldValue> extends ReduxFieldArrayFieldsProps<FieldValue> {
  splice(index: number, removeNum: number | null, value?: FieldValue): void
}

const restoreCursor = (
  element: HTMLInputElement | HTMLTextAreaElement,
  {
    selectionStart,
    selectionEnd,
    selectionDirection,
  }: Pick<HTMLInputElement, `selection${'Start' | 'End' | 'Direction'}`>,
) => {
  if (element) {
    element.selectionStart = selectionStart
    element.selectionEnd = selectionEnd
    element.selectionDirection = selectionDirection
  }
}

type TAnyInputElementRef = MutableRefObject<HTMLInputElement | HTMLTextAreaElement | { element: HTMLTextAreaElement }>

/**
 * Save cursor position before callback execution, then restore it after
 *
 * @param callback A callback function that can return a promise
 * @param refs An array of refs. A ref can be an input ref, a textarea ref, or a froala ref
 * @returns An enhanced callback of the same type as the given callback
 */
export const withRestoreCursor = <F extends (...args: any) => any>(callback: F, refs: TAnyInputElementRef[]) =>
  ((...args) => {
    const cursorPositions = refs.map(({ current = {} as HTMLTextAreaElement }) => {
      const { selectionStart, selectionEnd, selectionDirection } = 'element' in current ? current.element : current
      return { selectionStart, selectionEnd, selectionDirection }
    })
    const restoreCursorPosition = ({ current = {} as HTMLTextAreaElement }: TAnyInputElementRef, index: number) => {
      restoreCursor('element' in current ? current.element : current, cursorPositions[index])
    }

    // Callback execution
    const cbResult = callback(...args)
    if (cbResult instanceof Promise) {
      return new Promise((resolve, reject) => {
        cbResult
          .then((result) => {
            refs.forEach(restoreCursorPosition)
            resolve(result)
          })
          .catch(reject)
      })
    }
    refs.forEach(restoreCursorPosition)
    return cbResult
  }) as F
