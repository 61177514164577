import React, { ComponentProps } from 'react'
import { Trans } from 'react-i18next'

import { getI18nKey } from '@hozana/intl/functions'

export type TTranslateProps = ComponentProps<typeof Trans>

export const Translate: typeof Trans = ({ children, ...otherProps }) => {
  const key = getI18nKey(
    typeof children === 'string'
      ? children
      : Array.isArray(children) && children.length === 1 && typeof children[0] === 'string'
      ? children[0]
      : undefined,
  )

  if (!key) {
    return <>{children}</>
  }

  return <Trans {...otherProps}>{key}</Trans>
}
