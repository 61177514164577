// To avoid an error with "&sect" on links, we use "anchor"
export const ACCORDION_SECTION_PARAM_NAME = 'anchor'

export enum GENERAL_SETTINGS {
  DIGEST = 'digest',
  NEWSLETTER = 'newsletter',
  ON_COMMENT_ANSWER = 'on_comment_answer',
  ON_COMMUNITY_JOINED = 'on_community_joined',
  ON_PRIVATE_MESSAGE = 'on_private_message',
  ON_INTENTION_FINISHED = 'on_intention_finished',
  ON_INTENTION_WATCHMAN = 'on_intention_watchman',
  ON_USER_CATCHUP = 'on_user_catchup',
  ON_GODSON_JOINED = 'on_godson_joined',
  ON_RETREAT_ENDED = 'on_retreat_ended',
}

export const GENERAL_SETTINGS_TRANSLATIONS = {
  [GENERAL_SETTINGS.DIGEST]: 'trans:user:user.notifications.digest.digest',
  [GENERAL_SETTINGS.NEWSLETTER]: 'trans:user:user.notifications.newsletter.newsletter',
  [GENERAL_SETTINGS.ON_COMMUNITY_JOINED]: 'trans:user:user.notifications.on_community_joined',
  [GENERAL_SETTINGS.ON_PRIVATE_MESSAGE]: 'trans:user:user.notifications.on_private_message',
  [GENERAL_SETTINGS.ON_COMMENT_ANSWER]: 'trans:user:user.notifications.on_comment_answer',
  [GENERAL_SETTINGS.ON_INTENTION_FINISHED]: 'trans:user:user.notifications.on_intention_finished',
  [GENERAL_SETTINGS.ON_USER_CATCHUP]: 'trans:user:user.notifications.on_user_catchup',
  [GENERAL_SETTINGS.ON_RETREAT_ENDED]: 'trans:user:user.notifications.on_retreat_ended',
  [GENERAL_SETTINGS.ON_GODSON_JOINED]: 'trans:user:user.notifications.on_godson_joined',
  [GENERAL_SETTINGS.ON_INTENTION_WATCHMAN]: 'trans:user:user.notifications.watchman_reminder',
}

export const DEFAULT_AVATAR_IMG = '/img/hozana_img_avatar_default.png'

export enum GENDER {
  M = 'm',
  F = 'f',
}

export enum WIZARD_CHAPTER {
  WELCOME = 'welcome',
  THEMES = 'themes',
  TOPICS = 'topics',
  FORMATS = 'formats',
  PROFILE = 'profile',
}

export enum SETTINGS_SECTION_ID {
  EMAIL = 'email',
  PASSWORD = 'password',
  LANG = 'lang',
  ACCOUNT = 'account',
}
export const getMeQueryKey = 'getMe'
