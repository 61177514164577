import type { MutableRefObject, Ref, RefCallback } from 'react'

/**
 * Merge refs that can be a MutableRefObject or a RefCallBack
 * See https://julesblom.com/writing/ref-callback-use-cases
 * #mergeRefs
 */
export const mergeRefs =
  <T>(...refs: Ref<T>[]): RefCallback<T> =>
  (element: T | null) =>
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref) {
        ;(ref as MutableRefObject<T>).current = element
      }
    })
