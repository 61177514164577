import Bowser from 'bowser'

import { EFeatures } from '@hozana/screen/constants'
import { detectFeature } from '@hozana/screen/functions/detectFeature'
import { isIOSWebview } from '@hozana/screen/functions/isIOSWebview'

// https://stackoverflow.com/questions/36230214/keys-property-doesnt-exist-on-my-pushregistration-object
export const arrayBufferToString = (key: ArrayBuffer) => btoa(String.fromCharCode.apply(null, new Uint8Array(key)))

export function getNotificationsAvailable() {
  if (isIOSWebview()) {
    return false
  }
  return __CLIENT__ && detectFeature(EFeatures.SW) && 'Notification' in window && 'PushManager' in window
}

export function getDeviceInfo(props?: { userAgent?: string; getObject: true }): Bowser.Parser.ParsedResult
export function getDeviceInfo(props?: { userAgent?: string; getObject?: false }): string
export function getDeviceInfo({
  userAgent = __CLIENT__ ? window.navigator.userAgent : '',
  getObject,
}: { userAgent?: string; getObject?: boolean } = {}) {
  const data = Bowser.parse(userAgent)

  if (getObject) {
    return data
  }

  return [
    data.platform.type && data.platform.type.replace(/^./, (match) => match.toUpperCase()),
    data.platform.vendor,
    data.platform.model,
    data.os.name,
    data.os.version,
    data.browser.name && `- ${data.browser.name}`,
  ]
    .filter(Boolean)
    .join(' ')
}
