import React, { useEffect } from 'react'

import { useApiData } from '@hozana/api/hooks/useApiData'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { getFirstQueryValue } from '@hozana/router/functions'
import { cookie } from '@hozana/storage/cookies'
import { GTM } from '@hozana/tracking/gtm'

import { closeAlertBanner, openAlertBanner } from 'app/actions'
import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'
import { createCookieName } from 'app/managers/cookies/functions'
import { getAlertBannerQuery } from 'app/queries'
import { isAlertBannerOpen } from 'app/selectors'
import { PAGE } from 'routes/constants'

import { AlertBanner } from './AlertBanner'

export const AlertManager: React.FC = () => {
  const alertBannerOpen = useSelector((state) => isAlertBannerOpen(state))
  const { pathname, query } = useRouter()
  const dispatch = useDispatch()

  const [alertBanner] = useApiData(() => ({ query: getAlertBannerQuery() }), [])
  const BANNER_COOKIE_NAME = createCookieName(`${COOKIES.hideAlertBanner}_${alertBanner?.title}`)
  const hideBanner = (getFirstQueryValue(query.withBanner) || cookie.load(COOKIES.withBanner)) === 'false'

  useEffect(() => {
    if (
      alertBanner?.id &&
      // if user has no cookie
      !cookie.load(BANNER_COOKIE_NAME) &&
      // Do not display the banner on SocialAuthPage
      !([PAGE.AUTH_SOCIAL] as PAGE[]).includes(pathname)
    ) {
      // add isAlertBannerOpen condition to prevent error
      if (!alertBannerOpen) {
        // Dispatch to store
        dispatch(openAlertBanner())
        // tracking
        GTM.trackEvent(GTM.EVENTS.ALERT_BANNER_DISPLAYED, { bannerName: alertBanner?.title })
      }
    }
  }, [BANNER_COOKIE_NAME, alertBanner?.id, alertBanner?.title, alertBannerOpen, dispatch, pathname])

  const onClose = () => {
    // tracking
    GTM.trackEvent(GTM.EVENTS.ALERT_BANNER_CLOSED, { bannerName: alertBanner?.title })
    // save cookie
    cookie.save(BANNER_COOKIE_NAME, true, COOKIES_CONFIG.ONE_MONTH)
    // add isAlertBannerOpen condition to prevent error
    if (alertBannerOpen) {
      // Dispatch to store
      dispatch(closeAlertBanner())
    }
  }

  const onClick = () => GTM.trackEvent(GTM.EVENTS.ALERT_BANNER_CLICKED, { bannerName: alertBanner?.title })

  return alertBanner?.id && alertBannerOpen && !hideBanner ? (
    <AlertBanner to={alertBanner.link} onClick={onClick} onClose={onClose}>
      {alertBanner.text}
    </AlertBanner>
  ) : null
}
