import { useEffect } from 'react'
import { useIsMounted } from 'usehooks-ts'

import { useSyncedRef } from '@hozana/hooks/useSyncedRef'

/**
 * ### useTimeout ###
 *
 * Run the callback once the delay has passed.
 *
 * Inspired from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback Callback that can be changed: it won't restart the timer
 * @param delay Delay in ms: set it to 0 to trigger the callback immediately
 * @param disabled (optional: default to false) The timer will be canceled and will remain unstarted if disable is set to true
 */
export const useInterval = (callback: VoidFunction, delay: number, disabled = false): void => {
  const callbackRef = useSyncedRef(callback)
  const isMounted = useIsMounted()

  // Set up the interval
  useEffect(() => {
    if (!disabled) {
      const tick = () => {
        if (callbackRef.current && isMounted()) {
          callbackRef.current()
        }
      }
      if (delay) {
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
      } else if (delay === 0) {
        tick()
      }
    }
    return undefined
  }, [delay, isMounted, disabled, callbackRef])
}
