import styled from 'styled-components'

import { mergeStyles } from '@hozana/styling/mergeStyles'
import { TDefaultToZeroProps, defaultToZero } from '@hozana/styling/responsive'

import { Div, TDivProps } from './Div'

export type TAbsoluteDivProps = TDefaultToZeroProps<'top' | 'bottom' | 'left' | 'right'> & TDivProps

export const AbsoluteDiv = styled(Div).attrs<TAbsoluteDivProps, TAbsoluteDivProps>(
  ({ position = 'absolute', style }) => ({
    style: mergeStyles(style, { position }),
  }),
)`
  ${({ top }) => top && defaultToZero('top', top)};
  ${({ bottom }) => bottom && defaultToZero('bottom', bottom)};
  ${({ left }) => left && defaultToZero('left', left)};
  ${({ right }) => right && defaultToZero('right', right)};
`
