import React, { useState } from 'react'
import styled from 'styled-components'

import { FixedDiv } from 'elements/layout/FixedDiv'
import { Li } from 'elements/layout/Li'
import { Ul } from 'elements/layout/Ul'
import { P } from 'elements/text/P'

import { CONTENT_ID, HEADER_ID, NAVIGATION_ID } from 'app/constants'

type TStyledFixedDivProps = {
  displayed?: boolean
}

const StyledFixedDiv = styled(FixedDiv)<TStyledFixedDivProps>`
  height: ${(props) => (props.displayed ? 'auto' : '0px')};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: ${(props) => props.theme.borderRadius.normal};
  overflow: hidden;
`

const StyledA = styled.a`
  &,
  &:visited {
    color: ${(props) => props.theme.colors.yellow};
    text-decoration: none;
    cursor: pointer;
    /* To prevent the blue highlighting in Chrome on mobile */
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.blue};
    & p {
      font-weight: bold;
    }
  }
`

type TAvoidanceNavigationProps = {
  toggleNavigation?: VoidFunction
}

export const AvoidanceNavigation: React.FC<TAvoidanceNavigationProps> = ({ toggleNavigation }) => {
  const [displayed, display] = useState(false)

  return (
    <StyledFixedDiv
      as="nav"
      role="navigation"
      zIndex="avoidanceNavigation"
      top="70px"
      left="1em"
      displayed={displayed}
      p={displayed && '5px'}
      bg="white"
      maxW="250px"
      w="100%"
    >
      <Ul>
        <Li>
          <StyledA href={`#${HEADER_ID}`} onFocus={() => display(true)} onBlur={() => display(false)}>
            <P p="10px">trans:common:app.navigation.main</P>
          </StyledA>
        </Li>
        <Li>
          <StyledA
            href={`#${NAVIGATION_ID}`}
            onFocus={() => display(true)}
            onBlur={() => display(false)}
            onClick={toggleNavigation}
          >
            <P p="10px">trans:common:app.navigation.secondary</P>
          </StyledA>
        </Li>
        <Li>
          <StyledA href={`#${CONTENT_ID}`} onFocus={() => display(true)} onBlur={() => display(false)}>
            <P p="10px">trans:common:app.avoidance-navigation.content</P>
          </StyledA>
        </Li>
      </Ul>
    </StyledFixedDiv>
  )
}
