/**
 * All reducers should be added here so that Redux can use them.
 *
 * See https://github.com/hozana/hozana.org-v2/blob/develop/docs/3-functionnalities/redux.md
 */
import { produce } from 'immer'
import type { AnyAction } from 'redux'

import { apiReducer } from '@hozana/api/reducer'
import type { TApiState } from '@hozana/api/types'
import formReducer from '@hozana/form/reducer'
import { GlobalActionTypes } from '@hozana/redux/constants'

import { appReducer } from 'app/reducer'
import type { TState } from 'config/types'

import { intentionApiReducer } from 'modules/intention/apiReducer'
import { intentionReducer } from 'modules/intention/reducer'

const fullApiReducer = (state: TApiState, action: AnyAction) => {
  const apiState = apiReducer(state, action)
  return produce(apiState, (newState) => {
    newState.entities.intention = intentionApiReducer(newState.entities.intention, action)
  })
}

const reducers = {
  api: fullApiReducer,
  app: appReducer,
  intention: intentionReducer,
  form: formReducer,
}

export const getInitialState = (): TState =>
  Object.entries(reducers).reduce(
    (acc, [key, reducer]) => ({ ...acc, [key]: reducer(undefined, { type: GlobalActionTypes.NONE }) }),
    {} as TState,
  )

export default reducers
