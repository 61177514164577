import { produce } from 'immer'

import { GlobalActionTypes } from '@hozana/redux/constants'
import type { TGlobalAction } from '@hozana/redux/types'

import { IntentionActionTypes } from './constants'
import type { TIntentionAction, TIntentionState } from './types'

const INITIAL_STATE: TIntentionState = {
  widgetIntention: {
    id: null,
    hash: null,
  },
}

export const intentionReducer = (state = INITIAL_STATE, action: TIntentionAction | TGlobalAction): TIntentionState =>
  action.type === GlobalActionTypes.CLEAR_STATE
    ? INITIAL_STATE
    : produce(state, (newState) => {
        switch (action.type) {
          case IntentionActionTypes.SET_WIDGET_INTENTION:
            newState.widgetIntention = {
              id: action.id,
              hash: action.hash,
            }
            break

          default:
            break
        }
      })
