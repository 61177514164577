import { DefaultTheme } from 'styled-components'

import type { TQueryConstructor } from '@hozana/api/types'
import type { TDateTime } from '@hozana/dates/types'

import { STATS } from 'modules/home/constants'

export type TGetStatisticsData = {
  latestUsers: string[]
} & Record<STATS, number>

export const getStatisticsQuery: TQueryConstructor<void, TGetStatisticsData> = () => ({
  queryKey: 'statistics',
  url: '/statistics',
  headers: { 'Cache-Control': 'public' },
})

type TAlertBanner = {
  startAt: TDateTime
  endAt: TDateTime
  text: string
  link: string
  firstColor: keyof DefaultTheme['colors']
  secondColor: keyof DefaultTheme['colors']
  id: number
  title: string
  lang: string
}

export const getAlertBannerQuery: TQueryConstructor<void, TAlertBanner> = () => ({
  queryKey: 'alertBanner',
  url: '/alert-banner',
  headers: { 'Cache-Control': 'public' },
})
