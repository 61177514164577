import type { TEntities, TEntityType } from 'config/entities'
import type { TState } from 'config/types'

import { getQueryKey } from './functions'
import type { TQuery, TStoredQuery } from './types'

const emptyArray = [] as unknown[]

// Queries

export const selectQueryByKey = <ST extends TStoredQuery>(state: TState, queryKey: string): ST | undefined =>
  state.api.queries[queryKey] as ST

export const selectQuery = <Query extends TQuery>(state: TState, query: Query): TStoredQuery<Query> | undefined =>
  selectQueryByKey(state, getQueryKey(query))

export const selectQueryData = <Query extends TQuery>(state: TState, query: Query): Query['__DATA__'] =>
  selectQuery(state, query)?.data || null

// Returns true if the given query is currently loading
// If the query has not been triggered, false will be returned
export const isQueryPending = (state: TState, query: TQuery): boolean => {
  const queryData = selectQuery(state, query)
  if (!queryData) return false
  return queryData.loading
}

// Entities

export const selectEntity = <ET extends TEntityType>(
  state: TState,
  entityType: ET,
  entityId: number,
): TEntities[ET] | undefined => entityId && state?.api?.entities[entityType]?.[entityId]

export const selectEntities = <ET extends TEntityType>(
  state: TState,
  entityType: ET,
  entitiesArray: number[],
): TEntities[ET][] =>
  entitiesArray && entitiesArray.length > 0
    ? entitiesArray.map((entityId) => selectEntity(state, entityType, entityId))
    : (emptyArray as TEntities[ET][])

export const selectAllEntities = <ET extends TEntityType>(state: TState, entityType: ET): TEntities[ET][] =>
  state.api.entities[entityType] ? Object.values(state.api.entities[entityType]) : (emptyArray as TEntities[ET][])

export const selectMaintenanceStatus = (state: TState): boolean => state.api.isMaintenanceActive

export const selectMissingI18nFallbackNamespaces = (state: TState): boolean => state.api.fetchI18nFallbackNamespaces
