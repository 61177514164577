export enum NOTIFICATION_TYPE {
  COMMUNITY_ACCEPTED = 'community-accepted',
  COMMUNITY_REJECTED = 'community-rejected',
  COMMUNITY_JOINED = 'community-joined',
  NEW_PRAYER_ON_INTENTION = 'new-prayer-on-intention',
  NEW_PRAYER_ON_BIRTHDAY = 'new-prayer-on-birthday-intention',
  NEW_PRAYER_ON_ARRIVAL = 'new-prayer-on-arrival-intention',
  PROMOTED_TO_ROLE_ON_COMMUNITY = 'promoted-to-role-on-community',
  INTENTION_FINISHED = 'intention-finished',
  BIRTHDAY_INTENTION_FINISHED = 'birthday-intention-finished',
  ARRIVAL_INTENTION_FINISHED = 'arrival-intention-finished',
  NEW_COMMENT_ON_PUBLICATION = 'new-comment-on-publication',
  ON_INTENTION_WATCHMAN = 'prayer-watchman',
  /** Artificial notification created by the frontend */
  JOIN_COMMUNITY = 'join-community',
  /** Artificial notification created by the frontend */
  LEAVE_COMMUNITY = 'leave-community',
}

export enum NOTIFICATION_CATEGORY {
  GENERAL = 'general',
  PUBLICATIONS = 'publications',
  ANNOUNCEMENTS = 'announcements',
}
export enum NOTIFICATION_MEDIUM {
  EMAIL = 'email',
  PUSH = 'push',
}
