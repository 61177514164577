import React from 'react'
import { DefaultTheme } from 'styled-components'

import { useAttributes } from '@hozana/dom/hooks/useAttributes'

import { BorderedDiv } from 'elements/layout/BorderedDiv'
import { Shadow } from 'elements/layout/Shadow'
import { Icon } from 'elements/media/Icon'
import { Link, TLinkProps } from 'elements/text/Link'
import { P } from 'elements/text/P'

import { ICON } from 'config/icons'

type TBlockLinkProps = {
  icon?: ICON
  iconColor?: keyof DefaultTheme['colors']
  bg: keyof DefaultTheme['colors']
  hoverBg: keyof DefaultTheme['colors']
} & TLinkProps

export const BlockLink: React.FC<TBlockLinkProps> = ({ children, icon, bg, hoverBg, iconColor, ...otherProps }) => (
  <Link color={bg} hoverColor={hoverBg} w="100%" {...useAttributes()} {...otherProps}>
    <Shadow borderRadius="10px" y="4px" depth="6px" opacity="0.2" w="100%">
      <BorderedDiv bg="currentColor" borderRadius="10px" p="20px 1em" align="center">
        {icon && <Icon name={icon} color={iconColor ?? 'white'} size="2.5em" />}
        <P color="white" bold fontSize="1em" m="5px 0 0">
          {children}
        </P>
      </BorderedDiv>
    </Shadow>
  </Link>
)
