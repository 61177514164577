import { useContext } from 'react'

import { AttributesContext, TAttributesContext } from '../components/AttributesProvider'

/**
 * ### useAttributes ###
 *
 * To use with AttributesProvider
 */
export const useAttributes = () => useContext<TAttributesContext>(AttributesContext)
