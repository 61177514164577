import styled, { css } from 'styled-components'

import { FixedDiv, TFixedDivProps } from './FixedDiv'

export type TOverlayProps = TFixedDivProps & {
  noPointerEvents?: boolean
}

export const Overlay = styled(FixedDiv)<TOverlayProps>`
  ${({ noPointerEvents }) =>
    noPointerEvents &&
    css`
      pointer-events: none;
    `}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`

Overlay.defaultProps = {
  left: true,
  right: true,
  top: true,
  bottom: true,
  bg: 'fadedBlack20',
  zIndex: 'overlay',
}
