import React from 'react'
import { DefaultTheme } from 'styled-components'

import { AbsoluteDiv, TAbsoluteDivProps } from 'elements/layout/AbsoluteDiv'
import { BorderedDiv } from 'elements/layout/BorderedDiv'
import { Span } from 'elements/layout/Span'
import { Text } from 'elements/text/Text'

export type TBadgeProps = {
  number?: number
  isDot?: boolean
  bg?: keyof DefaultTheme['colors']
  borderColor?: keyof DefaultTheme['colors']
  prefixText?: string
} & Pick<TAbsoluteDivProps, 'top' | 'right'>

export const Badge: React.FC<TBadgeProps> = ({
  isDot = false,
  number,
  children,
  bg = 'orange',
  borderColor = 'white',
}) => (
  <Span>
    <span>{children}</span>
    {number > 0 &&
      (isDot ? (
        <AbsoluteDiv top="-5px" right="-8px">
          <BorderedDiv
            borderWidth="2px"
            borderColor={borderColor}
            align="middleCenter"
            bg={bg}
            borderRadius="50px"
            w="1em"
            h="1em"
          />
        </AbsoluteDiv>
      ) : (
        <AbsoluteDiv top="-10px" left="14px">
          <BorderedDiv
            borderWidth="2px"
            borderColor={borderColor}
            align="middleCenter"
            bg={bg}
            borderRadius="50px"
            p="2px 6px"
            minW="20px"
          >
            <Text bold color="white" fontSize="13px" lineHeight="16px" nowrap>
              {number}
            </Text>
          </BorderedDiv>
        </AbsoluteDiv>
      ))}
  </Span>
)
