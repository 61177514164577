import { buildQueryString } from '@hozana/api/functions'

import { SHARE_TYPES } from './constants'

export type TCounts = {
  facebook?: number
  twitter?: number
  pinterest?: number
  total?: number
  whatsapp?: number
  messenger?: number
  copylink?: number
  email?: number
}

export const getSharesCountTotal = (counts: TCounts): number =>
  counts ? counts.facebook + counts.twitter + counts.pinterest : 0

export const getSocialShareUrl = (
  medium: string,
  url: string,
  message: string,
  pictureUrl: string,
  isPopup = true,
): string => {
  switch (medium) {
    case SHARE_TYPES.FACEBOOK: {
      return `https://www.facebook.com/dialog/share?${buildQueryString({
        app_id: CONF_KEYS.FB_ID,
        href: url,
        message,
        display: isPopup ? 'popup' : 'page',
        redirect_uri: isPopup
          ? undefined
          : CONF_KEYS.HOME + '/fb-share-redirect?url=' + encodeURIComponent(window.location.href),
      })}`
    }

    case SHARE_TYPES.TWITTER: {
      return `https://twitter.com/intent/tweet?${buildQueryString({ url, text: message })}`
    }

    case SHARE_TYPES.PINTEREST: {
      return `https://www.pinterest.com/pin/create/button/?${buildQueryString({
        url,
        description: message,
        media: pictureUrl,
      })}`
    }

    case SHARE_TYPES.WHATSAPP: {
      return `https://api.whatsapp.com/send?${buildQueryString({ text: message + ' ' + url })}`
    }

    case SHARE_TYPES.MESSENGER: {
      return `https://www.facebook.com/dialog/send?${buildQueryString({
        app_id: CONF_KEYS.FB_ID,
        link: url,
        display: 'popup',
        redirect_uri: CONF_KEYS.HOME + '/fb-share-redirect?url=' + encodeURIComponent(window.location.href),
      })}`
    }

    default:
      return ''
  }
}
