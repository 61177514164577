import { selectQuery, selectQueryByKey } from '@hozana/api/selectors'
import { TQuery } from '@hozana/api/types'
import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { useSelector } from '@hozana/redux/hooks'

import { useUser } from 'modules/user/hooks/useUser'

/**
 * While a page is statically generated, the request is fetched with no user:
 * its user specific properties are not up to date.
 *
 * ⚠️ If `useRequestUpdate` is not executed, the request will never be up-to-date.
 * You can `useRequestUpdate` instead to make sure it is.
 */
export const useIsRequestUpToDate = (queryOrQueryKey?: TQuery | string): boolean => {
  const storedQuery = useSelector((state) =>
    typeof queryOrQueryKey === 'object'
      ? selectQuery(state, queryOrQueryKey)
      : selectQueryByKey(state, queryOrQueryKey),
  )
  const isHydrated = useIsHydrated()
  const { isLogged, loading } = useUser()

  return !storedQuery || (isHydrated && ((!loading && !isLogged) || !storedQuery.meta.ssgFetched))
}
