/*
 * Mapping to the old media-queries
 * --mobile = xs
 * --large-mobile = sm
 * --pad = md
 * --small-desktop = lg
 * --desktop = xl
 */
const theme = {
  breakpoints: { xs: '0px', sm: '540px', md: '820px', lg: '1060px', xl: '1200px' },
  breakpointsMax: { xs: '539px', sm: '819px', md: '1059px', lg: '1199px', xl: '9000px' },
  fontFamily: {
    openSans: "'Nunito', Helvetica, Arial, sans-serif",
    montserrat: "'Nunito', 'Open Sans', Helvetica, Arial, sans-serif",
    noto: "'Noto Serif', 'Open Sans', Helvetica, Arial, sans-serif",
    inherit: 'inherit',
  },
  colors: {
    /* Name of colors - new design system */
    /* start */

    primaryTextContrastDefault: '#8B3F0C',
    secondaryTextContrastHigh: '#062740',
    secondaryTextContrastLow: '#5591BF',
    link: '#3283E3',

    /* end */

    darkBlue: '#063A56',
    yellow: '#F7A02d',
    whiteYellow: '#FEFBED',
    blackYellow: '#572C14',
    darkRed: '#D80033',

    lightPurple: '#EFDBF3',
    purple: '#762D87',
    darkPurple: '#4a1755',
    whiteBlue: '#edf7ff',
    lightBlue: '#E9F5FB',
    mediumBlue: '#2E9AD4',
    blue: '#23719B',
    lightGreen: '#DFF0D8',
    green: '#3ea542',
    darkGreen: '#3C763D',
    darkOrange: '#F74902',
    whiteOrange: '#FFEFD9',
    orange: '#ED7600',
    lightRed: '#ffe2e2',
    red: '#E13434',

    brown: '#583B2D',
    highYellow: '#f7dc2d',
    lightYellow: '#ffca56',

    facebook: '#3B5998',
    facebookDark: '#2F4A84',
    google: '#DD4B3E',
    googleDark: '#BB3A2E',
    twitter: '#27AAE0',
    twitterDark: '#0c7abf',
    pinterest: '#E2012F',
    pinterestDark: '#CD181F',
    whatsapp: '#41C452',
    whatsappDark: '#009688',
    messenger: '#448AFE',
    messengerDark: '#3B5998',
    youtube: '#FF0000',
    instagram: '#3798EF',
    tiktok: '#16181A',

    black: '#16181A',
    black70: '#444A4D',
    black50: '#747A80',
    black30: '#A8AFB3',
    black10: '#E1E4E6',
    background: '#f7f7f7',
    white: '#FFF',
    fadedWhite20: 'rgba(255,255,255, 0.2)',
    fadedWhite50: 'rgba(255,255,255, 0.5)',
    fadedWhite70: 'rgba(255,255,255, 0.7)',
    fadedWhite10: 'rgba(255,255,255, 0.1)',
    fadedBlack50: 'rgba(0,0,0,0.5)',
    fadedBlack20: 'rgba(0,0,0,0.2)',
    fadedBlack10: 'rgba(0,0,0,0.10)',
    fadedBlack04: 'rgba(0,0,0,0.04)',
    fadedBlue10: 'rgba(6,58,86,0.10)',
    fadedBlue40: 'rgba(6,58,86,0.40)',
    transparent: 'transparent',
    currentColor: 'currentColor',

    /* Meditatio */
    meditatio1: '#24326d',
    meditatio2: '#50a98e',
    nuit_pour_la_mission_1: '#F7AD57',
    nuit_pour_la_mission_2: '#C31644',
    nuit_pour_la_mission_3: '#133670',
  },
  zIndex: {
    avoidanceNavigation: 1000,
    flashMessage: 180,
    popinClose: 163,
    popinHeader: 162,
    popinImage: 161,
    popin: 160,
    popover: 150,
    langBanner: 140,
    publicationBanner: 135,
    cookieBanner: 130,
    searchForm: 120,
    mobileBannerText: 101,
    mobileBanner: 100,
    photoSlider: 90,
    intentionsPanelButton: 73,
    intentionsPanel: 72,
    menu: 71,
    header: 70,
    subHeaderOverlay: 69,
    floatingButton: 60,
    overlay: 50,
    subMenuOverlay: 40,
    stickyTabs: 30,
    error: 10,
    zIndex2: 2,
    zIndex1: 1,
    default1: 0,
    background: -1,
  },
  sizes: {
    header: '92px',
    headerWithAlertBanner: { xs: '188px', md: '156px' },
    alertBanner: { xs: '96px', md: '64px' },
    headerItem: '60px',
    subHeader: '60px',
    widget: {
      linkAllPubli: '35px',
      communityCreator: '48px',
    },
  },
  borderRadius: {
    small: '3px',
    normal: '6px',
    medium: '10px',
    big: '1em',
    giant: '50px',
  },
  transitions: {
    quick: '100ms',
    default: '300ms',
    slow: '500ms',
  },
} as const

export default theme
