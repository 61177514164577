import React from 'react'

export type TAttributesContext = Record<string, any>

export const AttributesContext = React.createContext<TAttributesContext>(null)

export type TAttributesProviderProps = { attributes: TAttributesContext }

/**
 * ### AttributesProvider ###
 * To provide HTML attributes to childs
 *
 * @param attributes An object to pass HTML attributes.
 * @param children Components
 */
export const AttributesProvider: React.FC<TAttributesProviderProps> = ({ attributes, children }) => (
  <AttributesContext.Provider value={attributes}>{children}</AttributesContext.Provider>
)
