export enum ApiActionTypes {
  FETCH_API_TRIGGER = '@query/FETCH_API_TRIGGER',
  FETCH_API_SUCCESS = '@query/FETCH_API_SUCCESS',
  FETCH_API_FAILURE = '@query/FETCH_API_FAILURE',
  CLEAR_GLOBAL_API_ERROR = '@query/CLEAR_GLOBAL_API_ERROR',
  // Queries
  ADD_QUERY_DATA = 'ADD_QUERY_DATA',
  REMOVE_FROM_QUERY_DATA = 'REMOVE_FROM_QUERY_DATA',
  REMOVE_QUERY = 'REMOVE_QUERY',
  // Entities
  UPDATE_ENTITY = 'UPDATE_ENTITY',
  UPDATE_ENTITIES = 'UPDATE_ENTITIES',
  PUSH_INTO_ENTITY_LIST = 'PUSH_INTO_ENTITY_LIST',
  REMOVE_FROM_ENTITY_LIST = 'REMOVE_FROM_ENTITY_LIST',
  REMOVE_MATCHING_ENTITY = 'REMOVE_MATCHING_ENTITY',
  INCREMENT_ENTITY_VALUE = 'INCREMENT_ENTITY_VALUE',
  DECREMENT_ENTITY_VALUE = 'DECREMENT_ENTITY_VALUE',
  ADD_ENTITY = 'ADD_ENTITY',
  REMOVE_ENTITY = 'REMOVE_ENTITY',
  MAINTENANCE_ACTIVATE = 'MAINTENANCE_ACTIVATE',
  MAINTENANCE_DEACTIVATE = 'MAINTENANCE_DEACTIVATE',
  FETCH_I18N_FALLBACK_NAMESPACE = 'FETCH_I18N_FALLBACK_NAMESPACE',
}
