import { useDispatch as rawUseDispatch, useSelector as rawUseSelector } from 'react-redux'

import type { TDispatch } from '@hozana/redux/types'

import type { TState } from 'config/types'

type TUseSelector = <TSelected = unknown>(
  selector: (state: TState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => TSelected

export const useSelector = rawUseSelector as TUseSelector
export const useDispatch = rawUseDispatch as () => TDispatch
