import type { TAction } from '@hozana/redux/types'
import { ROUTINE_PROMISE_ACTION } from '@hozana/routines/constants'
import type { TRoutine, TRoutineData } from '@hozana/routines/types'

export type TRoutinePromiseAtion<R extends TRoutine> = TAction<typeof ROUTINE_PROMISE_ACTION> & {
  payload: ReturnType<R['trigger']>['payload']
  meta: {
    routine: R
    reduxFormCompatible?: boolean
    defer: {
      resolve: (result?: TRoutineData<R>) => void
      reject: (message: string | Error) => void
    }
  }
}

export const routinePromise = <R extends TRoutine>({
  payload,
  meta,
}: Omit<TRoutinePromiseAtion<R>, 'type'>): TRoutinePromiseAtion<R> => ({
  type: ROUTINE_PROMISE_ACTION,
  payload,
  meta,
})
