import { API_PATHNAME as rawAPI_PATHNAME } from './api'
import { REWRITTEN_PAGES, STATIC_PAGES_REVALIDATE, PAGE as rawPAGE } from './pages'

export { REWRITTEN_PAGES, STATIC_PAGES_REVALIDATE }

export const API_PATHNAME = rawAPI_PATHNAME
export type API_PATHNAME = TObjectValues<typeof rawAPI_PATHNAME>

export const PAGE = rawPAGE
export type PAGE = TObjectValues<typeof rawPAGE>

export const IMMUTABLE_PREFIX = `/immutable/${CONF_KEYS.BUILD_ID}`
